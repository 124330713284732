// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// @ts-ignore
const pjson = require('../../package.json');
// @ts-ignore
const appConfig = require('../../app.config.json');

export const environment = {
  production: false,
  preproduction: true,
  name: 'preprod',
  mode: 'preproduction',
  APP_VERSION: pjson.version,
  APP_NAME: appConfig.appName,
  // STORAGE_URL: 'https://preprod.goforlife.ch/storage',
  STORAGE_URL: 'https://preprod.nomadstudio.ch/storage',
  // API_URL: 'https://preprod.goforlife.ch/api',
  API_URL: 'https://preprod.nomadstudio.ch/api',
  API_AUTH: 'app_g4l:odDtY5u0hQuq9j1bYpvMFldr4JfGis1Z',
  CACHE_REFRESH_DELAY_LONG: 3600000 * 24,
  CACHE_REFRESH_DELAY: 3000000,
  CACHE_REFRESH_DELAY_SHORT: 2000,
  REFRESH_DELAY: 3600000,
  LOCK_MAX_DURATION_DOC_2G: 180,
  LOCK_MAX_DURATION_DOC_3G: 180,
  LOCK_MAX_DURATION_DOC_4G: 120,
  LOCK_MAX_DURATION_DOC_WIFI: 80,
  LOCK_MAX_DURATION_DOC_ETHERNET: 60,
  LOCK_MAX_DURATION_2G: 60,
  LOCK_MAX_DURATION_3G: 60,
  LOCK_MAX_DURATION_4G: 40,
  LOCK_MAX_DURATION_WIFI: 30,
  LOCK_MAX_DURATION_ETHERNET: 20,
  REQUEST_TIME_OUT: 15000,
  GOOGLE_ANALYTICS_KEY: 'UA-100518118-1',
  AGENCY_ID: appConfig.agencyId,
  DEDICATED_APP: appConfig.dedicatedApp,
  FILE_FOLDER: appConfig.appName,
  SVG_FOLDERS: {
    INSURANCE_TYPES: 'insuranceTypes',
    SAFEBOX_TYPES: 'safeboxTypes',
    COMPANIES: 'companies',
    DOCUMENT_CATEGORIES: 'documentCategories',
    SLIDERS: 'sliders',
  },
  PREFIX_COUNTRY: [
    { value: '+41', text: 'CONSTANTS.PREFIX_COUNTRY.SWITZERLAND', selected: false },
    { value: '+49', text: 'CONSTANTS.PREFIX_COUNTRY.GERMANY', selected: false },
    { value: '+33', text: 'CONSTANTS.PREFIX_COUNTRY.FRANCE', selected: false },
    { value: '+43', text: 'CONSTANTS.PREFIX_COUNTRY.AUSTRIA', selected: false },
    { value: '+39', text: 'CONSTANTS.PREFIX_COUNTRY.ITALY', selected: false },
    { value: '+423', text: 'CONSTANTS.PREFIX_COUNTRY.LICHTENSTEIN', selected: false },
  ],
  CIVILITY: [
    { value: 'M', text: 'CONSTANTS.CIVILITY.MISTER', selected: false },
    { value: 'F', text: 'CONSTANTS.CIVILITY.MISS', selected: false },
  ],
  DAYS: ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
