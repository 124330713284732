import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { combineLatest, fromEvent, Observable, of, Subscription } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';

import { CustomerFamilyMember } from '../../../customer/models/customer.model';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { PolicyFO, Rate } from '../../models/policy.model';
import { Chart, ChartPolicy, PeriodicityTypes } from '../../models/budget.model';
import { Mandate } from '../../models/mandate.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { PolicyService } from '../../services/policy.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';

@Component({
  selector: 'gfl-policy-budget',
  templateUrl: './policy-budget.component.html',
  styleUrls: ['./policy-budget.component.scss'],
})
export class PolicyBudgetComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() acls: Acls;
  @Input() style: FrontTheme;

  private policies: { [id: string]: { [lang: string]: Array<PolicyFO | Mandate> } };
  private members: CustomerFamilyMember[];
  public showBudgetOptimized: 'YES' | 'NO';
  public showBudgetGraph: 'YES' | 'NO';
  public charts: Chart[];
  public sliderPoliciesHeight: number;
  public rowMainChartHeight: number;
  public baseColor: Array<any> = [];
  public bgColorChart = [];
  public colorChart = [];
  public selectedPolicyStyle = {
    color: 'white',
    border: '2px solid white',
    padding: '',
  };
  public minHeight = 10;
  public selectedPeriodFilter: PeriodicityTypes = PeriodicityTypes.Year;
  public periodicityTypes = PeriodicityTypes;
  public slidersHeight: number;
  public deltaColor = 30;
  public slidesOpts = {
    noSwipingClass: 'swiper-no-swiping',
  };
  public errorDisplay: boolean;
  public noDataDisplay: string;

  public isOptimisedSelectedFilter = false;

  private subscriptions: Subscription[] = [];
  public rates: { [id: string]: Rate } = { 1: { rate: 20, comment: '' } };
  public lang$: Observable<string>;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private store: StoreService,
    private customerSrv: CustomerService,
    private policySrv: PolicyService,
    private modalCtrl: ModalController,
    public platform: Platform,
    private alertCtrl: AlertController,
    public translate: TranslateService,
    private notificationSrv: NotificationService,
    private navCtrl: NavController,
    private constantSrv: ConstantService
  ) {
    this.showBudgetOptimized = 'NO'; // just show budget for phone
    this.showBudgetGraph = 'NO';
  }

  ngOnInit(): void {
    this.lang$ = this.store.getLang();
    this.setBaseColor();
    this.initialize();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.style && this.acls) {
      this.setBaseColor();
      this.initialize();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy() {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * @ignore
   */
  ngAfterViewInit(): void {
    // bind isOptimisedSelectedFilter property to showBudgetOptimized value
    const selectorButton = document.querySelectorAll('.display-selector ion-segment-button');
    const subscription = fromEvent(selectorButton, 'click').subscribe(() => {
      this.isOptimisedSelectedFilter = this.showBudgetOptimized === 'YES';
    });

    this.subscriptions.push(subscription);
  }

  /**
   * Close View Modal
   */
  public closeModal(): void {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * Open
   * @param policy policy object
   */
  public viewPolicy(policy: PolicyFO): void {
    this.translate.get(['INSURANCE.BUDGET.OPEN_POLICY', 'COMMON.BUTTON_YES', 'COMMON.BUTTON_NO']).subscribe(
      async result => {
        const alert = await this.alertCtrl.create({
          subHeader: result['INSURANCE.BUDGET.OPEN_POLICY'],
          buttons: [
            {
              text: result['COMMON.BUTTON_NO'],
              cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
              role: 'cancel',
            },
            {
              text: result['COMMON.BUTTON_YES'],
              cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
              handler: () => {
                if (!this.tools.isMobile()) {
                  this.modalCtrl
                    .dismiss()
                    .then(() => {
                      this.navCtrl.navigateForward('/policies', {
                        queryParams: {
                          policyId: policy.policy_id,
                        },
                      });
                    })
                    .catch(err => this.tools.error('/policies', err));
                } else {
                  this.navCtrl
                    .navigateForward('/policies/policy-detail/' + policy.policy_id)
                    .catch(err => this.tools.error('/policies/policy-detail/' + policy.policy_id, err));
                }
              },
            },
          ],
        });
        await alert.present();
      },
      err => {
        this.tools.error('SignupEmployeeCreatePage openEmployeeAlreadyExistsAlert()', err);
        this.notificationSrv.showError({ message: err });
      }
    );
  }

  /**
   * Initialize members and policies attributes
   */
  private initialize(): void {
    this.subscriptions.push(
      combineLatest([
        this.lang$,
        this.customerSrv.getMembers().pipe(
          filter(val => {
            return !_.isEmpty(val);
          })
        ),
        this.policySrv.getPolicies().pipe(
          filter(val => {
            return !_.isEmpty(val);
          })
        ),
      ])
        .pipe(
          mergeMap(([lang, members, policies]) => {
            const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
            this.members = _.cloneDeep(members);

            this.policies = {};

            // remove employee from members in pro mode
            this.members = _.filter(this.members, member => {
              return member.customer_type_id !== CUSTOMER_TYPE_ID_EMPLOYEE;
            });

            // in pro mode, we only want to display one graph for each pro
            _.forEach(this.members, member => {
              this.policies[member.id] = this.policies[member.id] || {};
              this.policies[member.id][lang] = (policies[member.id] && policies[member.id][lang]) || [];
            });

            _.forEach(this.baseColor, color => {
              this.colorChart.push(this.lightenDarkenColor(color, -30));
              this.bgColorChart.push(this.lightenDarkenColor(color, 30));
            });

            return of(this.setCharts(lang));
          }),
          catchError(err => {
            this.errorDisplay = true;
            throw err;
          })
        )
        .subscribe(
          () => {},
          err => {
            this.tools.log('PolicyBudgetComponent initialize', err);
          }
        )
    );
  }

  /**
   * Set Chart height
   * @param lang selected language
   */
  public setChartHeight(lang) {
    const q = setInterval(() => {
      const height =
        document.querySelector('.chart-section-budget') && document.querySelector('.chart-section').clientHeight;
      if (height) {
        this.slidersHeight = height;
        this.setCharts(lang);
        clearInterval(q);
      }
    }, 100);
  }

  /**
   * calculate the height of slides
   * @param lang selected language
   */
  public setCharts(lang: string) {
    // we use a try catch block in  order
    //  to wait for the dom to be ready
    try {
      this.charts = this.buildCharts(lang);
      this.noDataDisplay = this.charts && !this.charts.length && 'COMMON.NO_DATA';
    } catch (e) {
      this.tools.error('setCharts', e);
      setTimeout(this.setCharts, 200);
    }
  }

  /**
   * Method called to fill in the charts object
   */
  private buildCharts(lang: string) {
    // TODO - Use 3D library instead

    const charts = [];

    this.sliderPoliciesHeight = document.getElementById('sliderPolicies').offsetHeight - 80;
    this.rowMainChartHeight = (this.sliderPoliciesHeight / 100) * 85;

    Object.keys(this.policies).forEach((id: string) => {
      const customerId = parseInt(id, 10);

      if ((this.members.length === 1 && customerId !== 0) || this.members.length > 1) {
        if (this.policies[customerId][lang].length > 0) {
          // compute total prime
          let totalPrime = 0;
          let totalReduction = 0;
          const member = _.find(this.members, { id: customerId });
          const userName = (member && member.first_name) || '';

          _.forEach(this.policies[customerId][lang], (policy: PolicyFO) => {
            const insuranceTypeId = policy.insurance_type_id;
            const reductionRate = (this.rates[insuranceTypeId] && this.rates[insuranceTypeId].rate) || 0;
            if (policy.policy_premium) {
              totalPrime += policy.policy_premium;
              policy.reduction = reductionRate !== 0 ? (policy.policy_premium / 100) * reductionRate : 0;
              totalReduction += policy.reduction;
            }
          });

          if (!totalPrime) {
            return charts;
          }

          // Build the chart object
          const chart: Chart = {
            name: userName,
            id: customerId,
            totalPrime,
            totalReduction,
            averageReduction: Math.ceil((totalReduction / totalPrime) * 100),
            totalPrimeReduct: totalPrime - totalReduction,
            selectedPolicy: {},
            policies: [],
          };

          // sort by premium value
          this.policies[customerId][lang].sort((policyA: PolicyFO, policyB: PolicyFO) => {
            return policyA.policy_premium - policyB.policy_premium;
          });

          // Go through all the policies
          let colorIndex = 0;
          _.forEach(this.policies[customerId][lang], (policy: PolicyFO) => {
            if (policy.policy_premium) {
              const expirationYear =
                policy.policy_end_contract_date && parseInt(policy.policy_end_contract_date.split('-')[0], 10);
              const actualYear = new Date().getFullYear();

              chart.policies.push({
                prime: policy.policy_premium,
                reduction: policy.reduction,
                basePrime: policy.policy_premium,
                label: policy.insurance_type_name,
                company: policy.company ? policy.company.name : '',
                insurance: policy.insurance_type_id,
                value: policy.policy_premium,
                color: this.baseColor[(colorIndex + 1) % this.baseColor.length],
                colorIndex,
                baseStyle: {
                  'background-color': this.bgColorChart[(colorIndex + 1) % this.bgColorChart.length],
                  color: this.colorChart[(colorIndex + 1) % this.colorChart.length],
                  border: '',
                  padding: '0px',
                },
                colorStyle: {
                  'background-color': this.bgColorChart[(colorIndex + 1) % this.bgColorChart.length],
                  color: this.colorChart[(colorIndex + 1) % this.colorChart.length],
                  padding: '0px',
                },
                optimizedColorStyle: {
                  'background-color': this.bgColorChart[(colorIndex + 1) % this.bgColorChart.length],
                },
                borderStyle: {
                  border: '',
                },
                heightStyle:
                  ((this.rowMainChartHeight - 10) / 100) * Math.round((policy.policy_premium * 100) / totalPrime),
                // Minus 10 for the row padding
                height: Math.round((policy.policy_premium * 100) / totalPrime),
                expiration: policy.policy_end_contract_date ? policy.policy_end_contract_date.split('-')[0] : '-',
                expired: actualYear >= expirationYear,
                policy,
              });
              colorIndex++;
            }
          });
          chart.selectedPolicy = chart.policies[chart.policies.length - 1] as ChartPolicy;

          chart.selectedPolicy.colorStyle.color = this.selectedPolicyStyle.color;
          chart.selectedPolicy.borderStyle.border = this.selectedPolicyStyle.border;
          chart.selectedPolicy.colorStyle.padding = this.selectedPolicyStyle.padding;
          // Push the chart object
          charts.push(chart);
        }
      }
    });

    return charts;
  }

  /**
   * Get the style for the info bar at the bottom
   * @param chart chart to display
   * @param haveRightBorder true is right border
   * @param isLighten true if lighten
   */
  public getInfoStyle(chart, haveRightBorder, isLighten) {
    const resultStyle = {
      'background-color': chart.selectedPolicy.color,
    };

    if (isLighten === true) {
      resultStyle['background-color'] = this.lightenDarkenColor(chart.selectedPolicy.color, this.deltaColor);
    }
    if (haveRightBorder === true) {
      resultStyle['border-right'] = '2px solid' + this.lightenDarkenColor(chart.selectedPolicy.color, this.deltaColor);
    }
    return resultStyle;
  }

  /**
   * Return a lighter color
   * @param color color
   * @param amount lighter strength
   */
  public lightenDarkenColor(color, amount) {
    let usePound = false;

    if (color && color[0] === '#') {
      color = color.slice(1);
      usePound = true;
    }

    const num = parseInt(color, 16);

    // tslint:disable-next-line:no-bitwise
    let r = (num >> 16) + amount;

    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    // tslint:disable-next-line:no-bitwise
    let b = ((num >> 8) & 0x00ff) + amount;

    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    // tslint:disable-next-line:no-bitwise
    let g = (num & 0x0000ff) + amount;

    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    // tslint:disable-next-line:no-bitwise
    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
  }

  /**
   * Return the height of a policy row
   * @param chart chart to display
   * @param policy policy object
   */
  public getHeightByPolicy(chart, policy): string {
    const height = (this.slidersHeight * policy.prime) / chart.totalPrime;

    return height + 'px';
  }

  /**
   * Select the policy to displauy in the info bar
   * @param chart chart to display
   * @param policy policy object
   */
  public selectPolicy(chart, policy) {
    chart.selectedPolicy = policy;
  }

  /**
   * Get style for optimized circle
   * @param policy policy object
   */
  public getOptimizedCircleStyle(policy) {
    const diameter = 50;
    const width = document.querySelector('.columnChart').clientWidth;
    return {
      width: diameter + 'px',
      height: diameter + 'px',
      'background-color': 'white',
      'border-radius': '50px',
      border: '2px solid ' + this.baseColor[1],
      position: 'absolute',
      top: -diameter / 2 + 'px',
      left: (width - diameter - 10) / 2 + 'px',
      'font-size': '50%',
      color: this.baseColor[1],
    };
  }

  /**
   * Manage text-align
   * @param policy policy object
   * @param left true if left
   */
  public getMarginLabel(policy, left = false): string {
    let margin: any = { 'text-align': 'end' };
    if (left) {
      margin = { 'text-align': 'initial' };
    }

    return margin;
  }

  /**
   * Method called to update policies' values on charts
   * @param periodicity selectPeriodFilter value
   */
  public updateChartByPeriodFilter(periodicity: PeriodicityTypes) {
    // Go through all the charts
    _.forEach(this.charts, chart => {
      // Init return value
      let totalPrime = 0;

      // For each chart, go through all the policies
      _.forEach(chart.policies, policy => {
        // If monthly, divide per 12
        if (periodicity === PeriodicityTypes.Month) {
          policy.prime = policy.basePrime / 12;
        } else {
          policy.prime = policy.basePrime;
        }

        // Add it to the total
        totalPrime += policy.prime;
      });

      // Update chart total
      chart.totalPrime = totalPrime;
    });
  }

  /**
   * set all colors to be used
   */
  private setBaseColor() {
    this.baseColor = _.filter(this.style, (item, key) => {
      const regExp = new RegExp('^color_budget_');
      return regExp.test(key);
    });
  }
}
