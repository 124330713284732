import { Component } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'gfl-policy-documents-subheader-tablet',
  templateUrl: './policy-documents-subheader-tablet.component.html',
  styleUrls: ['./policy-documents-subheader-tablet.component.scss'],
})
export class PolicyDocumentsSubheaderTabletComponent {
  constructor(public navCtrl: NavController) {}

  public goBack() {
    this.navCtrl.navigateRoot('/policies').then(() => {});
  }
}
