import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { AclsService } from '../../gfl-services/acls.service';
import { ContactsService } from '../../../contacts/services/contacts.service';
import { Acls } from '../../gfl-models/acls.model';

@Component({
  selector: 'gfl-footer-nav',
  templateUrl: './gfl-footer-nav.component.html',
  styleUrls: ['./gfl-footer-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflFooterNavComponent implements OnInit {
  @Input() activePage: string;
  public acls$: Observable<Acls>;
  public notificationsNb$: Observable<number>;

  /**
   * @ignore
   */
  constructor(private aclsSrv: AclsService, private contactsSrv: ContactsService, private cd: ChangeDetectorRef) {}

  /**
   * @ignore
   */
  ngOnInit() {
    this.initAcls();
    this.notificationsNb$ = this.contactsSrv.getNotificationsToDisplay().pipe(
      map(notifications => {
        return notifications.length;
      }),
      tap(() => this.cd.detectChanges())
    );
  }

  /**
   * Initialize permissions
   */
  private initAcls(): void {
    this.acls$ = this.aclsSrv.getAcls().pipe(shareReplay());
  }
}
