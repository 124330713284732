import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController, ModalController, NavController, Platform } from '@ionic/angular';
import { CameraOptions, CameraResultType } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { UploadDocument } from '../../../gfl-core/gfl-models/document.model';
import { PolicyFO } from '../../models/policy.model';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { RefundService } from '../../services/refund.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';

@Component({
  selector: 'gfl-policy-refund-add',
  templateUrl: './policy-refund-add.component.html',
  styleUrls: ['./policy-refund-add.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyRefundAddComponent {
  public refundAddForm: FormGroup;
  public documents: Array<UploadDocument> = [];
  public cameraOptions: CameraOptions;
  readonly isNative: boolean;

  @ViewChild('inputFileCamera', { static: false }) inputFileCamera: ElementRef;
  @Input() policy: PolicyFO;
  @Input() isRefundPage: boolean;
  @Input() style: FrontTheme;
  @Input() acls: Acls;
  @Output() refundSaved = new EventEmitter<any>();

  /**
   * @ignore
   */
  constructor(
    public platform: Platform,
    private navCtrl: NavController,
    private fb: FormBuilder,
    private loadingCtrl: LoadingController,
    private notificationSrv: NotificationService,
    private translate: TranslateService,
    private refundSrv: RefundService,
    private modalCtrl: ModalController,
    private documentSrv: DocumentService,
    public tools: ToolsService,
    private cd: ChangeDetectorRef
  ) {
    this.isNative = this.tools.isNative();
    this.cameraOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      // encodingType: this.camera.EncodingType.JPEG,
      // mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      height: 1800,
      width: 1350,
    };
    this.initForm();
  }

  public onDocumentsChanges() {
    this.cd.detectChanges();
  }

  /**
   * Close the modal !
   */
  public closeModal() {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * Save new refund
   */
  public onSaveRefund() {
    if (!this.documents.length) {
      return this.notificationSrv.showError({
        message: 'INSURANCE.REFUND.ERROR_PICTURE',
      });
    }

    this.tools.showLoader();

    // define payload
    const newRefundRequest = {
      policy_id: this.policy.policy_id,
      ...this.refundAddForm.value,
    };

    this.refundSrv
      .putRefund(newRefundRequest, this.policy.user_id)
      .pipe(
        switchMap((result: { refund: { id: number }; success: boolean }) => {
          // add documents to BO
          return this.saveDocuments(result.refund.id, this.policy.user_id);
        }),
        finalize(() => this.tools.hideLoader())
      )
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({ message: 'INSURANCE.REFUND.NOTIF_DEMAND_SAVED' });
          this.refundSaved.next();
        },
        err => {
          this.tools.error('InsuranceRefundAddComponent onSaveRefund', err);
          this.notificationSrv.showError({
            message: 'COMMON.NOTIF_ERROR',
          });
        }
      );
  }

  /**
   * Initialize the form
   */
  private initForm() {
    // form definition
    this.refundAddForm = this.fb.group({
      invoice_date: ['', Validators.required],
      amount_base: [''],
      amount_complementary: [''],
    });
  }

  /**
   * Save documents to BO
   * If first document is a picture type, we generate a pdf from documents array
   * @param refundId refund's id
   * @param customerId customer's id
   */
  private saveDocuments(refundId: number, customerId: number): Observable<any> {
    const regExp = /data:image/;
    const isPicture = regExp.test(this.documents[0].data);
    let document;

    // if (isPicture) {
    //   const data = this.documentSrv.generatePdfFromImages(this.documents);
    //
    //   document = {
    //     data,
    //     ext: 'pdf',
    //   };
    // } else {
    document = this.documents[0];
    // }

    return this.documentSrv.saveRefundDocument(refundId, document, customerId);
  }
}
