import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { GflThemeOptions } from '../../models/gfl-form-options.model';
import { GflModeDisplayType } from '../../models/gfl-form.model';
import { FrontTheme } from '../../../../gfl-core/gfl-models/agency.model';

@Component({
  selector: 'gfl-field-toggle-btn',
  templateUrl: './gfl-field-toggle-btn.component.html',
  styleUrls: ['./gfl-field-toggle-btn.component.scss'],
})
export class GflFieldToggleBtnComponent implements OnChanges {
  /**
   * initial value
   */
  @Input() value: string;

  /**
   * hide value and formatted value in order to display only label
   */
  @Input() hideValue: boolean;

  /**
   * the form implementing this field in two way binding
   */
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;

  /**
   * An array of validators to add to the formControl
   */
  @Input() validators: Array<ValidatorFn> = [];

  /**
   * Set type of displayed template between "mobile" and "tablet" options
   */
  @Input() modeDisplay: GflModeDisplayType;

  /**
   * Front theme style
   */
  @Input() style: FrontTheme;

  /**
   * Text used in ion-label component
   */
  @Input() label: string;

  /**
   * Text used in ion-label component
   */
  @Input() placeholder: string;

  /**
   * If isEditMode is false then the field value are read-only
   */
  @Input() isEditMode: boolean;

  /**
   * If isLabel is true then we only display label :)
   */
  @Input() isLabel: boolean;

  /**
   * name attribute for form control
   */
  @Input() name: string;

  /**
   * This is the value_reformat attribute provided by the BO
   * for objects using item_templates
   */
  @Input() formattedValue: string;

  /**
   * Color theme to apply to the component
   */
  @Input() theme: GflThemeOptions;

  /**
   * if true then ion-icon are displayed instead of text value
   */
  @Input() displayIcons: boolean;

  /**
   * if true then gfl-validation section is not displayed
   * this is used to display custom error message
   */
  @Input() noDisplayErrors: boolean;

  /**
   * readonly flag for HTML attribute field
   */
  @Input() readonly: boolean;

  /**
   * field required flag
   */
  @Input() required: boolean;

  /**
   * true if form parent has een submitted
   */
  @Input() submitted: boolean;

  public modeDisplays = GflModeDisplayType;
  private validatorsApplied: Array<ValidatorFn>;

  /**
   * @ignore
   */
  constructor(public translate: TranslateService) {
    this.theme = this.theme || {};
    this.formChange = new EventEmitter<FormGroup>();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue !== changes.form.previousValue) {
      if (!this.form.contains(this.name)) {
        this.validators = this.validators || [];
        // set validators
        this.validatorsApplied = [...this.validators];
        if (this.required) {
          this.validatorsApplied.push(Validators.required);
        }
        // generate formControl
        const formControl = new FormControl(this.value, this.validatorsApplied);
        // update form locally
        this.form.registerControl(this.name, formControl);
        // update form globally
        this.formChange.emit(this.form);
      }
    }

    if (changes.submitted && changes.submitted.currentValue !== changes.submitted.previousValue) {
      if (this.validatorsApplied.length) {
        this.form.get(this.name).markAsTouched();
        this.form.get(this.name).markAsDirty();
      }
    }
  }
}
