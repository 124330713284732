import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { Observable, Subscription } from 'rxjs';
import { finalize, first, switchMap } from 'rxjs/operators';

import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { AuthService } from '../../services/auth.service';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { LoginApiResponse } from '../../models/auth.model';

import { LoginCheckComponent } from '../login-check/login-check.component';

@Component({
  selector: 'gfl-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Input() logo$: Observable<string>;
  @Input() isOffline: boolean;
  public acls: Acls;
  public loginForm: FormGroup;
  public showSendPassword = false;
  private subscriptions: Subscription[] = [];

  /**
   * @ignore
   */
  constructor(
    private navCtrl: NavController,
    private authSrv: AuthService,
    private fb: FormBuilder,
    public platform: Platform,
    private store: StoreService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private notificationSrv: NotificationService,
    private aclsSrv: AclsService,
    public tools: ToolsService,
    private alertCtrl: AlertController
  ) {
    this.initLoginForm();
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.subscriptions.push(this.initAcls().subscribe(acls => (this.acls = acls)));
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Launch login process
   * data { login, password, rememberMe, agencyId, agencies }
   * agencies list
   */
  public onLogin(data?: object): void {
    if (this.isOffline) {
      this.notificationSrv.showOfflineNotAvailable();
      return;
    }

    this.tools.showLoader();

    // tslint:disable-next-line:no-shadowed-variable
    const { login, password, rememberMe, agencyId, agencies } = data || this.loginForm.value;

    this.authSrv
      .login(login, password, rememberMe, agencyId, agencies)
      .pipe(finalize(() => this.tools.hideLoader()))
      .subscribe(
        result => {
          this.onLoginSuccess(result.customer.api_token);
        },
        errMsgObj => {
          this.onLoginError(errMsgObj, { login, password, rememberMe });
        }
      );
  }

  /**
   * Display next step
   */
  public async onResetPassword(): Promise<void> {
    this.navCtrl.navigateForward('/authentication/reset-password/login-check').then(() => {});
  }

  /**
   * Manage login success
   * @param apiToken Api token provided by BO
   */
  private onLoginSuccess(apiToken) {
    // empty login form and hide loader
    this.loginForm.reset();

    if (this.platform.is('desktop')) {
      this.modalCtrl.dismiss(apiToken).then(() => {});
    }
    this.navCtrl.navigateRoot('/home').then(() => {});
  }

  /**
   * Manage login error
   * @param err error object
   * @param connexionData object with login, password, rememberMe
   */
  private onLoginError(err: string | HttpErrorResponse, connexionData: object) {
    if (err instanceof HttpErrorResponse && err.status === 409) {
      if (this.acls.LOGIN_MULTIPLE_CUSTOMERS) {
        return this.onMultipleCustomersResponse(err.error, connexionData);
      } else {
        this.notificationSrv.showError({ message: err.error.message as string });
      }
    } else {
      this.notificationSrv.showError({ message: err as string });
    }
  }

  /**
   * display an alert to choose the agency in case of multiple agencies
   * @param error response from login ws
   * @param connexionData object with login, password, rememberMe
   */
  private onMultipleCustomersResponse(error: LoginApiResponse, connexionData: object): void {
    const inputs = [];
    // @ts-ignore
    const { login, password, rememberMe } = connexionData;
    const agencies = error.fields.agency_id;

    _.forEach(agencies, (agency, id) => {
      inputs.push({
        type: 'radio',
        label: agency,
        value: parseInt(id, 10),
        checked: false,
      });
    });

    this.translate
      .get(['LOGIN.MULTIPLE_CUSTOMER', 'LOGIN.CHOOSE_AGENCY', 'COMMON.BUTTON_VALIDATE'])
      .pipe(
        switchMap(async translation => {
          const alert = await this.alertCtrl.create({
            header: translation['LOGIN.CHOOSE_AGENCY'],
            subHeader: error.message,
            inputs,
            buttons: [
              {
                text: translation['COMMON.BUTTON_VALIDATE'],
                handler: agencyId => {
                  this.onLogin({ login, password, rememberMe, agencyId, agencies });
                },
              },
            ],
          });
          await alert.present();
        })
      )
      .subscribe();
  }

  /**
   * Initialize the login form
   */
  private initLoginForm(): void {
    // form definition
    this.loginForm = this.fb.group({
      login: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: [],
    });

    this.store
      .getAuthData()
      .pipe(first())
      .subscribe(authData => {
        this.loginForm.patchValue({
          login: authData.customerLogin,
          rememberMe: authData.rememberMe,
        });
      });
  }

  /**
   * Initialize Acls
   */
  private initAcls(): Observable<Acls> {
    return this.aclsSrv.getAcls();
  }
}
