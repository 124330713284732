import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ModalController, NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import * as _ from 'lodash';

import { first, switchMap } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Customer, CustomerFamilyMember, CustomerRole } from '../../models/customer.model';
import { Constant } from '../../../gfl-core/gfl-models/constant.model';
import { GflModeDisplayType, GflSelectOption } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { GflThemeOptions } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form-options.model';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../services/customer.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { AuthService } from '../../../authentication/services/auth.service';
import { RolesService } from '../../../gfl-core/gfl-services/roles.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'gfl-customer-links-pair',
  templateUrl: './customer-links-pair.component.html',
  styleUrls: ['./customer-links-pair.component.scss'],
})
export class CustomerLinksPairComponent implements OnInit, OnChanges {
  @Input() customerTypeId: number;
  @Input() customer: Customer;
  @Input() selectedMember: CustomerFamilyMember | Customer;
  @Input() pairingPro: boolean;
  @Input() isSignUpProcess: boolean;
  @Input() style: FrontTheme;
  @Input() theme: GflThemeOptions;
  @Input() hideCloseButton: boolean;

  public title: string;
  public warning: string;
  public pairingForm: FormGroup = new FormGroup({});
  public pairingCodeValidators: Array<ValidatorFn>;
  public customerLinkTypes: Array<GflSelectOption>;
  public roles: Array<GflSelectOption>;
  public modeDisplay: GflModeDisplayType;
  public error: string;
  public isRoleVisible: boolean;
  private CUSTOMER_LINK_FOREIGN_TABLE_ID: number;

  readonly CUSTOMER_TYPE_ID_PRIVATE: number;
  readonly CUSTOMER_TYPE_ID_CORPORATE: number;
  readonly CUSTOMER_TYPE_ID_EMPLOYEE: number;
  readonly CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT: number;
  readonly CUSTOMER_LINK_TYPE_EMPLOYEE: number;
  readonly CUSTOMER_LINK_TYPE_PARENT: number;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private constantSrv: ConstantService,
    private customerSrv: CustomerService,
    private store: StoreService,
    private notificationSrv: NotificationService,
    private authSrv: AuthService,
    private roleSrv: RolesService,
    private aclsSrv: AclsService
  ) {
    this.CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    this.CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    this.CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
    this.CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT = this.constantSrv.getValueFromKey(
      'CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT'
    );
    this.CUSTOMER_LINK_TYPE_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_LINK_TYPE_EMPLOYEE');
    this.CUSTOMER_LINK_TYPE_PARENT = this.constantSrv.getValueFromKey('CUSTOMER_LINK_TYPE_PARENT');
    this.CUSTOMER_LINK_FOREIGN_TABLE_ID = this.constantSrv.getForeignTableIdByName('CUSTOMER_LINK', true) as number;
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    if (this.customerTypeId && (this.customer || this.selectedMember)) {
      this.init();
    }

    this.setModeDisplay();
    this.setForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.selectedMember && changes.selectedMember.currentValue) ||
      (changes.customer && changes.customer.currentValue)
    ) {
      this.init();
    }
  }

  private init() {
    this.setContent();
    this.setCustomerLinkTypesOptions(this.CUSTOMER_LINK_FOREIGN_TABLE_ID);
    this.setRolesOptions(this.CUSTOMER_TYPE_ID_EMPLOYEE);

    this.isRoleVisible = !(
      this.selectedMember &&
      ((this.selectedMember.customer_type_id === this.CUSTOMER_TYPE_ID_EMPLOYEE &&
        this.customerTypeId === this.CUSTOMER_TYPE_ID_CORPORATE) ||
        this.customerTypeId === this.CUSTOMER_TYPE_ID_PRIVATE)
    );
  }

  /**
   * Close current view
   */
  public close(): void {
    if (this.isSignUpProcess) {
      const navigationExtras: NavigationExtras = {
        state: {
          customer: this.customer,
        },
      };

      this.navCtrl.navigateForward('/authentication/sign-up/congratulation', navigationExtras).then(() => {});
    } else if (this.tools.isMobile()) {
      this.navCtrl.navigateRoot('/profile').then(() => {});
    } else {
      this.modalCtrl.dismiss().then();
    }
  }

  /**
   * Submit form
   */
  public onSubmit(): void {
    const f = this.pairingForm;

    this.customerSrv
      .registerCustomerLinkByPairingCode(
        f.value.pairingCode,
        this.isSignUpProcess ? this.customer && this.customer.id : this.selectedMember.id,
        this.isSignUpProcess,
        Number(f.value.customerLinkTypeId),
        this.isSignUpProcess && this.customer && this.customer.api_token,
        f.value.roleId
      )
      .subscribe(
        () => {
          this.onSuccess(this.customerTypeId, this.isSignUpProcess);
        },
        err => this.onError(err)
      );
  }

  /**
   * Stop signup process
   */
  public cancel(): void {
    this.authSrv.cancelSignup();
  }

  /**
   * Display a success message
   * @param customerTypeId customer type id
   * @param isSignUpProcess flag
   */
  private onSuccess(customerTypeId: number, isSignUpProcess: boolean): void {
    let message: string;

    switch (this.customerTypeId) {
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        message = 'PROFILE.ADD_PRO.EXISTING_SUCCESS';
        break;
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        message = 'PROFILE.ADD_EMPLOYEE.EXISTING_SUCCESS';
        break;
      default:
        message = 'PROFILE.ADD_PRIVATE.EXISTING_SUCCESS';
        break;
    }
    this.notificationSrv.showSuccess({ message });
    this.close();

    if (isSignUpProcess) {
      const navigationExtras: NavigationExtras = {
        state: {
          customer: this.customer,
        },
      };

      this.navCtrl.navigateForward('/authentication/sign-up/congratulation', navigationExtras).then();
    } else {
      this.store
        .getAuthData()
        .pipe(
          first(),
          switchMap(authData => {
            const token = authData.customerToken;

            return this.customerSrv.setCustomer({
              customerToken: token,
              customerIdLinked: null,
              noUpdateMode: false,
              noAskForValidationCustomerLinks: false,
              fetchCustomerLinks: true,
              noContract: false,
            });
          }),
          switchMap(() => this.aclsSrv.setAcls())
        )
        .subscribe(() => {
          this.authSrv.refreshCustomerData();
        });
    }
  }

  /**
   * Display an error notification
   * @param err error
   */
  public onError(err: any): void {
    this.error = err;

    if (err && err.indexOf('email') > -1) {
      this.notificationSrv.showError({ message: 'SIGNUP.ERRORS.EMAIL' });
      return;
    } else if (err) {
      this.notificationSrv.showError({ message: err });
      return;
    }
    this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
    return;
  }

  public gotToWelcome() {
    this.navCtrl.navigateForward('/welcome').then();
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }

  /**
   * Set title
   */
  private setContent(): void {
    switch (this.customerTypeId) {
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        this.title = 'PROFILE.ADD_PRO.ADD_EXISTING';
        this.warning = 'PROFILE.ADD_PRO.ADD_EXISTING_WARNING';
        break;
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        this.title = 'PROFILE.ADD_EMPLOYEE.ADD_EXISTING';
        this.warning = 'PROFILE.ADD_EMPLOYEE.ADD_EXISTING_WARNING';
        break;
      default:
        this.title = 'PROFILE.ADD_PRIVATE.ADD_EXISTING';
        this.warning = 'PROFILE.ADD_PRIVATE.ADD_EXISTING_WARNING';
        break;
    }
  }

  /**
   * Set form validators
   */
  private setForm(): void {
    this.pairingCodeValidators = [Validators.required, Validators.pattern('^[A-Za-z0-9]{6}$')];
  }

  /**
   * set customerLinkTypes options
   * @param customerLinkForeignTableId customerLinkForeignTable Id
   */
  private setCustomerLinkTypesOptions(customerLinkForeignTableId: number): void {
    this.constantSrv
      .getConstantByForeignTable(customerLinkForeignTableId, this.isSignUpProcess ? this.customer.api_token : undefined)
      .subscribe(result => {
        result = _.filter(result, (item: Constant) => {
          return this.isCustomerLinkTypeAuthorized(item);
        });

        this.customerLinkTypes = _.map(result, (item: Constant) => {
          return {
            value: item.value,
            text: item.key_translate,
            selected: result.length === 1,
          } as GflSelectOption;
        });
      });
  }

  /**
   * Set roles select options
   * @param customerTypeId customerType id
   */
  private setRolesOptions(customerTypeId: number) {
    this.roleSrv.getRolesByCustomerTypeId(customerTypeId).subscribe(roles => {
      this.roles = _.map(roles, (role: CustomerRole) => {
        return {
          value: role.id,
          text: role.name,
          selected: false,
        } as GflSelectOption;
      });
    });
  }

  /**
   * Check if this item is authorized for the customer's type id and the customer's link type id
   * @param item an item from constant service
   */
  private isCustomerLinkTypeAuthorized(item: Constant): boolean {
    const val = parseInt(item.value, 10);
    const customerTypeId =
      (this.customer && this.customer.customer_type_id) ||
      (this.selectedMember && this.selectedMember.customer_type_id);

    switch (customerTypeId) {
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        if (this.customerTypeId === this.CUSTOMER_TYPE_ID_PRIVATE) {
          // an employee can link to a private
          // with CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT only
          return val === this.CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT;
        }
        if (this.customerTypeId === this.CUSTOMER_TYPE_ID_CORPORATE) {
          return val === this.CUSTOMER_LINK_TYPE_EMPLOYEE;
        }
        return false;
      case this.CUSTOMER_TYPE_ID_PRIVATE:
        // Private case
        if (this.customerTypeId === this.CUSTOMER_TYPE_ID_EMPLOYEE) {
          // a private can link to employee
          // with CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT only
          return val === this.CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT;
        } else if (this.customerTypeId === this.CUSTOMER_TYPE_ID_PRIVATE) {
          // a private can link to a private
          // with all customerTypeId < or = to CUSTOMER_LINK_TYPE_PARENT
          return val <= this.CUSTOMER_LINK_TYPE_PARENT;
        }
        return false;
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        // corporate case
        if (this.customerTypeId === this.CUSTOMER_TYPE_ID_EMPLOYEE) {
          return val === this.CUSTOMER_LINK_TYPE_EMPLOYEE;
        }
        return false;
    }
  }
}
