import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FilesystemEncoding } from '@capacitor/core';

import { BehaviorSubject, from } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DocumentService } from '../../gfl-services/document.service';
import { ToolsService } from '../../gfl-services/tools.service';

export enum ImgTagTypes {
  Img,
  Svg,
}

@Component({
  selector: 'gfl-img',
  templateUrl: './gfl-img.component.html',
  styleUrls: ['./gfl-img.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflImgComponent implements OnChanges {
  @Input() source: string;
  @Input() classAttr: string;
  @Input() altAttr: string;
  public tag: ImgTagTypes;
  public imgTagTypes = ImgTagTypes;
  private dataSubject$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public data$ = this.dataSubject$.asObservable().pipe(
    tap(() => {
      this.ref.detectChanges();
    })
  );

  /**
   * @ignore
   */
  constructor(private ref: ChangeDetectorRef, private documentSrv: DocumentService, private tools: ToolsService) {}

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source && changes.source.currentValue) {
      this.selectTag();
    }
  }

  /**
   * choose which tag to use to display media
   */
  private selectTag() {
    const regExpImg = /^(http)|^(https)|^(data)/;
    const regExpSvg = /^<svg/;

    if (regExpImg.test(this.source)) {
      this.tag = ImgTagTypes.Img;
      this.dataSubject$.next(this.source);
    } else if (regExpSvg.test(this.source)) {
      this.tag = ImgTagTypes.Svg;
      this.dataSubject$.next(this.source);
    } else {
      const ext = this.tools.getExtension(this.source);
      const encoding = ext === 'svg' ? FilesystemEncoding.UTF8 : null;

      if (ext === 'svg') {
        // this is a uri from the device file system
        from(this.documentSrv.fileRead(this.source, encoding))
          .pipe(map(res => res.data))
          .subscribe(source => {
            this.tag = ImgTagTypes.Svg;
            this.dataSubject$.next(source);
            this.ref.detectChanges();
          });
      } else {
        this.tag = ImgTagTypes.Img;
        this.dataSubject$.next(this.source);
      }
    }
  }
}
