import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

import { FrontTheme } from '../gfl-core/gfl-models/agency.model';
import { Customer, CustomerFamilyMember } from '../customer/models/customer.model';
import { NetWorkStatus } from '../gfl-core/gfl-services/network-monitor.service';

import { CustomerActions } from '../customer/reducers/action-types';
import { UiActions } from './action.types';

export interface UiState {
  isCustomerCompleted: boolean;
  lang: string;
  style: FrontTheme;
  adminLogo: string;
  isProSelected: boolean;
  isProSelectorDisplayed: boolean;
  isAppStateReady: boolean;
  isSignupProcess: boolean;
  selectedMember: CustomerFamilyMember | Customer;
  isLoading: boolean;
  activatedPage: string;
  networkStatus: NetWorkStatus;
  refreshState: boolean;
  displayNomadLoader: boolean;
}

export const initialUiState: UiState = {
  isCustomerCompleted: undefined,
  lang: undefined,
  style: undefined,
  adminLogo: undefined,
  isProSelected: undefined,
  isProSelectorDisplayed: undefined,
  isAppStateReady: undefined,
  isSignupProcess: undefined,
  selectedMember: undefined,
  isLoading: undefined,
  activatedPage: undefined,
  networkStatus: NetWorkStatus.Online,
  refreshState: false,
  displayNomadLoader: false,
};

const _uiReducer = createReducer(
  initialUiState,

  on(UiActions.initialize, (state, action) => {
    return { ...action.uiState };
  }),

  on(UiActions.setLang, (state, action) => {
    return {
      ...state,
      lang: action.lang,
    };
  }),

  on(UiActions.setAdminLogo, (state, action) => {
    return {
      ...state,
      adminLogo: action.logo,
    };
  }),

  on(UiActions.setRefreshState, (state, action) => {
    return {
      ...state,
      refreshState: action.refreshState,
    };
  }),

  on(UiActions.setIsCustomerCompleted, (state, action) => {
    return {
      ...state,
      isCustomerCompleted: action.isCustomerCompleted,
    };
  }),

  on(UiActions.updateLang, (state, action) => {
    return {
      ...state,
      lang: action.lang,
    };
  }),

  on(UiActions.setIsProSelected, (state, action) => {
    return {
      ...state,
      isProSelected: action.isPro,
    };
  }),

  on(UiActions.setIsSignupProcess, (state, action) => {
    return {
      ...state,
      isSignupProcess: action.isSignupProcess,
    };
  }),

  on(UiActions.setIsProSelectorDisplayed, (state, action) => {
    return {
      ...state,
      isProSelectorDisplayed: action.isProSelectorDisplayed,
    };
  }),

  on(UiActions.setStyle, (state, action) => {
    return {
      ...state,
      style: action.style,
    };
  }),

  on(UiActions.setSelectedMember, (state, action) => {
    return {
      ...state,
      selectedMember: action.selectedMember,
    };
  }),

  on(UiActions.setLoaderState, (state, action) => {
    return {
      ...state,
      isLoading: action.display,
    };
  }),

  on(UiActions.setNomadLoaderState, (state, action) => {
    return {
      ...state,
      displayNomadLoader: action.display,
    };
  }),

  on(UiActions.setActivatedPage, (state, action) => {
    return {
      ...state,
      activatedPage: action.activatedPage,
    };
  }),

  on(UiActions.setNetworkStatus, (state, action) => {
    return {
      ...state,
      networkStatus: action.networkStatus,
    };
  }),

  on(CustomerActions.setPairingCode, (state, action) => {
    const newState = _.cloneDeep(state);

    if (newState.selectedMember.id === action.id) {
      newState.selectedMember.pairing_code = action.pairingCode;
    }

    return newState;
  }),

  on(UiActions.setIsAppStateReady, (state, action) => {
    return {
      ...state,
      isAppStateReady: action.isReady,
    };
  })
);

export function uiReducer(state, action) {
  return _uiReducer(state, action);
}
