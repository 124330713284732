import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import * as _ from 'lodash';

import { Subscription } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { CustomerFamilyMember } from '../../../customer/models/customer.model';
import { ToolsService } from '../../gfl-services/tools.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { ConstantService } from '../../gfl-services/constant.service';
import { StoreService } from '../../gfl-services/store.service';

@Component({
  selector: 'gfl-members-select',
  templateUrl: './gfl-members-select.component.html',
  styleUrls: ['./gfl-members-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflMembersSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() members: CustomerFamilyMember[];
  @Input() badges: { [memberId: number]: { count: number } };
  @Input() theme: string;
  @Input() selectedBorder: boolean;
  @Input() withoutIssuedOnly: boolean;
  @Input() mobileLike: boolean;

  @ViewChild('scroll', { static: false }) scroll: any;
  public selectedMemberId: number;
  public membersUpdated: CustomerFamilyMember[];
  public errorDisplay: boolean;
  public isLoading = true;
  public noDataDisplay: string;
  private subscriptions: Subscription[] = [];
  readonly CUSTOMER_TYPE_ID_PRIVATE: number;

  /**
   * @ignore
   */
  constructor(
    public platform: Platform,
    public tools: ToolsService,
    private customerSrv: CustomerService,
    private constantSrv: ConstantService,
    private cd: ChangeDetectorRef,
    private store: StoreService,
    public translate: TranslateService
  ) {
    this.CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
  }

  /**
   * Return false if customer or member doesn't have a dob attribute
   * and if customer is older than member
   * @param member family member or employee
   * @param customer family member customer
   */
  static isOlderThanCustomer(member: CustomerFamilyMember, customer: CustomerFamilyMember): boolean {
    if (!customer.customer_dob || !member.customer_dob) {
      return false;
    }

    return moment(member.customer_dob) < moment(customer.customer_dob);
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.customerSrv
        .getSelectedMember()
        .pipe(
          delay(0),
          map(selectedMember => {
            // set membersUpdated
            if (selectedMember) {
              this.selectedMemberId = selectedMember ? selectedMember.id : this.members[0].id;
              if (this.scroll) {
                setTimeout(() => {
                  const el: Element = this.scroll.nativeElement || this.scroll.el;
                  const memberEl = el.querySelector('.member');
                  const selectedMemberIndex = _.findIndex(this.members, { id: selectedMember.id });
                  if (this.members && el.scrollLeft === 0 && selectedMemberIndex > 3) {
                    // we wait for the DOM to be ready and members to be defined
                    // we scroll only if there's more than 4 members
                    // we scroll only if display has been updated and not if the user selects a member
                    el.scrollLeft = (selectedMemberIndex + 4) * memberEl.clientWidth;
                  }
                }, 300);
              }
              this.cd.detectChanges();
            }
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.store.getLang().subscribe(lang => {
        this.membersUpdated = this.updateMembers(this.members);
        this.cd.detectChanges();
      })
    );
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.members &&
      changes.members.currentValue &&
      changes.members.currentValue !== changes.members.previousValue
    ) {
      this.membersUpdated = this.updateMembers(this.members);
      this.errorDisplay = !_.isArray(this.members);
      this.isLoading = _.isArray(this.members) && !this.membersUpdated.length;
      this.cd.markForCheck();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Emit the member ID selected from IHM
   * @param  member family member or employee
   */
  public selectMember(member: CustomerFamilyMember) {
    this.customerSrv.selectMember(member);
    this.cd.markForCheck();
  }

  /**
   * Update members sprite attribute
   * @param membersArr an array of family members or employees
   */
  private updateMembers(membersArr: CustomerFamilyMember[]): CustomerFamilyMember[] {
    const members = _.cloneDeep(membersArr);
    const customer = _.find(members, member => member.customer_link_type_id !== 0);

    _.forEach(members, (member, idx) => {
      const typeId = member.customer_link_type_id;
      const civility = member.civility === 'M' ? 1 : 2;

      if (member.issued_only && this.withoutIssuedOnly) {
        delete members[idx];
      } else if (member.issued_only) {
        member.sprite = 'entity_10';
      } else if (typeId === 0) {
        // family or companies
        member.sprite = member.customer_type_id === this.CUSTOMER_TYPE_ID_PRIVATE ? 'entity_0' : 'entity_8';
      } else if (typeId >= 200) {
        member.sprite = 'entity_8';
      } else if ((typeId > 0 && typeId < 5) || !member.customer_dob) {
        // man or woman !
        member.sprite = civility === 1 ? 'entity_1' : 'entity_2';
      } else if (typeId === 5 || typeId === 10) {
        // son or daughter or parent
        if (!GflMembersSelectComponent.isOlderThanCustomer(member, customer)) {
          member.sprite = civility === 1 ? 'entity_3' : 'entity_4';
        } else {
          member.sprite = civility === 1 ? 'entity_6' : 'entity_7';
        }
      } else if (typeId === 9) {
        // house staff
        member.sprite = 'entity_5';
      }
    });

    return _.compact(members);
  }
}
