import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { map } from 'rxjs/operators';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ProfileAddCustomerLinkGuard implements CanActivate {
  constructor(private customerSrv: CustomerService, private navCtrl: NavController) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.customerSrv.getSelectedMember().pipe(
      map(selectedMember => {
        // selectedMember.id === 0 may correspond to family or all companies
        if (!selectedMember || selectedMember.id === 0) {
          this.navCtrl.navigateRoot('/profile').then();
        }
        return selectedMember.id !== 0;
      })
    );
  }
}
