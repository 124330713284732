import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { PolicyService } from '../../services/policy.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { CustomerFamilyMember } from '../../../customer/models/customer.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { PolicyFO } from '../../models/policy.model';

import { PolicyAddComponent } from '../policy-add/policy-add.component';
import { PolicyBudgetComponent } from '../policy-budget/policy-budget.component';
import { first } from 'rxjs/operators';

@Component({
  selector: 'gfl-policy-subheader-tablet',
  templateUrl: './policy-subheader-tablet.component.html',
  styleUrls: ['./policy-subheader-tablet.component.scss'],
})
export class PolicySubheaderTabletComponent implements OnChanges {
  @Input() policies: Array<PolicyFO | { empty: string }> = [];
  @Input() selectedMember: CustomerFamilyMember;
  @Input() style: FrontTheme;
  @Input() acls: Acls;
  @Input() isPro: boolean;
  @Input() isOffline: boolean;
  @Input() activePage: string;
  @Output() openPolicyModal: EventEmitter<PolicyFO> = new EventEmitter<PolicyFO>();

  public policiesNumber = 0;
  public totalePrime = 0;
  public possibleReductions = 0;
  public employeeCount: any = '-';

  /**
   * @ignore
   */
  constructor(
    private policySrv: PolicyService,
    private nav: NavController,
    private modalCtrl: ModalController,
    private customerSrv: CustomerService,
    public translate: TranslateService,
    public notificationSrv: NotificationService
  ) {}

  /**
   * @ignore
   */
  ngOnChanges(value): void {
    if (this.policies) {
      this.setData();
    }
    if (this.selectedMember && this.isPro) {
      this.setEmployeeCount();
    }
  }

  /**
   *  Open Add policy modal
   */
  public async openPolicyAddModal(): Promise<void> {
    const policyAddModal = await this.modalCtrl.create({
      component: PolicyAddComponent,
    });

    return await policyAddModal.present();
  }

  /**
   *  Open budget modal
   */
  public async openBudgetModal(): Promise<void> {
    const budgetModal = await this.modalCtrl.create({
      component: PolicyBudgetComponent,
      componentProps: {
        isPro: this.isPro,
        style: this.style,
        acls: this.acls,
      },
    });

    budgetModal.onDidDismiss().then(result => {
      if (result && result.data && result.data.policy) {
        this.openPolicyModal.next(result.data.policy);
      }
    });

    await budgetModal.present();
  }

  /**
   * Set totalPrime and policiesNumber
   */
  private setData(): void {
    let totalPrime = 0;
    let policiesNumber = 0;

    if (_.isArray(this.policies)) {
      // this is a flat array of policies coming from compare component
      _.forEach(this.policies, (policy: PolicyFO) => {
        policiesNumber++;
        if (policy.policy_premium) {
          totalPrime += policy.policy_premium;
          this.possibleReductions += policy.reduction;
        }
      });
    } else {
      _.forEach(this.policies, (policiesType: any) => {
        _.forEach(policiesType.policies, (policy: PolicyFO) => {
          policiesNumber++;
          if (policy.policy_premium) {
            totalPrime += policy.policy_premium;
          }
        });
      });
    }

    this.totalePrime = totalPrime;
    this.policiesNumber = policiesNumber;
  }

  /**
   * Set employee count according to customer pro
   */
  private setEmployeeCount(): void {
    this.customerSrv
      .getCustomerItems(this.selectedMember.id)
      .pipe(first())
      .subscribe(items => {
        const item = _.find(items, { item_template_key: 'item_templates_pro' });

        if (item && item.subitems) {
          // @ts-ignore
          const subItem = _.find(item.subitems, { item_template_key: 'employee_count' });

          this.employeeCount = (subItem && subItem.value) || '-';
        }
      });
  }
}
