import { NgModule } from '@angular/core';
import { CustomerAddOrPairSelectComponent } from './customer-add-or-pair-select/customer-add-or-pair-select.component';
import { CustomerCreateComponent } from './customer-create/customer-create.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { CustomerLinksEditComponent } from './customer-links-edit/customer-links-edit.component';
import { CustomerLinksPairComponent } from './customer-links-pair/customer-links-pair.component';
import { CustomerTypeSelectComponent } from './customer-type-select/customer-type-select.component';
import { MemberBankComponent } from './member-bank/member-bank.component';
import { MemberConnexionDataComponent } from './member-connexion-data/member-connexion-data.component';
import { MemberHealthComponent } from './member-health/member-health.component';
import { MemberIdentityComponent } from './member-identity/member-identity.component';
import { GflCoreModule } from '../../gfl-core/gfl-core.module';
import { IonicModule } from '@ionic/angular';
import { GflFormGeneratorModule } from '../../gfl-libraries/gfl-form-generator/gfl-form-generator.module';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SubHeaderProfileTabletComponent } from './sub-header-profile-tablet/sub-header-profile-tablet.component';

@NgModule({
  declarations: [
    CustomerAddOrPairSelectComponent,
    CustomerCreateComponent,
    CustomerFormComponent,
    CustomerLinksEditComponent,
    CustomerLinksPairComponent,
    CustomerTypeSelectComponent,
    MemberBankComponent,
    MemberConnexionDataComponent,
    MemberHealthComponent,
    MemberIdentityComponent,
    ChangePasswordComponent,
    SubHeaderProfileTabletComponent,
  ],
  imports: [GflCoreModule, IonicModule, GflFormGeneratorModule.forRoot()],
  entryComponents: [
    CustomerAddOrPairSelectComponent,
    CustomerCreateComponent,
    CustomerFormComponent,
    CustomerLinksEditComponent,
    CustomerLinksPairComponent,
    CustomerTypeSelectComponent,
    MemberBankComponent,
    MemberConnexionDataComponent,
    MemberHealthComponent,
    MemberIdentityComponent,
    ChangePasswordComponent,
    SubHeaderProfileTabletComponent,
  ],
  exports: [
    CustomerAddOrPairSelectComponent,
    CustomerCreateComponent,
    CustomerFormComponent,
    CustomerLinksEditComponent,
    CustomerLinksPairComponent,
    CustomerTypeSelectComponent,
    MemberBankComponent,
    MemberConnexionDataComponent,
    MemberHealthComponent,
    MemberIdentityComponent,
    ChangePasswordComponent,
    SubHeaderProfileTabletComponent,
  ],
})
export class CustomerComponentsModule {}
