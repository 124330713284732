import { Injectable } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Capacitor, FileReadResult, FilesystemDirectory, FilesystemEncoding, Plugins } from '@capacitor/core';

// import * as JSPDF from 'jspdf';
import * as _ from 'lodash';

import { concat, forkJoin, from, Observable, Observer, of, throwError } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  pluck,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';

import { ApiResponse } from '../gfl-models/api.model';
import { MandateContract } from '../../customer/models/customer.model';
import { Document, DocumentCategory, DocumentCategoryItem, UploadDocument } from '../gfl-models/document.model';
import { StoreService } from './store.service';
import { ImageService } from './image.service';
import { NotificationService } from './notification.service';
import { ToolsService } from './tools.service';
import { ApiService } from './api.service';
import { WsService } from './ws.service';
import { EncryptionService } from './encryption.service';
import { ConstantService } from './constant.service';
import { DataMonitorService } from './data-monitor.service';
import { AclsService } from './acls.service';

import { environment } from '../../../environments/environment';
import { CgType } from '../gfl-models/cg.enum';

const { Filesystem, App, BackgroundTask } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  readonly URL = {
    FILE: '/document/file/',
    DOCUMENTS: '/documents',
  };

  constructor(
    private apiSrv: ApiService,
    private wsSrv: WsService,
    private fileOpener: FileOpener,
    private tools: ToolsService,
    private translate: TranslateService,
    private store: StoreService,
    private imageSrv: ImageService,
    private notificationSrv: NotificationService,
    private encryptSrv: EncryptionService,
    private constantSrv: ConstantService,
    private dataMonitorSrv: DataMonitorService,
    private aclsSrv: AclsService
  ) {
    this.removeAllTmpDocuments()
      .pipe(
        catchError(err => {
          return of(null); // we catch if tmp folder doesn't exist
        })
      )
      .subscribe(); // this will launch permission request

    const locksToMonitor = [
      {
        name: 'documentCategories',
        lock: () => this.store.getDocumentCategoriesLock(),
        cb: () => this.setDocumentCategories(),
      },
      {
        name: 'documents',
        lock: () => this.store.getDocumentsLock(),
        cb: () =>
          this.store.getAuthData().pipe(
            switchMap(authState => {
              const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
              const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
              const CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');

              let except;

              switch (authState.customerTypeId) {
                case CUSTOMER_TYPE_ID_PRIVATE:
                  except = [CUSTOMER_TYPE_ID_EMPLOYEE, CUSTOMER_TYPE_ID_CORPORATE];
                  break;
                case CUSTOMER_TYPE_ID_EMPLOYEE:
                  except = [CUSTOMER_TYPE_ID_PRIVATE];
              }

              return this.store.getCustomersIdList(except);
            }),
            filter(val => !_.isEmpty(val)),
            first(),
            mergeMap(customersId => this.setDocuments(customersId))
          ),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Store a map of Document categories
   */
  public setDocumentCategories(): Observable<any> {
    let documentCategoriesObj;

    return this.store.setDocumentCategoriesLock(Date.now()).pipe(
      mergeMap(() => this.wsSrv.requestDocumentCategories()),
      mergeMap(documentCategories => {
        documentCategoriesObj = documentCategories.policy;
        const obs$: Observable<string>[] = [];

        _.forEach(documentCategoriesObj, (documentCategoriesItem, key) => {
          _.forEach(documentCategoriesItem.categories, (category, idx) => {
            if (!category.is_visible_frontend) {
              delete documentCategoriesObj[key][idx];
            } else {
              if (category.svg) {
                if (this.tools.isNative()) {
                  obs$.push(
                    this.tools.fetchSVGImage(category.svg).pipe(
                      mergeMap(data => {
                        return this.storeSVGFile(
                          data,
                          category.name + '.svg',
                          environment.SVG_FOLDERS.DOCUMENT_CATEGORIES
                        );
                      })
                    )
                  );
                } else {
                  obs$.push(of(category.svg));
                }
              }
            }
          });
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }

        return forkJoin(obs$);
      }),
      mergeMap(result => {
        _.forEach(documentCategoriesObj, documentCategoriesItem => {
          _.forEach(documentCategoriesItem.categories, category => {
            if (category.svg) {
              category.svg = result.shift() as string;
            }
          });
        });

        this.store.setDocumentCategories(documentCategoriesObj);
        return of(this.store.setDocumentCategoriesLock(null));
      }),
      catchError(err => {
        this.tools.error('DocumentService setDocumentCategories()', err);
        return of(null);
      })
    );
  }

  /**
   * Get document file by checksum
   * @param documentId document object
   */
  public openDocumentFile(documentId: number | string): void {
    this.tools.showLoader();

    this.store
      .getDocuments()
      .pipe(
        filter(val => {
          return !_.isEmpty(val);
        }),
        first(),
        delay(300),
        map(documents => {
          return documents[documentId];
        }),
        mergeMap(document => {
          if (this.tools.isNative()) {
            if (document.device_uri) {
              return this.openInDefaultApplicationWithUri(
                document.device_uri,
                document.id,
                document.filename,
                document.file_type
              );
            } else {
              const checksum = document.checksum || document.document_checksum;

              return this.wsSrv
                .requestDocumentByChecksum(checksum, document.document_customer_id || document.customer_id)
                .pipe(
                  mergeMap(blob => {
                    return this.openInDefaultApplicationWithBlob(blob, document.filename, document.file_type);
                  })
                );
            }
          } else {
            return this.wsSrv
              .requestDocumentByChecksum(
                document.checksum || document.document_checksum,
                document.document_customer_id || document.customer_id
              )
              .pipe(
                switchMap(blob => {
                  return this.openFileInBrowser(blob);
                })
              );
          }
        }),
        catchError(err => {
          this.tools.error('openDocumentFile Error', err);
          const key = err && err.errors && Object.keys(err.errors)[0];
          const message = key ? err.errors[key][0] : 'COMMON.DOCUMENT_NOT_FOUND';
          this.notificationSrv.showError({ message });
          this.tools.hideLoader();
          return of(false);
        })
      )
      .subscribe(
        () => {},
        err => console.error(err),
        () => {
          this.tools.hideLoader();
        }
      );
  }

  /**
   * Open and display customer contract
   * @param contract Contract object
   * @param customerId customer's id
   */
  public openCustomerContract(contract, customerId): Observable<any> {
    const params = { customer_id_linked: customerId };

    const obs$ =
      this.tools.isNative() && contract.uri
        ? this.openInDefaultApplicationWithUri(contract.uri, contract.id, contract.fileName, contract.fileType)
        : this.wsSrv.requestContract(params).pipe(map(blob => this.openFileInBrowser(blob)));

    return this.tools.showLoaderObs().pipe(
      switchMap(() => obs$),
      finalize(() => {
        this.tools.hideLoader();
      }),
      catchError(err => {
        this.notificationSrv.showError({ message: err });
        this.tools.error('openDocumentFile Error', err);
        return of(this.tools.hideLoader());
      })
    );
  }

  /**
   *
   * @param type CgType (cga or cgv)
   * @param id cga
   */
  public getCgDocument(type: CgType, id: number): Observable<Blob> {
    const obs$ = type === CgType.CGA ? this.wsSrv.requestCgaFile(id) : this.wsSrv.requestCgvFile(id);

    return obs$.pipe(
      catchError(err => {
        this.tools.error('getCgDocument Error', err);
        return of(null);
      })
    );
  }

  /**
   * launch sign process BO for the offer and return corresponding policy
   * @param documentId signature document id
   * @param offerId offer signed id
   */
  public signOffer(documentId: number, offerId: number): Observable<{ policy_id: number }> {
    return this.wsSrv.postOfferSign({
      document_id: documentId,
      offer_id: offerId,
    });
  }

  /**
   * Add documents in store and store all document's file
   * @param customersIdArr customers id
   */
  public setDocuments(customersIdArr: number[]): Observable<any> {
    let documentsFromBO;
    let documentsFromStore;
    const requests$ = [];

    _.forEach(customersIdArr, id => {
      requests$.push(
        this.aclsSrv.getAcls(id).pipe(
          first(),
          mergeMap(acls => {
            return acls.LOAD_DOCUMENTS ? this.wsSrv.requestCustomerDocuments({ customer_id_linked: id }) : of([]);
          })
        )
      );
    });

    // get list of updated documents id

    return this.store.setDocumentsLock(Date.now()).pipe(
      mergeMap(() => forkJoin([concat(...requests$).pipe(toArray()), this.store.getDocuments().pipe(first())])),
      mergeMap(([documents, documentsStore]: [Document[], { [id: string]: Document }]) => {
        // fetch data from BO and from store
        documentsFromBO = _.keyBy(_.concat(documents.shift(), ...documents), 'id');
        documentsFromStore = _.cloneDeep(documentsStore);
        const deletedDocumentIds = this.tools.findEntitiesToRemove(documentsFromStore, documentsFromBO);
        // get list of deleted documents id => delete files
        return this.removeDocumentsFromStore(documentsFromStore, deletedDocumentIds);
      }),
      mergeMap(result => {
        // check for documents updated
        documentsFromStore = result.shift();

        _.forEach(documentsFromBO, (doc, id) => {
          if (documentsFromStore[id] && doc.updated_at !== documentsFromStore[id].updated_at) {
            const updatedDoc = {
              ...doc,
              device_path: documentsFromStore[id].device_path,
              device_uri: documentsFromStore[id].device_uri,
            };

            documentsFromStore[id] = updatedDoc;
          }
        });

        return of(true);
      }),
      mergeMap(() => {
        // get list of new documents => store files
        const newDocuments = this.tools.findNewEntities<Document>(documentsFromStore, documentsFromBO, 'id');
        const documentsToAdd = _.keyBy(newDocuments, 'id');
        documentsFromStore = _.assign(documentsFromStore, documentsToAdd);
        const storeInFolder = newDocuments.length
          ? newDocuments[0].customer_id || newDocuments[0].document_customer_id
          : undefined;
        return this.storeDocumentsAndSetStore(newDocuments, documentsFromStore, storeInFolder);
      }),
      catchError(err => {
        this.tools.error('setDocuments', err);
        return of(null);
      })
    );
  }

  /**
   * Store documents in files and update the store with uri and path
   * @param newDocs documents to store
   * @param documentsFromStore documents list actually in store
   * @param folder folder where to store documents
   */
  private storeDocumentsAndSetStore(newDocs, documentsFromStore, folder?: any): Observable<void> {
    return this.storeDocuments(newDocs, folder).pipe(
      mergeMap(newDocuments => {
        const documentsToAdd = _.keyBy(newDocuments, 'id');
        const newDocumentsFromStore = _.assign(_.cloneDeep(documentsFromStore), documentsToAdd);
        this.store.setDocuments(newDocumentsFromStore);
        return this.store.setDocumentsLock(null);
      }),
      catchError(err => {
        this.tools.error('storeDocumentsAndSetStore', err);
        return of(null);
      })
    );
  }

  /**
   * Add safebox documents in store and store all document's file
   * @param storedDocuments safebox documents array from store
   * @param documentsFromBo safebox documents array from BO
   */
  public updateStoredSafeboxDocuments(
    storedDocuments: Document[],
    documentsFromBo: Document[],
    folder: any
  ): Observable<Document[]> {
    let documentsFromStore;
    const safeboxDocumentsFromBO = _.keyBy(documentsFromBo, 'document_id');
    let safeboxDocumentsFromStore = _.keyBy(storedDocuments, 'document_id');

    return this.store.getDocuments().pipe(
      first(),
      mergeMap((documents: { [id: string]: Document }) => {
        documentsFromStore = _.cloneDeep(documents);
        const deletedDocumentIds = this.tools.findEntitiesToRemove(safeboxDocumentsFromStore, safeboxDocumentsFromBO);
        // get list of deleted documents id => delete files
        return this.removeDocumentsFromStore(documentsFromStore, deletedDocumentIds);
      }),
      mergeMap(result => {
        documentsFromStore = result.shift() as { [id: string]: Document };
        // get list of new documents => store files
        const newDocuments = this.tools.findNewEntities(
          safeboxDocumentsFromStore,
          safeboxDocumentsFromBO,
          'document_id'
        );
        return this.storeDocuments(newDocuments, folder);
      }),
      mergeMap(newDocuments => {
        const documentsToAdd = _.keyBy(newDocuments, 'document_id') as { [id: string]: Document };
        documentsFromStore = _.assign(documentsFromStore, documentsToAdd);
        safeboxDocumentsFromStore = _.assign(safeboxDocumentsFromStore, documentsToAdd);
        this.store.setDocuments(documentsFromStore);

        return of(_.toArray(safeboxDocumentsFromStore));
      }),
      catchError(err => {
        this.tools.error('updateStoredSafeboxDocuments', err);
        this.notificationSrv.showError({
          message: 'DOCUMENTS.SET_ERROR',
          returnObservable: true,
        });
        return of([]);
      })
    );
  }

  /**
   * Upload and store in file system odf device all documents in array
   * @param documentsArr an array of document
   * @param folder sub folder where to store documents
   */
  public storeDocuments(documentsArr: Document[], folder?: any): Observable<Document[]> {
    const documents$: Array<Observable<Document>> = [];
    const path = !!folder ? `${environment.FILE_FOLDER}/${folder}` : `${environment.FILE_FOLDER}`;
    let storedDocuments;

    const documentsArrSorted = this.sortDocumentByCategory(documentsArr, 'DOCUMENT_CATEGORY_ID_POLICY');

    const obs$ =
      this.tools.isNative() && documentsArrSorted.length
        ? from(Filesystem.readdir({ directory: FilesystemDirectory.Data, path: `${environment.FILE_FOLDER}` })).pipe(
            mergeMap(rootList => {
              if (!rootList.files.includes(folder.toString())) {
                return from(Filesystem.mkdir({ directory: FilesystemDirectory.Data, path, recursive: true }));
              } else {
                return of(null);
              }
            }),
            mergeMap(() => {
              return from(Filesystem.readdir({ directory: FilesystemDirectory.Data, path }));
            }),
            mergeMap(storedDocumentsTmp => {
              storedDocuments = (storedDocumentsTmp && storedDocumentsTmp.files) || [];

              return of(documentsArrSorted);
            })
          )
        : of(documentsArrSorted);

    return obs$.pipe(
      mergeMap(documents => {
        if (documents.length) {
          _.forEach(documents, doc => {
            const document = _.cloneDeep(doc);
            const checksum = document.checksum || document.document_checksum;
            const id = document.id || document.document_id;
            const customerId = document.customer_id || document.document_customer_id;
            const filename = document.filename || document.document_filename;
            const fileType = document.file_type || document.document_file_type;
            const enc = !this.tools.isPicture(fileType) ? FilesystemEncoding.UTF8 : null;
            const isDocAlreadyStored = storedDocuments && storedDocuments.includes(filename);

            if (this.tools.isNative()) {
              if (isDocAlreadyStored) {
                documents$.push(
                  from(
                    Filesystem.getUri({
                      directory: FilesystemDirectory.Data,
                      path: path + '/' + filename,
                    })
                  ).pipe(
                    mergeMap(result => {
                      document.device_uri = result.uri;
                      document.device_path = Capacitor.convertFileSrc(result.uri);

                      return of(document);
                    })
                  )
                );
              } else {
                documents$.push(
                  this.getDocumentByChecksum(checksum, customerId).pipe(
                    mergeMap(blob => this.tools.readFileObs(blob)),
                    mergeMap(content => this.encryptSrv.encrypt(id, content)),
                    mergeMap((result: any) => {
                      return from(this.fileWrite(filename, result.encryptContent, customerId, result.enc || enc)).pipe(
                        catchError(err => {
                          this.tools.error('fileWrite', err);
                          return of(null);
                        })
                      );
                    }),
                    mergeMap(result => {
                      document.device_path = result && result.device_path;
                      document.device_uri = result && result.device_uri;
                      return of(document);
                    }),
                    catchError(err => {
                      this.tools.error('storeDocuments ERROR', err);
                      return of(document);
                    })
                  )
                );
              }
            } else {
              documents$.push(of(document));
            }
          });
          return forkJoin(documents$);
        } else {
          return of(null);
        }
      })
    );
  }

  /**
   * Store file in device's file system an return its uri
   * @param blob file's blob
   * @param documentId document Id
   * @param fileName File name
   * @param fileType File's extension
   */
  public storeFile(
    blob: Blob,
    documentId: number | string,
    fileName: string,
    fileType: string
  ): Observable<MandateContract> {
    return this.tools.readFileObs(blob).pipe(
      mergeMap(content => this.encryptSrv.encrypt(documentId, content)),
      mergeMap((result: any) => {
        const enc = result.enc || !this.tools.isPicture(fileType) ? FilesystemEncoding.UTF8 : null;

        return from(this.fileWrite(fileName, result.encryptContent, null, enc)).pipe(
          catchError(() => {
            return of(null);
          })
        );
      }),
      map(result => {
        return {
          uri: result.device_uri,
          id: documentId,
          fileName,
          fileType,
        };
      })
    );
  }

  /**
   * Store file without encryption in device's file system an return its uri
   * @param blob file's blob
   * @param fileName File's name
   * @param fileType File's type
   * @param folder specific folder where to store created file
   */
  public storeUnencryptedFile(
    blob: Blob,
    fileName: string,
    fileType: string,
    folder: string
  ): Observable<{ device_path: string; device_uri: string }> {
    return this.tools.readFileObs(blob).pipe(
      mergeMap((content: string) => {
        const enc = !this.tools.isPicture(fileType) ? FilesystemEncoding.UTF8 : null;

        return from(this.fileWrite(fileName, content, folder, enc));
      }),
      catchError(err => {
        this.tools.error('storeUnencryptedFile', err);
        return of(null);
      })
    );
  }

  /**
   * Store file in device's file system an return its uri
   * @param data file's text
   * @param fileName File name
   * @param folder specific folder where to store created file
   */
  public storeSVGFile(data: string, fileName: string, folder: string): Observable<string> {
    return from(this.fileWrite(fileName, data, folder, FilesystemEncoding.UTF8)).pipe(
      map(result => {
        return result.device_uri;
      }),
      catchError(err => {
        this.tools.error('storeSVGFile', err);
        return of(null);
      })
    );
  }

  /**
   * Return an observable Base64 encoded document
   * @param checksum checksum of document
   * @param customerId customer id of document's owner
   */
  public getDocumentByChecksum(checksum: string, customerId?: number): Observable<Blob> {
    return this.wsSrv.requestDocumentByChecksum(checksum, customerId);
  }

  /**
   * Return an observable of an array of document's category according to insuranceTypeId
   * @param insuranceTypeId insurance type id
   */
  public getDocumentCategoriesByInsuranceType(insuranceTypeId: number): Observable<DocumentCategory[]> {
    return this.store.getDocumentCategories().pipe(
      mergeMap((documentCategories: DocumentCategoryItem[]) => {
        const insuranceType = _.find(documentCategories, { insurance_type_id: insuranceTypeId });
        const categories = insuranceType ? insuranceType.categories : {};

        return of(_.values(categories));
      })
    );
  }

  // /**
  //  * return an observable of a document category
  //  * @param categoryId document category id
  //  */
  // public getDocumentCategoryById(categoryId: number): Observable<DocumentCategoryItem> {
  //   return this.store.getDocumentCategories().pipe(map(documentCategories => documentCategories[categoryId]));
  // }

  /**
   * Return an observable of a document according to type and category params
   * @param category document's category
   * @param customerId customer Id
   */
  public getDocumentByTypeAndCategory(category: number, customerId: number): Observable<Document> {
    return this.store.getDocuments().pipe(
      map(documents => {
        return _.find(documents, { category_id: category, customer_id: customerId });
      })
    );
  }

  /**
   * Save safebox's picture as a document in BO
   * @param safeboxId safebox id
   * @param categoryId document's category
   * @param document uploaded document
   */
  public saveSafeboxDocument(safeboxId: number, categoryId: number, document: UploadDocument): Observable<any> {
    return this.store.getAuthData().pipe(
      first(),
      mergeMap(authData => {
        // delete base64 tag
        document.data = document.data.replace(/data:.*;base64,/, '');
        const name = document.title
          ? document.title + '.' + document.ext
          : `${authData.customerToken}_safe.${document.ext}`;

        return this.wsSrv.postDocument(
          {},
          {
            category_id: categoryId,
            safebox_id: safeboxId,
            file: { tmp: document.data, name, ext: document.ext },
          }
        );
      })
    );
  }

  /**
   * Save refund's document as a document in BO
   * @param refundId Refund's id
   * @param document uploaded document
   * @param customerId customer's id
   */
  public saveRefundDocument(refundId: number, document: UploadDocument, customerId?: number): Observable<any> {
    // delete base64 tag
    document.data = document.data.replace(/data:.*;base64,/, '');
    const name = document.title ? document.title + '.' + document.ext : `${customerId}_safe.${document.ext}`;

    return this.wsSrv.postDocument(
      { customer_id_linked: customerId },
      {
        refund_id: refundId,
        file: { tmp: document.data, name, ext: document.ext },
      }
    );
  }

  /**
   * Save a document to BO
   * @param document uploaded document
   * @param customerId customer's id
   * @param categoryId document's category id
   * @param customerToken customer's token
   * @param policyId policy's id
   */
  public saveDocument(
    document,
    customerId?: number,
    categoryId?: number,
    customerToken?: string,
    policyId?: number
  ): Observable<any> {
    const data = {
      file: document,
      category_id: categoryId,
    };

    if (policyId) {
      // @ts-ignore
      data.policy_id = policyId;
    }

    const params = {};

    if (customerToken) {
      // @ts-ignore
      params.api_token = customerToken;
    }
    if (customerId) {
      // @ts-ignore
      params.customer_id_linked = customerId;
    }

    return this.wsSrv.postDocument(params, data).pipe(map((apiResponse: ApiResponse) => apiResponse.document));
  }

  /**
   * Delete a document
   * @param document document object
   */
  public deleteDocument(document: Document): Observable<{ success: boolean }> {
    const id = document.id || document.document_id;
    return (
      this.wsSrv
        // @ts-ignore
        .deleteDocument(id)
        .pipe(
          tap((result: { success: boolean }) => {
            if (result.success && this.tools.isNative()) {
              this.removeFile(document.device_uri)
                .then(() => {})
                .catch(err => {
                  throwError(err);
                });
            }
          }),
          catchError(err => {
            this.tools.error('deleteDocument', err);
            return of({ success: false });
          })
        )
    );
  }

  /**
   * Generate a pdf data string from images
   * @param images an array of uploaded images
   */
  // public generatePdfFromImages(images: Array<UploadDocument>): Promise<string> {
  //   try {
  //     const pdf = new JSPDF({
  //       orientation: 'p',
  //       unit: 'mm',
  //       format: 'a4',
  //     });
  //     const maxWidth = 180;
  //     let width: number;
  //     const maxHeight = 260;
  //     let height: number;
  //     const maxRatio = maxWidth / maxHeight;
  //
  //     _.forEach(images, document => {
  //       // set image dimensions
  //       const ratio = document.width / document.height;
  //       if (ratio > maxRatio) {
  //         // width fixes limit
  //         width = maxWidth;
  //         height = (maxWidth / document.width) * document.height;
  //       } else {
  //         // height fixes limit
  //         height = maxHeight;
  //         width = (maxHeight / document.height) * document.width;
  //       }
  //
  //       pdf.addImage(document.data, 'JPEG', 15, 15, width, height);
  //
  //       pdf.addPage();
  //     });
  //
  //     return pdf.output('dataurlstring');
  //   } catch (e) {
  //     this.tools.error('generatePdfFromImages error', e);
  //     throw e;
  //   }
  // }

  /**
   * Return an observable of a UploadDocument object
   * @param event an Event object
   * @param file a File object
   */
  public handleSelectedDocument(event: Event, file?: File): Observable<UploadDocument> {
    return new Observable((observer: Observer<any>) => {
      // fetch file from event
      file = file || (event.target as HTMLInputElement).files[0];
      const reader = this.tools.getFileReader();
      // send result when file is loaded
      reader.onloadend = () => {
        this.generateUploadedDocumentByFile(file, reader.result as string)
          .then((uploadedDocument: UploadDocument) => {
            observer.next(uploadedDocument);
            observer.complete();
          })
          .catch(err => {
            this.tools.error('generateUploadedDocument onerror', err);
            observer.error(err);
          });
      };
      // manage error state
      reader.onerror = (e: Event) => {
        this.tools.error('DocumentService onerror', e);
        observer.error(reader.error);
      };

      // read file as Base64 stream
      reader.readAsDataURL(file);
    });
  }

  /**
   * Return a promise of an UploadDocument object generate from document and reader
   * @param document  uploaded document
   * @param readerResult the stream of the uploaded document
   */
  public async generateUploadedDocument(document: Document, readerResult: any): Promise<UploadDocument> {
    const ext = document.document_file_type;
    const name = document.document_filename && document.document_filename.replace('.' + ext, '');

    let uploadedDocument: UploadDocument;

    try {
      if (this.tools.isPicture(ext)) {
        const dimensions = await this.imageSrv.getUploadedImageDimensions({
          file: null,
          base64: readerResult as string,
        });
        uploadedDocument = {
          document_id: document.document_id,
          category_id: document.document_category_id,
          checksum: document.document_checksum,
          filename: document.document_filename,
          title: null,
          thumbnail: readerResult as string,
          spriteItem: null,
          data: readerResult as string,
          ext,
          width: dimensions.width,
          height: dimensions.height,
        };
      } else {
        let spriteItem: string;

        switch (ext) {
          case 'pdf':
            spriteItem = 'pdf-icon';
            break;
          case 'doc':
            spriteItem = 'word-icon';
            break;
          case 'docx':
            spriteItem = 'word-icon';
            break;
          case 'xls':
            spriteItem = 'excel-icon';
            break;
          case 'xlsx':
            spriteItem = 'excel-icon';
            break;
          default:
            spriteItem = 'insurance_resume_icon_1';
        }
        uploadedDocument = {
          document_id: document.document_id,
          category_id: document.document_category_id,
          checksum: document.document_checksum,
          filename: document.document_filename,
          title: name,
          thumbnail: null,
          spriteItem,
          data: readerResult as string,
          ext,
        };
      }

      return uploadedDocument;
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Ask BO to send an email to the customer with an attached document
   * @param documentId id of the document to send to the customer
   */
  public mailDocumentToCustomer(documentId: number): Observable<any> {
    return this.wsSrv.requestMailDocumentToCustomer(documentId, {});
  }

  /**
   * Return a promise of an UploadDocument object generate from the reader result
   * @param file uploaded document
   * @param readerResult fileReader result
   */
  public async generateUploadedDocumentByFile(file: File, readerResult: string): Promise<UploadDocument> {
    const regExp = /data:image/;
    const isPicture = regExp.test(readerResult);
    const ext = this.tools.getExtension(file.name);
    const name = file.name.replace('.' + ext, '');
    let uploadedDocument: UploadDocument;

    try {
      if (isPicture) {
        const dimensions = await this.imageSrv.getUploadedImageDimensions({ file, base64: null });
        uploadedDocument = {
          title: null,
          thumbnail: readerResult,
          spriteItem: null,
          data: readerResult,
          ext,
          width: dimensions.width,
          height: dimensions.height,
        };
      } else {
        let spriteItem: string;

        switch (ext) {
          case 'pdf':
            spriteItem = 'pdf-icon';
            break;
          case 'doc':
            spriteItem = 'word-icon';
            break;
          case 'docx':
            spriteItem = 'word-icon';
            break;
          case 'xls':
            spriteItem = 'excel-icon';
            break;
          case 'xlsx':
            spriteItem = 'excel-icon';
            break;
          default:
            spriteItem = 'insurance_resume_icon_1';
        }
        uploadedDocument = {
          title: name,
          thumbnail: null,
          spriteItem,
          data: readerResult,
          ext,
        };
      }
      return uploadedDocument;
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Return an observable of temporary document's path
   * @param uri file's uri
   * @param fileType file MIME type
   * @param documentId file's id
   */
  public getFileData(documentId: number | string, fileType: string, uri: string): Observable<string> {
    const enc = !this.tools.isPicture(fileType) ? FilesystemEncoding.UTF8 : null;

    return from(this.fileRead(uri, enc)).pipe(
      mergeMap(result => {
        return this.encryptSrv.decrypt(documentId, result.data);
      })
    );
  }

  /**
   * Return content of file corresponding to uri
   * @param uri file uri
   * @param encoding optional encoding type... default is base64
   */
  public async fileRead(uri: string, encoding?: FilesystemEncoding): Promise<FileReadResult> {
    try {
      return await Filesystem.readFile({
        path: uri,
        encoding,
      });
    } catch (e) {
      this.tools.error('fileRead error', e);
      throw e;
    }
  }

  /**
   * Return an array of file's names
   * @param folder folder's name to read
   */
  public async folderRead(folder: string): Promise<string[]> {
    const path = folder ? `${environment.FILE_FOLDER}/${folder}` : `${environment.FILE_FOLDER}`;

    try {
      const result = await Filesystem.readdir({
        path,
        directory: FilesystemDirectory.Data,
      });

      return result.files;
    } catch (e) {
      return [];
    }
  }

  /**
   * Delete tmp folder on app launch
   */
  public removeAllTmpDocuments(): Observable<any> {
    if (this.tools.isNative()) {
      return from(
        Filesystem.rmdir({
          directory: FilesystemDirectory.Data,
          path: `${environment.FILE_FOLDER}/tmp`,
          recursive: true,
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Open file in device default application
   * @param uri file's uri
   * @param documentId file's id
   * @param fileName file's name
   * @param fileType file's type
   */
  private openInDefaultApplicationWithUri(
    uri: string,
    documentId: number | string,
    fileName: string,
    fileType: string
  ): Observable<any> {
    const enc = !this.tools.isPicture(fileType) ? FilesystemEncoding.UTF8 : null;

    return from(this.fileRead(uri, enc)).pipe(
      mergeMap(result => this.encryptSrv.decrypt(documentId, result.data)),
      mergeMap(content => this.tmpFileWrite(fileName, fileType, content)),
      mergeMap(uriTmp => from(this.openDocument(uriTmp, fileType))),
      mergeMap(() => {
        return of(this.tools.hideLoader());
      })
    );
  }

  /**
   * Open file in device default application
   * @param blob file's blob
   * @param fileName file's name
   * @param fileType file's type
   */
  private openInDefaultApplicationWithBlob(blob: Blob, fileName: string, fileType: string): Observable<any> {
    this.tools
      .readFileObs(blob)
      .pipe(
        mergeMap(content => this.tmpFileWrite(fileName, fileType, content)),
        mergeMap(uriTmp => from(this.openDocument(uriTmp, fileType))),
        mergeMap(() => of(this.tools.hideLoader())),
        catchError(err => {
          this.tools.error('openInDefaultApplicationWithBlob ERROR ', err);
          return throwError(err);
        })
      )
      .subscribe();

    return of(null);
  }

  /**
   * Open a file in browser
   * @param blob file's blob
   */
  public openFileInBrowser(blob: Blob): Observable<any> {
    if (window.navigator.msSaveOrOpenBlob) {
      // IE 11+
      window.navigator.msSaveOrOpenBlob(blob, 'my.pdf');
    } else if (window.navigator.userAgent.match('FxiOS') || window.navigator.userAgent.match('CriOS')) {
      const reader = new FileReader();

      reader.onload = () => {
        // @ts-ignore
        window.location.href = reader.result;
      };

      reader.readAsDataURL(blob);
    } else if (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i)) {
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = data;
      link.download = 'file.pdf';
      link.click();

      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    } else {
      const fileUrl = window.URL.createObjectURL(blob);
      window.open(fileUrl, '_blank');
    }

    this.tools.hideLoader();
    return of(null);
  }

  /**
   * Open file in a native app
   * @param uri temporary file uri
   * @param fileType file's mime type
   */
  private async openDocument(uri: string, fileType: string): Promise<void> {
    const mimeType = this.tools.getMIMEType(fileType);

    try {
      return await this.fileOpener.open(uri, mimeType);
    } catch (e) {
      this.tools.error('openDocument', e);
      throw e;
    }
  }

  /**
   * Use Capacitor FileSystem Plugin to store the document and return its locale path
   * @param fileName  file name
   * @param data file data
   * @param folder optional sub folder where to store the file
   * @param encoding optional encoding type... default is base64
   */
  private async fileWrite(
    fileName: string,
    data: string,
    folder?: any,
    encoding?: FilesystemEncoding
  ): Promise<{ device_path: string; device_uri: string }> {
    const path = !!folder
      ? `${environment.FILE_FOLDER}/${folder}/${fileName}`
      : `${environment.FILE_FOLDER}/${fileName}`;

    try {
      const result = await Filesystem.writeFile({
        path,
        data,
        directory: FilesystemDirectory.Data,
        recursive: true,
        encoding,
      });

      return {
        device_path: Capacitor.convertFileSrc(result.uri),
        device_uri: result.uri,
      };
    } catch (e) {
      this.tools.error('Unable to write file', e);
      throw e;
    }
  }

  /**
   * Use Capacitor FileSystem Plugin to store the decrypted document and return its uri
   * @param fileName  file name
   * @param fileType  file MIME type
   * @param data file data
   */
  private async tmpFileWrite(fileName: string, fileType: string, data): Promise<string> {
    try {
      const path = `${environment.FILE_FOLDER}/tmp/${fileName}.${fileType}`;
      const result = await Filesystem.writeFile({
        path,
        data,
        directory: FilesystemDirectory.Data,
        recursive: true,
      });

      const listener = App.addListener('appStateChange', state => {
        if (!state.isActive) {
          const taskId = BackgroundTask.beforeExit(() => {
            setTimeout(() => {
              Filesystem.deleteFile({
                directory: FilesystemDirectory.Data,
                path,
              })
                .then(() => {
                  BackgroundTask.finish({
                    taskId,
                  });
                  listener.remove();
                })
                .catch(err => this.tools.error('deleteFile', err));
            }, 60 * 1000);
          });
        }
      });

      return result.uri;
    } catch (e) {
      this.tools.error('tmpFileWrite error', e);
      throw e;
    }
  }

  /**
   * Delete file from device file system
   * @param uri file uri
   */
  public async removeFile(uri: string): Promise<any> {
    try {
      return await Filesystem.deleteFile({ path: uri });
    } catch (e) {
      this.tools.error('removeFile error', e);
      throw e;
    }
  }

  /**
   * Remove documents from store and delete corresponding files from device files system
   * @param documentsFromStore documents stored
   * @param documentIdsToRemove an array of document's id to remove from store
   */
  private removeDocumentsFromStore(
    documentsFromStore: { [id: string]: Document },
    documentIdsToRemove: string[]
  ): Observable<any[]> {
    const obs$ = [];
    const documents = _.cloneDeep(documentsFromStore);
    _.forEach(documentIdsToRemove, id => {
      if (this.tools.isNative()) {
        obs$.push(from(this.removeFile(documents[id].device_uri)));
      } else {
        obs$.push(of(null));
      }
      delete documents[id];
    });

    return forkJoin([of(documents), concat(...obs$).pipe(toArray())]).pipe(
      catchError(err => {
        this.tools.error('removeDocumentsFromStore Error', err);
        return forkJoin([of(documents)]);
      })
    );
  }

  /**
   * make a category document first item of array
   * @param docs documents array
   * @param category category to look for
   */
  private sortDocumentByCategory(docs: Document[], category: string): Document[] {
    const CAT = this.constantSrv.getValueFromKey(category);
    let docsCloned = _.cloneDeep(docs);

    const policyDocumentArr = _.remove(docsCloned, { category_id: CAT });

    if (policyDocumentArr && policyDocumentArr.length) {
      docsCloned = [...policyDocumentArr, ...docsCloned];
    }

    return docsCloned;
  }
}
