import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';

import { ToolsService } from './tools.service';
import { StoreService } from './store.service';
import { WsService } from './ws.service';
import { LanguageService } from './language.service';
import { DataMonitorService } from './data-monitor.service';
import { Constant, ForeignTable } from '../gfl-models/constant.model';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ConstantService {
  public constantsCurrentLanguage: Constant[] = [];
  private constants: { [lang: string]: Constant[] } = {};
  public foreignTables: ForeignTable[] = [];
  private readonly appName = environment.APP_NAME;

  constructor(
    private wsSrv: WsService,
    private tools: ToolsService,
    private store: StoreService,
    private langSrv: LanguageService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const lang$ = this.store.getLang().pipe(filter(val => !!val));

    const locksToMonitor = [
      {
        name: 'constants',
        lock: () => this.store.getConstantsLock(),
        cb: () => lang$.pipe(mergeMap(lang => this.setConstants(lang))),
      },
      {
        name: 'foreign_tables',
        lock: () => this.store.getForeignTablesLock(),
        cb: () => this.setForeignTables(),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Fetch constants on app initialization
   */
  public loadConstants(): Promise<void> {
    return new Promise(resolve => {
      this.manageConstants().subscribe(() => {
        resolve();
      });
    });
  }

  /**
   * update constants
   */
  public manageConstants(): Observable<any> {
    // first we fetch from localstorage
    return forkJoin([
      this.store.get(`${this.appName}_constants`),
      this.store.get(`${this.appName}_foreign_tables`),
      this.langSrv.getLang().pipe(first()),
    ]).pipe(
      mergeMap(([constants, foreignTables, lang]) => {
        const obs$: Observable<any>[] = [];
        if (constants && constants[lang]) {
          this.constants = constants;
          this.constantsCurrentLanguage = constants[lang];
          obs$.push(of(constants));
        } else {
          // if no local data then we fetch via http request
          obs$.push(this.setConstants(lang));
        }

        if (foreignTables) {
          this.foreignTables = foreignTables;
          obs$.push(of(foreignTables));
        } else {
          // if no local data then we fetch via http request
          obs$.push(this.setForeignTables());
        }

        return forkJoin(obs$);
      })
    );
  }

  /**
   * Retrieve Constants from BO endpoint
   * @param lang selected language
   * @param reset if true empty data first
   */
  public setConstants(lang: string, reset?: boolean): Observable<any> {
    return this.store.setConstantsLock(Date.now()).pipe(
      mergeMap(() => this.wsSrv.requestContants()),
      mergeMap((response: HttpResponse<Constant[]>) => {
        const boVersion = response.headers.get('bo-version');
        this.constantsCurrentLanguage = response.body as Constant[];
        this.constants = reset ? {} : this.constants;

        this.constants[lang] = this.constantsCurrentLanguage;

        return forkJoin([
          this.store.set(`${this.appName}_constants`, this.constants),
          this.store.set(`${this.appName}_bo-version`, boVersion),
        ]);
      }),
      mergeMap(() => this.store.setConstantsLock(null)),
      catchError(err => {
        this.tools.error('ConstantService setConstants()', err);
        return of(true);
      })
    );
  }

  /**
   * Retrieve Foreign tables from BO endpoint
   */
  public setForeignTables(): Observable<any> {
    return this.store.setForeignTablesLock(Date.now()).pipe(
      mergeMap(() => this.wsSrv.requestForeignTables()),
      map((foreignTables: ForeignTable[]) => {
        this.foreignTables = foreignTables;
        return foreignTables;
      }),
      mergeMap(foreignTables => this.store.set(`${this.appName}_foreign_tables`, foreignTables)),
      mergeMap(() => this.store.setForeignTablesLock(null)),
      catchError(err => {
        this.tools.error('ConstantService setForeignTables()', err);
        return of(true);
      })
    );
  }

  /**
   * Get constants from BO
   */
  public getConstants(): Array<Constant> {
    return _.cloneDeep(this.constantsCurrentLanguage);
  }

  /**
   * Return the translated key based on the category and value constant
   * @param category constant's category
   * @param value constant's value
   */
  public getValueFromConstant(category: string, value: string | number): string {
    const result = this.getConstants().filter(item => item.category === category && item.value === value);

    if (result.length > 0 && result[0].key_translate) {
      return result[0].key_translate;
    }

    return '';
  }

  /**
   * Return number value corresponding to the key
   * @param key constant's key
   */
  public getValueFromKey(key: string): number {
    const result = _.find(this.getConstants(), { key });
    return result && parseInt(result.value, 10);
  }

  /**
   * Return string value corresponding to the key
   * @param key constant's key
   */
  public getStringValueFromKey(key: string): string {
    const result = _.find(this.getConstants(), { key });
    return result.value;
  }

  /**
   * Return the translated key according to key parameter
   * @param  key constant's key
   */
  public getTranslateKeyConstantByKey(key: string): string {
    const result = this.getConstants().filter(item => item.key === key);

    if (result.length > 0 && result[0].key_translate) {
      return result[0].key_translate;
    }

    return '';
  }

  /**
   * Return the id of the key parameter
   * @param key constant's key
   */
  public getIdConstantByKey(key: string): string {
    const result = this.getConstants().filter(item => item.key === key);

    if (result.length > 0 && result[0].key_translate) {
      return result[0].value;
    }

    return null;
  }

  /**
   * Return constants of the corresponding foreign table's id
   * @param foreignTableId the table's id
   * @param apiToken Api token provided by BO
   */
  public getConstantByForeignTable(foreignTableId: number, apiToken?: string): Observable<Array<Constant>> {
    const res = this.getConstants().filter(item => item.foreign_table_id === foreignTableId);
    return of(res);
  }

  /**
   * Return constants of the corresponding foreign table's name
   * @param foreignTableName the table's name
   */
  public getConstantByForeignTableName(foreignTableName: string): Observable<Array<Constant>> {
    const foreignTable = _.find(this.foreignTables, { key: foreignTableName });
    return of(_.filter(this.getConstants(), { foreign_table_id: foreignTable.id }));
  }

  /**
   * Return Foreign table's id retrieved by foreign table's name
   * @param foreignTableName the table's name
   * @param numberType if true return a number else an observable
   */
  public getForeignTableIdByName(foreignTableName: string, numberType?: boolean): Observable<number> | number {
    const foreignTable = _.find(this.foreignTables, { key: foreignTableName });

    return numberType ? foreignTable.id : of(foreignTable.id);
  }
}
