import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { DefaultAddress, FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { GflThemeOptions } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form-options.model';
import { CompareFO, ComparePoliciesFO } from '../../models/compare.model';
import { DocumentBO } from '../../../gfl-core/gfl-models/document.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';
import { NetworkMonitorService } from '../../../gfl-core/gfl-services/network-monitor.service';

@Component({
  selector: 'gfl-compare-list-select',
  templateUrl: './compare-list-select.component.html',
  styleUrls: ['./compare-list-select.component.scss'],
})
export class CompareListSelectComponent implements OnInit, OnDestroy {
  /**
   * policy offer chosen by customer
   */
  @Input() policy: ComparePoliciesFO;
  /**
   * compare studied
   */
  @Input() compare$: Observable<CompareFO>;
  /**
   * Front theme style
   */
  @Input() style: FrontTheme;
  /**
   * Permissions definition object
   */
  @Input() acls: Acls;
  /**
   * flag on true if compare current_status_id is COMPARE_TERMINATED
   */
  @Input() isTerminatedCompare: boolean;

  /**
   * Mode display type
   */
  public modeDisplay: GflModeDisplayType;
  /**
   * flag on true if form is edited
   */
  public isEditMode = false;
  public isOffline: boolean;
  public subscriptions: Subscription[] = [];
  public form = new FormGroup({});
  readonly CONTACT_TYPE_ID_AGENCY_CONTACT: number;
  public advisorEmail$: Observable<string>;
  public address$: Observable<DefaultAddress>;
  /**
   * theme options for main items generated by GflFieldGeneratorComponent
   */
  public mainItemTheme: GflThemeOptions = {};

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private modalCtrl: ModalController,
    private documentSrv: DocumentService,
    public notificationSrv: NotificationService,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private constantSrv: ConstantService,
    private agencySrv: AgencyService,
    private network: NetworkMonitorService
  ) {
    this.CONTACT_TYPE_ID_AGENCY_CONTACT = this.constantSrv.getValueFromKey('CONTACT_TYPE_ID_AGENCY_CONTACT');
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.setModeDisplay();

    this.advisorEmail$ = this.agencySrv.getMyContact().pipe(map(contact => contact.email));

    this.address$ = this.agencySrv.getAgencyAddress();

    this.subscriptions.push(
      this.network.isOffline().subscribe(flag => {
        this.isOffline = flag;
      })
    );

    this.mainItemTheme = {
      tabletLabelTxtColor: this.style.color_txt_label,
      tabletContentTxtColor: this.style.color_success,
      mobileLabelTxtColor: this.style.color_txt_label,
      mobileContentTxtColor: this.style.color_success,
    };
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Close Modal
   */
  public closeModal(): void {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * open offer pdf file
   */
  public openDocument(): void {
    const document = this.getDocument(this.policy.documents);
    this.openFile(document);
  }

  /**
   * Send a request to the bo to send an email to the customer with offer pdf file attached
   */
  public sendDocument(): void {
    let loader: HTMLIonLoadingElement;
    // Display loading modal
    this.translate.get('MODAL_SENDING_EMAIL').subscribe(async value => {
      loader = await this.loadingCtrl.create({
        message: value,
      });
      await loader.present();

      const document = this.getDocument(this.policy.documents);

      if (document) {
        this.mailDocument(document.document_id, loader);
      } else {
        this.notificationSrv.showError({
          message: 'MAIL_NOT_SENT',
          showCloseButton: true,
        });
      }
    });
  }

  /**
   * Send a mail form the BO with the offer document
   * @param documentId document id
   * @param loader ionic loader element
   */
  private mailDocument(documentId: number, loader: HTMLIonLoadingElement): void {
    this.documentSrv.mailDocumentToCustomer(documentId).subscribe(
      () => {
        loader.dismiss().then(() => {
          this.notificationSrv.showSuccess({ message: 'COMPARE.DISPLAY.MAIL_SENT' });
        });
      },
      err => {
        loader.dismiss().then(() => {
          this.tools.error('CompareDisplayComponent displayOfferModal()', err);
          this.notificationSrv.showError({
            message: 'COMPARE.ERRORS.MAIL_NOT_SENT',
            showCloseButton: true,
            // dismissOnPageChange: true,
          });
        });
      }
    );
  }

  /**
   * Open document file
   * @param document document bject
   */
  private openFile(document: DocumentBO): void {
    this.documentSrv.openDocumentFile(document.document_id);
  }

  /**
   * Return a document of offer category
   * @param documents documents of policy
   */
  private getDocument(documents: Array<DocumentBO>): DocumentBO {
    const key = this.isTerminatedCompare ? 'DOCUMENT_CATEGORY_ID_SIGNED_OFFER' : 'DOCUMENT_CATEGORY_ID_OFFER';
    const DOCUMENT_CATEGORY_ID = this.constantSrv.getValueFromKey(key);
    return _.find(documents, { document_category_id: DOCUMENT_CATEGORY_ID });
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }
}
