import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentBO } from '../../../gfl-core/gfl-models/document.model';
import * as _ from 'lodash';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { RowPremiumFO } from '../../models/compare.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
// tslint:disable-next-line:max-line-length
import { SignatureFullScreenComponent } from '../../../authentication/components/signature-full-screen/signature-full-screen.component';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'gfl-compare-insurimmo-offer',
  templateUrl: './compare-insurimmo-offer.component.html',
  styleUrls: ['./compare-insurimmo-offer.component.scss'],
})
export class CompareInsurimmoOfferComponent implements OnInit {
  @Input() policy: RowPremiumFO;
  @Output() title = new EventEmitter<string>();
  @Output() nextButtonLabel = new EventEmitter<string>();
  @Output() signed: EventEmitter<number> = new EventEmitter<number>();
  @Input() customerId: number;
  @Output() signature = new EventEmitter<string>();
  @Output() enableNext = new EventEmitter<boolean>();

  public signatureImg = '';

  constructor(
    private documentSrv: DocumentService,
    private constantSrv: ConstantService,
    private tools: ToolsService,
    private notificationSrv: NotificationService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.title.emit('COMPARE.DISPLAY.SEE_OFFER_DOC');
    this.nextButtonLabel.emit('COMPARE.DISPLAY.SIGN_OFFER');
  }

  /**
   * Open Modal to add Signature
   */
  public async openSignatureModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: SignatureFullScreenComponent,
    });
    modal.onDidDismiss().then(rep => {
      this.signatureImg = rep.data && rep.data.signatureImg;
      this.signature.emit(this.signatureImg);
      this.enableNext.emit(true);
    });
    await modal.present();
  }

  /**
   * open offer pdf file
   */
  public openDocument(): void {
    const document = this.getDocument(this.policy.documents);
    this.openFile(document);
  }

  /**
   * Open document file
   * @param document document bject
   */
  private openFile(document: DocumentBO): void {
    this.documentSrv.openDocumentFile(document.document_id);
  }

  /**
   * Return a document of offer category
   * @param documents documents of policy
   */
  private getDocument(documents: Array<DocumentBO>): DocumentBO {
    const key = 'DOCUMENT_CATEGORY_ID_OFFER';
    const DOCUMENT_CATEGORY_ID = this.constantSrv.getValueFromKey(key);
    return _.find(documents, { document_category_id: DOCUMENT_CATEGORY_ID });
  }
}
