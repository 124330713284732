import { createReducer, on } from '@ngrx/store';
import { LocksActions } from './action.types';

export interface LocksState {
  documentCategoriesLock: number;
  documentsLock: number;
  safeboxTypesLock: number;
  safeboxesLock: number;
  agencyLock: number;
  sliderLock: number;
  companiesLock: number;
  insuranceTypesLock: number;
  policiesLock: number;
  customerLock: number;
  chatItemsLock: number;
  comparesLock: number;
  constantsLock: number;
  foreignTablesLock: number;
  spriteLock: number;
  statusesLock: number;
  rolesLock: number;
  itemTemplatesLock: number;
  translationsLock: number;
  permissionsLock: number;
}

export const initialLocksState: LocksState = {
  documentCategoriesLock: null,
  documentsLock: null,
  safeboxTypesLock: null,
  safeboxesLock: null,
  agencyLock: null,
  sliderLock: null,
  companiesLock: null,
  insuranceTypesLock: null,
  policiesLock: null,
  customerLock: null,
  chatItemsLock: null,
  comparesLock: null,
  constantsLock: null,
  foreignTablesLock: null,
  spriteLock: null,
  statusesLock: null,
  rolesLock: null,
  itemTemplatesLock: null,
  translationsLock: null,
  permissionsLock: null,
};

const _locksReducer = createReducer(
  initialLocksState,

  on(LocksActions.setDocumentCategoriesLock, (state, action) => {
    return {
      ...state,
      documentCategoriesLock: action.lock,
    };
  }),

  on(LocksActions.setDocumentsLock, (state, action) => {
    return {
      ...state,
      documentsLock: action.lock,
    };
  }),

  on(LocksActions.setSafeboxTypesLock, (state, action) => {
    return {
      ...state,
      safeboxTypesLock: action.lock,
    };
  }),

  on(LocksActions.setAgencyLock, (state, action) => {
    return {
      ...state,
      agencyLock: action.lock,
    };
  }),

  on(LocksActions.setSlidersLock, (state, action) => {
    return {
      ...state,
      sliderLock: action.lock,
    };
  }),

  on(LocksActions.setPoliciesAndMandatesLock, (state, action) => {
    return {
      ...state,
      policiesLock: action.lock,
    };
  }),

  on(LocksActions.setInsuranceTypesLock, (state, action) => {
    return {
      ...state,
      insuranceTypesLock: action.lock,
    };
  }),

  on(LocksActions.setCompaniesLock, (state, action) => {
    return {
      ...state,
      companiesLock: action.lock,
    };
  }),

  on(LocksActions.setCustomerLock, (state, action) => {
    return {
      ...state,
      customerLock: action.lock,
    };
  }),

  on(LocksActions.setChatItemsLock, (state, action) => {
    return {
      ...state,
      chatItemsLock: action.lock,
    };
  }),

  on(LocksActions.setComparesLock, (state, action) => {
    return {
      ...state,
      comparesLock: action.lock,
    };
  }),

  on(LocksActions.setConstantsLock, (state, action) => {
    return {
      ...state,
      constantsLock: action.lock,
    };
  }),

  on(LocksActions.setSpriteLock, (state, action) => {
    return {
      ...state,
      spriteLock: action.lock,
    };
  }),

  on(LocksActions.setStatusesLock, (state, action) => {
    return {
      ...state,
      statusesLock: action.lock,
    };
  }),

  on(LocksActions.setPermissionsLock, (state, action) => {
    return {
      ...state,
      permissionsLock: action.lock,
    };
  }),

  on(LocksActions.setRolesLock, (state, action) => {
    return {
      ...state,
      rolesLock: action.lock,
    };
  }),

  on(LocksActions.setItemTemplatesLock, (state, action) => {
    return {
      ...state,
      itemTemplatesLock: action.lock,
    };
  }),

  on(LocksActions.setTranslationLock, (state, action) => {
    return {
      ...state,
      translationsLock: action.lock,
    };
  })
);

export function locksReducer(state, action) {
  return _locksReducer(state, action);
}
