import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';

import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';

@Injectable({
  providedIn: 'root',
})
export class AuthLoginAsCustomerGuard implements CanActivate {
  /**
   * @ignore
   */
  constructor(private tools: ToolsService, private store: StoreService, private navCtrl: NavController) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (route.queryParams && route.queryParams.user_token && route.queryParams.customer_id) {
      this.tools.setCookie(); // refresh cookie for PWA
      return true;
    } else {
      this.navCtrl.navigateRoot('/welcome').then(() => {});
    }
  }
}
