import { NgModule, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import { IonicStorageModule } from '@ionic/storage';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeFr from '@angular/common/locales/fr';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule, RouterState } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { IonicSelectableModule } from 'ionic-selectable';
import { CookieService } from 'ngx-cookie-service';

import { forkJoin, Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GflCoreModule } from './gfl-core/gfl-core.module';
import { CustomerModule } from './customer/customer.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { PoliciesPageModule } from './policies/policies.module';
import { ComparesPageModule } from './compares/compares.module';
import { ContactsModule } from './contacts/contacts.module';

// tslint:disable-next-line:max-line-length
import { NotificationsDisplayComponent } from './contacts/components/notifications-display/notifications-display.component';
import { ChangeCustomerComponent } from './authentication/components/change-customer/change-customer.component';
import { GflUpdateAppComponent } from './gfl-core/gfl-components/gfl-update-app/gfl-update-app.component';

import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { TouchID } from '@ionic-native/touch-id/ngx';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { MobileAccessibility } from '@ionic-native/mobile-accessibility/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { SignaturePad } from 'angular2-signaturepad/signature-pad';
import { Network } from '@ionic-native/network/ngx';
import { Market } from '@ionic-native/market/ngx';

import { ConstantService } from './gfl-core/gfl-services/constant.service';
import { StatusService } from './gfl-core/gfl-services/status.service';
import { ApiService } from './gfl-core/gfl-services/api.service';
import { StoreService } from './gfl-core/gfl-services/store.service';
import { AgencyService } from './gfl-core/gfl-services/agency.service';
import { ToolsService } from './gfl-core/gfl-services/tools.service';
import { CustomerService } from './customer/services/customer.service';
import { LanguageService } from './gfl-core/gfl-services/language.service';
import { RolesService } from './gfl-core/gfl-services/roles.service';

import { httpInterceptorProviders } from './gfl-core/gfl-http-interceptors';
import { AppEffects } from './reducers/app.effects';
import { UiEffects } from './reducers/ui.effects';
import { reducers, metaReducers } from './reducers';

import { environment } from '../environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';

registerLocaleData(localeFr);

export function languageProviderFactory(provider: LanguageService) {
  return () => provider.initService();
}

export function constantsProviderFactory(provider: ConstantService) {
  return () => provider.loadConstants();
}

export function statusesProviderFactory(provider: StatusService) {
  return () => provider.loadStatuses();
}

export function rolesProviderFactory(provider: RolesService) {
  return () => provider.loadRoles();
}

export function agencyProviderFactory(provider: AgencyService) {
  return () => provider.loadAgency();
}

export function storeProviderFactory(provider: StoreService) {
  return () => provider.loadStore(environment.APP_NAME);
}

/**
 * Translation loader class using either storage or http request
 */
export class CustomLoader implements TranslateLoader {
  /**
   * @ignore
   */
  constructor(private store: StoreService, private http: HttpClient) {}

  /**
   * return an observable of translation according to lang
   */
  getTranslation(): Observable<any> {
    let lang;
    let translationObj;

    return this.store.getLang().pipe(
      first(),
      mergeMap(langStr => {
        if (!langStr) {
          lang = navigator.language.substring(0, 2);
        } else {
          lang = langStr;
        }

        return this.store.get(`${environment.APP_NAME}_translations`);
      }),
      mergeMap(translationObjRes => {
        translationObj = translationObjRes || {};

        if (translationObj[lang]) {
          return of(translationObj[lang]);
        } else if (lang) {
          return this.setTranslation(lang, translationObj);
        } else {
          return of(false);
        }
      })
    );
  }

  setTranslation(lang: string, translationObj: object): Observable<any> {
    return this.store.setTranslationsLock(Date.now()).pipe(
      mergeMap(() =>
        forkJoin([
          this.http.get(environment.API_URL + '/translations/mobile?language_iso=' + lang),
          this.http.get(environment.API_URL + '/translations/roles?language_iso=' + lang),
        ])
      ),
      mergeMap(([translationMobileLang, translationRolesLang]) => {
        translationObj[lang] = { ...translationMobileLang, ...translationRolesLang };
        return this.store.set(`${environment.APP_NAME}_translations`, translationObj);
      }),
      mergeMap(() => this.store.setTranslationsLock(null)),
      mergeMap(() => of(translationObj[lang]))
    );
  }
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [NotificationsDisplayComponent, ChangeCustomerComponent, GflUpdateAppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      backButtonText: '',
    }),
    IonicStorageModule.forRoot({
      name: environment.APP_NAME,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
        deps: [StoreService, HttpClient],
      },
    }),
    GflCoreModule,
    AuthenticationModule,
    CustomerModule,
    PoliciesPageModule,
    ComparesPageModule,
    ContactsModule,
    AppRoutingModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    EffectsModule.forRoot([AppEffects, UiEffects]),
    StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      routerState: RouterState.Full,
    }),
    IonicSelectableModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    ApiService,
    ToolsService,
    ConstantService,
    StatusService,
    AgencyService,
    LanguageService,
    StoreService,
    CustomerService,
    CookieService,
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: languageProviderFactory,
      deps: [LanguageService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: constantsProviderFactory,
      deps: [ConstantService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: statusesProviderFactory,
      deps: [StatusService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: rolesProviderFactory,
      deps: [RolesService],
      multi: true,
    },
    { provide: APP_INITIALIZER, useFactory: storeProviderFactory, deps: [StoreService], multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: agencyProviderFactory,
      deps: [AgencyService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fr-CH' },
    SocialSharing,
    File,
    FileTransfer,
    TouchID,
    ScreenOrientation,
    MobileAccessibility,
    FileOpener,
    Chooser,
    SignaturePad,
    Network,
    Market,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private cookieService: CookieService, private tools: ToolsService, private store: StoreService) {
    if (!this.tools.isNative() && !this.cookieService.check(environment.APP_NAME)) {
      this.store.removeAllPersistentStores();
    }
  }
}
