import { NgModule } from '@angular/core';
import { MandatesResumeComponent } from './mandates-resume/mandates-resume.component';
import { PolicyAddComponent } from './policy-add/policy-add.component';
import { PolicyBudgetComponent } from './policy-budget/policy-budget.component';
import { PolicyBudgetGlobalComponent } from './policy-budget-global/policy-budget-global.component';
import { PolicyClaimComponent } from './policy-claim/policy-claim.component';
// tslint:disable-next-line:max-line-length
import { PolicyDocumentsSubheaderTabletComponent } from './policy-documents-subheader-tablet/policy-documents-subheader-tablet.component';
import { PolicyListItemComponent } from './policy-list-item/policy-list-item.component';
import { PolicyRefundComponent } from './policy-refund/policy-refund.component';
import { PolicyRefundAddComponent } from './policy-refund-add/policy-refund-add.component';
import { PolicyRefundEditComponent } from './policy-refund-edit/policy-refund-edit.component';
import { PolicyRefundHistoryComponent } from './policy-refund-history/policy-refund-history.component';
import { PolicyRefundSimpleComponent } from './policy-refund-simple/policy-refund-simple.component';
import { PolicyResumeComponent } from './policy-resume/policy-resume.component';
import { PolicySubheaderTabletComponent } from './policy-subheader-tablet/policy-subheader-tablet.component';
import { IonicModule } from '@ionic/angular';
import { GflCoreModule } from '../../gfl-core/gfl-core.module';
import { GflFormGeneratorModule } from '../../gfl-libraries/gfl-form-generator/gfl-form-generator.module';
import { RouterModule } from '@angular/router';
import { RefundSubheaderTabletComponent } from './refund-subheader-tablet/refund-subheader-tablet.component';
import { PolicyInvoicesComponent } from './policy-invoices/policy-invoices.component';
import { PolicyCgComponent } from './policy-cg/policy-cg.component';

@NgModule({
  declarations: [
    MandatesResumeComponent,
    PolicyAddComponent,
    PolicyBudgetComponent,
    PolicyBudgetGlobalComponent,
    PolicyClaimComponent,
    PolicyDocumentsSubheaderTabletComponent,
    PolicyListItemComponent,
    PolicyRefundComponent,
    PolicyRefundAddComponent,
    PolicyRefundEditComponent,
    PolicyRefundHistoryComponent,
    PolicyRefundSimpleComponent,
    PolicyResumeComponent,
    PolicySubheaderTabletComponent,
    RefundSubheaderTabletComponent,
    PolicyInvoicesComponent,
    PolicyCgComponent,
  ],
  imports: [GflCoreModule, IonicModule, GflFormGeneratorModule.forRoot(), RouterModule],
  entryComponents: [
    MandatesResumeComponent,
    PolicyAddComponent,
    PolicyBudgetComponent,
    PolicyBudgetGlobalComponent,
    PolicyClaimComponent,
    PolicyDocumentsSubheaderTabletComponent,
    PolicyListItemComponent,
    PolicyRefundComponent,
    PolicyRefundAddComponent,
    PolicyRefundEditComponent,
    PolicyRefundHistoryComponent,
    PolicyRefundSimpleComponent,
    PolicyResumeComponent,
    PolicySubheaderTabletComponent,
    RefundSubheaderTabletComponent,
    PolicyCgComponent,
  ],
  exports: [
    MandatesResumeComponent,
    PolicyAddComponent,
    PolicyBudgetComponent,
    PolicyBudgetGlobalComponent,
    PolicyClaimComponent,
    PolicyDocumentsSubheaderTabletComponent,
    PolicyListItemComponent,
    PolicyRefundComponent,
    PolicyRefundAddComponent,
    PolicyRefundEditComponent,
    PolicyRefundHistoryComponent,
    PolicyRefundSimpleComponent,
    PolicyResumeComponent,
    PolicySubheaderTabletComponent,
    RefundSubheaderTabletComponent,
    PolicyInvoicesComponent,
    PolicyCgComponent,
  ],
})
export class PoliciesComponentsModule {}
