import { Injectable } from '@angular/core';
import { TouchID } from '@ionic-native/touch-id/ngx';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

import { ToolsService } from './tools.service';
import { ApiService } from './api.service';
import { NotificationService } from './notification.service';
import { AuthService } from '../../authentication/services/auth.service';
import { ApiResponse } from '../gfl-models/api.model';

import { AuthState } from '../../authentication/reducers';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  readonly URL = {
    LOGIN: '/login',
  };

  /**
   * @ignore
   */
  constructor(
    private apiSrv: ApiService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private touchId: TouchID,
    private tools: ToolsService,
    private authSrv: AuthService,
    private notificationSrv: NotificationService,
    private navCtrl: NavController
  ) {}

  /**
   * Method called at init to ask for the code pin in order to enter the page
   * @param successPath Path to navigate to
   */
  public showCodePin(successPath: string): void {
    this.askAuthentication(
      () => {
        // Success callback
        this.navCtrl.navigateForward(successPath).then(() => this.tools.hideLoader());
      },
      () => {
        // Failure callback
        this.notificationSrv.showError({
          message: 'COMMON.CODE_PIN_ERROR',
        });
        this.tools.hideLoader();
      }
    );
  }

  /**
   * Return an observable of a boolean that checks if credentials are correct
   * @param login customer's login
   * @param password customer's password
   * TODO - make use of the login method of auth service
   */
  public validatePin(login: string, password: string): Observable<boolean> {
    return this.apiSrv
      .httpPost(
        this.URL.LOGIN,
        {},
        {
          login,
          password,
        }
      )
      .pipe(
        mergeMap((apiResponse: ApiResponse) => {
          return of(apiResponse.success);
        })
      );
  }

  /**
   * Display the touch ID box or a prompt popup to a user in order to validate password
   */
  public askAuthentication(success: () => void, fail: () => void): void {
    this.touchId
      .isAvailable()
      .then(() => {
        this.loginWithTouch(success, fail).then(() => {});
      })
      .catch(() => {
        // No TouchId
        this.translate
          .get(['COMMON.CODE_PIN_TITLE', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_VALIDATE'])
          .subscribe(result => this.showAuthenticationPopup(result, success, fail));
      });
  }

  /**
   * Display prompt popup and manage authentication result
   * @param result an object with translated strings
   * @param success the success callback
   * @param fail the fail callback
   */
  private showAuthenticationPopup(result: string, success: () => void, fail: () => void): void {
    this.alertCtrl
      .create({
        header: result['COMMON.CODE_PIN_TITLE'],
        inputs: [{ name: 'code', placeholder: 'Code', type: 'password' }],
        buttons: [
          {
            text: result['COMMON.BUTTON_CANCEL'],
            cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
            role: 'cancel',
            handler: () => {},
          },
          {
            text: result['COMMON.BUTTON_VALIDATE'],
            cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
            handler: data => {
              this.tools.showLoader();

              this.authSrv
                .getAuthData()
                .pipe(
                  first(),
                  mergeMap((authState: AuthState) => {
                    return this.validatePin(authState.customerLogin, data.code);
                  })
                )
                .subscribe(
                  authenticated => {
                    if (authenticated) {
                      success();
                    } else {
                      this.tools.hideLoader();
                      fail();
                    }
                  },
                  err => {
                    this.tools.hideLoader();
                    fail();
                  }
                );
            },
          },
        ],
      })
      .then(alert => alert.present());
  }

  /**
   * Launch login process with Touch ID
   */
  private async loginWithTouch(success: () => void, fail: () => void): Promise<void> {
    try {
      this.translate.get('LOGIN.APP_ACCESS').subscribe((msg: string) => {
        this.verifyFingerprint(msg)
          .then(() => {
            success();
          })
          .catch(err => {
            this.tools.error('Error', err);
            if (err.code === -3) {
              // Fallback authentication mechanism selected.
              this.translate
                .get(['COMMON.CODE_PIN_TITLE', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_VALIDATE'])
                .subscribe(result => this.showAuthenticationPopup(result, success, fail));
            }
          });
      });
    } catch (err) {
      this.tools.error('TouchID is not available', err);
    }
  }

  /**
   * Check finger print and login
   * @param msg a message
   */
  private verifyFingerprint(msg: string): Promise<any> {
    return this.touchId.verifyFingerprintWithCustomPasswordFallback(msg);
  }
}
