import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable, of } from 'rxjs';

import { ToolsService } from './gfl-core/gfl-services/tools.service';

@Injectable({
  providedIn: 'root',
})
export class MobileGuard implements CanActivate {
  /**
   * @ignore
   */
  constructor(private tools: ToolsService, private navCtrl: NavController) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.tools.isMobile()) {
      return of(true);
    } else {
      this.navCtrl.navigateRoot('/policies').then(() => {});
      return of(false);
    }
  }
}
