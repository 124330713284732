import { Injectable } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CameraOptions, CameraPhoto, CameraResultType, CameraSource, Plugins } from '@capacitor/core';

import { Observable, Observer } from 'rxjs';

import { NotificationService } from './notification.service';
import { ToolsService } from './tools.service';

const { Camera } = Plugins;

@Injectable({
  providedIn: 'root',
})
export class ImageService {
  private reader: FileReader = new FileReader();
  private cameraOptions: CameraOptions;

  constructor(
    private tools: ToolsService,
    private actionSheetCtrl: ActionSheetController,
    private translate: TranslateService,
    private platform: Platform,
    private notificationSrv: NotificationService
  ) {
    this.cameraOptions = {
      quality: 50,
      resultType: CameraResultType.DataUrl,
      // encodingType: this.camera.EncodingType.JPEG,
      // mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      height: 1800,
      width: 1350,
    };
  }

  /**
   * Return an observable of the picture encoded in Base64
   * @param event Event object
   */
  public handleSelectedImage(event: Event): Observable<any> {
    return new Observable((observer: Observer<any>) => {
      // fetch file from event
      const file: File = (event.target as HTMLInputElement).files[0];
      // read file as Base64 stream
      this.reader.readAsDataURL(file);
      // send result when file is loaded
      this.reader.onload = () => {
        observer.next(this.reader.result);
        observer.complete();
      };
      // manage error state
      this.reader.onerror = (e: Event) => {
        this.tools.error('ImageService onerror', e);
        observer.error(this.reader.error);
      };
    });
  }

  /**
   * Return dimensions of the image
   * @param  obj  the image file
   */
  public getUploadedImageDimensions(obj: { file: File; base64: string }): Promise<{ width: number; height: number }> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const _URL = window.URL || window.webkitURL;
      const img = new Image();

      if (obj.file) {
        img.src = _URL.createObjectURL(obj.file);
      } else if (obj.base64) {
        img.src = obj.base64;
      } else {
        reject('getUploadedImageDimensions Error - Invalid data');
      }

      img.onload = () => {
        _URL.revokeObjectURL(img.src);

        resolve({
          width: img.naturalWidth,
          height: img.naturalHeight,
        });
      };

      // manage error state
      img.onerror = (event: Event) => {
        this.tools.error('getUploadedImageDimensions onerror', event);
        reject(event);
      };
    });
  }

  /**
   * Select Source type in order to deliver the picture
   */
  public takeNativePicture(cb: (img) => void) {
    this.translate
      .get(['COMMON.PHOTO_FROM_CAMERA', 'COMMON.PHOTO_FROM_LIBRARY', 'COMMON.BUTTON_CANCEL'])
      .subscribe(async result => {
        const actionSheet = await this.actionSheetCtrl.create({
          header: result['COMMON.CONTACT_TITLE'],
          buttons: [
            {
              text: result['COMMON.PHOTO_FROM_CAMERA'],
              cssClass: 'gfl-alert-validate-btn',
              icon: !this.platform.is('ios') ? 'camera' : null,
              handler: () => {
                this.cameraOptions.source = CameraSource.Camera;
                this.getPicture().then(imgObj => {
                  cb(imgObj);
                });
              },
            },
            {
              text: result['COMMON.PHOTO_FROM_LIBRARY'],
              cssClass: 'gfl-alert-info-btn',
              icon: !this.platform.is('ios') ? 'images' : null,
              handler: async () => {
                this.cameraOptions.source = CameraSource.Photos;
                this.getPicture().then(imgObj => {
                  cb(imgObj);
                });
              },
            },
            {
              text: result['COMMON.BUTTON_CANCEL'],
              role: 'cancel', // will always sort to be on the bottom
              icon: !this.platform.is('ios') ? 'close' : null,
              handler: () => {},
            },
          ],
        });
        await actionSheet.present();
      });
  }

  /**
   * Use Native Camera or Photo Library to get the picture
   */
  private async getPicture(): Promise<string> {
    try {
      const imageData: CameraPhoto = await Camera.getPhoto(this.cameraOptions);
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      const base64Image: string = imageData.dataUrl;
      return base64Image.replace('data:image/png;base64,', '');
    } catch (err) {
      this.tools.error('GflUploadFilesToPdfComponent getPicture', err);
      if (typeof err !== 'string') {
        this.notificationSrv.showError({
          message: 'API.ERROR_MESSAGE',
        });
      }
    }
  }
}
