import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, NavController } from '@ionic/angular';

import { forkJoin, Observable, of } from 'rxjs';
import { finalize, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { LoginApiResponse } from '../models/auth.model';
import { LinkRelationItem } from '../../gfl-core/gfl-models/api.model';
import { Customer } from '../../customer/models/customer.model';
import { ConstantService } from '../../gfl-core/gfl-services/constant.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { NotificationService } from '../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { NetworkMonitorService } from '../../gfl-core/gfl-services/network-monitor.service';
import { WsService } from '../../gfl-core/gfl-services/ws.service';
import { AgencyService } from '../../gfl-core/gfl-services/agency.service';
import { AclsService } from '../../gfl-core/gfl-services/acls.service';

import { AuthState } from '../reducers';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  /**
   * @ignore
   */
  constructor(
    private wsSrv: WsService,
    private constantSrv: ConstantService,
    private store: StoreService,
    private notificationSrv: NotificationService,
    private tools: ToolsService,
    private network: NetworkMonitorService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private agencySrv: AgencyService,
    private aclsSrv: AclsService
  ) {}

  /**
   * Return an observable of the true value if customer is logged in
   */
  public isLoggedIn(): Observable<boolean> {
    return this.store.getIsLoggedIn();
  }

  /**
   * Log user with credentials
   * @param login customer's login
   * @param password customer's password
   * @param rememberMe if true we fill login input in login form
   * @param agencyId id's agency linked to customer
   * @param agencies map of id -> agency's name corresponding to credentials
   */
  public login(
    login: string,
    password: string,
    rememberMe: boolean,
    agencyId?: number,
    agencies?: { [id: string]: string }
  ): Observable<LoginApiResponse> {
    if (environment.DEDICATED_APP) {
      agencyId = environment.AGENCY_ID;
    }

    return this.wsSrv.postLogin(login, password, agencyId).pipe(
      switchMap((apiResponse: LoginApiResponse) => {
        if (!environment.DEDICATED_APP) {
          environment.AGENCY_ID = apiResponse.customer.agency_id;
        }
        this.store.resetGlobalCustomerData();

        // set language storage
        this.store.updateLang(apiResponse.language.iso, true, true);

        // set auth storage
        this.store.login({
          authData: {
            isLoggedIn: true,
            customerToken: apiResponse.customer.api_token,
            customerLogin: apiResponse.customer.login,
            customerTypeId: apiResponse.customer.customer_type_id,
            customerId: apiResponse.customer.id,
            agencyId: apiResponse.customer.agency_id,
            agencies,
            customerRefreshedTimeStamp: Date.now(),
            language: apiResponse.language.iso,
            rememberMe,
          },
          reloadAll: true,
          noNotifications: true,
        });

        return of(apiResponse);
      })
    );
  }

  public loginExternalCustomer(token: string): Observable<LoginApiResponse> {
    return this.wsSrv.postExternalAuthValidateCustomer(token).pipe(
      switchMap((apiResponse: LoginApiResponse) => {
        if (!environment.DEDICATED_APP) {
          environment.AGENCY_ID = apiResponse.customer.agency_id;
        }
        this.store.resetGlobalCustomerData();

        // set language storage
        this.store.updateLang(apiResponse.language.iso, true, true);

        return of(apiResponse);
      })
    );
  }

  /**
   * Login a given customer
   * @param customer a customer object
   */
  public loginUserAsCustomer(customer: Customer): void {
    if (!environment.DEDICATED_APP) {
      environment.AGENCY_ID = customer.agency_id;
    }

    this.store.login({
      authData: {
        isLoggedIn: true,
        customerToken: customer.api_token,
        customerLogin: customer.email,
        customerTypeId: customer.customer_type_id,
        customerId: customer.id,
        agencyId: customer.agency_id,
        agencies: undefined,
        customerRefreshedTimeStamp: Date.now(),
        language: customer.language,
        rememberMe: false,
      },
      reloadAll: false,
      noNotifications: true,
      reset: true,
    });
  }

  /**
   * This login method is used when pro mode has been switched
   * in order to use private or employee as logged in customer
   * @param customerObj a linked relation item object
   */
  public loginOnSwitchMode(customerObj: LinkRelationItem): Observable<LoginApiResponse> {
    return this.wsSrv.postLoginOnSwitchMode(customerObj.customer_id_linked).pipe(
      withLatestFrom(this.store.getLang()),
      map(([apiResponse, lang]: [LoginApiResponse, string]) => {
        this.store.resetGlobalCustomerData();

        // set auth storage
        this.store.login({
          authData: {
            isLoggedIn: true,
            customerToken: apiResponse.customer.api_token,
            customerLogin: apiResponse.customer.email,
            customerTypeId: apiResponse.customer.customer_type_id,
            customerId: apiResponse.customer.id,
            agencyId: apiResponse.customer.agency_id,
            agencies: apiResponse.customer.sibling_customer_agencies,
            customerRefreshedTimeStamp: Date.now(),
            language: lang,
            rememberMe: true,
          },
          reloadAll: false,
          noNotifications: true,
          reset: true,
        });

        return apiResponse;
      })
    );
  }

  /**
   * Disconnect customer
   * @param noRedirection if true no redirection to welcome page (use fo login user as customer feature
   */
  public logout(noRedirection?: boolean): boolean {
    this.store.logout(noRedirection);
    this.store.setSelectedMember(null);
    return true;
  }

  /**
   * Refresh all customer's data
   */
  public refreshCustomerData() {
    this.getAuthData()
      .pipe(first())
      .subscribe((authState: AuthState) => {
        this.store.setCustomerCompletedFlag(false);
        // we update customer, policies,... in store
        this.store.login({
          authData: {
            isLoggedIn: true,
            customerToken: authState.customerToken,
            customerLogin: authState.customerLogin,
            customerId: authState.customerId,
            agencyId: authState.agencyId,
            agencies: authState.agencies,
            customerTypeId: authState.customerTypeId,
            customerRefreshedTimeStamp: Date.now(),
            language: authState.language,
            rememberMe: authState.rememberMe,
          },
          reloadAll: false,
          noNotifications: false,
          reset: false,
        });
      });
  }

  /**
   * check if data need to be refreshed
   */
  public checkDataRefresh(): void {
    this.getAuthData()
      .pipe(
        first(),
        mergeMap((authState: AuthState) => {
          const now = Date.now();
          const delay = environment.REFRESH_DELAY;
          const reloadCustomerData = now > authState.customerRefreshedTimeStamp + delay;

          return forkJoin([of(reloadCustomerData), of(authState), this.network.isOffline().pipe(first())]);
        })
      )
      .subscribe(
        ([reloadCustomerData, authState, offline]) => {
          if (offline) {
            return;
          }

          if (reloadCustomerData) {
            this.store.setCustomerCompletedFlag(false);

            // we update customer, policies,... in store
            this.store.login({
              authData: {
                isLoggedIn: true,
                customerToken: authState.customerToken,
                customerLogin: authState.customerLogin,
                customerId: authState.customerId,
                agencyId: authState.agencyId,
                agencies: authState.agencies,
                customerTypeId: authState.customerTypeId,
                customerRefreshedTimeStamp: Date.now(),
                language: authState.language,
                rememberMe: authState.rememberMe,
              },
              reloadAll: false,
              reset: false,
            });
          }
        },
        err => this.tools.error('checkConnexionReset Err', err)
      );
  }

  /**
   * Refresh all application data in store
   */
  public refreshAllData(): void {
    this.getAuthData()
      .pipe(first())
      .subscribe(authState => {
        this.store.refreshAllData(authState);
      });
  }

  public getAuthData(): Observable<AuthState> {
    return this.store.getAuthData();
  }

  public cancelSignup() {
    this.translate
      .get(['SIGNUP.CANCEL_TITLE', 'SIGNUP.CANCEL_TEXT', 'COMMON.BUTTON_YES', 'COMMON.BUTTON_NO'])
      .subscribe(async result => {
        const alert = await this.alertCtrl.create({
          header: result['SIGNUP.CANCEL_TITLE'],
          message: result['SIGNUP.CANCEL_TEXT'],
          buttons: [
            {
              text: result['COMMON.BUTTON_NO'],
              cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
              role: 'cancel',
            },
            {
              text: result['COMMON.BUTTON_YES'],
              cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
              handler: () => {
                this.tools.showLoader();
                this.store.resetCustomerData();
                this.store.setIsSignupProcess(false);
                this.agencySrv
                  .getAdminAgencyId()
                  .pipe(
                    first(),
                    switchMap(agencyId => this.agencySrv.setPartialAgency(agencyId)),
                    switchMap(() => this.aclsSrv.setAcls().pipe(first())),
                    tap(() => this.navCtrl.navigateRoot('/welcome')),
                    finalize(() => this.tools.hideLoader())
                  )
                  .subscribe();
              },
            },
          ],
        });
        await alert.present();
      });
  }
}
