import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { forkJoin, Observable, of } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs/operators';

import { CompareFO, RowPremiumFO } from '../../models/compare.model';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { CgType } from '../../../gfl-core/gfl-models/cg.enum';
import { PolicyService } from '../../../policies/services/policy.service';
import { DocumentBO } from '../../../gfl-core/gfl-models/document.model';
import * as _ from 'lodash';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { CompareService } from '../../services/compare.service';
import { ErrorType } from '../../../gfl-core/gfl-models/error-type.enum';

@Component({
  selector: 'gfl-compare-insurimmo-process',
  templateUrl: './compare-insurimmo-process.component.html',
  styleUrls: ['./compare-insurimmo-process.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompareInsurimmoProcessComponent implements OnInit {
  @Input() offer: RowPremiumFO;
  @Input() compare$: Observable<CompareFO>;
  @Input() style: FrontTheme;
  @Input() acls: Acls;
  public step = 1;
  public maxStep = 3;
  public title: string;
  public enableNextButton: boolean;
  public cgvBlob: Blob;
  public cgaBlob: Blob;
  public display = false;
  public buttonLabel: string;
  public signature: string;
  private customerId: number;
  private signedOfferDocumentId: number;
  private signedPolicyId: number;

  constructor(
    private modalCtrl: ModalController,
    private documentSrv: DocumentService,
    private compareSrv: CompareService,
    private tools: ToolsService,
    private store: StoreService,
    private constantSrv: ConstantService,
    private policySrv: PolicyService,
    private notificationSrv: NotificationService,
    private navCtrl: NavController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.cgvBlob === undefined && this.cgaBlob === undefined) {
      this.tools
        .showLoaderObs()
        .pipe(
          switchMap(() =>
            forkJoin([
              this.offer.cgvId ? this.documentSrv.getCgDocument(CgType.CGV, this.offer.cgvId) : of(null),
              this.offer.cgaId ? this.documentSrv.getCgDocument(CgType.CGA, this.offer.cgaId) : of(null),
              this.store.getCustomerId().pipe(first()),
            ])
          )
        )
        .subscribe(([cgv, cga, customerId]) => {
          this.cgvBlob = cgv;
          this.cgaBlob = cga;
          this.customerId = customerId;
          if (this.cgaBlob === null && this.cgvBlob === null) {
            this.notificationSrv.showError({ message: ErrorType.HTTP_RESPONSE });
          } else {
            this.display = true;
            this.cd.detectChanges();
          }
          this.tools.hideLoader();
        });
    }
  }

  /**
   * upgrade step number
   */
  public next(): void {
    if (this.step === 2) {
      this.onSignatureValidation().subscribe(() => {
        this.step += 1;
        this.cd.detectChanges();
      });
    } else if (this.step === this.maxStep) {
      this.openPolicy();
    } else {
      this.step += 1;
    }

    this.updateEnableNextButton(false);
  }

  /**
   * downgrade step number
   */
  public previous(): void {
    this.step -= 1;
  }

  /**
   * Close current view
   */
  public close(): void {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * open cga file in web browser
   */
  public openCga(): void {
    this.documentSrv.openFileInBrowser(this.cgaBlob).subscribe();
  }

  /**
   * open cgv file in web browser
   */
  public openCgv(): void {
    this.documentSrv.openFileInBrowser(this.cgvBlob).subscribe();
  }

  public updateButtonLabel(label: string): void {
    this.buttonLabel = label;
    this.cd.detectChanges();
  }

  public updateEnableNextButton(val: boolean): void {
    this.enableNextButton = val;
    this.cd.detectChanges();
  }

  /**
   * Save Signature document to BO and set signed offer document id
   */
  public onSignatureValidation(): Observable<any> {
    const DOCUMENT_CATEGORY_ID_SIGNATURE = this.constantSrv.getValueFromKey('DOCUMENT_CATEGORY_ID_SIGNATURE');

    let image = this.signature;
    image = image.replace('data:image/png;base64,', '');

    this.tools.showLoader();

    const document = {
      path: image,
      tmp: image,
      name: this.customerId + '_signature.png',
      ext: 'png',
    };

    return this.documentSrv.saveDocument(document, null, DOCUMENT_CATEGORY_ID_SIGNATURE).pipe(
      switchMap(resp => {
        return this.documentSrv.signOffer(resp.id, this.offer.id);
      }),
      switchMap(resp => {
        this.signedPolicyId = resp.policy_id;
        return this.policySrv.setPoliciesAndMandates();
      }),
      switchMap(() => this.compareSrv.setCompares(true)),
      switchMap(() => this.documentSrv.setDocuments([this.customerId])),
      switchMap(() => {
        return this.store.getPolicy(this.signedPolicyId).pipe(first());
      }),
      tap(signedPolicy => {
        const signedOfferDocument = this.getSignedOfferDocument(signedPolicy.documents);
        this.signedOfferDocumentId = signedOfferDocument.document_id;
      }),
      finalize(() => this.tools.hideLoader())
    );
  }

  /**
   * Navigate to newly created policy
   */
  public openPolicy(): void {
    this.close();

    if (this.tools.isMobile()) {
      this.navCtrl.navigateForward('/policies/policy-detail/' + this.signedPolicyId).then();
    } else {
      this.navCtrl.navigateForward('/policies?policyId=' + this.signedPolicyId).then();
    }
  }

  /**
   * Open the signed offer document
   */
  public openSignedOfferDocument(): void {
    this.documentSrv.openDocumentFile(this.signedOfferDocumentId);
  }

  /**
   * send bay email the signed offer document
   */
  public sendSignedOfferDocument(): void {
    this.tools
      .showLoaderObs()
      .pipe(
        switchMap(() => this.documentSrv.mailDocumentToCustomer(this.signedOfferDocumentId)),
        finalize(() => this.tools.hideLoader())
      )
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({ message: 'COMPARE.DISPLAY.MAIL_SENT' });
        },
        err => {
          this.tools.error('CompareInsurimmoProcessComponent sendSignedOfferDocument()', err);
          this.notificationSrv.showError({
            message: 'COMPARE.ERRORS.MAIL_NOT_SENT',
            showCloseButton: true,
          });
        }
      );
  }

  /**
   * Return a document of signed offer category
   * @param documents documents of policy
   */
  private getSignedOfferDocument(documents: Array<DocumentBO>): DocumentBO {
    const key = 'DOCUMENT_CATEGORY_ID_SIGNED_OFFER';
    const DOCUMENT_CATEGORY_ID = this.constantSrv.getValueFromKey(key);
    return _.find(documents, { document_category_id: DOCUMENT_CATEGORY_ID });
  }
}
