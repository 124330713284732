import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthLoginGuard implements CanActivate, CanActivateChild {
  /**
   * @ignore
   */
  constructor(private tools: ToolsService, private authSrv: AuthService, private navCtrl: NavController) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.fn();
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.fn();
  }

  private fn(): Observable<boolean> {
    this.tools.setCookie(); // refresh cookie for PWA

    return this.authSrv.isLoggedIn().pipe(
      map(loggedIn => {
        if (loggedIn) {
          // already logged in... redirect to home page
          if (!this.tools.isMobile()) {
            this.navCtrl.navigateRoot('/policies').then(() => {});
          } else {
            this.navCtrl.navigateRoot('/home').then(() => {});
          }
        }
        return !loggedIn;
      })
    );
  }
}
