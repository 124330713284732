import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { combineLatest, Observable, Subscription } from 'rxjs';
import { first, tap } from 'rxjs/operators';

import { GflFormGeneratorService } from '../../../gfl-libraries/gfl-form-generator/services/gfl-form-generator.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ItemService } from '../../../gfl-core/gfl-services/item.service';
import { PolicyService } from '../../services/policy.service';
import { GflThemeOptions } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form-options.model';
import { GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { PolicyFO } from '../../models/policy.model';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { ActivatedRoute } from '@angular/router';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';

@Component({
  selector: 'gfl-policy-resume',
  templateUrl: './policy-resume.component.html',
  styleUrls: ['./policy-resume.component.scss'],
})
export class PolicyResumeComponent implements OnInit, OnDestroy {
  /**
   * the Id of the policy displayed
   */
  @Input() policyId: number;
  /**
   * isRefund flag
   */
  @Input() isRefund: boolean;
  /**
   *  Front theme style
   */
  @Input() style$: Observable<FrontTheme>;

  /**
   * Permissions definition object
   */
  @Input() acls$: Observable<Acls>;
  @Input() isOffline: boolean;
  /**
   * Policy object
   */
  public policy: PolicyFO;
  /**
   * Policy cloned object with form data displayed in the view
   */
  public policyFormData: PolicyFO;
  /**
   * an subscriptions array to be unsubscribed on component OnDestroyed event
   */
  private subscriptions: Subscription[] = [];
  /**
   * The foreign table name from which items template are fetched
   */
  private FOREIGN_TABLE_NAME = 'INSURED_OBJECT';
  /**
   * The foreign table id fetched from the constants service
   */
  private FOREIGN_TABLE_KEY: number;
  /**
   * flag on true if form is edited
   */
  public isEditMode = false;
  /**
   * Mode display type
   */
  public modeDisplay: GflModeDisplayType;

  /**
   * theme options for main items generated by GflFieldGeneratorComponent
   */
  public mainItemTheme: GflThemeOptions = {};
  /**
   * theme options for sub items generated by GflFieldGeneratorComponent
   */
  public subItemTheme: GflThemeOptions = {};

  public errorDisplay: boolean;
  public noDataDisplay: string;

  public policiesForm: { [id: string]: FormGroup } = {};
  private initialFormsValues: { [id: string]: { [id: string]: any } } = {};

  public slidesOpts = {
    noSwipingClass: 'swiper-no-swiping',
  };

  /**
   * @ignore
   */
  constructor(
    public translation: TranslateService,
    private policySrv: PolicyService,
    private modalCtrl: ModalController,
    private gflFormSrv: GflFormGeneratorService,
    private constantSrv: ConstantService,
    private itemSrv: ItemService,
    public tools: ToolsService,
    private activatedRoute: ActivatedRoute,
    public notificationSrv: NotificationService
  ) {
    this.policyId = this.policyId || parseInt(this.activatedRoute.snapshot.paramMap.get('policyId'), 10);
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.setModeDisplay();
    (this.constantSrv.getForeignTableIdByName(this.FOREIGN_TABLE_NAME) as Observable<number>)
      .pipe(first())
      .subscribe(foreignTableId => {
        this.FOREIGN_TABLE_KEY = foreignTableId;
        this.setData();
      });

    this.style$
      .pipe(
        first(),
        tap(style => {
          this.mainItemTheme = {
            tabletLabelTxtColor: style.color_txt_label,
            tabletContentTxtColor: style.color_success,
            mobileLabelTxtColor: style.color_txt_label,
            mobileContentTxtColor: style.color_success,
          };
          this.subItemTheme = {
            tabletLabelTxtColor: style.color_success,
            tabletContentTxtColor: style.color_success,
            mobileLabelTxtColor: style.color_success,
            mobileContentTxtColor: style.color_success,
          };
        })
      )
      .subscribe();
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Close the current display passing params type "onDisplayContract" and current policy to parent component
   * @param policy policy object
   */
  public onDisplayContract(policy: PolicyFO): void {
    this.modalCtrl.dismiss({ type: 'onDisplayContract', policy }).then(() => {});
  }

  /**
   * Close the current display passing params type "onRequestClaim" and current policy to parent component
   * @param policy policy object
   */
  public onRequestClaim(policy: PolicyFO): void {
    this.modalCtrl.dismiss({ type: 'onRequestClaim', policy }).then(() => {});
  }

  /**
   * Close the current display passing params type "onRequestRefund" and current policy to parent component
   * @param policy policy object
   */
  public onRequestRefund(policy: PolicyFO): void {
    this.modalCtrl.dismiss({ type: 'onRequestRefund', policy }).then(() => {});
  }

  /**
   * Close current modal display
   */
  public closeModal(): void {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * Toggle isEditMode flag to enable or not the form
   */
  public toggleEditMode(): void {
    this.isEditMode = !this.isEditMode;
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }

  /**
   * Set policy and policyFormData attributes
   */
  private setData(): void {
    this.subscriptions.push(
      combineLatest([
        this.policySrv.getPolicyById(this.policyId),
        this.itemSrv.getItemTemplateByItemTemplateKey(this.FOREIGN_TABLE_KEY, true),
      ]).subscribe(
        ([policy, itemsTemplate]) => {
          if (!policy) {
            return (this.noDataDisplay = 'INSURANCE.ERRORS.NO_POLICY_RESUME');
          }

          this.policy = _.cloneDeep(policy);

          this.policyFormData = _.cloneDeep(policy);

          _.forEach(this.policyFormData.insured_objects, (insuredObject, insuredObjectId) => {
            _.forEach(insuredObject.itemsDisplay, type => {
              // @ts-ignore
              const itemsData = this.gflFormSrv.setFormData(type.items, itemsTemplate);

              type.items = this.gflFormSrv.formatItemsTemplate(itemsData);

              this.initialFormsValues['insuredObject-' + insuredObjectId] = this.gflFormSrv.getFormInitialValues(
                type.items
              );
            });

            this.initForm(insuredObjectId);
          });
        },
        err => {
          this.tools.error('PolicyResumeComponent setData()', err);
          this.errorDisplay = true;
        }
      )
    );
  }

  /**
   * Generate dynamic form
   */
  private initForm(insuredObjectId: string): void {
    this.policiesForm['insuredObject-' + insuredObjectId] = new FormGroup({});
  }
}
