import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import * as _ from 'lodash';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, delay, first, map } from 'rxjs/operators';

import { Invoice } from '../../models/policy.model';
import { PolicyService } from '../../services/policy.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { StatusService } from '../../../gfl-core/gfl-services/status.service';
import { NetworkMonitorService } from '../../../gfl-core/gfl-services/network-monitor.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';

@Component({
  selector: 'gfl-policy-invoices',
  templateUrl: './policy-invoices.component.html',
  styleUrls: ['./policy-invoices.component.scss'],
})
export class PolicyInvoicesComponent implements OnInit, OnDestroy {
  @Input() policyId: number;
  @Input() acls: Acls;

  public isOffline: boolean;
  public subscriptions: Subscription[] = [];
  public invoices$: Observable<Invoice[]>;
  public errorDisplay: boolean;
  public noDataDisplay: string;

  constructor(
    public tools: ToolsService,
    public notificationSrv: NotificationService,
    public translate: TranslateService,
    private policySrv: PolicyService,
    private modalCtrl: ModalController,
    private documentSrv: DocumentService,
    private statusSrv: StatusService,
    private network: NetworkMonitorService,
    private alertCtrl: AlertController,
    private activatedRoute: ActivatedRoute
  ) {
    this.policyId = this.policyId || parseInt(this.activatedRoute.snapshot.paramMap.get('id'), 10);
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.subscriptions.push(
      this.network.isOffline().subscribe(flag => {
        this.isOffline = flag;
      })
    );

    this.invoices$ = this.policySrv.getPolicyById(this.policyId).pipe(
      first(),
      delay(0),
      map(policy => {
        const invoices = _.cloneDeep(policy.invoices);
        this.noDataDisplay = !invoices.length ? 'INSURANCE.DETAIL.INVOICES.EMPTY' : null;

        _.map(invoices, invoice => {
          invoice.current_status_label = this.statusSrv.getKeyTranslateFromId(invoice.current_status_id);

          return invoice;
        });

        return invoices;
      }),
      catchError(() => {
        this.errorDisplay = true;
        return of(null);
      })
    );
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Close View Modal
   */
  public closeModal(): void {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * Open document file
   * @param invoice invoice object
   */
  public openFile(invoice: Invoice): void {
    if (!invoice.documents.length) {
      this.notificationSrv.showWarning({ message: 'INSURANCE.DETAIL.INVOICES.NO_DOCUMENT' });
    } else if (invoice.documents.length === 1) {
      this.documentSrv.openDocumentFile(invoice.documents[0].id);
    } else {
      const inputs = [];

      _.forEach(invoice.documents, (document, idx) => {
        inputs.push({
          type: 'radio',
          label: document.filename,
          checked: idx === 0,
          value: idx,
        });
      });

      this.translate.get(['INSURANCE.DETAIL.INVOICES.OPEN_FILE', 'COMMON.BUTTON_VALIDATE']).subscribe(async result => {
        const alert = await this.alertCtrl.create({
          subHeader: result['INSURANCE.DETAIL.INVOICES.OPEN_FILE'],
          inputs,
          buttons: [
            {
              text: result['COMMON.BUTTON_VALIDATE'],
              handler: data => {
                this.documentSrv.openDocumentFile(invoice.documents[parseInt(data, 10)].id);
              },
            },
          ],
        });
        await alert.present();
      });
    }
  }
}
