import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { Observable, of } from 'rxjs';
import { catchError, first, mergeMap, shareReplay, switchMap, withLatestFrom } from 'rxjs/operators';

import { Acls } from '../gfl-models/acls.model';
import { StoreService } from './store.service';
import { CustomerLinks, StoredCustomer } from '../../customer/models/customer.model';
import { DataMonitorService } from './data-monitor.service';
import { ToolsService } from './tools.service';
import { ConstantService } from './constant.service';
import { WsService } from './ws.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AclsService {
  private readonly appName = environment.APP_NAME;

  /**
   * @ignore
   */
  constructor(
    private store: StoreService,
    private dataMonitorSrv: DataMonitorService,
    private tools: ToolsService,
    private wsSrv: WsService,
    private constantSrv: ConstantService
  ) {
    const locksToMonitor = [
      {
        name: 'permissions',
        lock: () => this.store.getPermissionsLock(),
        cb: () => of(this.setAcls().pipe(first())),
      },
    ];
    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Fetch permissions on app initialization
   */
  public loadPermissions(): Promise<void> {
    return new Promise(resolve => {
      this.managePermissions().subscribe(() => {
        resolve();
      });
    });
  }

  /**
   * update permissions
   */
  public managePermissions(): Observable<any> {
    // first we fetch from localstorage
    return this.store.get(`${this.appName}_permissions`).pipe(
      first(),
      mergeMap(acls => {
        return acls && !_.isEmpty(acls) ? of(acls) : this.setAcls().pipe(first());
      })
    );
  }

  /**
   * Return an observable of Acls
   * @param customerId customer id  - optional
   * if customerId is undefined the logged in customer's id is used
   */
  public getAcls(customerId?: number): Observable<Acls> {
    return this.store.getAcls(customerId);
  }

  /**
   * Return an observable of selected member's Acls
   * @param customerLinks customerLinks of selected member
   */
  public getAclsForSelectedMember(customerLinks: CustomerLinks): Observable<Acls> {
    let isEmployee;
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE'),
      CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');

    return this.store.getSelectedMember().pipe(
      shareReplay(),
      switchMap(selectedMember => {
        const id = (selectedMember && selectedMember.id) || undefined;
        isEmployee = selectedMember && selectedMember.customer_type_id === CUSTOMER_TYPE_ID_EMPLOYEE;
        return this.getAcls(id);
      }),
      switchMap(aclsOjb => {
        const acls = { ...aclsOjb };

        if (isEmployee && customerLinks) {
          // check if employee is already linked with a private
          const hasPrivateLink = _.find(customerLinks.available, {
            customer_type_id: CUSTOMER_TYPE_ID_PRIVATE,
          });
          acls.PROFILE_CUSTOMER_ADD_PRIVATE = !hasPrivateLink;
        }

        return of(acls);
      }),
      shareReplay()
    );
  }

  /**
   * Set Acls object in store
   * @param customerToken this is mandatory in case of signup process
   */
  public setAcls(customerToken?: string): Observable<Acls> {
    return this.store.setPermissionsLock(Date.now()).pipe(
      mergeMap(() => {
        const params = customerToken ? { api_token: customerToken } : {};
        return this.wsSrv.requestPermissions(params);
      }),
      withLatestFrom(this.store.getCustomers()),
      mergeMap(([permissionsMap, storedCustomersMap]) => {
        const acls = {};

        _.forEach(permissionsMap, (permissions, customerId) => {
          const isEmployeeLinkedToPrivate = storedCustomersMap[customerId]
            ? this.isEmployeeLinkedToPrivate(storedCustomersMap[customerId])
            : false;
          acls[customerId] = this.buildAcls(permissions, isEmployeeLinkedToPrivate);
        });

        return of(this.store.setAcls(acls));
      }),
      mergeMap(() => this.store.setPermissionsLock(null)),
      mergeMap(() => this.store.getAcls()),
      catchError(err => {
        this.tools.error('AclsService setAcls()', err);
        return of(null);
      })
    );
  }

  /**
   * Returns Acls Object
   * @param permissions an array of strings from BO
   * @param removeAddPrivate if true we force PROFILE_CUSTOMER_ADD_PRIVATE to false
   */
  private buildAcls(permissions: string[], removeAddPrivate?: boolean): Acls {
    const acls = {
      ADD_EXISTING_EMPLOYEE_DELAYED: permissions.includes('mobile.customer.employee.add.existing.delayed'),
      BUDGET: permissions.includes('mobile.budget'),
      BUDGET_TAB_OPTIMIZE: permissions.includes('mobile.budget.optimized'),
      SAFEBOX_ADD: permissions.includes('mobile.safebox.add'),
      SAFEBOX_LIST: permissions.includes('mobile.safebox.list'),
      SAFEBOX_EDIT: permissions.includes('mobile.safebox.edit'),
      SAFEBOX_UPLOAD_DOCUMENT: permissions.includes('mobile.safebox.upload.document'),
      SAFEBOX_UPLOAD_DOCUMENT_INSURED_OBJECT: permissions.includes('mobile.safebox.upload.document.insured_object'),
      SAFEBOX_UPLOAD_DOCUMENT_INVOICE: permissions.includes('mobile.safebox.upload.document.invoice'),
      SAFEBOX_UPLOAD_DOCUMENT_WARRANTY: permissions.includes('mobile.safebox.upload.document.warranty'),
      REFUND_REQUEST: permissions.includes('mobile.refund.request'),
      REFUND_DOCUMENT_ADD: permissions.includes('mobile.refund.document.add'),
      REFUND_DOCUMENT_SUBMIT: permissions.includes('mobile.refund.submit'),
      REFUND_LIST: permissions.includes('mobile.refund.list'),
      REFUND_DELAYED: permissions.includes('mobile.refund.delayed'),
      MOBILE_SIDEBAR_MENU: permissions.includes('mobile.sidebar.menu.mobile'),
      TABLET_SIDEBAR_MENU: permissions.includes('mobile.sidebar.menu.tablet'),
      SIDEBAR_REFRESHER_BTN: permissions.includes('mobile.sidebar.refresher_btn'),
      SIDEBAR_WISHES: permissions.includes('mobile.sidebar.suggestions'),
      SIDEBAR_ABOUT: permissions.includes('mobile.sidebar.agency.about_us'),
      SIDEBAR_NOTIFICATIONS: permissions.includes('mobile.sidebar.notifications'),
      SIDEBAR_CHAT: permissions.includes('mobile.sidebar.chat'),
      SIDEBAR_WEBSITE: permissions.includes('mobile.sidebar.agency.website'),
      SIDEBAR_LOGOUT: permissions.includes('mobile.sidebar.logout'),
      SIDEBAR_CHANGE_CUSTOMER: permissions.includes('mobile.sidebar.change_customer'),
      SIDEBAR_HOME: permissions.includes('mobile.sidebar.home'),
      SIDEBAR_INSURANCES: permissions.includes('mobile.sidebar.insurances'),
      SIDEBAR_SAFEBOX: permissions.includes('mobile.sidebar.safebox'),
      SIDEBAR_REFUND: permissions.includes('mobile.sidebar.refund'),
      SIDEBAR_COMPARE: permissions.includes('mobile.sidebar.compare'),
      SIDEBAR_PROFILE: permissions.includes('mobile.sidebar.profile'),
      SIDEBAR_CONTACT: permissions.includes('mobile.sidebar.contact'),
      SIDEBAR_LANGUAGES: permissions.includes('mobile.sidebar.languages'),
      SIDEBAR_LANGUAGES_DE: permissions.includes('mobile.sidebar.languages_de'),
      SIDEBAR_LANGUAGES_EN: permissions.includes('mobile.sidebar.languages_en'),
      SIDEBAR_LANGUAGES_FR: permissions.includes('mobile.sidebar.languages_fr'),
      SIDEBAR_LANGUAGES_IT: permissions.includes('mobile.sidebar.languages_it'),
      SIDEBAR_LANGUAGES_ES: permissions.includes('mobile.sidebar.languages_es'),
      SIDEBAR_LANGUAGES_PT: permissions.includes('mobile.sidebar.languages_pt'),
      SIDEBAR_VERSION: permissions.includes('mobile.sidebar.version'),
      WELCOME_LOGIN: permissions.includes('mobile.welcome.login'),
      WELCOME_SLIDER: permissions.includes('mobile.welcome.slider.public'),
      WELCOME_SIGNUP: permissions.includes('mobile.welcome.signup'),
      WELCOME_LANGUAGES: permissions.includes('mobile.welcome.languages'),
      WELCOME_LANGUAGES_FR: permissions.includes('mobile.welcome.languages.fr'),
      WELCOME_LANGUAGES_EN: permissions.includes('mobile.welcome.languages.en'),
      WELCOME_LANGUAGES_DE: permissions.includes('mobile.welcome.languages.de'),
      WELCOME_LANGUAGES_IT: permissions.includes('mobile.welcome.languages.it'),
      WELCOME_LANGUAGES_ES: permissions.includes('mobile.welcome.languages.es'),
      WELCOME_LANGUAGES_PT: permissions.includes('mobile.welcome.languages.pt'),
      LOGIN_PASSWORD_RECOVER: permissions.includes('mobile.login.password.recover'),
      LOGIN_REMEMBER_ME: permissions.includes('mobile.login.remember_me'),
      LOGIN_CREDENTIALS_EMAIL: permissions.includes('mobile.login.credentials.email'),
      LOGIN_CREDENTIALS_PASSWORD: permissions.includes('mobile.login.credentials.password'),
      LOGIN_SIGNIN: permissions.includes('mobile.login.sign_in'),
      LOGIN_SIGNUP: permissions.includes('mobile.login.sign_up'), // TODO remove from BO
      LOGIN_MULTIPLE_CUSTOMERS: permissions.includes('mobile.login.multiple_customers'),
      HOME_INSURANCES: permissions.includes('mobile.home.insurances'),
      HOME_INSURANCES_SIMPLE_BTN: permissions.includes('mobile.home.insurances_simple_btn'),
      HOME_INSURANCES_DOUBLE_BTN: permissions.includes('mobile.home.insurances_double_btn'),
      HOME_REFUND: permissions.includes('mobile.home.refund'),
      HOME_BUDGET: permissions.includes('mobile.home.budget'),
      HOME_SAFEBOX: permissions.includes('mobile.home.safebox'),
      HOME_REQUEST_OFFER: permissions.includes('mobile.home.offer.request'),
      HOME_SLIDER_PRIVATE: permissions.includes('mobile.home.slider.private'),
      FOOTERNAV_CONTACT: permissions.includes('mobile.footer_nav.contact'),
      FOOTERNAV_NOTIFICATIONS: permissions.includes('mobile.footer_nav.notifications'),
      FOOTERNAV_PROFILE: permissions.includes('mobile.footer_nav.profile'),
      FOOTERNAV_HOME: permissions.includes('mobile.footer_nav.home'),
      HEADERMOBILE_POWERED_BY: permissions.includes('mobile.header_mobile.powered_by'),
      HEADERMOBILE_PRO_SELECTOR: permissions.includes('mobile.header_mobile.pro_selector'),
      HEADERMOBILE_MENU: permissions.includes('mobile.header_mobile.menu'),
      HEADERTABLET_MENU: permissions.includes('mobile.header_tablet.menu'),
      HEADERTABLET_POWERED_BY: permissions.includes('mobile.header_tablet.powered_by'),
      HEADERTABLET_INSURANCES: permissions.includes('mobile.header_tablet.insurances'),
      HEADERTABLET_REFUND: permissions.includes('mobile.header_tablet.refund'),
      HEADERTABLET_SAFEBOX: permissions.includes('mobile.header_tablet.safebox'),
      HEADERTABLET_NOTIFICATIONS: permissions.includes('mobile.header_tablet.notifications'),
      HEADERTABLET_CONTACT: permissions.includes('mobile.header_tablet.contact'),
      HEADERTABLET_PROFILE: permissions.includes('mobile.header_tablet.profile'),
      FOOTERTABLET_POWERED_BY: permissions.includes('mobile.footer_tablet.powered_by'),
      FOOTERTABLET_PRO_SELECTOR: permissions.includes('mobile.footer_tablet.pro_selector'),
      FOOTERTABLET_EXPAND: permissions.includes('mobile.footer_tablet.expand'),
      FOOTERTABLET_LANGUAGES: permissions.includes('mobile.footer_tablet.languages.'),
      FOOTERTABLET_LANGUAGES_FR: permissions.includes('mobile.footer_tablet.languages.fr'),
      FOOTERTABLET_LANGUAGES_EN: permissions.includes('mobile.footer_tablet.languages.en'),
      FOOTERTABLET_LANGUAGES_DE: permissions.includes('mobile.footer_tablet.languages.de'),
      FOOTERTABLET_LANGUAGES_ES: permissions.includes('mobile.footer_tablet.languages.es'),
      FOOTERTABLET_LANGUAGES_PT: permissions.includes('mobile.footer_tablet.languages.pt'),
      FOOTERTABLET_LANGUAGES_IT: permissions.includes('mobile.footer_tablet.languages.it'),
      FOOTERTABLET_ABOUT: permissions.includes('mobile.footer_tablet.about'),
      FOOTERTABLET_WEB: permissions.includes('mobile.footer_tablet.web'),
      FOOTERTABLET_WISHES: permissions.includes('mobile.footer_tablet.wishes'),
      FOOTERTABLET_CHANGE_CUSTOMER: permissions.includes('mobile.footer_tablet.change_customer'),
      FOOTERTABLET_VERSION: permissions.includes('mobile.footer_tablet.version'),
      FOOTERTABLET_SCHEDULE: permissions.includes('mobile.footer_tablet.schedule'),
      FOOTERTABLET_EMAIL: permissions.includes('mobile.footer_tablet.email'),
      FOOTERTABLET_PHONE: permissions.includes('mobile.footer_tablet.phone'),
      FOOTERTABLET_ADDRESS: permissions.includes('mobile.footer_tablet.address'),
      FOOTERTABLET_ADVISOR: permissions.includes('mobile.footer_tablet.advisor'),
      FOOTERTABLET_SOCIALS: permissions.includes('mobile.footer_tablet.socials'),
      FOOTERTABLET_SOCIALS_FACEBOOK: permissions.includes('mobile.footer_tablet.socials.facebook'),
      FOOTERTABLET_SOCIALS_TWITTER: permissions.includes('mobile.footer_tablet.socials.twitter'),
      FOOTERTABLET_SOCIALS_INSTAGRAM: permissions.includes('mobile.footer_tablet.socials.instagram'),
      FOOTERTABLET_SOCIALS_LINKEDIN: permissions.includes('mobile.footer_tablet.socials.linkedin'),
      INSURANCE_LIST: permissions.includes('mobile.insurance_list'),
      INSURANCE_LIST_MEMBER_SELECT: permissions.includes('mobile.insurance_list.member_select'),
      INSURANCE_LIST_POLICY_LIST: permissions.includes('mobile.insurance_list.policy_list'),
      INSURANCE_LIST_MANDATE_LIST: permissions.includes('mobile.insurance_list.mandate_list'),
      INSURANCE_LIST_ADD_POLICY: permissions.includes('mobile.insurance_list.add_policy'),
      INSURANCE_LIST_BUDGET: permissions.includes('mobile.insurance_list.budget'),
      INSURANCE_LIST_SLIDER_PRIVATE: permissions.includes('mobile.insurance_list.slider.private'),
      INSURANCE_LIST_HEADER_CIRCLE: permissions.includes('mobile.insurance_list.header.circle'),
      INSURANCE_LIST_HEADER_CIRCLE_TOTAL_POLICIES: permissions.includes(
        'mobile.insurance_list.header.circle.total_policies'
      ),
      INSURANCE_LIST_HEADER_CIRCLE_TOTAL_PRIME: permissions.includes('mobile.insurance_list.header.circle.total_prime'),
      INSURANCE_LIST_HEADER_CIRCLE_TOTAL_RECOMMENDATIONS: permissions.includes(
        'mobile.insurance_list.header.circle.total_recommendations'
      ),
      INSURANCE_LIST_HEADER_CIRCLE_TOTAL_EMPLOYEES: permissions.includes(
        'mobile.insurance_list.header.circle.total_employees'
      ),
      INSURANCE_LIST_HEADER_COMPARES: permissions.includes('mobile.insurance_list.header.compares'),
      INSURANCE_LIST_HEADER_POLICY_ADD: permissions.includes('mobile.insurance_list.header.policy_add'),
      INSURANCE_LIST_HEADER_BUDGET: permissions.includes('mobile.insurance_list.header.budget'),
      INSURANCE_ITEM_POLICY_DETAIL: permissions.includes('mobile.insurance_item.policy_detail'),
      INSURANCE_ITEM_MANDATE_DELETE: permissions.includes('mobile.insurance_item.mandate_delete'),
      INSURANCE_ITEM_MANDATE_DISPLAY: permissions.includes('mobile.insurance_item.mandate_display'),
      INSURANCE_ITEM_DOCUMENTS_DISPLAY: permissions.includes('mobile.insurance_item.documents.display'),
      INSURANCE_ITEM_INVOICES_DISPLAY: permissions.includes('mobile.insurance_item.invoices.display'),
      INSURANCE_CLAIM_REQUEST: permissions.includes('mobile.insurance.claim_request'),
      INSURANCE_DETAIL_CLAIM_REQUEST_CALL_ASSISTANCE: permissions.includes(
        'mobile.insurance_detail.claim_request.call_assistance'
      ),
      INSURANCE_DETAIL_CLAIM_REQUEST_CALL_EMERGENCY: permissions.includes(
        'mobile.insurance_detail.claim_request.call_emergency'
      ),
      INSURANCE_REFUND_REQUEST: permissions.includes('mobile.insurance.refund_request'),
      INSURANCE_DETAIL: permissions.includes('mobile.insurance_detail'),
      INSURANCE_DETAIL_SUMMARY: permissions.includes('mobile.insurance_detail.summary'),
      INSURANCE_DETAIL_DOCUMENT: permissions.includes('mobile.insurance_detail.document'),
      INSURANCE_DETAIL_DOCUMENTS: permissions.includes('mobile.insurance_detail.documents'),
      POLICY_RESUME: permissions.includes('mobile.policy_resume'),
      POLICY_RESUME_CONTRACT: permissions.includes('mobile.policy_resume.contract'),
      POLICY_RESUME_CLAIM_REQUEST: permissions.includes('mobile.policy_resume.claim_request'),
      POLICY_RESUME_REFUND_REQUEST: permissions.includes('mobile.policy_resume.refund_request'),
      BUDGET_TAB: permissions.includes('mobile.budget.current'),
      BUDGET_TAB_GRAPH: permissions.includes('mobile.budget.graph'),
      BUDGET_SUMMARY: permissions.includes('mobile.budget.summary'),
      POLICY_ADD: permissions.includes('mobile.policy_add'),
      POLICY_ADD_MEMBER_SELECT: permissions.includes('mobile.policy_add.member_select'),
      POLICY_DOCUMENTS: permissions.includes('mobile.policy_documents'),
      POLICY_DOCUMENTS_CATEGORIES: permissions.includes('mobile.policy_documents.categories'),
      POLICY_DOCUMENTS_DOCUMENT_DISPLAY: permissions.includes('mobile.policy_documents.document_display'),
      POLICY_DOCUMENTS_DOCUMENT_ADD: permissions.includes('mobile.policy_documents.document_add'),
      POLICY_INVOICES: permissions.includes('mobile.policy_invoices'),
      POLICY_INVOICES_DOCUMENT: permissions.includes('mobile.policy_invoices_document'),
      REFUND: permissions.includes('mobile.refund'),
      REFUND_HISTORY: permissions.includes('mobile.refund.history'),
      REFUND_DOCUMENT: permissions.includes('mobile.refund.document'),
      RECOMMENDATIONS: permissions.includes('mobile.recommendations'),
      SAFEBOX: permissions.includes('mobile.safebox'),
      SAFEBOX_TYPES: permissions.includes('mobile.safebox.types'),
      SAFEBOX_OBJECTS: permissions.includes('mobile.safebox.objects'),
      SAFEBOX_OFFICIAL_DOCUMENTS: permissions.includes('mobile.safebox.official_documents'),
      SAFEBOX_DELETE: permissions.includes('mobile.safebox.delete'),
      SAFEBOX_SUBMIT: permissions.includes('mobile.safebox.submit'),
      PROFILE: permissions.includes('mobile.profile'),
      PROFILE_MEMBER_SELECT: permissions.includes('mobile.profile.member_select'),
      PROFILE_IDENTITY: permissions.includes('mobile.profile.identity'),
      PROFILE_IDENTITY_EDIT: permissions.includes('mobile.profile.identity.edit'),
      PROFILE_IDENTITY_BANK: permissions.includes('mobile.profile.identity.bank_account'),
      PROFILE_IDENTITY_BANK_EDIT: permissions.includes('mobile.profile.identity.bank_account.edit'),
      PROFILE_IDENTITY_HEALTH: permissions.includes('mobile.profile.identity.health'),
      PROFILE_IDENTITY_HEALTH_EDIT: permissions.includes('mobile.profile.identity.health.edit'),
      PROFILE_CUSTOMER_ADD: permissions.includes('mobile.profile.customer_link.add'),
      PROFILE_CUSTOMER_ADD_PAIR: permissions.includes('mobile.profile.customer_link.add.pair'),
      PROFILE_CUSTOMER_ADD_CREATE: permissions.includes('mobile.profile.customer_link.add.create'),
      PROFILE_CUSTOMER_ADD_CREATE_EMPLOYEE: permissions.includes('mobile.profile.customer_link.add.create_employee'),
      PROFILE_MANDATE_SHOW: permissions.includes('mobile.profile.mandate.show'),
      PROFILE_MANDATE_SHOW_DOCUMENT: permissions.includes('mobile.profile.mandate.show.document'),
      PROFILE_SIGNATURE_ADD: permissions.includes('mobile.profile.signature.add'),
      PROFILE_CONNEXION_PASSWORD: permissions.includes('mobile.profile.connexion.password'),
      PROFILE_CONNEXION_REMEMBER: permissions.includes('mobile.profile.connexion.remember'),
      PROFILE_CALL_REQUEST: permissions.includes('mobile.profile.call_request'),
      COMPARE: permissions.includes('mobile.compare'),
      COMPARE_LIST: permissions.includes('mobile.compare.list'),
      COMPARE_SLIDER_PRIVATE: permissions.includes('mobile.compare.slider_private'),
      COMPARE_MEMBER_SELECT: permissions.includes('mobile.compare.member_select'),
      COMPARE_ITEM: permissions.includes('mobile.compare.item'),
      COMPARE_ITEM_ORDER: permissions.includes('mobile.compare.item.order'),
      COMPARE_ITEM_TOP_POLICIES: permissions.includes('mobile.compare.item.top_policies'),
      COMPARE_ITEM_BROKER_COMMENT: permissions.includes('mobile.compare.item.broker_comment'),
      COMPARE_ITEM_INSURED_OBJECT: permissions.includes('mobile.compare.item.insured_object'),
      COMPARE_ITEM_RESUMED_POLICIES: permissions.includes('mobile.compare.item.resumed_policies'),
      COMPARE_ITEM_OUT_OF_CRITERIA_OFFERS: permissions.includes('mobile.compare.item.out_of_criteria_offers'),
      COMPARE_ITEM_NO_RESPONSE_POLICIES: permissions.includes('mobile.compare.item.no_response_policies'),
      COMPARE_ITEM_OFFER: permissions.includes('mobile.compare.item.offer'),
      COMPARE_ITEM_OFFER_INSURED_OBJECT_DATA: permissions.includes('mobile.compare.item.offer.insured_object_data'),
      COMPARE_ITEM_OFFER_INFO: permissions.includes('mobile.compare.item.offer.info'),
      COMPARE_ITEM_OFFER_DOCUMENT: permissions.includes('mobile.compare.item.offer.document'),
      COMPARE_ITEM_OFFER_EMAIL: permissions.includes('mobile.compare.item.offer.email'),
      PROFILE_CUSTOMER_LINKS: permissions.includes('mobile.profile.customer_links'),
      PROFILE_CUSTOMER_LINKS_PRO: permissions.includes('mobile.profile.customer_links_pro'),
      PROFILE_CUSTOMER_LINKS_DELETE: permissions.includes('mobile.profile.customer_links.delete'),
      PROFILE_CUSTOMER_LINKS_EDIT: permissions.includes('mobile.profile.customer_links.edit'),
      PROFILE_CUSTOMER_ADD_PRIVATE: removeAddPrivate
        ? false
        : permissions.includes('mobile.customer_link.add.customer_type.CUSTOMER_TYPE_ID_PRIVATE'),
      PROFILE_CUSTOMER_ADD_PRO: permissions.includes(
        'mobile.customer_link.add.customer_type.CUSTOMER_TYPE_ID_CORPORATE'
      ),
      PROFILE_CUSTOMER_ADD_EMPLOYEE: permissions.includes(
        'mobile.customer_link.add.customer_type.CUSTOMER_TYPE_ID_EMPLOYEE'
      ),
      NOTIFICATIONS: permissions.includes('mobile.notifications'),
      NOTIFICATIONS_ITEM: permissions.includes('mobile.notifications_item'),
      CONTACT: permissions.includes('mobile.contact'),
      CONTACT_EMAIL: permissions.includes('mobile.contact.email'),
      CONTACT_REQUEST_CALL: permissions.includes('mobile.contact.request_call'),
      CONTACT_CHAT: permissions.includes('mobile.contact.chat'),
      CONTACT_ADDRESS: permissions.includes('mobile.contact.address'),
    } as Acls;

    acls.LOAD_POLICIES = this.tools.IsInArrayOfString(permissions, 'polic');
    acls.LOAD_MANDATES = this.tools.IsInArrayOfString(permissions, 'mandate');
    acls.LOAD_COMPARES = this.tools.IsInArrayOfString(permissions, 'compare');
    acls.LOAD_SAFEBOX = this.tools.IsInArrayOfString(permissions, 'safebox');
    acls.LOAD_CHAT = this.tools.IsInArrayOfString(permissions, 'chat');
    acls.LOAD_DOCUMENTS = this.tools.IsInArrayOfString(permissions, 'document');

    return acls;
  }

  private isEmployeeLinkedToPrivate(customer: StoredCustomer): boolean {
    let result;
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE'),
      CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');

    // find employee
    const isEmployee = customer.customer.customer_type_id === CUSTOMER_TYPE_ID_EMPLOYEE;

    if (isEmployee) {
      // find customerLink private
      result = _.find(customer.customerLinks.available, link => {
        return link.customer_type_id === CUSTOMER_TYPE_ID_PRIVATE;
      });
    }

    return !!result;
  }
}
