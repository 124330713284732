import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';
import * as _ from 'lodash';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { ApiResponse } from '../gfl-models/api.model';
import { WsService } from './ws.service';

@Injectable({
  providedIn: 'root',
})
export class RequestCallService {
  readonly BASE_HOUR_SLOTS = [
    { start: '9:00', end: '12:00', threshold: '11:00', label: 'COMMON.MORNING' },
    { start: '14:00', end: '18:00', threshold: '16:00', label: 'COMMON.AFTERNOON' },
  ];

  constructor(private wsSrv: WsService) {}

  /**
   * Save request call to BO
   * @param startDate start date
   * @param phone phone number without prefix
   * @param phonePrefix phone number prefix
   */
  public saveRequestCall(startDate: string, phone: string, phonePrefix: string): Observable<object> {
    const data = {
      expected_date: startDate,
      phone,
      phone_prefix: phonePrefix,
    };

    return this.wsSrv.postRequestCall(data).pipe(map((apiResponse: ApiResponse) => apiResponse.recall));
  }

  /**
   * Set timeslots available according to the date of the request
   * @param date only for testing purposes
   */
  public setTimeSlots(date?: Moment): any[] {
    // initialise all variables needed
    const timeSlots = [];
    const timeSlotsLength = 4; // four consecutive half days
    const currentDate: Moment = date || moment();
    const baseHourSlots = this.BASE_HOUR_SLOTS;
    const baseHourSlotsLength = baseHourSlots.length;
    const availableDays = this.getAvailableDays(currentDate);
    // const today = availableDays[0];
    let idx;
    let day = availableDays.shift();

    // if sunday or saturday...
    if (currentDate.day() === 0 || currentDate.day() === 6) {
      idx = 0;
    } else {
      let i: number;
      for (i = 0; i < baseHourSlotsLength; i++) {
        const threshold = this.BASE_HOUR_SLOTS[i].threshold.split(':');
        const hours: number = parseInt(threshold[0], 10);
        const minutes: number = parseInt(threshold[1], 10);
        const tmpThreshold: Moment = _.cloneDeep(currentDate)
          .hours(hours)
          .minutes(minutes);

        if (currentDate < tmpThreshold) {
          break;
        }
      }

      if (i === baseHourSlotsLength) {
        idx = 0;
        day = availableDays.shift();
      } else {
        idx = i;
      }
    }

    while (timeSlots.length < timeSlotsLength) {
      timeSlots.push(this.setTimeSlot(idx, day));
      if (idx + 1 === baseHourSlotsLength) {
        idx = 0;
        day = availableDays.shift();
      } else {
        idx++;
      }
    }

    return timeSlots;
  }

  /**
   * set a time slot
   * @param idx BASE_HOUR_SLOTS item's index
   * @param day moment object
   */
  private setTimeSlot(idx, day: Moment) {
    const tmpStart = this.BASE_HOUR_SLOTS[idx].start.split(':');
    const startHours: number = parseInt(tmpStart[0], 10);
    const startMinutes: number = parseInt(tmpStart[1], 10);

    const dateRecall = _.cloneDeep(day)
      .hours(startHours)
      .minutes(startMinutes);

    return {
      day: day.format('dddd'),
      time: this.BASE_HOUR_SLOTS[idx].label,
      dateRecall,
    };
  }

  /**
   * Return an array with three days as moment's object based on date parameter
   * @param date a moment's object
   */
  private getAvailableDays(date: Moment): Moment[] {
    let result;

    const currentDayOfWeek: number = date.day();

    switch (currentDayOfWeek) {
      case 0: // sunday
        result = [
          _.cloneDeep(date)
            .add(1, 'd')
            .hours(8)
            .minutes(0),
          _.cloneDeep(date)
            .add(2, 'd')
            .hours(8)
            .minutes(0),
          _.cloneDeep(date)
            .add(3, 'd')
            .hours(8)
            .minutes(0),
        ];
        break;
      case 5: // friday
        result = [
          _.cloneDeep(date),
          _.cloneDeep(date)
            .add(3, 'd')
            .hours(8),
          _.cloneDeep(date)
            .add(4, 'd')
            .hours(8),
        ];
        break;
      case 6: // saturday
        result = [
          _.cloneDeep(date)
            .add(2, 'd')
            .hours(8),
          _.cloneDeep(date)
            .add(3, 'd')
            .hours(8),
          _.cloneDeep(date)
            .add(4, 'd')
            .hours(8),
        ];
        break;
      default:
        result = [
          _.cloneDeep(date),
          _.cloneDeep(date)
            .add(1, 'd')
            .hours(8),
          _.cloneDeep(date)
            .add(2, 'd')
            .hours(8),
        ];
    }

    return result;
  }
}
