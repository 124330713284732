import { createAction, props } from '@ngrx/store';

import { FrontTheme } from '../gfl-core/gfl-models/agency.model';
import { Customer, CustomerFamilyMember } from '../customer/models/customer.model';
import { NetWorkStatus } from '../gfl-core/gfl-services/network-monitor.service';

import { UiState } from './ui.reducer';

export const initialize = createAction(
  '[Store Service setNgrxStore] initialize UiState',
  props<{ uiState: UiState }>()
);

export const setLang = createAction('[App Component] Set Language', props<{ lang: string }>());

export const setAdminLogo = createAction('[Agency service] Set admin logo', props<{ logo: string }>());

export const setIsCustomerCompleted = createAction(
  '[Auth Service] Set isCustomerCompleted',
  props<{ isCustomerCompleted: boolean }>()
);

export const updateLang = createAction(
  '[App Component] Update Language',
  props<{ lang: string; noPoliciesUpdate: boolean; noUpdateLoaderIndicator: boolean }>()
);
export const setIsProSelected = createAction(
  '[gfl-mode-pro-selector Component or Customer Service] Set isProSelected',
  props<{ isPro: boolean }>()
);

export const setIsSignupProcess = createAction(
  '[Store Service] Set isSignupProcess',
  props<{ isSignupProcess: boolean }>()
);

export const setIsProSelectorDisplayed = createAction(
  '[Customer Service setModeDisplay()] Set isProSelectorDisplayed',
  props<{ isProSelectorDisplayed: boolean }>()
);

export const setIsAppStateReady = createAction('[App Component] Set isAppStateReady', props<{ isReady: boolean }>());

export const setStyle = createAction('[Agency Service setAgency()] Set Style', props<{ style: FrontTheme }>());

export const setSelectedMember = createAction(
  '[Customer Service] set selected member',
  props<{ selectedMember: CustomerFamilyMember | Customer }>()
);

export const setLoaderState = createAction('[Store Service] set global loader state', props<{ display: boolean }>());

export const setNomadLoaderState = createAction(
  '[Store Service] set Nomad loader state',
  props<{ display: boolean }>()
);

export const setActivatedPage = createAction(
  '[Navigation Service] set activated page',
  props<{ activatedPage: string }>()
);

export const setNetworkStatus = createAction(
  '[NetworkStatusService] set network status',
  props<{ networkStatus: NetWorkStatus }>()
);

export const reload = createAction(
  '[AuthService checkDataRefresh()] update constants, statuses, agency, companies and insuranceTypes data',
  props()
);

export const setRefreshState = createAction(
  '[app component or footer tablet component]Set Refresh state',
  props<{ refreshState: boolean }>()
);
