import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap } from 'rxjs/operators';

import { Slider, SliderType } from '../gfl-models/slider.model';
import { ToolsService } from './tools.service';
import { StoreService } from './store.service';
import { WsService } from './ws.service';
import { DocumentService } from './document.service';
import { DataMonitorService } from './data-monitor.service';

import { environment } from '../../../environments/environment';
import { AuthState } from '../../authentication/reducers';
import { Agency } from '../gfl-models/agency.model';

export interface SliderResponse {
  success: boolean;
  sliders: Array<Slider>;
}

@Injectable({
  providedIn: 'root',
})
export class SliderService {
  /**
   * @ignore
   */
  constructor(
    public wsSrv: WsService,
    private tools: ToolsService,
    private store: StoreService,
    private documentSrv: DocumentService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const locksToMonitor = [
      {
        name: 'sliders',
        lock: () => this.store.getSlidersLock(),
        cb: () => this.initService(),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Store sliders in Store according to language
   * launched every time language is changed
   * @param reset if true empty data first
   */
  public initService(reset?: boolean): Observable<any> {
    let agencyId;
    let selectedLang;
    let slidersType;

    // fetch and store private sliders

    return forkJoin([
      this.store.getLang().pipe(
        filter(val => !!val),
        first()
      ),
      this.store.getAgency().pipe(first()),
      this.store.get(environment.APP_NAME + '_auth').pipe(first()),
    ]).pipe(
      mergeMap(([lang, agency, auth]: [string, Agency, AuthState]) => {
        selectedLang = lang;
        agencyId = (auth && auth.agencyId) || agency.id;
        slidersType = auth && auth.customerToken ? SliderType.Private : SliderType.Public;

        return this.store.getSliders(slidersType, selectedLang).pipe(first());
      }),
      mergeMap(slidersTest => {
        if (slidersTest && !reset) {
          return of(true); // sliders for this language are already loaded
        } else {
          return this.setSliders(agencyId, slidersType, selectedLang, reset);
        }
      }),
      catchError(err => {
        this.tools.error('getSliders Error', err);
        return of([]);
      })
    );
  }

  /**
   * fetch and store sliders
   * @param agencyId agency id
   * @param slidersType SliderType.public or SliderType.private
   * @param selectedLang current language
   * @param reset if true empty data first
   */
  public setSliders(
    agencyId: number,
    slidersType: SliderType,
    selectedLang: string,
    reset?: boolean
  ): Observable<void> {
    let sliders;

    return this.store.setSlidersLock(Date.now()).pipe(
      mergeMap(() => this.wsSrv.requestSliders(agencyId, slidersType)),
      mergeMap((sliderResp: SliderResponse) => {
        sliders = sliderResp.sliders;

        const obs$: Observable<string>[] = [];

        _.forEach(sliders, item => {
          if (item.photo) {
            item.photo = environment.STORAGE_URL + item.photo;
            const ext = this.tools.getExtension(item.photo);

            if (this.tools.isNative()) {
              if (ext === 'svg') {
                obs$.push(
                  this.tools.fetchSVGImage(item.photo).pipe(
                    mergeMap(data => {
                      return this.documentSrv.storeSVGFile(data, item.id + '.' + ext, environment.SVG_FOLDERS.SLIDERS);
                    })
                  )
                );
              } else {
                obs$.push(
                  this.tools.fetchImage(item.photo).pipe(
                    mergeMap(data => {
                      return this.documentSrv.storeUnencryptedFile(
                        data,
                        item.id + '.' + ext,
                        ext,
                        environment.SVG_FOLDERS.SLIDERS
                      );
                    }),
                    map(result => {
                      return result.device_path;
                    })
                  )
                );
              }
            } else {
              obs$.push(of(item.photo));
            }
          }
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }
        return forkJoin(obs$);
      }),
      mergeMap(result => {
        _.forEach(sliders, slider => {
          if (slider.photo) {
            slider.photo = result.shift() as string;
          }
        });

        if (reset) {
          this.store.resetSliders(SliderType.Private);
        }
        this.store.setSliders(sliders, slidersType, selectedLang);
        return this.store.setSlidersLock(null);
      })
    );
  }

  /**
   * Return an observable of an array of sliders
   * @param type type of slider between SliderType
   */
  public getSliders(type: SliderType): Observable<Slider[]> {
    return this.store.getLang().pipe(
      filter(val => !!val),
      mergeMap(lang => {
        return this.store.getSliders(type, lang);
      })
    );
  }
}
