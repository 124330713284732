import { createReducer, on } from '@ngrx/store';

import { Acls } from '../gfl-core/gfl-models/acls.model';
import { PermissionsActions } from './action.types';

export interface PermissionsState {
  acls: { [id: number]: Acls };
}

export const initialPermissionsState: PermissionsState = {
  acls: {},
};

const _permissionsReducer = createReducer(
  initialPermissionsState,

  on(PermissionsActions.initialize, (state, action) => {
    return { ...action.permissionsState };
  }),

  on(PermissionsActions.setAcls, (state, action) => {
    return {
      acls: action.acls,
    };
  })
);

export function permissionsReducer(state, action) {
  return _permissionsReducer(state, action);
}
