import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AgencyService } from '../../gfl-services/agency.service';
import { ToolsService } from '../../gfl-services/tools.service';

@Component({
  selector: 'gfl-powered-by',
  templateUrl: './gfl-powered-by.component.html',
  styleUrls: ['./gfl-powered-by.component.scss'],
})
export class GflPoweredByComponent implements OnInit, OnDestroy {
  @Input() isFooter: boolean;
  public isAdmin: boolean;
  public item: string;
  public center: boolean;
  private subscriptions: Subscription[] = [];

  /**
   * @ignore
   */
  constructor(private agencySrv: AgencyService, private tools: ToolsService) {}

  /**
   * @ignore
   */
  ngOnInit() {
    const subscription = this.agencySrv.getIsAgencyAdmin().subscribe(isAdmin => {
      this.isAdmin = isAdmin;
      this.center = this.tools.isMobile();

      this.item = this.tools.isMobile() ? 'powered_by_goforlife' : 'powered_by_goforlife_footer';
    });

    this.subscriptions.push(subscription);
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }
}
