import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { forkJoin, iif, Observable, of, Subject, throwError } from 'rxjs';
import { first, mergeMap } from 'rxjs/operators';

import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { StatusService } from '../../gfl-core/gfl-services/status.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { ApiService } from '../../gfl-core/gfl-services/api.service';
import { PolicyService } from './policy.service';
import { CustomerService } from '../../customer/services/customer.service';
import { NetWorkStatus } from '../../gfl-core/gfl-services/network-monitor.service';
import { InsuranceService } from './insurance.service';
import { WsService } from '../../gfl-core/gfl-services/ws.service';
import { ApiResponse } from '../../gfl-core/gfl-models/api.model';
import { Mandate } from '../models/mandate.model';
import { Customer } from '../../customer/models/customer.model';

@Injectable({
  providedIn: 'root',
})
export class MandateService {
  public updateMandates$: Subject<any>;

  readonly URL = {
    MANDATE: '/mandate/',
  };

  /**
   * @ignore
   */
  constructor(
    private apiSrv: ApiService,
    private wsSrv: WsService,
    private policySrv: PolicyService,
    private customerSrv: CustomerService,
    private store: StoreService,
    private statusService: StatusService,
    private insuranceSrv: InsuranceService,
    private translate: TranslateService,
    private loadingCtrl: LoadingController,
    private tools: ToolsService
  ) {
    this.updateMandates$ = new Subject();
  }

  /**
   * Return an observable of an array of mandates
   * @param customerId customer ID
   * @param apiToken current customer's apiToken
   */
  public getMandates(customerId?: number, apiToken?: string): Observable<Array<Mandate>> {
    return this.store.getMandates(customerId);
  }

  /**
   * Return an observable that will trigger an event if mandates need to be updated
   */
  public getUpdateMandates(): Observable<any> {
    return this.updateMandates$.asObservable();
  }

  /**
   * Trigger an event on updateMandates$ subject
   */
  public setUpdateMandates(): void {
    this.updateMandates$.next();
  }

  /**
   * Return an observable of BO response after adding mandate request
   * @param newMandates Array of objects with company id, insurance type id and optional policy number
   * @param customerId customer's id
   * @param apiToken current customer's apiToken
   */
  public saveMandates(
    newMandates: Array<{
      company_id: number;
      insurance_type_id: number;
      temp_policy_number?: string;
    }>,
    customerId: number | boolean,
    apiToken?: string
  ): Observable<any[]> {
    const params: any = {};
    if (apiToken) {
      params.api_token = apiToken;
    }
    if (customerId) {
      params.customer_id_linked = customerId;
    }

    // const action = (apiResponse): Observable<Array<any>> => {
    //   // we delete this mandate in store by updating policiesAndMandates in store
    //   // this.policySrv.setPoliciesAndMandates(false, apiToken);
    //   return of(apiResponse.mandates);
    // };

    const onlineObs$ = [];

    _.forEach(newMandates, newMandate => {
      onlineObs$.push(
        this.wsSrv
          .postMandate(newMandate, params)
          .pipe(
            mergeMap((apiResponse: ApiResponse) =>
              iif(() => apiResponse.success, of(apiResponse.mandates), throwError('API.NO_RIGHT'))
            )
          )
      );
    });

    return this.store.getNetworkStatus().pipe(
      first(),
      mergeMap(status => iif(() => status === NetWorkStatus.Online, forkJoin(onlineObs$), this.tools.notAvailableMsg()))
    );
  }

  /**
   * Return an observable of BO response after deleting mandate
   * @param mandateId mandate's id
   * @param customer customer object
   * @param signupProcess flag true if signing up
   */
  public deleteMandate(mandateId: number, customer?: Customer, signupProcess?: boolean): Observable<any> {
    const params = {};

    if (customer) {
      // @ts-ignore
      params.customer_id_linked = customer.id;

      if (signupProcess && customer.api_token) {
        // @ts-ignore
        params.api_token = customer.api_token;
      }
    }

    const action = (apiResponse): Observable<boolean> => {
      // we delete this mandate in store by updating policiesAndMandates in store
      if (signupProcess && customer) {
        return this.policySrv.setPoliciesAndMandates(true, customer.api_token, signupProcess);
      }
      return this.policySrv.setPoliciesAndMandates();
    };

    const onlineObs$ = this.wsSrv
      .deleteMandate(mandateId, params)
      .pipe(
        mergeMap((apiResponse: ApiResponse) =>
          iif(() => apiResponse.success, action(apiResponse), throwError('API.NO_RIGHT'))
        )
      );

    return this.store.getNetworkStatus().pipe(
      first(),
      mergeMap(status => iif(() => status === NetWorkStatus.Online, onlineObs$, this.tools.notAvailableMsg()))
    );
  }
}
