import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import * as _ from 'lodash';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { ApiService } from './api.service';
import { AgencyResponse, Contract } from '../gfl-models/agency.model';
import { Company } from '../gfl-models/company.model';
import { Contact } from '../gfl-models/contact.model';
import { Constant, ForeignTable } from '../gfl-models/constant.model';
import { DocumentCategories } from '../gfl-models/document.model';
import { ApiResponse } from '../gfl-models/api.model';
import { ItemTemplateFO } from '../gfl-models/item-template.model';
import { Status } from '../gfl-models/status.model';
import { LoginApiResponse, LoginUserAsCustomerApiResponse } from '../../authentication/models/auth.model';
import { Customer, CustomerBank, CustomerRole } from '../../customer/models/customer.model';
import { City } from '../gfl-models/city.model';
import { Address } from '../gfl-models/address.model';
import { Refund } from '../../policies/models/refund.model';
import { InsuranceType } from '../../policies/models/insurance-type.model';
import { SafeboxType } from '../../safebox/models/safebox-type.model';
import { Document } from '../gfl-models/document.model';
import { SliderType } from '../gfl-models/slider.model';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WsService {
  private URL = {
    // Agency service
    AGENCY: '/v2/agency/',
    AGENCIES: '/agencies',
    CONTRACT: '/templatePdfAgency',
    // Company service
    COMPANY: '/company/',
    COMPANIES: '/companies',
    COMPANIES_BY_CUSTOMERTYPE_BY_INSURANCETYPE: '/all_companies',
    // Constant service
    CONSTANTS: '/v2/constants',
    FOREIGN_TABLES: '/foreign_tables',
    // Acls service
    PERMISSIONS: '/permissions',
    // Document service
    CATEGORIES: '/v2/document_categories',
    ROLES: '/roles',
    DOCUMENTS: '/v2/documents',
    FILE: '/document/file/',
    DOCUMENT: '/document',
    // Item service
    ITEM_TEMPLATE: '/item_template',
    ITEM: '/item',
    // RequestCall service
    RECALL: '/v2/recall',
    // Slider service
    SLIDERS: '/sliders/',
    // Status service
    STATUS: '/statuses',
    // Auth service
    LOGIN: '/login',
    LOGIN_AS_CUSTOMER: '/login_as_customer/',
    // Compare service
    COMPARES: '/compares',
    COMPARE: '/compare/',
    // Customer service
    CUSTOMER: '/v2/customer',
    CUSTOMER_CONTRACT: '/mandate/template',
    LOGIN_USER_AS_CUSTOMER: '/login_user_as_customer',
    CUSTOMER_EXISTS: '/v2/customer/exists',
    CUSTOMER_LINK: '/v2/customer_link',
    CUSTOMER_LINK_PAIRING: '/customer_link/pairing_code',
    CUSTOMER_LINK_DELETE: '/customer_link/delete',
    RESET_PASSWORD: '/password/new',
    LOGIN_VALIDATION: '/v2/customer/password_reset',
    REGISTER: '/v2/register',
    DEVICE: '/v2/device',
    SMS_AUTH: '/v2/sms/auth',
    VALIDATE: '/v2/customer/validate',
    AUTH_LINK_VALIDATE: '/v2/auth_link/validate',
    CITY: '/city/postcode/',
    ADDRESS: '/v2/address',
    BANK: '/v2/bank_account',
    // Policy service
    POLICIES: '/v2/policies',
    POLICY: '/v2/policy/',
    POLICY_INVOICES: '/invoices/',
    // Mandate service
    MANDATES: '/v2/mandates',
    MANDATE: '/mandate/',
    // Refund service
    REFUNDS: '/v2/refunds/',
    REFUND: '/v2/refund',
    // Insurance service
    INSURANCE_TYPES: '/insurance_types/',
    // Safebox service
    SAFEBOX_TYPES: '/safebox_types',
    SAFEBOXES: '/v2/safeboxes',
    SAFEBOXE: '/safebox',
    // Contacts service
    CHAT: '/comments',
    CGA: '/v2/cga/',
    CGA_FILE: '/v2/cga/file/',
    CGV: '/v2/cgv/',
    CGV_FILE: '/v2/cgv/file/',
    OFFER_SIGN: '/v2/offer/sign',
  };

  constructor(private apiSrv: ApiService) {}

  /**
   * Return an observable of WS agency response
   * @param agencyId Agency ID
   */
  public requestAgencyWs(agencyId: number): Observable<AgencyResponse> {
    return this.apiSrv.httpGet(this.URL.AGENCY + agencyId);
  }

  /**
   * Return an observable of WS agencies response
   */
  public requestAgenciesWs(): Observable<{ [id: number]: any }> {
    return this.apiSrv.httpGet(this.URL.AGENCIES);
  }

  /**
   * Return an observable of WS templatePdfAgency response
   * @param params with customerId customer's id, apiToken current customer's apiToken
   */
  public requestAgencyContractTemplate(params: object): Observable<Contract> {
    return this.apiSrv.httpGet(this.URL.CONTRACT, params);
  }

  /**
   * Return an observable of a companies map
   */
  public requestCompanies(): Observable<{ [id: number]: Company }> {
    return this.apiSrv.httpGet(this.URL.COMPANIES);
  }

  /**
   * Return an observable of a map of companies id by customerType and insuranceType
   */
  public requestCompaniesByCustomerTypeByInsuranceType(): Observable<{ [id: string]: { [id: string]: number[] } }> {
    return this.apiSrv.httpGet(this.URL.COMPANIES_BY_CUSTOMERTYPE_BY_INSURANCETYPE);
  }

  /**
   * Return an observable of the company for the given id
   * @param companyId company ID
   */
  public requestCompany(companyId: number): Observable<{ company: Company; contact: Contact }> {
    return this.apiSrv.httpGet(this.URL.COMPANY + companyId);
  }

  /**
   * Return an observable of an array of constants
   */
  public requestContants(): Observable<HttpResponse<Constant[]>> {
    return this.apiSrv.httpGet(this.URL.CONSTANTS, null, null, true);
  }

  /**
   * Return an observable of a map permissions array by customer's id
   */
  public requestPermissions(params: object): Observable<{ [id: string]: [string] }> {
    return this.apiSrv.httpGet(this.URL.PERMISSIONS, params);
  }

  /**
   * Return an observable of an array of foreignTable objects
   */
  public requestForeignTables(): Observable<ForeignTable[]> {
    return this.apiSrv.httpGet(this.URL.FOREIGN_TABLES);
  }

  /**
   * Return an observable of an array of all customer's documents
   * @param params query params with api_token
   * TODO - not used ...  to remove ?
   */
  public requestCustomerActiveDocuments(params: object): Observable<Document[]> {
    return this.apiSrv.httpGet(this.URL.DOCUMENTS, params).pipe(
      map(docs => {
        return _.filter(docs, { active: 1 });
      })
    );
  }

  /**
   * Return an observable of an array of all customer's documents
   * @param params query params with api_token
   */
  public requestCustomerDocuments(params: object): Observable<Document[]> {
    return this.apiSrv.httpGet(this.URL.DOCUMENTS, params);
  }

  /**
   * Return an observable of DocumentCategoryItem's map
   */
  public requestDocumentCategories(): Observable<DocumentCategories> {
    return this.apiSrv.httpGet(this.URL.CATEGORIES, {});
  }

  /**
   * Return an observable of customer roles
   */
  public requestCustomerRoles(): Observable<{ [customerTypeId: string]: CustomerRole[] }> {
    return this.apiSrv.httpGet(this.URL.ROLES, {});
  }

  /**
   * Return an observable of document's blob
   * @param checksum document checksum
   * @param customerIdLinked customer id of document's owner
   * TODO - add TU
   */
  public requestDocumentByChecksum(checksum: string, customerIdLinked?: number): Observable<Blob> {
    const url = encodeURI(this.URL.FILE + checksum),
      params = customerIdLinked ? {} : { customer_id_linked: customerIdLinked };
    return this.apiSrv.httpGet(url, params, 'blob').pipe(shareReplay());
  }

  /**
   * Return contract file blob
   * @param params query params with api_token
   * TODO - add TU
   */
  public requestContract(params: object): Observable<Blob> {
    return this.apiSrv.httpGet(this.URL.CUSTOMER_CONTRACT, params, 'blob');
  }

  /**
   * return cga of current agency
   * @param cgaId cga's id
   */
  public requestCga(cgaId: number): Observable<any> {
    return this.apiSrv.httpGet(this.URL.CGA + cgaId);
  }

  /**
   * return cgv of current agency
   * @param cgvId cgv's id
   */
  public requestCgv(cgvId: number): Observable<any> {
    return this.apiSrv.httpGet(this.URL.CGV + cgvId);
  }

  /**
   * return cga file of current agency
   * @param cgaId cga's id
   */
  public requestCgaFile(cgaId: number): Observable<Blob> {
    return this.apiSrv.httpGet(this.URL.CGA_FILE + cgaId, {}, 'blob');
  }

  /**
   * return cgv file of current agency
   * @param cgvId cgv's id
   */
  public requestCgvFile(cgvId: number): Observable<Blob> {
    return this.apiSrv.httpGet(this.URL.CGV_FILE + cgvId, {}, 'blob');
  }

  /**
   * Sign offer and return corresponding policy
   * @param data object with document_id and offer_id
   */
  public postOfferSign(data: any): Observable<any> {
    return this.apiSrv.httpPost(this.URL.OFFER_SIGN, {}, data).pipe(
      map((apiResponse: ApiResponse) => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Request BO to add a customer's document
   * @param params query params like api_token
   * @param data file object is mandatory
   */
  public postDocument(params: any, data: any): Observable<any> {
    return this.apiSrv.httpPost('/v2' + this.URL.DOCUMENT, params, data).pipe(
      map((apiResponse: ApiResponse) => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Request BO to make a soft delete of customer's document
   * @param documentId customer's document id
   */
  public deleteDocument(documentId: number): Observable<{ success: boolean }> {
    return this.apiSrv.httpDelete(this.URL.DOCUMENT + '/' + documentId);
  }

  /**
   * Request BO to send document to customer by mail
   * @param documentId document id
   * @param params query params like api_token
   */
  public requestMailDocumentToCustomer(documentId: number, params: any): Observable<any> {
    const path = this.URL.DOCUMENT + '/' + documentId + '/mailCustomer';
    return this.apiSrv.httpGet(path, params);
  }

  /**
   * Return an observable of a map of item templates
   * @param key foreign table id
   */
  public requestItemTemplates(key: number): Observable<{ [id: string]: ItemTemplateFO }> {
    return this.apiSrv.httpGet(this.URL.ITEM_TEMPLATE + '/' + key);
  }

  /**
   * Save items data to BO
   * @param params params query params like api_token
   * @param items items
   */
  public postItems(params: any, items: Array<{ item_template_id: string; value: any }>): Observable<any> {
    return this.apiSrv.httpPost('/v2' + this.URL.ITEM, params, items).pipe(
      map((apiResponse: ApiResponse) => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Update items data to BO
   * @param params query params like api_token
   * @param items items
   */
  public putItems(params: any, items: Array<{ item_template_id: string; value: any }>): Observable<any> {
    return this.apiSrv.httpPut('/v2' + this.URL.ITEM, params, items).pipe(
      map((apiResponse: ApiResponse) => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Delete items from BO
   * @param params query params like api_token
   */
  public deleteItem(id: number, params: any): Observable<any> {
    return this.apiSrv.httpDelete(this.URL.ITEM + '/' + id, params).pipe(
      map((apiResponse: ApiResponse) => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Request BO to store a call rdv
   * @param data phone, phone prefix and expected data
   */
  public postRequestCall(data: { expected_date: string; phone: string; phone_prefix: string }): Observable<any> {
    return this.apiSrv.httpPost(this.URL.RECALL, {}, data).pipe(
      map((apiResponse: ApiResponse) => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Return an observable
   * @param agencyId agency id
   * @param sliderType slider type
   */
  public requestSliders(agencyId: number, sliderType: SliderType): Observable<any> {
    return this.apiSrv.httpGet(this.URL.SLIDERS + sliderType, { agency_id: agencyId }).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Return an observable of a statuses array
   */
  public requestStatuses(): Observable<Status[]> {
    return this.apiSrv.httpGet(this.URL.STATUS);
  }

  /**
   * Return an observable of LoginApiResponse
   * @param login customer login
   * @param password customer password
   * @param agencyId id's agency linked to customer
   */
  public postLogin(login: string, password: string, agencyId?: number): Observable<LoginApiResponse> {
    const noAgency = !agencyId,
      params = noAgency ? {} : { agency_id: agencyId };

    return this.apiSrv.httpPost(this.URL.LOGIN, params, { login, password }, noAgency, true);
  }

  /**
   * Return an observable of LoginApiResponse
   * @param customerIdLinked linked customer id
   */
  public postLoginOnSwitchMode(customerIdLinked: number): Observable<LoginApiResponse> {
    return this.apiSrv.httpPost(this.URL.LOGIN_AS_CUSTOMER + customerIdLinked);
  }

  /**
   * Return an observable of compares object
   * @param params query params like api_token
   */
  public requestCompares(params: object): Observable<any> {
    return this.apiSrv.httpGet(this.URL.COMPARES, params);
  }

  /**
   * Return an observable of compare object
   * @param compareId compare id
   * @param params query params like api_token
   */
  public requestCompare(compareId: number, params: object): Observable<any> {
    return this.apiSrv.httpGet(this.URL.COMPARE + compareId, params);
  }

  /**
   * Return an observable of customer api response
   * @param params query params like api_token
   * @param noAgency flag to remove agency_id from request
   */
  public requestCustomer(params: object, noAgency: boolean): Observable<ApiResponse> {
    return this.apiSrv.httpGet(this.URL.CUSTOMER, params, null, null, noAgency);
  }

  /**
   * Return an observable of WS response
   * @param query object with customerTypeId, lastname, firstname and dob attributes
   * @param params query params like api_token
   * TODO
   */
  public requestCustomerExists(
    query: { customerTypeId: number; lastname: string; firstname: string; dob: string },
    params: object
  ): Observable<{ exists: boolean; error: any; customer: any }> {
    const url = _.compact([
      this.URL.CUSTOMER_EXISTS,
      query.customerTypeId,
      query.lastname,
      query.firstname,
      query.dob,
    ]).join('/');

    return this.apiSrv.httpGet(url, params);
  }

  /**
   * Return an observable of api response
   * @param data object with customer_link_type_id, customer_id_linked
   * @param params query params like api_token
   */
  public postCustomerLink(
    data: { customer_link_type_id?: number; customer_id_linked: number; role_id?: number; api_token?: string },
    params
  ): Observable<ApiResponse> {
    return this.apiSrv.httpPost(this.URL.CUSTOMER_LINK, params, data).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Update customer_link right read, write and delete
   * @param customerLinkId customer link id
   * @param params query params like api_token
   * @param roleId customer link role's id
   * @param rights an object with read, write and delete attributes
   */
  public putCustomerLink(
    customerLinkId: number,
    params: object,
    roleId: number,
    rights?: { read: number; write: number; delete: number }
  ): Observable<ApiResponse> {
    const url = [this.URL.CUSTOMER_LINK, customerLinkId].join('/');
    let payload = { role_id: roleId };

    if (rights) {
      payload = _.assign(payload, rights);
    }

    return this.apiSrv.httpPut(url, params, payload).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Get a new pairing code from BO for the current customer only
   * @param params query params like api_token
   */
  public requestPairingCode(params) {
    return this.apiSrv.httpGet(this.URL.CUSTOMER_LINK_PAIRING, params).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Register a customerLink between two customers using a pairing code
   * @param data object with customer_link_type_id and pairing_code attributes
   * @param params query params like api_token
   */
  public postCustomerLinkPairing(
    data: {
      pairing_code: string;
      customer_link_type_id?: number;
    },
    params: object
  ): Observable<ApiResponse> {
    return this.apiSrv.httpPost(this.URL.CUSTOMER_LINK_PAIRING, params, data).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Delete customer link from BO
   * @param data object with customer_id, customer_id_linked and is_bidirectional attributes
   */
  public postDeleteCustomerLink(data: { customer_id: number; customer_id_linked: number; is_bidirectional: boolean }) {
    return this.apiSrv.httpPost(this.URL.CUSTOMER_LINK_DELETE, {}, data).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Request to BO in order to change password with SMS code
   * @param data object with code, login, password and password_confirmation attributes
   * @param noAgency flag to remove agency_id from params
   * @param agencyId customer agency id
   */
  public putResetCustomerPasswordWithSMS(
    data: {
      code: string;
      login: string;
      password: string;
      password_confirmation: string;
    },
    noAgency?: boolean,
    agencyId?: number
  ): Observable<ApiResponse> {
    let params;

    if (!environment.DEDICATED_APP) {
      params = agencyId ? { agency_id: agencyId } : {};
    } else {
      params = { agency_id: environment.AGENCY_ID };
    }
    return this.apiSrv.httpPut(this.URL.RESET_PASSWORD, params, data, noAgency, true).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Request to BO in order to change password with old password
   * @param data object with old_password, password and password_confirmation attributes
   */
  public putResetCustomerPassword(data: {
    old_password: string;
    password: string;
    password_confirmation: string;
    customer_id_linked: number;
  }): Observable<ApiResponse> {
    const params = {};

    if (data.customer_id_linked) {
      // @ts-ignore
      params.customer_id_linked = data.customer_id_linked;
    }
    return this.apiSrv.httpPut(this.URL.RESET_PASSWORD, params, data).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Ask BO to check login provided and send an SMS with validation code
   * @param data object with login attribute
   * @param noAgency flag to remove agency_id from params
   * @param agencyId Agency's id
   */
  public postResetPasswordLoginValidation(
    data: { login: string },
    noAgency: boolean,
    agencyId: number
  ): Observable<any> {
    let params;

    if (!environment.DEDICATED_APP) {
      params = agencyId ? { agency_id: agencyId } : {};
    } else {
      params = { agency_id: environment.AGENCY_ID };
    }
    return this.apiSrv.httpPost(this.URL.LOGIN_VALIDATION, params, data, noAgency).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * login a BO user as a customer in PWA
   * @param data object with user_token and customer_id attributes
   */
  public postLoginUserAsCustomer(data: {
    user_token: string;
    customer_id: number;
  }): Observable<LoginUserAsCustomerApiResponse> {
    return this.apiSrv.httpPost(this.URL.LOGIN_USER_AS_CUSTOMER, {}, data, true).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Register new customer to the BO
   * @param customer Customer object
   * @param agencyId agency Id
   */
  public postRegisterCustomer(customer: Customer, agencyId: number): Observable<ApiResponse> {
    const params = {};

    if (agencyId) {
      // @ts-ignore
      params.agency_id = agencyId;
    }

    return this.apiSrv.httpPost(this.URL.REGISTER, params, customer, true).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Register customer's device
   * @param device device used
   * @param params query params like api_token
   */
  public postAddCustomerDevice(device: any, params: object): Observable<ApiResponse> {
    return this.apiSrv.httpPost(this.URL.DEVICE, params, device, true).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Ask for an SMS authentication
   * @param params query params with api_token
   */
  public postSendSmsAuthentication(params: object): Observable<number> {
    return this.apiSrv.httpPost(this.URL.SMS_AUTH, params, null, true);
  }

  /**
   * Send SMS code for customer validation
   * @param code code received by SMS
   * @param params query params with api_token
   */
  public postValidateCustomer(code: string, params: object): Observable<any> {
    return this.apiSrv.httpPost(this.URL.VALIDATE + '/' + code, params).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * call to validate customer via external link
   * @param authToken token to validate
   */
  public postExternalAuthValidateCustomer(authToken: string): Observable<any> {
    return this.apiSrv.httpPost(this.URL.AUTH_LINK_VALIDATE, {}, { auth_token: authToken }).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Return an observable of cities array
   * @param zipcode city's postal code
   */
  public requestCitiesFromZipCode(zipcode: string): Observable<City[]> {
    return this.apiSrv.httpGet(this.URL.CITY + zipcode);
  }

  /**
   * Save Customer Address
   * @param address address object
   * @param params query params with api_token
   */
  public postCustomerAddress(address: Address, params: object): Observable<ApiResponse> {
    return this.apiSrv.httpPost(this.URL.ADDRESS, params, address).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Save Customer bank data
   * @param bankData customer's bank data
   * @param params query params with api_token
   */
  public postCustomerBank(bankData: CustomerBank, params: object): Observable<ApiResponse> {
    return this.apiSrv.httpPost(this.URL.BANK, params, bankData).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * update Customer bank data
   * @param bankData customer's bank data
   * @param params query params with api_token
   */
  public putCustomerBank(bankData: CustomerBank, params: object): Observable<ApiResponse> {
    return this.apiSrv.httpPut(this.URL.BANK, params, bankData).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Return an observable of policies response
   * @param params query params with api_token
   */
  public requestPolicies(params: object): Observable<any> {
    return this.apiSrv.httpGet(this.URL.POLICIES, params);
  }

  /**
   * Return an observable of policy response
   * @param id policy id
   * @param params query params with api_token and version
   */
  public requestPolicy(id: number, params: object): Observable<any> {
    return this.apiSrv.httpGet(this.URL.POLICY + id, params);
  }

  /**
   * Return an observable of policies response
   * @param policyId policy id
   * @param params query params with api_token
   */
  public requestPolicyInvoices(policyId: number, params: object): Observable<any> {
    return this.apiSrv.httpGet(this.URL.POLICY_INVOICES + policyId, params);
  }

  /**
   * Return an observable of mandates response
   * @param params query params with api_token
   */
  public requestMandates(params: object): Observable<any> {
    return this.apiSrv.httpGet(this.URL.MANDATES, params);
  }

  /**
   * Return an observable of BO response on adding mandate
   * @param data an object with company id, insurance type id and optional policy number
   * @param params query params with api_token
   */
  public postMandate(
    data: {
      company_id: number;
      insurance_type_id: number;
      temp_policy_number?: string;
    },
    params: object
  ): Observable<ApiResponse> {
    return this.apiSrv.httpPost(this.URL.MANDATES, params, data).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Return an Observable of api response
   * @param id mandate id
   * @param params query params with api_token
   */
  public deleteMandate(id: number, params: object): Observable<ApiResponse> {
    return this.apiSrv.httpDelete(this.URL.MANDATE + id, params).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Return an observable of map of refunds for a given policy
   * @param policyId policy Id
   * @param params query params with api_token
   */
  public requestRefunds(policyId: number, params: object): Observable<any> {
    return this.apiSrv.httpGet(this.URL.REFUNDS + policyId, params);
  }

  /**
   * Save new refund to BO
   * @param refund refund object
   * @param params query params with api_token
   */
  public postRefund(refund: Refund, params): Observable<ApiResponse> {
    return this.apiSrv.httpPost(this.URL.REFUND, params, refund).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Return an Observable of a map of insurance types
   * @param type private or corporate constant
   */
  public requestInsuranceTypes(type: number): Observable<{ [id: string]: InsuranceType }> {
    return this.apiSrv.httpGet(this.URL.INSURANCE_TYPES + type);
  }

  /**
   * Return an Observable of a map of safebox types
   */
  public requestSafeboxTypes(): Observable<{ [id: string]: SafeboxType }> {
    return this.apiSrv.httpGet(this.URL.SAFEBOX_TYPES);
  }

  /**
   * Return an observable of a map of safeboxes
   */
  public requestSafeboxes(): Observable<any> {
    return this.apiSrv.httpGet(this.URL.SAFEBOXES);
  }

  /**
   * Return an observable of a safeboxe
   * @param id safebox Id
   */
  public requestSafeboxe(id: number): Observable<any> {
    return this.apiSrv.httpGet('/v2' + this.URL.SAFEBOXE + '/' + id);
  }

  /**
   * Save new safebox
   * @param data object with json and safebox_type_id properties
   */
  public postSafebox(data: { json: string; safebox_type_id: number }): Observable<object> {
    return this.apiSrv.httpPost('/v2' + this.URL.SAFEBOXE, {}, data);
  }

  /**
   * Update the safebox corresponding to the safeboxId
   * @param data object with json, safebox_type_id and safebox_id properties
   */
  public putSafebox(data: { json: string; safebox_type_id: number; safebox_id: number }): Observable<object | number> {
    return this.apiSrv.httpPut('/v2' + this.URL.SAFEBOXE, {}, data);
  }

  /**
   * Remove safebox
   * @param safeboxId safebox id
   */
  public deleteSafebox(safeboxId: number): Observable<object> {
    return this.apiSrv.httpDelete(this.URL.SAFEBOXE + '/' + safeboxId);
  }

  /**
   * Return an observable of a map of arrays of comments
   * @param customerId customer's id
   */
  public requestChatItems(
    customerId: number
  ): Observable<{
    customer: { [id: string]: any[] };
    policy: { [id: string]: any[] };
    compare: { [id: string]: any[] };
  }> {
    return this.apiSrv.httpGet(this.URL.CHAT, { customer_id_linked: customerId });
  }

  /**
   * Return an observable of response object
   * @param data object with message attribute
   * @param customerId customer's id
   */
  public postChatItem(data: { message: string }, customerId: number): Observable<any> {
    return this.apiSrv.httpPost(this.URL.CHAT + '/customer/' + customerId, {}, data).pipe(
      map(apiResponse => {
        this.checkResponse(apiResponse);
        return apiResponse;
      })
    );
  }

  /**
   * Throw an error if apiResponse success is false
   * @param apiResponse response from BO
   */
  private checkResponse(apiResponse: ApiResponse) {
    if (!apiResponse.success && apiResponse.msgs) {
      if (typeof apiResponse.msgs === 'string') {
        throw apiResponse.msgs;
      } else {
        const key = Object.keys(apiResponse.msgs)[0];
        throw _.isArray(apiResponse.msgs[key]) ? apiResponse.msgs[key][0] : apiResponse.msgs[key];
      }
    } else if (!apiResponse.success && apiResponse.errors) {
      if (typeof apiResponse.errors === 'string') {
        throw apiResponse.errors;
      } else {
        const key = Object.keys(apiResponse.errors)[0];
        throw _.isArray(apiResponse.errors[key]) ? apiResponse.errors[key][0] : apiResponse.errors[key];
      }
    }
  }
}
