import { Injectable } from '@angular/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';

import { Agency, AgencyResponse, Contract, DefaultAddress } from '../gfl-models/agency.model';
import { Contact } from '../gfl-models/contact.model';
import { Customer } from '../../customer/models/customer.model';
import { WsService } from './ws.service';
import { ConstantService } from './constant.service';
import { StoreService } from './store.service';
import { ToolsService } from './tools.service';
import { AclsService } from './acls.service';
import { ThemeService } from './theme.service';
import { SpriteService } from './sprite.service';
import { NotificationService } from './notification.service';
import { DataMonitorService } from './data-monitor.service';

import { environment } from '../../../environments/environment';

interface Email {
  to: string[];
  subject: string;
  body: string;
}

@Injectable({
  providedIn: 'root',
})
export class AgencyService {
  private readonly appName = environment.APP_NAME;
  private agencyId;

  /**
   * @ignore
   */
  constructor(
    private wsSrv: WsService,
    private constantSrv: ConstantService,
    private tools: ToolsService,
    private translate: TranslateService,
    private socialSharing: SocialSharing,
    private store: StoreService,
    private aclsSrv: AclsService,
    private themeSrv: ThemeService,
    private spriteSrv: SpriteService,
    private notificationSrv: NotificationService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const locksToMonitor = [
      {
        name: 'agency',
        lock: () => this.store.getAgencyLock(),
        cb: () => this.setAgency(this.agencyId),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Fetch Agency on app initialization
   */
  public async loadAgency(): Promise<any> {
    return this.store
      .get(this.appName + '_auth')
      .pipe(
        first(),
        switchMap(auth => {
          if (auth && auth.customerToken) {
            return of(auth.agencyId);
          } else if (environment.DEDICATED_APP) {
            return of(environment.AGENCY_ID);
          } else {
            return this.getAdminAgencyId();
          }
        }),
        switchMap(id => {
          return this.store.get(`${this.appName}_agency`).pipe(
            mergeMap(agency => {
              if (!environment.DEDICATED_APP) {
                environment.AGENCY_ID = id;
              }

              this.agencyId = id;
              if (!agency || agency.agency.id !== id) {
                return this.setAgency(id);
              } else {
                this.themeSrv.setTheme();
                this.spriteSrv.initService(environment.STORAGE_URL + agency.spriteUrl);
                return of(null);
              }
            })
          );
        })
      )
      .toPromise();
  }

  /**
   * Return admin agency id
   */
  public getAdminAgencyId(): Observable<number> {
    return this.wsSrv.requestAgenciesWs().pipe(
      map(agencies => {
        const agency = _.find(agencies, o => {
          return o.is_admin === 1;
        });

        return agency.id;
      })
    );
  }

  /**
   * Return an array of reel agencies
   */
  public getAgencies(): Observable<Agency[]> {
    return this.wsSrv.requestAgenciesWs().pipe(
      map(agenciesMap => {
        const agencies = _.toArray(agenciesMap) as Agency[];
        _.remove(agencies, agency => {
          return agency.is_admin === 1;
        });

        return agencies;
      })
    );
  }

  /**
   * Set agency data and style in store
   * @param agencyId agency's id
   */
  public setAgency(agencyId: number): Observable<void> {
    return this.store.setAgencyLock(Date.now()).pipe(
      mergeMap(() => {
        return this.wsSrv.requestAgencyWs(agencyId);
      }),
      mergeMap(apiResponse => {
        return forkJoin([
          this.tools.fetchSVGImage(apiResponse.agency.logo_public),
          this.tools.fetchSVGImage(apiResponse.agency.logo_private),
          of(apiResponse),
        ]);
      }),
      mergeMap(([logoPublic, logoPrivate, apiResponse]) => {
        // we store public and private svg logos
        apiResponse.agency.logo_public = logoPublic;
        apiResponse.agency.logo_private = logoPrivate;

        this.store.setAdminLogo(logoPublic);

        return of(apiResponse);
      }),
      mergeMap(apiResponse => {
        apiResponse = this.formatOpeningDate(apiResponse);
        apiResponse.spriteUrl = apiResponse.front_theme.icons_global;

        this.store.setAgency(apiResponse.agency);
        if (apiResponse.default_address) {
          this.store.setAgencyAddress(apiResponse.default_address);
        }
        if (apiResponse.contacts && apiResponse.contacts.length) {
          this.store.setContacts(apiResponse.contacts);
        }
        this.store.setStyle(apiResponse.front_theme);
        this.themeSrv.setTheme();
        return of(apiResponse);
      }),
      mergeMap(apiResponse => this.spriteSrv.setSprite(environment.STORAGE_URL + apiResponse.front_theme.icons_global)),
      mergeMap(() => this.store.setAgencyLock(null)),
      catchError(err => {
        this.tools.error('setAgency Error', err);
        return of(null);
      })
    );
  }

  /**
   * Set agency partial data in store (used in sign up process)
   * @param agencyId agency's id
   */
  public setPartialAgency(agencyId) {
    return this.wsSrv.requestAgencyWs(agencyId).pipe(
      withLatestFrom(this.getPublicLogo().pipe(first())),
      mergeMap(([apiResponse, logo]) => {
        apiResponse.agency.logo_public = logo;
        this.store.setAgency(apiResponse.agency);
        if (apiResponse.default_address) {
          this.store.setAgencyAddress(apiResponse.default_address);
        }
        return of(true);
      })
    );
  }

  /**
   * Return an observable of agency object
   */
  public getAgency(): Observable<Agency> {
    return this.store.getAgency();
  }

  /**
   * Return an observable of agency's contacts
   */
  public getContacts(): Observable<Contact[]> {
    return this.store.getContacts();
  }

  /**
   * Set Contacts in store
   * @param customerId customer ID
   */
  public setContacts(customerId: number): Observable<void> {
    return this.store.getCustomer(customerId).pipe(
      first(),
      withLatestFrom(this.getContacts()),
      map(([storedCustomer, contacts]) => {
        this.store.setContacts(this.updateContacts(contacts, storedCustomer.customer));
      })
    );
  }

  /**
   * Return public logo of agency if defined
   */
  public getPublicLogo(): Observable<string> {
    return this.store.getPublicLogo();
  }

  /**
   * Return an observable of agency's contract template data
   * @param customerId customer's id
   * @param apiToken current customer's apiToken
   */
  public getContractTemplate(customerId: number, apiToken?: string): Observable<Contract> {
    const params = {};
    if (customerId) {
      // @ts-ignore
      params.customer_id_linked = customerId;
    }

    if (apiToken) {
      // @ts-ignore
      params.api_token = apiToken;
    }
    return this.wsSrv.requestAgencyContractTemplate(params);
  }

  /**
   * Return an observable of the agency's name
   */
  public getAgencyName(): Observable<string> {
    return this.store.getAgencyName();
  }

  /**
   * Return an observable of the agency's address
   */
  public getAgencyAddress(): Observable<DefaultAddress> {
    return this.store.getAgencyAddress();
  }

  /**
   * Return an observable of the private logo of agency if defined
   */
  public getPrivateLogo(): Observable<string> {
    return this.store.getPrivateLogo();
  }

  /**
   * Return an observable of isAgencyAdmin flag
   */
  public getIsAgencyAdmin(): Observable<boolean> {
    return this.store.getIsAgencyAdmin();
  }

  /**
   * Send an email to the advisor
   */
  public sendMessageToAdvisor(): Observable<any> {
    return this.store.getContacts().pipe(
      first(),
      mergeMap(contacts => {
        if (!contacts.length) {
          throwError('API.NO_CONTACT_EMAIL');
        }

        // Get the director contact
        const CONTACT_TYPE_ID_AGENCY_CONTACT = this.constantSrv.getValueFromKey('CONTACT_TYPE_ID_AGENCY_CONTACT');
        let contact = _.find(contacts, { contact_type_id: CONTACT_TYPE_ID_AGENCY_CONTACT });

        if (!contact || !contact.email) {
          // if no director then use the first contact of contacts array
          if (!contacts[0].email) {
            throwError('API.NO_CONTACT_EMAIL');
          }

          contact = contacts[0];
        }

        const email: Email = {
          to: [contact.email],
          subject: '',
          body: '',
        };

        return this.shareIt(email);
      })
    );
  }

  /**
   * Send a suggestion email
   */
  public sendSuggestions(): Observable<any> {
    return this.store.getAgency().pipe(
      first(),
      mergeMap(agency => {
        return this.translate.get(['COMMON.SUGGESTION_EMAIL_SUBJECT', 'COMMON.SUGGESTION_EMAIL_BODY'], {
          agency_name: agency.name,
        });
      }),
      withLatestFrom(this.store.getContacts()),
      mergeMap(([translation, contacts]) => {
        if (!contacts.length) {
          throwError('API.NO_CONTACT_EMAIL');
        }

        // Get the director contact
        const CONTACT_TYPE_ID_AGENCY_CONTACT = this.constantSrv.getValueFromKey('CONTACT_TYPE_ID_AGENCY_CONTACT');
        let contact = _.find(contacts, { contact_type_id: CONTACT_TYPE_ID_AGENCY_CONTACT });

        if (!contact || !contact.email) {
          // if no director then use the first contact of contacts array
          if (!contacts[0].email) {
            throwError('API.NO_CONTACT_EMAIL');
          }

          contact = contacts[0];
        }

        const email: Email = {
          to: [contact.email],
          subject: translation['COMMON.SUGGESTION_EMAIL_SUBJECT'],
          body: translation['COMMON.SUGGESTION_EMAIL_BODY'],
        };

        return this.shareIt(email);
      }),
      catchError(err => {
        this.notificationSrv.showError({ message: err });
        return of(null);
      })
    );
  }

  /**
   * Format openingHours data to an array of objects {day,hours}
   * @param apiResponse response from BO
   */
  private formatOpeningDate(apiResponse: AgencyResponse): AgencyResponse {
    const openingHours = [];

    if (typeof apiResponse.agency.opening_hours !== 'string') {
      _.forEach(apiResponse.agency.opening_hours, (item: string, key: string) => {
        if (item.length) {
          // @ts-ignore
          openingHours.push({ day: this.tools.getWeekDays()[key - 1], hours: item });
        }
      });
    }

    apiResponse.agency.opening_hours_formatted = openingHours;

    return apiResponse;
  }

  /**
   * Return advisor data
   */
  public getMyContact(): Observable<Contact> {
    const CONTACT_TYPE_ID_AGENCY_CONTACT = this.constantSrv.getValueFromKey('CONTACT_TYPE_ID_AGENCY_CONTACT');

    return this.store.getContacts().pipe(
      map(contacts => {
        return _.find(contacts, { contact_type_id: CONTACT_TYPE_ID_AGENCY_CONTACT });
      })
    );
  }

  /**
   * Send an email
   * @param email email object
   */
  private shareIt(email: Email): Observable<any> {
    if (this.tools.isNative()) {
      // Share via email
      return from(this.socialSharing.shareViaEmail(email.body, email.subject, email.to));
    } else {
      email.body = email.body.replace(/<br\/>/gi, '\n');

      let href = `mailto:${email.to}?`;
      href += `subject=${email.subject}&`;
      href += `body=${email.body}`;

      window.open(href);
      return of(null);
    }
  }

  /**
   * Update contact of the agency with the customer advisor if any
   * @param contacts agency's contacts
   * @param customer a customer
   */
  private updateContacts(contacts: Contact[], customer: Customer): Contact[] {
    contacts = _.cloneDeep(contacts);

    if (customer && customer.advisor) {
      const CONTACT_TYPE_ID_AGENCY_CONTACT = this.constantSrv.getValueFromKey('CONTACT_TYPE_ID_AGENCY_CONTACT');
      const key = _.findKey(contacts, { contact_type_id: CONTACT_TYPE_ID_AGENCY_CONTACT });

      const advisor = customer.advisor;
      const update: boolean = !!(advisor && advisor.email && advisor.phone);

      // advisor must have an email and a phone else we keep agency contact
      if (update) {
        if (contacts && key) {
          contacts[key].firstname = customer.advisor.firstname;
          contacts[key].lastname = customer.advisor.lastname;
          contacts[key].email = customer.advisor.email;
          contacts[key].phone = customer.advisor.phone;
        } else {
          contacts = [];
          contacts.push({
            firstname: customer.advisor.firstname,
            lastname: customer.advisor.lastname,
            email: customer.advisor.email,
            phone: customer.advisor.phone,
            contact_type_id: CONTACT_TYPE_ID_AGENCY_CONTACT,
          });
        }
      }
    }

    return contacts;
  }
}
