import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import * as hash from 'object-hash';

import { forkJoin, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap, pluck } from 'rxjs/operators';

import { WsService } from '../../gfl-core/gfl-services/ws.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { ChatItem, ChatItemsMap, NotificationItem, NotificationType } from '../models/contacts.model';
import { AuthService } from '../../authentication/services/auth.service';
import { ConstantService } from '../../gfl-core/gfl-services/constant.service';
import { DataMonitorService } from '../../gfl-core/gfl-services/data-monitor.service';
import { CustomerService } from '../../customer/services/customer.service';
import { AclsService } from '../../gfl-core/gfl-services/acls.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  constructor(
    private wsSrv: WsService,
    private store: StoreService,
    private tools: ToolsService,
    private authSrv: AuthService,
    private customerSrv: CustomerService,
    private constantSrv: ConstantService,
    private dataMonitorSrv: DataMonitorService,
    private aclsSrv: AclsService
  ) {
    const locksToMonitor = [
      {
        name: 'chatItems',
        lock: () => this.store.getChatItemsLock(),
        cb: () => this.setChatItems(),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * store Chat items
   */
  public setChatItems(): Observable<any> {
    return this.store.setChatItemsLock(Date.now()).pipe(
      mergeMap(() => this.store.getAuthData()),
      mergeMap(authData => {
        const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
        const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
        const except =
          authData.customerTypeId === CUSTOMER_TYPE_ID_PRIVATE
            ? [CUSTOMER_TYPE_ID_EMPLOYEE]
            : [CUSTOMER_TYPE_ID_PRIVATE];

        return this.customerSrv.getCustomersIdList(except);
      }),
      filter(val => !_.isEmpty(val)),
      first(),
      mergeMap(customersIdArr => {
        const obs$: Array<Observable<any>> = [];

        _.forEach(customersIdArr, (id: number) => {
          obs$.push(this.setChatItemsByCustomer(id));
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }

        return forkJoin(obs$);
      }),
      mergeMap((chatMap: { customerId: number; new: number; items: ChatItem[] }[]) => {
        const chatItemsStore = {} as ChatItemsMap;

        _.forEach(chatMap, item => {
          chatItemsStore[item.customerId] = {
            new: item.new,
            items: item.items,
          };
        });

        this.store.setChatItems(chatItemsStore);

        return this.store.setChatItemsLock(null);
      })
    );
  }

  /**
   * Return an observable of compares of a given customer, customer's token is also given
   * @param customerId customer ID
   */
  private setChatItemsByCustomer(
    customerId: number
  ): Observable<{ customerId: number; new: number; items: ChatItem[] }> {
    const chatItemsFromBO = {} as { [id: number]: ChatItem };
    const COMMENT_COMMENTABLE_AUTHOR_USER = this.constantSrv.getValueFromKey('COMMENT_COMMENTABLE_AUTHOR_USER');

    return this.aclsSrv.getAcls(customerId).pipe(
      first(),
      mergeMap(acls => {
        return forkJoin([
          acls.LOAD_CHAT ? this.wsSrv.requestChatItems(customerId) : of({}),
          this.store.getChatItemsByCustomer(customerId).pipe(first()),
          this.store.get(environment.APP_NAME + '_setNotifications').pipe(first()),
        ]);
      }),
      mergeMap(
        ([chatItemsBO, chatItemsStore, setNotifications]: [
          {
            customer?: { [id: string]: any[] };
            policy?: { [id: string]: any[] };
            compare?: { [id: string]: any[] };
          },
          { new: number; items: ChatItem[] },
          boolean
        ]) => {
          _.forEach(chatItemsBO, typeContent => {
            _.forEach(typeContent, items => {
              _.forEach(items, item => {
                const deepLink = this.setDeeplinkForChatItem(item);

                chatItemsFromBO[item.id] = {
                  id: item.id,
                  message: item.message,
                  authorType: item.author_type,
                  authorName: item.author && item.author.name,
                  createdAt: moment(item.created_at).format('LL'),
                  done: false,
                  commentable: {
                    type: item.commentable_type,
                    id: item.commentable_id,
                    deepLink,
                  },
                };
              });
            });
          });

          const chatItemsFromStoreMap = _.keyBy(chatItemsStore.items, 'id');

          const chatItemsToAdd: ChatItem[] = this.tools.findNewEntities(chatItemsFromStoreMap, chatItemsFromBO, 'id');

          if (setNotifications) {
            const notifications: NotificationItem[] = [];

            _.forEach(chatItemsToAdd, item => {
              if (item.authorType === COMMENT_COMMENTABLE_AUTHOR_USER) {
                // we don't need to warn customer that he/she has send a message :)
                notifications.push({
                  done: false,
                  id: hash(item),
                  icon: 'chatbubbles',
                  mobileLink: '/chat',
                  tabletLink: '?chat=true',
                  type: NotificationType.Chat,
                  text: 'CONTACT.NOTIFICATIONS.NEW_CHAT',
                  customerId,
                });
              }
            });

            this.store.addNotifications(notifications);
          }

          return of({
            customerId,
            new: chatItemsStore.new + chatItemsToAdd.length,
            items: [...chatItemsStore.items, ...chatItemsToAdd],
          });
        }
      )
    );
  }

  /**
   * return an observable of an array of chat items
   */
  public getChatItems(): Observable<{ [customerId: number]: { new: number; items: ChatItem[] } }> {
    return this.store.getChatItems();
  }

  /**
   * Send chat message and reload chat items
   * @param message message to send
   * @param customerId customer's id
   */
  public sendChatItem(message, customerId: number): Observable<any> {
    return this.wsSrv.postChatItem({ message }, customerId).pipe(mergeMap(() => this.setChatItems()));
  }

  /**
   * Mark all chatItems as seen and update store
   * @param customerId customer's id
   */
  public markChatItemsAsSeen(customerId?: number): Observable<any> {
    let clonedItemsMap;

    return this.getChatItems().pipe(
      first(),
      mergeMap(itemsMap => {
        clonedItemsMap = _.cloneDeep(itemsMap);

        if (clonedItemsMap[customerId]) {
          clonedItemsMap[customerId].items = _.map(clonedItemsMap[customerId].items, (item: ChatItem) => {
            item.done = true;
            return item;
          });

          clonedItemsMap[customerId].new = 0;
        }

        return of(clonedItemsMap);
      }),
      mergeMap(items => of(this.store.setChatItems(items)))
    );
  }

  /**q
   * Add a notification to store
   */
  public addNotification(notifications: NotificationItem[]): Observable<any> {
    return of(this.store.addNotifications(notifications));
  }

  /**
   * return an observable of an array of notification items
   */
  public getNotifications(): Observable<NotificationItem[]> {
    return this.store.getNotifications();
  }

  /**
   * return an observable of an array of notification items not done
   */
  public getNotificationsToDisplay(): Observable<NotificationItem[]> {
    return this.store.getNotifications().pipe(
      map(notifications =>
        _.filter(notifications, notif => {
          return notif.done === false;
        })
      )
    );
  }

  private getNotReadChatItems(customerId: number): Observable<ChatItem[]> {
    return this.store.getChatItemsByCustomer(customerId).pipe(
      first(),
      pluck('items'),
      map(items =>
        _.filter(items, item => {
          return item.done === false;
        })
      )
    );
  }

  /**
   * Set and store a notification to done
   * @param notification notification object
   */
  public updateNotification(notification: NotificationItem): Observable<any> {
    return this.store.getNotifications().pipe(
      first(),
      map(notificationsArr => {
        const notifications = _.cloneDeep(notificationsArr);
        const idx = _.findIndex(notifications, { id: notification.id });

        notifications[idx].done = true;
        this.store.setNotifications(notifications);
      })
    );
  }

  /**
   * Set deep links for a chat item
   * @param item a chat item from BO
   */
  private setDeeplinkForChatItem(item: any): { path: string; label: string } {
    let deepLink = {
      path: undefined,
      label: undefined,
    };
    const COMMENT_COMMENTABLE_AUTHOR_USER = this.constantSrv.getValueFromKey('COMMENT_COMMENTABLE_AUTHOR_USER');
    const COMMENT_COMMENTABLE_TYPE_CUSTOMER = this.constantSrv.getStringValueFromKey(
      'COMMENT_COMMENTABLE_TYPE_CUSTOMER'
    );
    const COMMENT_COMMENTABLE_TYPE_POLICY = this.constantSrv.getStringValueFromKey('COMMENT_COMMENTABLE_TYPE_POLICY');
    const COMMENT_COMMENTABLE_TYPE_COMPARE = this.constantSrv.getStringValueFromKey('COMMENT_COMMENTABLE_TYPE_COMPARE');

    if (item.author_type === COMMENT_COMMENTABLE_AUTHOR_USER) {
      switch (item.commentable_type) {
        case COMMENT_COMMENTABLE_TYPE_POLICY:
          deepLink = {
            path: '/policies/policy-detail/' + item.commentable_id,
            label: 'CONTACT.CHAT.DISPLAY_POLICY',
          };
          break;
        case COMMENT_COMMENTABLE_TYPE_CUSTOMER:
          deepLink = {
            path: '/profile',
            label: 'CONTACT.CHAT.DISPLAY_CUSTOMER',
          };
          break;
        case COMMENT_COMMENTABLE_TYPE_COMPARE:
          deepLink = {
            path: '/compares/' + item.commentable_id,
            label: 'CONTACT.CHAT.DISPLAY_COMPARE',
          };
          break;
      }
    }

    return deepLink;
  }
}
