import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { IonicSelectableComponent } from 'ionic-selectable';

import { Observable, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { GflThemeOptions } from '../../models/gfl-form-options.model';
import { GflModeDisplayType, GflSelectOption } from '../../models/gfl-form.model';
import { FrontTheme } from '../../../../gfl-core/gfl-models/agency.model';
import { ToolsService } from '../../../../gfl-core/gfl-services/tools.service';

@Component({
  selector: 'gfl-field-select',
  templateUrl: './gfl-field-select.component.html',
  styleUrls: ['./gfl-field-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflFieldSelectComponent implements OnChanges {
  /**
   * initial value
   */
  @Input() value: string;

  /**
   * hide value and formatted value in order to display only label
   */
  @Input() hideValue: boolean;

  /**
   * the form implementing this field in two way binding
   */
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;

  /**
   * An array of validators to add to the formControl
   */
  @Input() validators: Array<ValidatorFn> = [];

  /**
   * Set type of displayed template between "mobile" and "tablet" options
   */
  @Input() modeDisplay: GflModeDisplayType;

  /**
   * Front theme style
   */
  @Input() style: FrontTheme;

  /**
   * Text used in ion-label component
   */
  @Input() label: string;

  /**
   * Text used in ion-label component
   */
  @Input() placeholder: string;

  /**
   * If isEditMode is false then the field value are read-only
   */
  @Input() isEditMode: boolean;

  /**
   * If isLabel is true then we only display label :)
   */
  @Input() isLabel: boolean;

  /**
   * name attribute for form control
   */
  @Input() name: string;

  /**
   * This is the value_reformat attribute provided by the BO
   * for objects using item_templates
   */
  @Input() formattedValue: string;

  /**
   * Color theme to apply to the component
   */
  @Input() theme: GflThemeOptions;

  /**
   * All options for the select HTML tag
   */
  @Input() selectOptionsArr: Array<GflSelectOption>;

  /**
   * if true then gfl-validation section is not displayed
   * this is used to display custom error message
   */
  @Input() noDisplayErrors: boolean;

  /**
   * readonly flag for HTML attribute field
   */
  @Input() readonly: boolean;

  /**
   * field required flag
   */
  @Input() required: boolean;

  /**
   * field disabled flag
   */
  @Input() disabled: boolean;

  /**
   * field hidden flag
   */
  @Input() hidden: boolean;

  /**
   * true if form parent has been submitted
   */
  @Input() submitted: boolean;

  public formControl: FormControl;
  public modeDisplays = GflModeDisplayType;
  public isIcon: boolean;
  private validatorsApplied: Array<ValidatorFn>;
  private subscription: Subscription;
  public page;
  public size = 20;

  /**
   * @ignore
   */
  constructor(public tools: ToolsService, public translate: TranslateService, private ref: ChangeDetectorRef) {
    this.theme = this.theme || {};
    this.formChange = new EventEmitter<FormGroup>();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.form && changes.form.currentValue !== changes.form.previousValue) {
      if (!this.form.contains(this.name)) {
        this.validators = this.validators || [];
        // set validators
        this.validatorsApplied = [...this.validators];
        if (this.required) {
          this.validatorsApplied.push(Validators.required);
        }
        // generate formControl
        const formControl = new FormControl(this.value && this.value.toString(), this.validatorsApplied);
        // generate local formControl
        // we need a decoupled formControl because we only want the value
        // sadly ionic-selectable returns an object { text: '', value:'', selected: false}
        // this formControl will handle the selected item and the registred formControl will get only value attribute
        this.formControl = new FormControl(this.value && this.value.toString(), this.validatorsApplied);
        // update form locally
        this.form.registerControl(this.name, formControl);
        // update form globally
        this.formChange.emit(this.form);
        // launch angular change detection
        this.ref.markForCheck();
      }
    }
    if (changes.formattedValue && changes.formattedValue.currentValue !== changes.formattedValue.previousValue) {
      this.isIcon = !!RegExp('class="icons"').test(changes.formattedValue.currentValue);
    }

    if (changes.submitted && changes.submitted.currentValue !== changes.submitted.previousValue) {
      if (this.validatorsApplied.length) {
        this.form.get(this.name).markAsTouched();
        this.form.get(this.name).markAsDirty();
      }
    }
  }

  public refresh($event) {
    this.form.get(this.name).setValue(parseInt($event.value.value, 10));
    this.form.get(this.name).updateValueAndValidity({
      emitEvent: true,
      onlySelf: false,
    });
    this.form.get(this.name).markAsTouched();
    this.form.get(this.name).markAsDirty();

    this.ref.markForCheck();
  }

  public searchOptions(event: { component: IonicSelectableComponent; text: string }) {
    const text = event.text.trim().toLowerCase();
    event.component.startSearch();

    // Close any running subscription.
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (!text) {
      // Close any running subscription.
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      event.component.items = this.getOptions(1, this.size);

      // Enable and start infinite scroll from the beginning.
      this.page = 2;
      event.component.endSearch();
      event.component.enableInfiniteScroll();
      return;
    }

    this.subscription = this.getOptionsAsync().subscribe(options => {
      // Subscription will be closed when unsubscribed manually.
      if (this.subscription.closed) {
        return;
      }

      event.component.items = this.filterOptions(options, text);
      event.component.endSearch();
    });
  }

  public getOptions(page?: number, size?: number) {
    let options = this.selectOptionsArr;

    if (page && size) {
      options = this.selectOptionsArr.slice((page - 1) * size, (page - 1) * size + size);
    }

    return options;
  }

  public getOptionsAsync(page?: number, size?: number, timeout = 300): Observable<GflSelectOption[]> {
    return new Observable<GflSelectOption[]>(observer => {
      observer.next(this.getOptions(page, size));
      observer.complete();
    }).pipe(delay(timeout));
  }

  public filterOptions(options: GflSelectOption[], text: string) {
    return options.filter(option => {
      return (
        option.text
          .toString()
          .toLowerCase()
          .indexOf(text) !== -1
      );
    });
  }

  public getMoreOptions(event: { component: IonicSelectableComponent; text: string }) {
    const text = (event.text || '').trim().toLowerCase();

    // There're no more ports - disable infinite scroll.
    if (this.page + 1 > this.selectOptionsArr.length) {
      event.component.disableInfiniteScroll();
      return;
    }

    this.getOptionsAsync(this.page, this.size).subscribe(options => {
      options = event.component.items.concat(options);

      if (text) {
        options = this.filterOptions(options, text);
      }

      event.component.items = options;
      event.component.endInfiniteScroll();
      this.page++;
    });
  }
}
