import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PermissionsState } from './permissions.reducer';

export const selectPermissionsState = createFeatureSelector<PermissionsState>('permissions');

export const acls = createSelector(
  selectPermissionsState,
  permissionsState => {
    return permissionsState.acls;
  }
);
