import { createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';

import { SafeboxActions } from './action-types';

import { SafeboxType } from '../models/safebox-type.model';
import { Safebox } from '../models/safebox.model';

export const safeboxFeatureKey = 'safebox';

export interface SafeboxState {
  safeboxTypes: { [lang: string]: SafeboxType[] };
  safeboxes: { [id: string]: Safebox };
}

export const initialSafeboxState: SafeboxState = {
  safeboxTypes: {},
  safeboxes: {},
};

const _safeboxReducer = createReducer(
  initialSafeboxState,

  on(SafeboxActions.initialize, (state, action) => {
    return {
      ...action.safeboxState,
    };
  }),

  on(SafeboxActions.reset, state => {
    return {
      ...state,
      safeboxes: {},
    };
  }),

  on(SafeboxActions.resetSafeboxTypes, state => {
    return {
      ...state,
      safeboxTypes: {},
    };
  }),

  on(SafeboxActions.setSafeboxTypes, (state, action) => {
    const safeboxTypes = { ...state.safeboxTypes };
    safeboxTypes[action.lang] = action.safeboxTypes;

    return {
      ...state,
      safeboxTypes,
    };
  }),

  on(SafeboxActions.setSafeboxes, (state, action) => {
    return {
      ...state,
      safeboxes: action.safeboxes,
    };
  }),

  on(SafeboxActions.addSafebox, (state, action) => {
    const safeboxes = _.cloneDeep(state.safeboxes);

    safeboxes[action.safebox.safebox_id] = action.safebox;

    return {
      ...state,
      safeboxes,
    };
  }),

  on(SafeboxActions.removeSafebox, (state, action) => {
    const safeboxes = _.cloneDeep(state.safeboxes);

    delete safeboxes[action.safeboxId];

    return {
      ...state,
      safeboxes,
    };
  })
);

export function safeboxReducer(state, action) {
  return _safeboxReducer(state, action);
}
