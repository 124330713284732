import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as hash from 'object-hash';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, mergeMap, take, withLatestFrom } from 'rxjs/operators';

import { InsuredObjectBO } from '../../policies/models/insured-object.model';
import {
  CompareBO,
  CompareFO,
  CompareListCompare,
  CompareListItem,
  ComparePoliciesBO,
  ComparePoliciesFO,
  ComparePolicyFO,
  Row,
} from '../models/compare.model';
import { ItemTemplateFO } from '../../gfl-core/gfl-models/item-template.model';
import { InsuranceType } from '../../policies/models/insurance-type.model';
import { NotificationItem, NotificationType } from '../../contacts/models/contacts.model';
import { InsuranceService, InsuranceTypesCategories } from '../../policies/services/insurance.service';
import { CompanyService } from '../../gfl-core/gfl-services/company.service';
import { StatusService } from '../../gfl-core/gfl-services/status.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { ApiService } from '../../gfl-core/gfl-services/api.service';
import { CustomerService } from '../../customer/services/customer.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { ConstantService } from '../../gfl-core/gfl-services/constant.service';
import { WsService } from '../../gfl-core/gfl-services/ws.service';
import { DataMonitorService } from '../../gfl-core/gfl-services/data-monitor.service';
import { AclsService } from '../../gfl-core/gfl-services/acls.service';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompareService {
  private compares: { [id: string]: Array<CompareListItem> } = {};
  private POLICY_OFFER_REQUEST_VALIDATED_KEY: number;
  private POLICY_OFFER_REQUEST_SENT_COMPANY_KEY: number;
  private POLICY_OFFER_CREATED_KEY: number;
  private POLICY_POLICY_CREATED_KEY: number;
  private POLICY_OFFER_SIGNED_GENERATED_KEY: number;
  private COMPARE_TERMINATED: number;
  private POLICY_OFFER_REFUSED_CUSTOMER: number;

  /**
   * @ignore
   */
  constructor(
    private apiSrv: ApiService,
    private wsSrv: WsService,
    private insuranceSrv: InsuranceService,
    private tools: ToolsService,
    private statusSrv: StatusService,
    private companySrv: CompanyService,
    private customerSrv: CustomerService,
    private store: StoreService,
    private constantSrv: ConstantService,
    private dataMonitorSrv: DataMonitorService,
    private aclsSrv: AclsService
  ) {
    const locksToMonitor = [
      {
        name: 'compares',
        lock: () => this.store.getComparesLock(),
        cb: () => this.setCompares(),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * update compares
   */
  public manageCompares(): Observable<any> {
    // first we fetch from localstorage
    return forkJoin([this.store.getCompares().pipe(first()), this.store.getLang().pipe(first())]).pipe(
      mergeMap(([compares, lang]) => {
        const key = compares && Object.keys(compares) && Object.keys(compares)[0];

        if (compares && compares[key] && compares[key][lang]) {
          return of(compares);
        } else {
          // if no local data then we fetch via http request
          return this.setCompares();
        }
      })
    );
  }

  /**
   * Store compares
   * @param reset if true empty data first
   */
  public setCompares(reset?: boolean): Observable<any> {
    let lang: string;

    return this.store.setComparesLock(Date.now()).pipe(
      mergeMap(() => this.store.getLang().pipe(first())),
      mergeMap(langRes => {
        lang = langRes;
        return this.store.getAuthData();
      }),
      mergeMap(authData => {
        const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
        // tslint:disable-next-line:no-shadowed-variable
        const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
        const except = [CUSTOMER_TYPE_ID_EMPLOYEE];

        if (authData.customerTypeId === CUSTOMER_TYPE_ID_EMPLOYEE) {
          except.push(CUSTOMER_TYPE_ID_PRIVATE);
        }

        return this.customerSrv.getCustomersIdList(except);
      }),
      filter(val => !_.isEmpty(val)),
      first(),
      mergeMap(customersIdArr => {
        const obs$: Array<Observable<any>> = [];

        _.forEach(customersIdArr, (id: number) => {
          obs$.push(this.setComparesByCustomer(id, lang));
        });

        if (!obs$.length) {
          obs$.push(of(null));
        }

        return forkJoin(obs$);
      }),
      mergeMap((comparesByCustomerId: { customerId: number; compares: CompareListItem[] }[]) => {
        const comparesList: { [id: number]: { [lang: string]: CompareListItem[] } } = {};

        _.forEach(comparesByCustomerId, item => {
          comparesList[item.customerId] = {};
          comparesList[item.customerId][lang] = item.compares;
        });

        // return of(comparesList);

        return forkJoin([
          of(comparesList),
          this.getCompares().pipe(first()),
          this.store.get(environment.APP_NAME + '_setNotifications').pipe(first()),
        ]);
      }),
      mergeMap(
        ([compareList, compareListStoreTmp, setNotifications]: [
          { [id: number]: { [lang: string]: CompareListItem[] } },
          { [id: number]: { [lang: string]: CompareListItem[] } },
          boolean
        ]) => {
          let compareListStore = _.cloneDeep(compareListStoreTmp);

          // @ts-ignore
          const step1Bo = _.reduce(
            _.cloneDeep(compareList),
            (result, value, key) => {
              if (!_.isArray(value[lang])) {
                value[lang] = [];
              }
              return [...result, ...value[lang]];
            },
            []
          );

          // @ts-ignore
          const step1Store = _.reduce(
            _.cloneDeep(compareListStore),
            (result, value, key) => {
              if (!_.isArray(value[lang])) {
                value[lang] = [];
              }

              return [...result, ...value[lang]];
            },
            []
          );

          const compareListFromBo: { [id: string]: CompareListItem } = {};
          const compareListFromStore: { [id: string]: CompareListItem } = {};

          _.forEach(step1Bo, item => {
            if (item.compare) {
              compareListFromBo[item.compare.id] = item;
            }
          });

          _.forEach(step1Store, item => {
            if (item.compare) {
              compareListFromStore[item.compare.id] = item;
            }
          });

          const compareToAdd: CompareListItem[] = this.tools.findNewEntities(
            compareListFromStore,
            compareListFromBo,
            'compare.id'
          );

          if (setNotifications) {
            const notifications: NotificationItem[] = [];

            _.forEach(compareToAdd, (item: CompareListItem) => {
              // we don't need to warn customer that he/she has send a message :)
              notifications.push({
                done: false,
                id: hash(item),
                customerId: item.compare.customerId,
                sprite: 'home_menu_icon_5',
                mobileLink: '/compares/' + item.compare.id,
                tabletLink: '/compares/' + item.compare.id,
                type: NotificationType.Compare,
                text: 'CONTACT.NOTIFICATIONS.NEW_COMPARE',
              });
            });

            this.store.addNotifications(notifications);
          }

          if (reset) {
            compareListStore = compareList;
            this.store.resetComparesData();
          } else {
            _.forEach(compareList, (item, customerId) => {
              compareListStore[customerId] = compareListStore[customerId] || {};
              compareListStore[customerId][lang] = item[lang];
            });
          }

          this.store.setCompares(compareListStore);

          const comparesArr$ = [];

          _.forEach(compareList, (comparesByLang, key) => {
            const compares = comparesByLang[lang];
            const id = parseInt(key, 10);

            if (id !== 0) {
              if (compares.length) {
                _.forEach(compares, compare => {
                  comparesArr$.push(this.setCompareById(compare.compare, id, lang));
                });
              }
            }
          });

          if (!comparesArr$.length) {
            comparesArr$.push(of(null));
          }

          return forkJoin(comparesArr$);
        }
      ),
      mergeMap(() => this.store.setComparesLock(null)),
      catchError(err => {
        this.tools.error('setCompares ERROR', err);
        return of(null);
      })
    );
  }

  /**
   * Return an observable of compares of a given customer, customer's token is also given
   * @param customerId customer ID
   * @param lang selected lang
   */
  private setComparesByCustomer(
    customerId: number,
    lang: string
  ): Observable<{ customerId: number; compares: CompareListItem[] }> {
    const params = { customer_id_linked: customerId, language_iso: lang };
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');

    return this.customerSrv.getCustomer(customerId).pipe(
      filter(val => !!val),
      take(1),
      withLatestFrom(this.aclsSrv.getAcls(customerId)),
      mergeMap(([customer, acls]) => {
        let insuranceTypes$: Observable<{ [id: string]: InsuranceType }>;
        let insuranceTypeCategory;

        if (customer.customer_type_id === CUSTOMER_TYPE_ID_PRIVATE) {
          insuranceTypeCategory = InsuranceTypesCategories.private;
        } else {
          insuranceTypeCategory = InsuranceTypesCategories.corporate;
        }

        insuranceTypes$ = this.insuranceSrv.getFlattenInsuranceTypes(insuranceTypeCategory);

        return forkJoin([
          acls.LOAD_COMPARES ? this.wsSrv.requestCompares(params) : of([]),
          insuranceTypes$,
          of(customer),
        ]);
      }),
      mergeMap(([comparesResponse, insuranceTypes, customer]) => {
        const compares: CompareListItem[] = _.values(comparesResponse.compares);

        _.map(compares, o => {
          o.compare.customerId = customerId;
          return o;
        });

        _.remove(compares, o => {
          return !o.compare || !o.policies;
        });

        _.map(compares, (compare: CompareListItem) => {
          // format policies as an array
          compare.policies = _.values(compare.policies);

          // add compare label attribute
          const insuredObjectsLabel = [];
          _.forEach(compare.policies[0].insuredObjects, obj => {
            insuredObjectsLabel.push(obj.name);
          });
          compare.compare.label = insuredObjectsLabel.join(', ');

          // add insuranceType data
          const insuranceTypeId = compare.policies[0].policy.insurance_type_id;
          compare.compare.insuranceType = insuranceTypes[insuranceTypeId];

          return compare;
        });

        return of({
          customerId: customer.id,
          compares,
        });
      })
    );
  }

  /**
   * Return an observable of formatted compares sorted by insuranceType name
   * @param customerId customer ID
   */
  public getCompares(
    customerId?: number
  ): Observable<{ [lang: string]: CompareListItem[] } | { [id: number]: { [lang: string]: CompareListItem[] } }> {
    return this.store.getCompares(customerId);
  }

  /**
   * Store compare item
   * @param compare compare object from compares list
   * @param customerId customer Id
   * @param lang selected language
   */
  public setCompareById(compare: CompareListCompare, customerId: number, lang: string): Observable<any> {
    const params = { customer_id_linked: customerId };

    return forkJoin([this.wsSrv.requestCompare(compare.id, params), this.companySrv.getCompanies().pipe(first())]).pipe(
      mergeMap(([compareResponse, companies]) => {
        const compareBO: CompareBO = compareResponse.compare;
        const compareFO = compareBO[compare.id] as CompareFO;
        const policies: ComparePoliciesBO[] = _.values(compareBO.policies);

        this.POLICY_OFFER_REQUEST_VALIDATED_KEY = this.statusSrv.getIdFromKey('POLICY_OFFER_REQUEST_VALIDATED');
        this.POLICY_OFFER_REQUEST_SENT_COMPANY_KEY = this.statusSrv.getIdFromKey('POLICY_OFFER_REQUEST_SENT_COMPANY');
        this.POLICY_OFFER_CREATED_KEY = this.statusSrv.getIdFromKey('POLICY_OFFER_CREATED');
        this.POLICY_POLICY_CREATED_KEY = this.statusSrv.getIdFromKey('POLICY_POLICY_CREATED');
        this.POLICY_OFFER_SIGNED_GENERATED_KEY = this.statusSrv.getIdFromKey('POLICY_OFFER_SIGNED_GENERATED');
        this.COMPARE_TERMINATED = this.statusSrv.getIdFromKey('COMPARE_TERMINATED');
        this.POLICY_OFFER_REFUSED_CUSTOMER = this.statusSrv.getIdFromKey('POLICY_OFFER_REFUSED_CUSTOMER');

        // add flag to check if offer has been refused
        _.forEach(policies, (policy: ComparePoliciesFO, idx) => {
          policy.refused = policy.policy.current_status_id === this.POLICY_OFFER_REFUSED_CUSTOMER;
          policy.favorite = !!policy.policy.is_favorite;
          // policy.documents = policiesDocuments[idx];
        });

        const topPolicies = _.filter(policies, (policy: ComparePoliciesFO) => {
          return !!policy.insuredObjects && !policy.policy.criteria_not_matched;
        });

        // add flag to check if compare is terminated
        compareFO.terminated = compareFO.current_status_id === this.COMPARE_TERMINATED;

        // Add insuranceType using first policy
        compareFO.insuranceType = compare.insuranceType;

        // Add starting date using first policy
        compareFO.startingDate = topPolicies[0].policy.starting_date;

        // Add compared insured objects using first policy
        compareFO.insuredObjects = [];

        // Add out of criteria and no response policies

        // @ts-ignore
        compareFO.policiesResumed = _.filter(policies, (policy: ComparePoliciesFO) => {
          if (!policy.insuredObjects && !policy.policy.criteria_not_matched) {
            policy.company = _.find(companies, { id: policy.policy.company_id });
            policy.index = topPolicies.length;
            return true;
          }
          return false;
        });

        // @ts-ignore
        compareFO.policiesOutOfCriteria = _.filter(policies, (policy: ComparePoliciesFO) => {
          if (policy.policy.criteria_not_matched) {
            policy.company = _.find(companies, { id: policy.policy.company_id });
            policy.index = topPolicies.length + compareFO.policiesResumed.length;

            return true;
          }
          return false;
        });

        // @ts-ignore
        compareFO.policiesNoResponse = _.filter(policies, (policy: ComparePoliciesFO) => {
          if (
            policy.policy.current_status_id >= this.POLICY_OFFER_REQUEST_VALIDATED_KEY &&
            policy.policy.current_status_id <= this.POLICY_OFFER_REQUEST_SENT_COMPANY_KEY
          ) {
            policy.company = _.find(companies, { id: policy.policy.company_id });
            policy.index =
              topPolicies.length + compareFO.policiesResumed.length + compareFO.policiesOutOfCriteria.length;

            return true;
          }
          return false;
        });

        compareFO.otherPolicies = _.map(compareBO.otherPolicies as ComparePolicyFO[], policy => {
          policy.company = _.find(companies, { id: policy.company_id });
          policy.index = policy.position;

          return policy;
        });

        const rowsByInsuredObjects = [];
        const itemTemplateIdListByInsuredObjects = [];

        _.forEach(topPolicies[0].insuredObjects, (insuredObject: InsuredObjectBO) => {
          // @ts-ignore
          compareFO.insuredObjects.push({
            header: _.values(insuredObject.header),
            rowCompanies: [],
            rows: [],
            rowPremiums: [],
            itemTemplateIdList: [],
            name: insuredObject.parent.name,
          });

          rowsByInsuredObjects.push({});
          itemTemplateIdListByInsuredObjects.push([]);
        });

        // loop over policies in order to set rows and itemTemplateIdList data

        const rowCompanies = [];
        const rowPremiums = [];

        // first, we fill itemTemplateIdListByInsuredObjects
        _.forEach(topPolicies, (topPolicy: ComparePoliciesFO) => {
          const insuredObjectPolicyItemsArr: Array<InsuredObjectBO> = _.values(topPolicy.insuredObjects);

          _.forEach(insuredObjectPolicyItemsArr, (insuredObjectPolicyItems, insuredObjectIdx) => {
            // Go through all items type only of insured object policy items
            itemTemplateIdListByInsuredObjects[insuredObjectIdx] = {};
            _.forEach(insuredObjectPolicyItems, (itemType, itemTypeIdx) => {
              // itemTypeIdx must be integer
              if (this.tools.isInt(itemTypeIdx)) {
                itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx] = [];

                _.forEach(itemType, (item: ItemTemplateFO, itemIdx) => {
                  if (this.tools.isInt(itemIdx)) {
                    if (!_.includes(itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx], itemIdx)) {
                      itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx].push(parseInt(itemIdx, 10));
                    }

                    if (item.children) {
                      _.forEach(item.children, (subItem: ItemTemplateFO, subItemIdx) => {
                        if (
                          !_.includes(itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx], subItemIdx)
                        ) {
                          itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx].push(
                            parseInt(subItemIdx, 10)
                          );
                        }
                      });
                    }
                  }
                });
              }

              if (itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx]) {
                itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx] = _.uniq(
                  itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx]
                );
                // itemTemplateIdListByInsuredObjects[insuredObjectIdx][itemTypeIdx].sort();
              }
            });
          });
        });

        _.forEach(topPolicies, (topPolicy: ComparePoliciesFO, policyIdx) => {
          const refused = topPolicy.policy.current_status_id === this.POLICY_OFFER_REFUSED_CUSTOMER;
          const favorite = topPolicy.favorite;
          const insuredObjectPolicyItemsArr: Array<InsuredObjectBO> = _.values(topPolicy.insuredObjects);
          const company = _.find(companies, { id: topPolicy.policy.company_id });
          rowCompanies.push({ ...company, refused: topPolicy.refused, favorite: topPolicy.favorite });
          rowPremiums.push({
            id: topPolicy.policy.id,
            premium: topPolicy.policy.premium,
            documents: topPolicy.documents,
            company,
            refused,
            favorite,
            cgaId: topPolicy.policy.cga_id,
            cgvId: topPolicy.policy.cgv_id,
          });

          _.forEach(itemTemplateIdListByInsuredObjects, (itemTypes, insuredObjectIdx) => {
            _.forEach(itemTypes, (itemIdArr, itemTypeIdx) => {
              _.forEach(itemIdArr, itemIdx => {
                if (!rowsByInsuredObjects[insuredObjectIdx][itemIdx]) {
                  rowsByInsuredObjects[insuredObjectIdx][itemIdx] = [];
                }

                if (!rowsByInsuredObjects[insuredObjectIdx][itemIdx][policyIdx]) {
                  const item =
                    (insuredObjectPolicyItemsArr[insuredObjectIdx][itemTypeIdx] &&
                      insuredObjectPolicyItemsArr[insuredObjectIdx][itemTypeIdx][itemIdx]) ||
                    {};

                  // @ts-ignore
                  item.value_reformat = item.value_reformat || '-';

                  rowsByInsuredObjects[insuredObjectIdx][itemIdx].push({
                    ...item,
                    refused,
                    favorite,
                  });

                  // Go through all subitems if there are any
                  // @ts-ignore
                  if (item.children) {
                    // @ts-ignore
                    _.forEach(item.children, (subItem: ItemTemplateFO, subItemIdx) => {
                      subItem.item_sub_item_parent_id = parseInt(itemIdx, 10);

                      if (!rowsByInsuredObjects[insuredObjectIdx][subItemIdx]) {
                        rowsByInsuredObjects[insuredObjectIdx][subItemIdx] = [];
                      }

                      subItem.value_reformat = subItem.value_reformat || '-';

                      rowsByInsuredObjects[insuredObjectIdx][subItemIdx].push({
                        ...subItem,
                        refused,
                        favorite,
                      });
                    });
                  }
                }
              });
            });
          });
        });

        // set rows and itemTemplateIdList to insuredObjects

        _.forEach(compareFO.insuredObjects, (insuredObject, idx) => {
          this.formatRows(rowsByInsuredObjects[idx]);

          insuredObject.rowCompanies = rowCompanies;
          insuredObject.rows = rowsByInsuredObjects[idx];
          insuredObject.rowPremiums = rowPremiums;
          insuredObject.itemTemplateIdList = this.formatItemTemplateIdListByInsuredObjects(
            itemTemplateIdListByInsuredObjects[idx]
          );
        });
        this.store.setCompare(compareFO, lang);
        return of(compareFO);
      })
    );
  }

  private formatItemTemplateIdListByInsuredObjects(o) {
    let arr = [];
    _.forEach(o, items => {
      arr = [...arr, ...items];
    });

    return _.uniq(arr);
  }

  /**
   * Return an observable of compare object to be displayed
   * @param compareId compare id
   * @param apiToken API token provided by BO
   */
  public getCompare(compareId: number, apiToken?: string): Observable<CompareFO> {
    return this.store.getCompare(compareId);
  }

  /**
   * Loop in rowsByInsuredObjects[idx] in order to add possible missing data
   * such as item_template_id, item_template_key, item_template_key_translate, item_type_id, item_type_name
   * @param rows insuredObject rows
   */
  private formatRows(rows: Array<Row>): Array<Row> {
    _.forEach(rows, (row, idx) => {
      const data = _.find(row, o => {
        // @ts-ignore
        return o.item_template_id === parseInt(idx, 10);
      });

      // @ts-ignore
      rows[idx] = _.map(row, o => {
        o = {
          ...o,
          // @ts-ignore
          item_template_id: data && data.item_template_id,
          // @ts-ignore
          item_template_key: data && data.item_template_key,
          // @ts-ignore
          item_template_key_translate: data && data.item_template_key_translate,
          // @ts-ignore
          item_type_id: data && data.item_type_id,
          // @ts-ignore
          item_type_name: data && data.item_type_name,
          // @ts-ignore
          item_template_format: data && data.item_template_format,
          // @ts-ignore
          item_sub_item_parent_id: data && data.item_sub_item_parent_id,
        };
        return o;
      });
    });

    return rows;
  }

  /**
   * Order compares by parent_insurance_type_id and by date Desc
   * @param compares an array of compareListItems
   */
  public orderCompareByParentInsuranceType(
    compares: Array<CompareListItem>
  ): Array<{ parentInsuranceTypeName: string; compares: Array<CompareListItem> }> {
    const result = [];

    const parentInsuranceTypes = _.compact(_.uniq(_.map(compares, 'compare.insuranceType.name_translate')));

    _.forEach(parentInsuranceTypes, parentInsuranceType => {
      compares = _.orderBy(
        compares,
        (item: CompareListItem) => {
          const createdAt = item.compare.created_at.replace(/-/g, '/');

          return new Date(createdAt);
        },
        'desc'
      );

      const comparesItem = _.filter(compares, (item: CompareListItem) => {
        return item.compare.insuranceType.name_translate === parentInsuranceType;
      });

      result.push({
        parentInsuranceTypeName: parentInsuranceType,
        compares: comparesItem,
      });
    });

    return result;
  }
}
