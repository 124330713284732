import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';
import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { NotificationService } from '../../gfl-core/gfl-services/notification.service';

@Injectable({
  providedIn: 'root',
})
export class ExternalAuthGuard implements CanActivate {
  /**
   * @ignore
   */
  constructor(
    private navCtrl: NavController,
    private store: StoreService,
    private notificationSrv: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (
      route.queryParams &&
      route.queryParams.token &&
      route.queryParams.phone &&
      route.queryParams.route &&
      route.queryParams['compare-id']
    ) {
      return true;
    } else {
      this.store.logout();
      this.navCtrl.navigateRoot('/welcome').then(() =>
        this.notificationSrv.showError({
          message: 'LOGIN.INVALID_EXTERNAL_AUTH_CUSTOMER_LINK',
          showCloseButton: true,
          duration: 0,
        })
      );
    }
  }
}
