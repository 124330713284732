import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';

import { ApiService } from './api.service';
import { ToolsService } from './tools.service';
import { StoreService } from './store.service';
import { ConstantService } from './constant.service';
import { WsService } from './ws.service';
import { LanguageService } from './language.service';
import { DataMonitorService } from './data-monitor.service';
import { GflFormItem, GflFormSubItem } from '../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { ItemTemplateFO } from '../gfl-models/item-template.model';
import { ApiResponse } from '../gfl-models/api.model';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private itemTemplatesByForeignKeys: { [lang: string]: { [key: number]: { [id: string]: ItemTemplateFO } } } = {};
  private itemTemplatesByForeignKeysCurrentLanguage: { [key: number]: { [id: string]: ItemTemplateFO } } = {};

  private readonly appName = environment.APP_NAME;

  /**
   * @ignore
   */
  constructor(
    private apiSrv: ApiService,
    private tools: ToolsService,
    private store: StoreService,
    private constantSrv: ConstantService,
    private wsSrv: WsService,
    private langSrv: LanguageService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const lang$ = this.store.getLang().pipe(filter(val => !!val));

    const locksToMonitor = [
      {
        name: 'item_templates',
        lock: () => this.store.getItemTemplatesLock(),
        cb: () => lang$.pipe(mergeMap(lang => this.setItemTemplates(lang))),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * get item templates by foreign keys in memory
   */
  public loadItemTemplates(): Observable<any> {
    return forkJoin([
      this.store.get(`${this.appName}_item_templates`),
      this.langSrv.getLang().pipe(
        filter(val => !!val),
        first()
      ),
    ]).pipe(
      mergeMap(([itemTemplatesByForeignKeys, lang]) => {
        if (itemTemplatesByForeignKeys && itemTemplatesByForeignKeys[lang]) {
          this.itemTemplatesByForeignKeys = itemTemplatesByForeignKeys;
          this.itemTemplatesByForeignKeysCurrentLanguage = itemTemplatesByForeignKeys[lang];
          return of(itemTemplatesByForeignKeys);
        } else {
          // if no local data then we fetch via http request
          return this.setItemTemplates(lang);
        }
      })
    );
  }

  /**
   * store item templates in locale storage
   * @param lang current language
   * @param reset if true empty data first
   */
  public setItemTemplates(lang: string, reset?: boolean): Observable<any> {
    const foreignTableNames = ['INSURED_OBJECT', 'CUSTOMER'];
    const foreignTableKeys = [];
    const obs$: Array<Observable<{ [id: string]: ItemTemplateFO }>> = [];

    _.forEach(foreignTableNames, foreignTableName => {
      obs$.push(
        (this.constantSrv.getForeignTableIdByName(foreignTableName) as Observable<number>).pipe(
          mergeMap(key => {
            foreignTableKeys.push(key);
            return this.wsSrv.requestItemTemplates(key);
          })
        )
      );
    });

    return this.store.setItemTemplatesLock(Date.now()).pipe(
      mergeMap(() => forkJoin(obs$)),
      mergeMap(itemTemplates => {
        const itemTemplatesByForeignKeys: { [key: string]: { [id: string]: ItemTemplateFO } } = {};
        _.forEach(foreignTableKeys, key => {
          itemTemplatesByForeignKeys[key] = itemTemplates.shift();
        });
        this.itemTemplatesByForeignKeys = reset ? {} : this.itemTemplatesByForeignKeys;

        this.itemTemplatesByForeignKeys[lang] = itemTemplatesByForeignKeys;

        return this.store.set(`${this.appName}_item_templates`, this.itemTemplatesByForeignKeys);
      }),
      mergeMap(() => this.store.setItemTemplatesLock(null)),
      catchError(err => {
        this.tools.error('setItemTemplates ERROR', err);
        return of(null);
      })
    );
  }

  /**
   * Return an observable of an item template
   * @param foreignKey key of the foreign table used call BO webservice GET item_template
   * @param asArray if true then an array of itemTemplate is returned
   * @param itemTemplateKey key of item template to filter the BO webservice result
   * @param apiToken optional value to override the apiToken of the connected customer
   */
  public getItemTemplateByItemTemplateKey(
    foreignKey: number,
    asArray: boolean,
    itemTemplateKey?: string,
    apiToken?: string
  ): Observable<ItemTemplateFO[] | { [id: string]: ItemTemplateFO }> {
    return this.store.getItemTemplatesLock().pipe(
      filter(lock => !lock),
      mergeMap(() => {
        return this.langSrv.getLang();
      }),
      filter(lang => !!lang),
      map(lang => {
        return this.itemTemplatesByForeignKeys[lang] && this.itemTemplatesByForeignKeys[lang][foreignKey];
      }),
      mergeMap(itemTemplates => {
        let itemTemplateArr: Array<ItemTemplateFO>;

        if (itemTemplateKey) {
          itemTemplateArr = _.filter(itemTemplates, (itemTemplate: ItemTemplateFO) => {
            if (itemTemplate.item_template_key === itemTemplateKey) {
              if (itemTemplate.subitems) {
                itemTemplate.subitems = _.values(itemTemplate.subitems);
              }
              return true;
            }

            return false;
          });
        } else if (asArray) {
          itemTemplateArr = _.values(itemTemplates);
        }

        return asArray ? of(itemTemplateArr) : of(itemTemplates);
      })
    );
  }

  /**
   * Return a promise of observables recording customer's items to BO
   * @param formData form data
   * @param customerId customer's id
   * @param apiToken current customer's apiToken
   */
  public generateSubmissionObservables(
    formData: Array<GflFormItem>,
    customerId: number,
    apiToken?: string
  ): Promise<Array<Observable<any>>> {
    return new Promise((resolve, reject) => {
      try {
        const { itemsPut, itemsPost, itemsDelete } = this.prepareItemsDataForBOCall(formData);

        const observables = [];
        if (itemsPost.length > 0) {
          observables.push(this.saveItems(itemsPost, customerId, apiToken));
        }
        if (itemsPut.length > 0) {
          observables.push(this.updateItems(itemsPut, customerId, apiToken));
        }
        if (itemsDelete.length > 0) {
          _.forEach(itemsDelete, itemId => {
            observables.push(this.deleteItem(parseInt('' + itemId, 10), customerId));
          });
        }

        resolve(observables);
      } catch (e) {
        this.tools.error('generateSubmissionObservables', e);
        reject(e);
      }
    });
  }

  /**
   * Return an array of observables recording customer's items to BO
   * @param formData form data
   * @param customerId customer's id
   * @param apiToken current customer's apiToken
   */
  public generateItemsSubmissionObservables(
    formData: Array<GflFormItem>,
    customerId: number,
    apiToken: string
  ): Array<Observable<any>> {
    const { itemsPut, itemsPost, itemsDelete } = this.prepareItemsDataForBOCall(formData);

    const observables = [];
    if (itemsPut.length > 0) {
      observables.push(this.updateItems(itemsPut, customerId, apiToken));
    }
    if (itemsPost.length > 0) {
      observables.push(this.saveItems(itemsPost, customerId, apiToken));
    }
    if (itemsDelete.length > 0) {
      _.forEach(itemsDelete, itemId => {
        observables.push(this.deleteItem(parseInt('' + itemId, 10), customerId));
      });
    }

    return observables;
  }

  /**
   * Return an object of arrays for PUT, POST, DELETE request to BO
   * @param formData form data
   */
  private prepareItemsDataForBOCall(
    formData: Array<GflFormItem>
  ): {
    itemsPost: Array<{ item_template_id: string; value: any }>;
    itemsPut: Array<{ item_id: string | number; value: any }>;
    itemsDelete: Array<string | number>;
  } {
    // Format the array to send
    const itemsPut = [];
    const itemsPost = [];
    const itemsDelete = [];

    _.forEach(formData, item => {
      let objectItem: any;

      // affect item value if needed
      if (!item.is_abstract) {
        objectItem = {
          item_template_id: item.item_template_id,
          value: item.value,
        };
      } else {
        objectItem = {
          item_template_id: item.item_template_id,
          value: item.item_template_key_translate,
        };
      }

      // manage subitems
      _.forEach(item.subitems, (subitem: GflFormSubItem) => {
        // Most of time only required subitems are displayed in generated forms
        // but we can't filter to keep only those items because of health subitems that are not is_required
        // but must be sent in health form... so we have to keep subitems not displayed as well.
        if (_.isArray(subitem.value)) {
          _.forEach(subitem.value, (obj: { key: string; value: string; deleted: boolean }) => {
            if (obj.key && obj.deleted) {
              // this is a deleted item...
              itemsDelete.push(obj.key);
            } else if (obj.key) {
              // this is an updated item
              itemsPut.push({ item_id: obj.key, value: obj.value });
            } else {
              // This is a new item
              itemsPost.push({ item_template_id: subitem.item_template_id, value: obj.value });
            }
          });
        } else {
          if (subitem.item_id) {
            itemsPut.push({ item_id: subitem.item_id, value: subitem.value });
          } else {
            itemsPost.push({ item_template_id: subitem.item_template_id, value: subitem.value });
          }
        }
      });

      if (itemsPut.length > 0 && objectItem) {
        itemsPut.push(objectItem);
      }
      if (itemsPost.length > 0 && objectItem) {
        itemsPost.push(objectItem);
      }
    });

    return {
      itemsPut,
      itemsPost,
      itemsDelete,
    };
  }

  /**
   * Save items data to BO
   * @param items items
   * @param customerId customer's id
   * @param apiToken current customer's apiToken
   */
  private saveItems(
    items: Array<{ item_template_id: string; value: any }>,
    customerId: number,
    apiToken: string
  ): Observable<boolean> {
    const params = { customer_id_linked: customerId };

    if (apiToken) {
      // @ts-ignore
      params.api_token = apiToken;
    }

    return this.wsSrv.postItems(params, items).pipe(map((apiResponse: ApiResponse) => apiResponse.success));
  }

  /**
   * Update items data to BO
   * @param items items
   * @param customerId customer's id
   * @param apiToken current customer's apiToken
   */
  private updateItems(items: any, customerId: number, apiToken: string): Observable<boolean> {
    const params = { customer_id_linked: customerId };

    if (apiToken) {
      // @ts-ignore
      params.api_token = apiToken;
    }
    return this.wsSrv.putItems(params, items).pipe(map((apiResponse: ApiResponse) => apiResponse.success));
  }

  /**
   * Delete items from BO
   * @param itemId item id
   * @param customerId customer's id
   */
  private deleteItem(itemId: number, customerId: number): Observable<boolean> {
    const params = { customer_id_linked: customerId };

    return this.wsSrv.deleteItem(itemId, params).pipe(map((apiResponse: ApiResponse) => apiResponse.success));
  }
}
