import { Action, createReducer, on } from '@ngrx/store';
import * as ContactsActions from './contacts.actions';
import { ChatItem, NotificationItem } from '../models/contacts.model';
import * as _ from 'lodash';

export const contactsFeatureKey = 'contacts';

export interface ContactsState {
  chat: { [customerId: number]: { new: number; items: ChatItem[] } };
  notifications: NotificationItem[];
}

export const initialContactsState: ContactsState = {
  chat: {},
  notifications: [],
};

const _contactsReducer = createReducer(
  initialContactsState,

  on(ContactsActions.initialize, (state, action) => {
    return {
      ...action.contactsState,
    };
  }),

  on(ContactsActions.reset, () => {
    return initialContactsState;
  }),

  on(ContactsActions.setChatItems, (state, action) => {
    return {
      ...state,
      chat: action.chatItemsMap,
    };
  }),

  on(ContactsActions.setNotifications, (state, action) => {
    return {
      ...state,
      notifications: action.notifications,
    };
  }),

  on(ContactsActions.addNotifications, (state, action) => {
    const notifications = _.cloneDeep(state.notifications);

    _.forEach(action.notifications, notif => {
      const item = _.find(notifications, { id: notif.id });

      if (item) {
        const idx = _.indexOf(notifications, item);
        notifications[idx] = item;
      } else {
        notifications.push(notif);
      }
    });

    return {
      ...state,
      notifications,
    };
  })
);

export function reducer(state: ContactsState | undefined, action: Action) {
  return _contactsReducer(state, action);
}
