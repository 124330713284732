import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, filter, first, mergeMap, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

import { UiActions } from './action.types';

import { AgencyService } from '../gfl-core/gfl-services/agency.service';
import { ConstantService } from '../gfl-core/gfl-services/constant.service';
import { StatusService } from '../gfl-core/gfl-services/status.service';
import { InsuranceService } from '../policies/services/insurance.service';
import { CompanyService } from '../gfl-core/gfl-services/company.service';
import { StoreService } from '../gfl-core/gfl-services/store.service';
import { SafeboxService } from '../safebox/services/safebox.service';
import { SliderService } from '../gfl-core/gfl-services/slider.service';
import { ToolsService } from '../gfl-core/gfl-services/tools.service';
import { ItemService } from '../gfl-core/gfl-services/item.service';
import { CustomerService } from '../customer/services/customer.service';
import { PolicyService } from '../policies/services/policy.service';
import { CompareService } from '../compares/services/compare.service';

import { environment } from '../../environments/environment';

@Injectable()
export class UiEffects {
  readonly appName: string;
  readonly agencyId: number;
  private lang: string;

  proModeUpdate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UiActions.setIsProSelected),
        tap(action => {
          this.customerSrv.switchModePro(action.isPro);
        }),
        catchError(err => {
          this.tools.error('UI Effect proModeUpdate$', err);
          return of(true);
        })
      ),
    { dispatch: false }
  );

  langUpdate$ = createEffect(
    () => {
      let action;

      return this.actions$.pipe(
        ofType(UiActions.updateLang),
        mergeMap(actionRep => {
          action = actionRep;
          if (!action.noUpdateLoaderIndicator) {
            this.store.setIsRefreshingData(true);
          }

          return this.sliderSrv.initService().pipe(
            catchError(err => {
              this.tools.error('UI Effect sliderSrv.initService', err);
              return of(true);
            })
          );
        }),
        mergeMap(() =>
          forkJoin([
            // this.constantSrv.manageConstants().pipe(first()),
            this.statusSrv.manageStatuses().pipe(first()),
            this.insuranceSrv.loadInsuranceTypes().pipe(first()),
            this.safeboxSrv.loadSafeboxTypes().pipe(first()),
            this.itemSrv.loadItemTemplates().pipe(first()),
          ]).pipe(
            catchError(err => {
              this.tools.error('UI Effect all_load', err);
              return of(true);
            })
          )
        ),
        mergeMap(() => {
          return action.noPoliciesUpdate
            ? of(true)
            : this.policySrv.managePoliciesAndMandates().pipe(
                first(),
                catchError(err => {
                  this.tools.error('UI Effect managePoliciesAndMandates', err);
                  return of(true);
                })
              );
        }),
        mergeMap(() => {
          return action.noPoliciesUpdate
            ? of(true)
            : this.compareSrv.manageCompares().pipe(
                first(),
                catchError(err => {
                  this.tools.error('UI Effect manageCompares', err);
                  return of(true);
                })
              );
        }),
        tap(() => {
          if (!action.noUpdateLoaderIndicator) {
            this.store.setIsRefreshingData(false);
          }
        })
      );
    },
    { dispatch: false }
  );

  reload$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UiActions.reload),
        mergeMap(() =>
          this.store.getLang().pipe(
            filter(val => !!val),
            first(),
            catchError(err => {
              this.tools.error('UI Effect getLang', err);
              return of('fr');
            })
          )
        ),
        mergeMap(lang => {
          this.lang = lang;
          return this.store.setTranslationsLock(Date.now()).pipe(
            mergeMap(() => this.http.get(environment.API_URL + '/translations?language_iso=' + lang)),
            catchError(err => {
              this.tools.log('UI Effect reloadAll$ translation HTTP ERROR', err);
              return of(null);
            })
          );
        }),
        mergeMap(translationObj => {
          const translations = {};
          translations[this.lang] = translationObj;

          if (translationObj) {
            return this.store.set(`${this.appName}_translations`, translations);
          } else {
            return of(true);
          }
        }),
        mergeMap(() => this.store.setTranslationsLock(null)),
        mergeMap(() =>
          this.constantSrv.setConstants(this.lang, true).pipe(
            catchError(err => {
              this.tools.error('UI Effect setConstants', err);
              return of(true);
            })
          )
        ),
        mergeMap(() =>
          this.constantSrv.setForeignTables().pipe(
            catchError(err => {
              this.tools.error('UI Effect setForeignTables', err);
              return of(true);
            })
          )
        ),
        mergeMap(() =>
          this.statusSrv.setStatuses(this.lang, true).pipe(
            catchError(err => {
              this.tools.error('UI Effect setStatuses', err);
              return of(true);
            })
          )
        ),
        mergeMap(() =>
          this.agencySrv.setAgency(this.agencyId).pipe(
            catchError(err => {
              this.tools.error('UI Effect setAgency', err);
              return of(true);
            })
          )
        ),
        mergeMap(() =>
          this.sliderSrv.initService(true).pipe(
            catchError(err => {
              this.tools.error('UI Effect sliderSrv.initService', err);
              return of(true);
            })
          )
        ),
        mergeMap(() =>
          this.insuranceSrv.setInsuranceTypes(this.lang, true).pipe(
            catchError(err => {
              this.tools.error('UI Effect setInsuranceTypes', err);
              return of(true);
            })
          )
        ),
        mergeMap(() =>
          this.itemSrv.setItemTemplates(this.lang, true).pipe(
            catchError(err => {
              this.tools.error('UI Effect setItemTemplates', err);
              return of(true);
            })
          )
        ),
        mergeMap(() =>
          this.safeboxSrv.setSafeboxTypes(this.lang, true).pipe(
            catchError(err => {
              this.tools.error('UI Effect setSafeboxTypes', err);
              return of(true);
            })
          )
        ),
        mergeMap(() =>
          this.companySrv.setCompanies().pipe(
            catchError(err => {
              this.tools.error('UI Effect setCompanies', err);
              return of(true);
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private customerSrv: CustomerService,
    private agencySrv: AgencyService,
    private sliderSrv: SliderService,
    private constantSrv: ConstantService,
    private statusSrv: StatusService,
    private insuranceSrv: InsuranceService,
    private itemSrv: ItemService,
    private safeboxSrv: SafeboxService,
    private companySrv: CompanyService,
    private store: StoreService,
    private http: HttpClient,
    private tools: ToolsService,
    private policySrv: PolicyService,
    private compareSrv: CompareService
  ) {
    this.appName = environment.APP_NAME;
    this.agencyId = environment.AGENCY_ID;
  }
}
