import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { DefaultAddress } from '../../../gfl-core/gfl-models/agency.model';
import { Contact } from '../../../gfl-core/gfl-models/contact.model';

import { RequestCallComponent } from '../request-call/request-call.component';
import { ChatDisplayComponent } from '../chat-display/chat-display.component';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { CustomerFamilyMember } from '../../../customer/models/customer.model';

@Component({
  selector: 'gfl-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  @Input() acls: Acls;
  @Input() isOffline: boolean;

  public contact$: Observable<Contact>;
  public address$: Observable<DefaultAddress>;

  /**
   * @ignore
   */
  constructor(
    public translate: TranslateService,
    public tools: ToolsService,
    private modalCtrl: ModalController,
    private agencySrv: AgencyService,
    private notificationSrv: NotificationService
  ) {}

  ngOnInit() {
    this.contact$ = this.agencySrv.getMyContact();
    this.address$ = this.agencySrv.getAgencyAddress();
  }

  /**
   * Open RequestCallComponent in a modal
   */
  public async openRequestCallModal() {
    this.close();

    const modal = await this.modalCtrl.create({ component: RequestCallComponent });
    await modal.present();
  }

  /**
   * Open ChatDisplayComponent in a modal
   */
  public async openChatModal() {
    this.close();

    const modal = await this.modalCtrl.create({
      component: ChatDisplayComponent,
      componentProps: {
        acls: this.acls,
      },
    });
    await modal.present();
  }

  /**
   * Send an email to the customer's advisor
   */
  public sendMessageToAdvisor(): void {
    this.agencySrv.sendMessageToAdvisor().subscribe(() => {}, err => this.notificationSrv.showError({ message: err }));
  }

  /**
   * Close current view
   */
  public close(): void {
    this.modalCtrl.dismiss().then(() => {});
  }
}
