import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';

import { tap } from 'rxjs/operators';

import { StoreService } from '../gfl-services/store.service';
import { environment } from '../../../environments/environment';
import { NetworkMonitorService } from '../gfl-services/network-monitor.service';

@Injectable()
export class BoVersionInterceptor implements HttpInterceptor {
  readonly appName: string;
  private isOnline: boolean;
  constructor(private store: StoreService, private network: NetworkMonitorService) {
    this.appName = environment.APP_NAME;
    this.network.isOnline().subscribe(isOnline => (this.isOnline = isOnline));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap(event => {
        if (event instanceof HttpResponse) {
          const newBoVersion = event.headers.get('bo-version');

          if (newBoVersion) {
            this.store.get(`${this.appName}_bo-version`).subscribe(boVersion => {
              if (boVersion && boVersion !== newBoVersion && this.isOnline) {
                this.store.reloadStaticData();
              }
            });
          }
        }
      })
    );
  }
}
