import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';

import { GflCoreModule } from '../../../gfl-core/gfl-core.module';
import { CustomerComponentsModule } from '../../components/customer-components.module';
import { ContactComponentsModule } from '../../../contacts/components/contact-components.module';

import { ProfilePage } from './profile.page';
import { RequestCallComponent } from '../../../contacts/components/request-call/request-call.component';
import { SignupBrokerComponent } from '../../../authentication/components/signup-broker/signup-broker.component';
// tslint:disable-next-line:max-line-length
import { AuthentificationComponentsModule } from '../../../authentication/components/authentification-components.module';

@NgModule({
  imports: [
    GflCoreModule,
    CustomerComponentsModule,
    ContactComponentsModule,
    AuthentificationComponentsModule,
    IonicModule,
  ],
  declarations: [ProfilePage],
  entryComponents: [RequestCallComponent, SignupBrokerComponent],
})
export class ProfilePageModule {}
