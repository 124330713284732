import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { Observable, of, Subscription } from 'rxjs';
import { first, map, shareReplay, switchMap } from 'rxjs/operators';

import { Company } from '../../../gfl-core/gfl-models/company.model';
import { Customer, CustomerFamilyMember } from '../../../customer/models/customer.model';
import { InsuranceType } from '../../models/insurance-type.model';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { MandateService } from '../../services/mandate.service';
import { InsuranceService, InsuranceTypesCategories } from '../../services/insurance.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { CompanyService } from '../../../gfl-core/gfl-services/company.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { PolicyService } from '../../services/policy.service';

@Component({
  selector: 'gfl-policy-add',
  templateUrl: './policy-add.component.html',
  styleUrls: ['./policy-add.component.scss'],
})
export class PolicyAddComponent implements OnInit, OnDestroy {
  @Input() standaloneMode: boolean;
  @Input() customer: Customer;
  @Input() signupProcess: boolean;
  @Output() mandateAdded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() cancelAction = new EventEmitter<any>();

  public members$: Observable<CustomerFamilyMember[]>;
  public selectedMember$: Observable<CustomerFamilyMember | Customer>;
  public insuranceTypes$: Observable<InsuranceType[]>;
  public isMandates$: Observable<boolean>;

  public companies: Company[];
  public selectedInsuranceType: InsuranceType;
  public selectedCompany: Company;
  public policyNumber: string;
  public selectCustomerStep = true;
  public selectInsuranceTypeStep = false;
  public selectCompanyStep = false;
  public confirmStep = false;
  public errorDisplay: boolean;
  public noDataDisplay: string;
  public acls$: Observable<Acls>;
  public style: FrontTheme;
  private subscriptions: Subscription[] = [];

  /**
   * @ignore
   */
  constructor(
    public modalCtrl: ModalController,
    public tools: ToolsService,
    private store: StoreService,
    private customerSrv: CustomerService,
    private companySrv: CompanyService,
    private insuranceSrv: InsuranceService,
    private mandateSrv: MandateService,
    private translate: TranslateService,
    private notificationSrv: NotificationService,
    private aclsSrv: AclsService,
    private constantSrv: ConstantService,
    private policySrv: PolicyService
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    this.selectCustomerStep = !this.signupProcess;
    this.selectInsuranceTypeStep = this.signupProcess;

    this.selectedMember$ = this.customerSrv.getSelectedMember().pipe(shareReplay());

    this.isMandates$ = this.selectedMember$.pipe(
      switchMap(selectedMember => this.mandateSrv.getMandates(selectedMember.id)),
      switchMap(mandates => of(!!mandates.length))
    );

    this.insuranceTypes$ = this.selectedMember$.pipe(
      switchMap(selectedMember => {
        return selectedMember.customer_type_id === CUSTOMER_TYPE_ID_PRIVATE
          ? of(InsuranceTypesCategories.private)
          : of(InsuranceTypesCategories.corporate);
      }),
      switchMap(category => {
        return this.insuranceSrv.getInsuranceTypes(category);
      }),
      map(insuranceTypes => {
        return _.values(insuranceTypes);
      })
    );

    if (this.customer) {
      this.store.setSelectedMember(this.customer);
    } else {
      this.getMembers();
    }

    this.initAcls();
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Set selected insurance type
   * @param insuranceType insurance type object
   */
  public selectType(insuranceType: InsuranceType): void {
    this.selectedInsuranceType = insuranceType;
  }

  /**
   * Set selected company
   * @param company company object
   */
  public selectCompany(company: Company): void {
    this.selectedCompany = company;
  }

  /**
   * display previous view according to current step
   */
  public back(): void {
    if (this.selectInsuranceTypeStep) {
      this.modalCtrl.dismiss().then(() => {});
    } else {
      this.goFirstStep();
    }
  }

  /**
   * what to do next...
   */
  public action() {
    if (this.selectInsuranceTypeStep) {
      return this.goThirdStep();
    }
    if (this.selectCompanyStep) {
      return this.goFourthStep();
    }
    if (this.confirmStep) {
      this.saveMandateRequest();
    }
  }

  /**
   * Set to display "select customer" step
   */
  public goFirstStep(): void {
    this.selectedCompany = undefined;
    this.selectCustomerStep = true;
    this.selectInsuranceTypeStep = false;
    this.selectCompanyStep = false;
    this.confirmStep = false;
  }

  /**
   * Set to display "select Insurance type" step
   */
  public goSecondStep(): void {
    this.selectedCompany = undefined;
    this.selectCustomerStep = false;
    this.selectInsuranceTypeStep = true;
    this.selectCompanyStep = false;
    this.confirmStep = false;
  }

  /**
   * Set to display "select company" step
   */
  public goThirdStep(): void {
    this.selectCustomerStep = false;
    this.selectInsuranceTypeStep = false;
    this.selectCompanyStep = true;
    this.confirmStep = false;
    this.getCompanies(this.selectedInsuranceType.id);
  }

  /**
   * Set to display "confirm" step
   */
  public goFourthStep(): void {
    this.selectCustomerStep = false;
    this.selectInsuranceTypeStep = false;
    this.selectCompanyStep = false;
    this.confirmStep = true;
  }

  /**
   * Send Request to BO to save new mandate
   */
  public saveMandateRequest(): void {
    this.tools.showLoader();

    const customerApiToken = this.signupProcess && this.customer.api_token;

    // Send request to BO
    const newMandateRequest = [
      {
        company_id: this.selectedCompany.id,
        insurance_type_id: this.selectedInsuranceType.id,
        temp_policy_number: this.policyNumber,
      },
    ];

    this.customerSrv
      .getSelectedMember()
      .pipe(
        first(),
        switchMap(selectedMember => {
          const selectedMemberId = !this.signupProcess && selectedMember.id;
          return this.mandateSrv.saveMandates(newMandateRequest, selectedMemberId, customerApiToken);
        })
      )
      .subscribe(
        () => {
          this.onSavedMandateRequestSuccess();
        },
        err => this.onSaveMandateRequestError(err)
      );
  }

  /**
   * Close current modal
   */
  public closeModal(): void {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * Emit cancel event
   */
  public cancel(): void {
    if (this.selectCompanyStep) {
      this.errorDisplay = false;
      this.noDataDisplay = null;
      this.companies = null;
      this.goSecondStep();
    } else {
      this.cancelAction.next();
    }
  }

  /**
   * Return an Observable of members
   * The family member is removed from the members array
   * The selected member is set also
   */
  private getMembers(): void {
    const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');

    // remove family member from list
    this.members$ = this.customerSrv.getMembers().pipe(
      map(members => {
        if (members.length) {
          // remove employee member
          _.remove(members, { customer_type_id: CUSTOMER_TYPE_ID_EMPLOYEE });
          if (!members[0].customer_link_type_id) {
            // remove family member
            members.shift();
          }
        }

        this.customerSrv.selectMember(members[0]);
        return members;
      })
    );
  }

  /**
   * Get an array of companies for the given insurance type
   * @param selectedInsuranceTypeId insurance type id
   */
  private getCompanies(selectedInsuranceTypeId: number): void {
    this.selectedMember$
      .pipe(
        switchMap(selectedMember => {
          return this.companySrv.getCompaniesByInsuranceType(selectedInsuranceTypeId, selectedMember.customer_type_id);
        }),
        first()
      )
      .subscribe(
        companies => {
          this.companies = companies;

          if (!this.companies.length) {
            this.noDataDisplay = 'SIGNUP.ERRORS.NO_COMPANY';
          }
        },
        () => {
          this.errorDisplay = true;
        }
      );
  }

  /**
   * Dismiss loader popup and display error notification
   * @param  msg message
   */
  private onSaveMandateRequestError(msg: string): void {
    this.tools.hideLoader();
    this.tools.error('saveMandateRequest Error', msg);

    this.notificationSrv.showError({
      message: typeof msg === 'string' ? msg : 'INSURANCE.MANDATE.NOTIF_ERROR',
    });
    this.mandateAdded.next();
  }

  /**
   * Dismiss loader popup, display success notification and shutdown the modal
   */
  private onSavedMandateRequestSuccess(): void {
    this.notificationSrv.showSuccess({
      message: 'INSURANCE.MANDATE.NOTIF_DEMAND_SAVED',
      callback: () => {},
    });
    const customerApiToken = this.signupProcess && this.customer.api_token;

    this.policySrv.setPoliciesAndMandates(false, customerApiToken, this.signupProcess).subscribe(() => {
      this.tools.hideLoader();
      this.mandateAdded.next();

      if (!this.standaloneMode) {
        this.modalCtrl.dismiss().then(() => {});
      }
    });
  }

  /**
   * Initialize Acls
   */
  private initAcls(): void {
    this.acls$ = this.aclsSrv.getAcls();
  }
}
