import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as _ from 'lodash';

import { catchError, delay, filter, map, shareReplay } from 'rxjs/operators';
import { combineLatest, Observable, of, Subscription } from 'rxjs';

import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { Agency, FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Slider, SliderType } from '../../../gfl-core/gfl-models/slider.model';
import { Customer, CustomerFamilyMember } from '../../../customer/models/customer.model';
import { PolicyFO } from '../../../policies/models/policy.model';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';
import { SliderService } from '../../../gfl-core/gfl-services/slider.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { NavigationService } from '../../../gfl-core/gfl-services/navigation.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ApiService } from '../../../gfl-core/gfl-services/api.service';
import { PolicyService } from '../../../policies/services/policy.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';

import { Store } from '@ngrx/store';
import { GflState } from '../../../reducers';

@Component({
  selector: 'gfl-compares',
  templateUrl: './compares.page.html',
  styleUrls: ['./compares.page.scss'],
})
export class ComparesPage implements OnInit, OnDestroy {
  public style$: Observable<FrontTheme>;
  public acls$: Observable<Acls>;
  public sliders$: Observable<Slider[]>;
  public members$: Observable<CustomerFamilyMember[]>;
  public selectedMember$: Observable<CustomerFamilyMember | Customer>;
  public policies$: Observable<PolicyFO[]>;
  public agency$: Observable<Agency>;

  public isPro: boolean;
  private subscriptions: Subscription[] = [];
  public errorDisplay: boolean;
  public noDataDisplay: string;
  public expanded: boolean;

  /**
   * @ignore
   */
  constructor(
    private navCtrl: NavController,
    public tools: ToolsService,
    private apiSrv: ApiService,
    private customerSrv: CustomerService,
    private store: StoreService,
    private ngrxStore: Store<GflState>,
    private agencySrv: AgencyService,
    private policySrv: PolicyService,
    private navigationSrv: NavigationService,
    private sliderSrv: SliderService,
    private aclsSrv: AclsService,
    private constantSrv: ConstantService,
    private ngZone: NgZone
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    // we want to get Mode state but we don't want screen to be
    // refreshed before new screen is displaed when user switches mode private/pro
    this.subscriptions.push(
      this.store.getIsProSelected().subscribe(isPro => {
        this.isPro = isPro;
      })
    );

    this.style$ = this.store.getStyle();
    this.agency$ = this.agencySrv.getAgency();
    this.policies$ = this.initPolicies();
    this.members$ = this.initMembers();
    this.selectedMember$ = this.customerSrv.getSelectedMember();
    this.sliders$ = this.sliderSrv.getSliders(SliderType.Private);

    this.initAcls();
  }

  /**
   * @ignore
   */
  ionViewDidEnter() {
    this.navigationSrv.set('ComparePage');
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Set member selected from IHM
   * @param member a family member or employee
   */
  public selectMember(member: CustomerFamilyMember): void {
    this.errorDisplay = false;
    this.customerSrv.selectMember(member);
  }

  /**
   * Set members observable,
   * Remove family member and set selected member data
   */
  private initMembers(): Observable<CustomerFamilyMember[]> {
    const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');

    return this.customerSrv.getMembers().pipe(
      delay(0),
      filter(val => !_.isEmpty(val)),
      map(members => {
        if (members.length) {
          // remove employee member
          _.remove(members, { customer_type_id: CUSTOMER_TYPE_ID_EMPLOYEE });
          if (!members[0].customer_link_type_id) {
            // remove family member
            members.shift();
          }
          // reset display attributes
          this.errorDisplay = false;
          this.noDataDisplay = null;
          this.customerSrv.selectMember(members[0]);

          if (!NgZone.isInAngularZone()) {
            this.ngZone.run(() => {
              this.errorDisplay = false;
              this.noDataDisplay = null;
              this.customerSrv.selectMember(members[0]);
            });
          }
        }

        return members;
      }),
      catchError(err => {
        this.tools.error('initMembers Error', err);
        this.errorDisplay = true;
        return of([]);
      })
    );
  }

  /**
   * Set policies observable,
   * Remove family member and set selected member data
   */
  private initPolicies(): Observable<Array<PolicyFO>> {
    // @ts-ignore
    return combineLatest([this.policySrv.getPolicies(), this.store.getLang()]).pipe(
      map(([policiesAndMandates, lang]) => _.filter(policiesAndMandates[0][lang], { is_mandate: false })),
      catchError(() => {
        this.errorDisplay = true;
        return of(null);
      }),
      shareReplay()
    );
  }

  /**
   * Initialize Acls
   */
  private initAcls(): void {
    this.acls$ = this.aclsSrv.getAcls();
  }
}
