import { NgModule } from '@angular/core';
import { GflCoreModule } from '../gfl-core/gfl-core.module';
import { IonicModule } from '@ionic/angular';
import { StoreModule } from '@ngrx/store';

import { PoliciesRoutingModule } from './policies-routing.module';
import { PoliciesComponentsModule } from './components/policies-components.module';
import { PoliciesPage } from './pages/policies/policies.page';
import { PolicyResumeComponent } from './components/policy-resume/policy-resume.component';
import { PolicyInvoicesComponent } from './components/policy-invoices/policy-invoices.component';
import * as fromPolicies from './reducers';

@NgModule({
  imports: [
    GflCoreModule,
    IonicModule,
    PoliciesComponentsModule,
    PoliciesRoutingModule,
    StoreModule.forFeature(fromPolicies.policiesFeatureKey, fromPolicies.policiesReducer, {
      metaReducers: fromPolicies.metaReducers,
    }),
  ],
  declarations: [PoliciesPage],
  entryComponents: [PolicyResumeComponent, PolicyInvoicesComponent],
})
export class PoliciesPageModule {}
