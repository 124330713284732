import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { ConstantService } from '../../../../gfl-core/gfl-services/constant.service';
import { GflFormGeneratorService } from '../../services/gfl-form-generator.service';
import { ToolsService } from '../../../../gfl-core/gfl-services/tools.service';
import { ComponentType, CountableItem, ItemType, ItemTypeParent } from '../../models/gfl-form-component.model';
import { FrontTheme } from '../../../../gfl-core/gfl-models/agency.model';
import { GflThemeOptions } from '../../models/gfl-form-options.model';
import { GflModeDisplayType, GflSelectOption } from '../../models/gfl-form.model';

/**
 * This generic component is used to create one of the gfl-field components
 *
 * @example
 * <gfl-field-generator
 *                      [form]="form"
 *                      [value]="subitem.value"
 *                      [name]="subItem.item_template_key"
 *                      [isEditMode]="isEditMode"
 *                      [label]="subItem.item_template_key_translate"
 *                      [style]="style"
 *                      [itemType]="subItem.item_type_name"
 *                      [modeDisplay]="modeDisplay"
 *                      [itemTypeParent]="subItem.item_sub_item_parent_key"
 *                      [selectOptions]="subItem.values"
 *                      [placeholder]="'...'"></gfl-field-generator>
 */
@Component({
  selector: 'gfl-field-generator',
  templateUrl: './gfl-field-generator.component.html',
  styleUrls: ['./gfl-field-generator.component.scss'],
})
export class GflFieldGeneratorComponent implements OnInit {
  /**
   * initial value
   */
  @Input() value: any;

  /**
   * hide value and formatted value in order to display only label
   */
  @Input() hideValue: boolean;

  /**
   * initial values of countable_values attribute
   */
  @Input() countableItems: { [id: string]: CountableItem } | null;

  /**
   * the form implementing this field in two way binding
   */
  @Input() form: FormGroup;
  @Output() formChange: EventEmitter<FormGroup>;

  /**
   * true if form has been submitted
   */
  @Input() submitted: boolean;

  /**
   * Front theme style
   */
  @Input() style: FrontTheme;

  /**
   * Text used in ion-label component
   */
  @Input() label: string;

  /**
   * Text used in placeholder component
   */
  @Input() placeholder: string;

  /**
   * Text used in placeholder prefix of component GflFieldPhoneComponent
   */
  @Input() placeholderPrefix: string;

  /**
   * Text used in placeholder phone of component GflFieldPhoneComponent
   */
  @Input() placeholderPhone: string;

  /**
   * Text used in ion-label zipCode of component GflFieldZipcodeCityComponent
   */
  @Input() labelZipCode: string;

  /**
   * Text used in ion-label city of component GflFieldZipcodeCityComponent
   */
  @Input() labelCity: string;

  /**
   * Text used in placeholder zipCode of component GflFieldZipcodeCityComponent
   */
  @Input() placeholderZipCode: string;

  /**
   * Text used in placeholder city of component GflFieldZipcodeCityComponent
   */
  @Input() placeholderCity: string;

  /**
   * The item type definition used to select which Ionic Component to use
   */
  @Input() itemType: ItemType;

  /**
   * The parent's item type definition used to select which Ionic Component to use
   */
  @Input() itemTypeParent: ItemTypeParent;

  /**
   * format is used to check if item is a countable item template
   */
  @Input() format: number | null;

  /**
   * If isEditMode is false then the field value are read-only
   */
  @Input() isEditMode: boolean;

  /**
   * If isLabel is true then we only display label :)
   */
  @Input() isLabel: boolean;

  /**
   * Set type of displayed template between "mobile" and "tablet" options
   */
  @Input() modeDisplay: GflModeDisplayType;

  /**
   * if true then ion-icon are displayed instead of text value
   */
  @Input() displayIcons: boolean;

  /**
   * value for name's HTML form tag
   */
  @Input() name: string;

  /**
   * This is the value_reformat attribute provided by the BO
   * for objects using item_templates
   */
  @Input() formattedValue: string;

  /**
   * Color theme to apply to the component
   */
  @Input() theme: GflThemeOptions;

  /**
   * field required flag
   */
  @Input() required: boolean;

  /**
   * Options for the select gfl-field-select component
   */
  @Input() selectOptions: { [id: string]: any };

  /**
   * Picker format used on display, by default 'DD MM YYYY'
   */
  @Input() pickerFormat: string;

  /**
   * Date format used on display, by default 'DD/MM/YYYY'
   */
  @Input() displayFormat: string;

  /**
   * itemCol is used in tablet/desktop display mode to indicate the width of items
   */
  @Input() itemCol: number;

  public componentTypes = ComponentType;
  public selectOptionsArr: Array<GflSelectOption> = [];
  public cancelTxt: string;
  public doneTxt: string;
  public validators: Array<ValidatorFn> = [];
  public componentTypeSelected: ComponentType;
  readonly ITEM_TEMPLATE_FORMAT_COUNTABLE: number;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    public translate: TranslateService,
    private gflFormSrv: GflFormGeneratorService,
    private constantSrv: ConstantService
  ) {
    this.theme = this.theme || {};
    this.cancelTxt = 'COMMON.BUTTON_CANCEL';
    this.doneTxt = 'COMMON.BUTTON_VALIDATE';

    this.formChange = new EventEmitter<FormGroup>();
    this.ITEM_TEMPLATE_FORMAT_COUNTABLE = this.constantSrv.getValueFromKey('ITEM_TEMPLATE_FORMAT_COUNTABLE');
  }

  /**
   * @ignore
   */
  ngOnInit() {
    const isCountable = this.format === this.ITEM_TEMPLATE_FORMAT_COUNTABLE;

    this.componentTypeSelected = this.gflFormSrv.setComponentType(
      this.itemTypeParent,
      this.selectOptions,
      this.itemType,
      isCountable
    );

    // Select component case
    if (!_.isEmpty(this.selectOptions)) {
      _.forEach(this.selectOptions, (item, key) => {
        this.selectOptionsArr.push({
          text: item,
          value: key,
          selected: false,
        });
      });
    }
  }
}
