import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as _ from 'lodash';

import { Observable } from 'rxjs';

import { ContactsService } from '../../services/contacts.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { NotificationItem } from '../../models/contacts.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { first, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CustomerService } from '../../../customer/services/customer.service';
import { Customer } from '../../../customer/models/customer.model';

@Component({
  selector: 'gfl-notifications-display',
  templateUrl: './notifications-display.component.html',
  styleUrls: ['./notifications-display.component.scss'],
})
export class NotificationsDisplayComponent implements OnInit {
  @ViewChild('contentRef', { static: false }) contentRef: ElementRef;
  @Input() acls: Acls;
  public notifications$: Observable<NotificationItem[]>;
  public customers: { [p: number]: Customer };

  constructor(
    private contactsSrv: ContactsService,
    private customerSrv: CustomerService,
    private notificationSrv: NotificationService,
    public tools: ToolsService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    this.notifications$ = this.getNotifications();

    const setInt = setInterval(() => {
      if (this.contentRef) {
        this.contentRef.nativeElement.scrollTop = this.contentRef.nativeElement.scrollHeight;
        this.contactsSrv.markChatItemsAsSeen();
        clearInterval(setInt);
      }
    }, 100);
  }

  /**
   * Close current view
   */
  public close(): void {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * return notifications
   */
  private getNotifications(): Observable<NotificationItem[]> {
    return this.contactsSrv.getNotifications().pipe(
      withLatestFrom(this.customerSrv.getCustomersList([]).pipe(first())),
      map(([notifications, customers]) => {
        const clonedNotifications = _.cloneDeep(notifications);
        _.map(clonedNotifications, o => {
          o.customerName = o.customerId
            ? customers[o.customerId].firstname || customers[o.customerId].lastname
            : undefined;

          return o;
        });

        return clonedNotifications;
      })
    );
  }
}
