import { Injectable } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import * as _ from 'lodash';

import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { ComponentType, ItemType, ItemTypeParent } from '../models/gfl-form-component.model';
import { GflFormItem, GflFormSubItem } from '../models/gfl-form.model';
import { ItemTemplateFO } from '../../../gfl-core/gfl-models/item-template.model';
import { GflFieldOptions } from '../models/gfl-form-options.model';

@Injectable({
  providedIn: 'root',
})
export class GflFormGeneratorService {
  readonly ITEM_TEMPLATE_FORMAT_COUNTABLE: number;

  /**
   * @ignore
   */
  constructor(private constantSrv: ConstantService) {
    this.ITEM_TEMPLATE_FORMAT_COUNTABLE = this.constantSrv.getValueFromKey('ITEM_TEMPLATE_FORMAT_COUNTABLE');
  }

  /**
   * This method formats attributes name in a coherent way
   * @param formData form data
   */
  public formatItemsTemplate(formData: Array<GflFormItem>): Array<GflFormItem> {
    return this.formatItemTemplate(formData);
  }

  /**
   * Set formData with fields definition and initial values to be used in GflFormGeneratorComponent
   *
   * @param entityItems Items attributes filtered
   * by item_template_key from BO WebService
   * @param itemsTemplate Items template filtered
   * by foreign_table_key and item_template_key from BO webservice GET item_template
   * webservice
   * @param keepAll if true reformat_value isn't used to check if subitem is kept
   */
  public setFormData(
    entityItems: Array<ItemTemplateFO>,
    itemsTemplate: Array<ItemTemplateFO> | { [id: string]: ItemTemplateFO },
    keepAll: boolean = false
  ): Array<GflFormItem> {
    // Init form array
    const formData = [];

    // Get form's fields and set form's fields initial values
    _.forEach(entityItems, item => {
      // define children items
      let children;

      // @ts-ignore
      const itemTemplate: ItemTemplateFO = _.filter(itemsTemplate, { item_template_id: item.item_template_id })[0];
      const formDataItem = {
        ...itemTemplate,
        item_id: item.item_id,
        unit: item.unit,
        countable_values: item.countable_values,
      };

      if (item.value !== undefined) {
        formDataItem.value = item.value;
      } else if (item.value_int !== undefined) {
        // @ts-ignore
        formDataItem.value = item.value_int;
      } else if (item.value_double !== undefined) {
        // @ts-ignore
        formDataItem.value = item.value_double;
      } else if (item.value_boolean !== undefined) {
        // @ts-ignore
        formDataItem.value = item.value_boolean;
      } else if (item.value_varchar !== undefined) {
        formDataItem.value = item.value_varchar;
      } else if (item.value_text !== undefined) {
        formDataItem.value = item.value_text;
      } else if (item.value_date !== undefined) {
        // @ts-ignore
        formDataItem.value = item.value_date;
      }

      if (item.value_reformat !== undefined) {
        formDataItem.value_reformat = item.value_reformat;
      } else if (item.value_int !== undefined) {
        // @ts-ignore
        formDataItem.value_reformat = item.value_int;
      } else if (item.value_double !== undefined) {
        // @ts-ignore
        formDataItem.value_reformat = item.value_double;
      } else if (item.value_boolean !== undefined) {
        // @ts-ignore
        formDataItem.value_reformat = item.value_boolean;
      } else if (item.value_varchar !== undefined) {
        formDataItem.value_reformat = item.value_varchar;
      } else if (item.value_text !== undefined) {
        formDataItem.value_reformat = item.value_text;
      } else if (item.value_date !== undefined) {
        // @ts-ignore
        formDataItem.value_reformat = item.value_date;
      }

      delete formDataItem.children;
      delete formDataItem.subitems;

      if (!_.isEmpty(item.children) || !_.isEmpty(item.subitems)) {
        children = item.children || item.subitems;
      }

      if (children) {
        const subItemsTemplate = itemTemplate && itemTemplate.subitems;
        // @ts-ignore
        formDataItem.subitems = [];

        _.forEach(children, subItem => {
          const subItemTemplate = _.filter(subItemsTemplate, { item_template_id: subItem.item_template_id })[0];
          const formDataSubItem = {
            ...subItemTemplate,
            item_id: subItem.item_id,
            unit: subItem.unit,
            value:
              subItem.value ||
              subItem.value_int ||
              subItem.value_double ||
              subItem.value_boolean ||
              subItem.value_varchar ||
              subItem.value_text ||
              subItem.value_date,
            value_reformat:
              subItem.value_reformat ||
              subItem.value_int ||
              subItem.value_double ||
              subItem.value_boolean ||
              subItem.value_varchar ||
              subItem.value_text ||
              subItem.value_date,
            countable_values: subItem.countable_values,
          };

          if (subItem.value !== undefined) {
            formDataSubItem.value = subItem.value;
          } else if (subItem.value_int !== undefined) {
            // @ts-ignore
            formDataSubItem.value = subItem.value_int;
          } else if (subItem.value_double !== undefined) {
            // @ts-ignore
            formDataSubItem.value = subItem.value_double;
          } else if (subItem.value_boolean !== undefined) {
            // @ts-ignore
            formDataSubItem.value = subItem.value_boolean;
          } else if (subItem.value_varchar !== undefined) {
            formDataSubItem.value = subItem.value_varchar;
          } else if (subItem.value_text !== undefined) {
            formDataSubItem.value = subItem.value_text;
          } else if (subItem.value_date !== undefined) {
            // @ts-ignore
            formDataSubItem.value = subItem.value_date;
          }

          if (subItem.value_reformat !== undefined) {
            formDataSubItem.value_reformat = subItem.value_reformat;
          } else if (subItem.value_int !== undefined) {
            // @ts-ignore
            formDataSubItem.value_reformat = subItem.value_int;
          } else if (subItem.value_double !== undefined) {
            // @ts-ignore
            formDataSubItem.value_reformat = subItem.value_double;
          } else if (subItem.value_boolean !== undefined) {
            // @ts-ignore
            formDataSubItem.value_reformat = subItem.value_boolean;
          } else if (subItem.value_varchar !== undefined) {
            formDataSubItem.value_reformat = subItem.value_varchar;
          } else if (subItem.value_text !== undefined) {
            formDataSubItem.value_reformat = subItem.value_text;
          } else if (subItem.value_date !== undefined) {
            // @ts-ignore
            formDataSubItem.value_reformat = subItem.value_date;
          }

          if (keepAll || formDataSubItem.value_reformat || formDataSubItem.countable_values) {
            // @ts-ignore
            formDataItem.subitems.push(formDataSubItem);
          }
        });
      }

      // @ts-ignore
      formData.push(formDataItem);
    });

    return formData;
  }

  /**
   * Return FormData updated with values from form
   * @param formData form data
   * @param values these are values from a FormGroup
   */
  public updateFormData(formData: Array<GflFormItem>, values: { [id: string]: any }): Array<GflFormItem> {
    _.forEach(formData, (item: GflFormItem) => {
      this.updateFormDataItem(item, values);

      _.forEach(item.subitems, (subitem: GflFormSubItem) => {
        this.updateFormDataItem(subitem, values);
      });
    });

    return formData;
  }

  /**
   * Return componentType value according to parameters
   * @param itemTypeParent itemTypeParent
   * @param selectOptions linked to a select component
   * @param itemType itemType
   * @param isCountable isCountable is used to check if item is a countable item template
   */
  public setComponentType(
    itemTypeParent: ItemTypeParent,
    selectOptions: { [id: string]: string | number },
    itemType: ItemType,
    isCountable?: boolean
  ): ComponentType {
    // First level - itemTypeParent value
    if (itemTypeParent === ItemTypeParent.EMAIL) {
      return isCountable ? ComponentType.EMAIL_MULTI : ComponentType.EMAIL;
    }

    if (itemTypeParent === ItemTypeParent.PHONE) {
      return isCountable ? ComponentType.PHONE_MULTI : ComponentType.PHONE;
    }

    // Second Level - Values attribute
    if (!_.isEmpty(selectOptions)) {
      return ComponentType.SELECT;
    }

    // third level - itemType attribute
    let componentTypeSelected: ComponentType;

    switch (itemType) {
      case ItemType.BOOLEAN:
        componentTypeSelected = ComponentType.TOGGLE;
        break;
      case ItemType.VARCHAR:
        componentTypeSelected = ComponentType.INPUT_TEXT;
        break;
      case ItemType.TEXT:
        componentTypeSelected = ComponentType.INPUT_TEXT;
        break;
      case ItemType.INT:
        componentTypeSelected = ComponentType.INPUT_NUM_INT;
        break;
      case ItemType.DOUBLE:
        componentTypeSelected = ComponentType.INPUT_NUM;
        break;
      case ItemType.DATE:
        componentTypeSelected = ComponentType.INPUT_DATE;
        break;
      // case ItemType.TEXT:
      //   componentTypeSelected = ComponentType.TEXTAREA;
      //   break;
    }

    return componentTypeSelected;
  }

  /**
   * Return an object formatted as form values
   * @param arrInit arrInit
   */
  public getFormInitialValues(arrInit: Array<GflFormItem | GflFormSubItem | ItemTemplateFO>): { [id: string]: any } {
    const obj = {};

    _.forEach(arrInit, item => {
      if (!item.is_header) {
        obj[item.item_template_key] = item.value;
      }

      // @ts-ignore
      _.forEach(item.subitems, (subitem: ItemTemplateFO) => {
        if (!subitem.is_header) {
          if (subitem.value) {
            obj[subitem.item_template_key] = subitem.value;
          } else if (this.isCountable(subitem.format)) {
            obj[subitem.item_template_key] = obj[subitem.item_template_key] || [];

            _.forEach(subitem.countable_values, (countableValue, key) => {
              obj[subitem.item_template_key].push({
                key,
                ...countableValue,
              });
            });
          }
        }
      });
    });

    return obj;
  }

  /**
   * This method formats attributes name in a coherent way
   * @param arrInit arrInit
   * @param itemTemplateKey This argument is used to inject it
   * in sub-items to choose gfl-field-component type
   * @returns  Array<GflFormItem|GflFormSubItem>
   * @ignore
   */
  private formatItemTemplate(arrInit: Array<GflFormItem | GflFormSubItem>, itemTemplateKey?: string) {
    _.forEach(arrInit, item => {
      // @ts-ignore
      item.is_abstract = item.is_abstract || item.item_template_is_abstract;
      // @ts-ignore
      item.is_required = item.is_required || item.item_template_is_required;
      // @ts-ignore
      item.is_header = item.is_header || item.item_template_is_header;
      // @ts-ignore
      item.is_offer = item.is_offer || item.item_template_is_offer;
      // @ts-ignore
      item.is_offer_compare = item.is_offer_compare || item.item_template_is_offer_compare;
      // @ts-ignore
      item.position = item.position || item.item_template_position;
      // @ts-ignore
      item.format = item.format || item.item_template_format;
      // @ts-ignore
      item.unit = item.unit || item.item_template_unit;
      // @ts-ignore
      item.item_sub_item_parent_key = itemTemplateKey;
      // @ts-ignore
      item.item_sub_item_parent_label = itemTemplateKey;
      // @ts-ignore
      item.countable_values = item.countable_values || item.item_template_countable_values;

      if (item.subitems) {
        // @ts-ignore
        item.subitems = this.formatItemTemplate(item.subitems, item.item_template_key);
      }
    });

    return arrInit;
  }

  /**
   * Return an array of validators according to component type, GflFieldOptions and specific validators
   *
   * @param componentType componentType
   * @param options options
   * @param validatorsSpecific validatorsSpecific
   */
  public setValidators(
    componentType: ComponentType,
    options?: GflFieldOptions,
    validatorsSpecific?: ValidatorFn[]
  ): ValidatorFn[] {
    let validators = [];

    switch (componentType) {
      case ComponentType.EMAIL:
        validators = [Validators.email];
        break;
      case ComponentType.PHONE:
        validators = [Validators.minLength(9), Validators.maxLength(10), Validators.pattern('[0-9]{0,10}')];
        break;
      case ComponentType.ZIPCODE_CITY:
        break;
      case ComponentType.INPUT_TEXT:
        validators = [Validators.minLength(2), Validators.maxLength(255)];
        break;
      case ComponentType.INPUT_NUM:
        break;
      case ComponentType.INPUT_NUM_INT:
        break;
      case ComponentType.INPUT_DATE:
        break;
      case ComponentType.SELECT:
        break;
      case ComponentType.TOGGLE:
        break;
    }

    if (options.required) {
      validators.push(Validators.required);
    }
    if (options.minlength) {
      validators.push(Validators.minLength(options.minlength));
    }
    if (options.maxlength) {
      validators.push(Validators.maxLength(options.maxlength));
    }

    return validators;
  }

  /**
   * Update a formData's item or subitem value attribute with form values
   * In case of a countable item template, value attribute will be an array
   * @param item item or subitem coming from a formData
   * @param values values object coming from a form
   */
  private updateFormDataItem(
    item: GflFormItem | GflFormSubItem,
    values: { [id: string]: any }
  ): GflFormItem | GflFormSubItem {
    const itemTemplateKeys: Array<string> = _.keys(values);

    // @ts-ignore
    if (itemTemplateKeys.includes(item.item_template_key)) {
      item.value = values[item.item_template_key];
    }

    return item;
  }

  /**
   * Return true if format of a countable item template
   * @param format format
   */
  private isCountable(format: string): boolean {
    return parseInt(format, 10) === this.ITEM_TEMPLATE_FORMAT_COUNTABLE;
  }
}
