import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Observable, Subscription } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { StoreService } from '../../gfl-services/store.service';
import { NetworkMonitorService } from '../../gfl-services/network-monitor.service';
import { ToolsService } from '../../gfl-services/tools.service';
import { NotificationService } from '../../gfl-services/notification.service';

@Component({
  selector: 'gfl-mode-pro-selector',
  templateUrl: './gfl-mode-pro-selector.component.html',
  styleUrls: ['./gfl-mode-pro-selector.component.scss'],
})
export class GflModeProSelectorComponent implements OnInit, OnDestroy {
  private selectedMod: string;
  public selectedModTranslated$: Observable<string>;
  public isPro$: Observable<boolean>;
  public isOffline: boolean;
  public isSelectorDisplay$: Observable<boolean> = this.store.getIsProSelectorDisplayed();
  private subscriptions: Subscription[] = [];

  /**
   * @ignore
   */
  constructor(
    public translate: TranslateService,
    private store: StoreService,
    private network: NetworkMonitorService,
    private tools: ToolsService,
    public notificationSrv: NotificationService
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.isPro$ = this.store.getIsProSelected().pipe(shareReplay());
    this.selectedModTranslated$ = this.isPro$.pipe(
      switchMap(isPro => {
        this.selectedMod = isPro ? 'SELECTOR.PRO' : 'SELECTOR.PRIVATE';

        return this.translate.get(this.selectedMod);
      })
    );
    this.subscriptions.push(this.network.isOffline().subscribe(isOffline => (this.isOffline = isOffline)));
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Set isPro context
   */
  public toggle(isPro): void {
    if (!this.isOffline) {
      this.store.setIsProSelected(!JSON.parse(isPro));
    }
  }
}
