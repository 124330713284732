import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as hash from 'object-hash';

import { forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, mergeMap, pluck, tap } from 'rxjs/operators';

import { ConstantService } from '../../gfl-core/gfl-services/constant.service';
import { StoreService } from '../../gfl-core/gfl-services/store.service';
import { ApiService } from '../../gfl-core/gfl-services/api.service';
import { NotificationService } from '../../gfl-core/gfl-services/notification.service';
import { AuthService } from '../../authentication/services/auth.service';
import { WsService } from '../../gfl-core/gfl-services/ws.service';
import { ToolsService } from '../../gfl-core/gfl-services/tools.service';
import { DocumentService } from '../../gfl-core/gfl-services/document.service';
import { DataMonitorService } from '../../gfl-core/gfl-services/data-monitor.service';
import { ApiResponse, LinkRelationItem, LinksAPI } from '../../gfl-core/gfl-models/api.model';
import {
  Customer,
  CustomerBank,
  CustomerFamilyMember,
  CustomerLinks,
  MandateContract,
  StoredCustomer,
} from '../models/customer.model';
import { LoginUserAsCustomerApiResponse } from '../../authentication/models/auth.model';
import { ItemTemplateFO } from '../../gfl-core/gfl-models/item-template.model';
import { City } from '../../gfl-core/gfl-models/city.model';
import { Address } from '../../gfl-core/gfl-models/address.model';
import { DefaultAddress } from '../../gfl-core/gfl-models/agency.model';
import { NotificationItem, NotificationType } from '../../contacts/models/contacts.model';

import { environment } from '../../../environments/environment';
import { AuthState } from '../../authentication/reducers';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private readonly appName = environment.APP_NAME;

  /**
   * @ignore
   */
  constructor(
    private apiSrv: ApiService,
    private wsSrv: WsService,
    private store: StoreService,
    private constantSrv: ConstantService,
    private translate: TranslateService,
    private authSrv: AuthService,
    private notificationSrv: NotificationService,
    private navCtrl: NavController,
    private tools: ToolsService,
    private documentSrv: DocumentService,
    private dataMonitorSrv: DataMonitorService
  ) {
    const locksToMonitor = [
      {
        name: 'customer',
        lock: () => this.store.getCustomerLock(),
        cb: () =>
          this.authSrv.getAuthData().pipe(
            pluck('customerToken'),
            mergeMap(token =>
              this.setCustomer({
                customerToken: token,
                customerIdLinked: null,
                noUpdateMode: false,
                noAskForValidationCustomerLinks: false,
                fetchCustomerLinks: true,
                noContract: false,
              })
            )
          ),
      },
    ];

    this.dataMonitorSrv.setMonitor(locksToMonitor);
  }

  /**
   * Returns Customer object
   * @param customerId customer ID
   */
  public getCustomer(customerId?: number): Observable<Customer> {
    return this.getStoredCustomer(customerId).pipe(
      map(storedCustomer => {
        return storedCustomer ? storedCustomer.customer : null;
      })
    );
  }

  /**
   * Returns Customer's contract object
   * @param customerId customer id
   */
  public openCustomerContract(customerId: number): Observable<any> {
    return this.getStoredCustomer(customerId).pipe(
      first(),
      pluck('contract'),
      mergeMap(contract => this.documentSrv.openCustomerContract(contract, customerId))
    );
  }

  /**
   * Returns Customers links
   * @param customerId customer ID
   */
  public getCustomerLinks(customerId?: number): Observable<CustomerLinks> {
    return this.getStoredCustomer(customerId).pipe(
      map(storedCustomer => (storedCustomer ? storedCustomer.customerLinks : null))
    );
  }

  /**
   * Returns an array of customers token but employee
   */
  public getCustomersIdList(except: number[]): Observable<number[]> {
    return this.store.getCustomersIdList(except);
  }

  /**
   * Returns an map of storedCustomers
   * @param except array of customer's ids that should not be returned
   */
  public getCustomersList(except: number[]): Observable<{ [id: number]: Customer }> {
    return this.store.getCustomersList(except);
  }

  /**
   * Set Customer's data
   * @param paramObj object with attributes:
   *  - apiToken Api token provided by BO
   *  - customerIdLinked used to get data of customer linked
   *  - noUpdateMode if true mode display is not updated
   *  - noAskForValidationCustomerLinks set to true to avoid to display an alert about pending customer links
   *  - fetchCustomerLinks if true we also fetch and set customer linked to the store
   *  - noContract if true we don't fetch the customer contract
   *  - reset if true empty customers store before action
   */
  public setCustomer(paramObj: {
    customerToken?: string;
    customerIdLinked?: number;
    noUpdateMode?: boolean;
    noAskForValidationCustomerLinks?: boolean;
    fetchCustomerLinks?: boolean;
    noContract?: boolean;
    reset?: boolean;
  }): Observable<any> {
    const {
      customerToken,
      customerIdLinked,
      noUpdateMode,
      noAskForValidationCustomerLinks,
      fetchCustomerLinks,
      noContract,
      reset,
    } = paramObj;

    if (reset) {
      this.store.resetCustomerData();
    }
    const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');

    const params: { api_token?: string; customer_id_linked?: number } = {};
    if (customerToken) {
      params.api_token = customerToken;
    }
    if (customerIdLinked) {
      params.customer_id_linked = customerIdLinked;
    }

    let apiResponseTmp: ApiResponse;

    return this.wsSrv.requestCustomer(params, true).pipe(
      mergeMap((apiResponse: ApiResponse) => {
        apiResponseTmp = apiResponse;
        const noContractParam = apiResponse.master.customer_type_id === CUSTOMER_TYPE_ID_EMPLOYEE ? true : noContract;

        return forkJoin([
          this.setContract(customerToken, noContractParam),
          this.store.getCustomers().pipe(first()),
          this.store.getAuthData().pipe(first()),
          this.store.get(this.appName + '_setNotifications').pipe(first()),
        ]);
      }),
      mergeMap(
        ([contract, customers, authData, setNotifications]: [
          MandateContract,
          { [id: string]: StoredCustomer },
          AuthState,
          boolean
        ]) => {
          const apiResponse = apiResponseTmp;

          if (authData && authData.customerId !== apiResponse.master.id) {
            delete apiResponse.master.api_token;
          }

          const data: StoredCustomer = {
            customer: _.cloneDeep(apiResponse.master) as Customer,
            customerLinks: undefined,
            customerLinkReceived: undefined,
            contract,
          };

          data.customer.language = apiResponse.language.iso;
          data.customer.agencies = apiResponse.agencies;
          data.customer.default_address = apiResponse.default_address;
          data.customer.bank_account = apiResponse.bank_account;
          data.customer.items = apiResponse.items;

          if (apiResponse.link) {
            if (data.customer.id === authData.customerId) {
              const customerLinksStoredAvailable =
                (customers[data.customer.id] &&
                  customers[data.customer.id].customerLinks &&
                  customers[data.customer.id].customerLinks.available) ||
                [];
              data.customerLinks = this.setCustomerLinks(
                apiResponse.link,
                customerLinksStoredAvailable,
                setNotifications
              );
            } else {
              data.customerLinks = this.setCustomerLinks(apiResponse.link, null, setNotifications);
            }
          }

          if (apiResponse.link && apiResponse.link.received_only && !noAskForValidationCustomerLinks) {
            data.customerLinkReceived = this.setCustomerLinkReceivedData(apiResponse.link.received_only);
          }

          this.store.setCustomer(data);

          if (!noUpdateMode) {
            this.setModeDisplay(apiResponse);
          }

          const obs$: Array<Observable<any>> = [];

          if (fetchCustomerLinks) {
            const customerIdsLinked = _.map(data.customerLinks.available, 'customer_id_linked');

            _.forEach(customerIdsLinked, idLinked => {
              obs$.push(
                this.setCustomer({
                  customerToken: authData.customerToken || customerToken,
                  customerIdLinked: idLinked,
                  noUpdateMode: true,
                  noAskForValidationCustomerLinks: false,
                  fetchCustomerLinks: false,
                  noContract: false,
                })
              );
            });
          }

          return forkJoin([...obs$, of(apiResponse)]);
        }
      ),
      mergeMap(result => {
        if (fetchCustomerLinks) {
          return this.buildMembers(result.pop());
        } else {
          return of(null);
        }
      }),
      map((members: CustomerFamilyMember[]) => {
        if (members) {
          return this.store.setMembers(members);
        }
        return true;
      })
    );
  }

  /**
   * Return an observable of Members array of logged in customer
   */
  public getMembers(): Observable<CustomerFamilyMember[]> {
    return this.store.getMembers().pipe(map(members => _.cloneDeep(members)));
  }

  /**
   * login a BO user as a customer in PWA
   * @param userToken BO user token
   * @param customerId customer ID to login
   */
  public loginUserAsCustomer(userToken: string, customerId: number): Observable<Customer> {
    return this.wsSrv
      .postLoginUserAsCustomer({
        user_token: userToken,
        customer_id: customerId,
      })
      .pipe(
        mergeMap((apiResponse: LoginUserAsCustomerApiResponse) => {
          const customerToken = apiResponse.api_token;
          const params = {
            api_token: customerToken,
          };

          return this.wsSrv.requestCustomer(params, true).pipe(pluck('master'));
        }),
        tap(customer => {
          this.authSrv.loginUserAsCustomer(customer);
        })
      );
  }

  /**
   * Returns links received for customer
   * @param customerId customer ID
   */
  public getCustomerLinkReceived(customerId: number): Observable<LinkRelationItem[]> {
    return this.store.getCustomer(customerId).pipe(
      filter(val => !!val),
      map(storedCustomer => {
        return storedCustomer ? storedCustomer.customerLinkReceived : null;
      })
    );
  }

  /**
   * Set selected member to the store
   * @param member family member or employee
   */
  public selectMember(member: CustomerFamilyMember): void {
    this.store.setSelectedMember(member);
  }

  /**
   * Return an observable of selected member family
   */
  public getSelectedMember(): Observable<CustomerFamilyMember | Customer> {
    return this.store.getSelectedMember();
  }

  /**
   * Return an observable of customer's bank account data
   * @param customerId customer ID
   */
  public getCustomerBank(customerId: number): Observable<CustomerBank> {
    return this.store.getCustomerBank(customerId);
  }

  /**
   * Return an observable of customer's items data
   * @param customerId customer's id
   */
  public getCustomerItems(customerId): Observable<{ [id: string]: ItemTemplateFO }> {
    return this.store.getCustomerItems(customerId);
  }

  /**
   * Return an observable of customer's items data filtered by item_template_key
   * @param customerId customer ID
   * @param itemTemplateKey item template key
   */
  public getCustomerItemsByItemTemplateKey(customerId: number, itemTemplateKey: string): Observable<ItemTemplateFO[]> {
    return this.store.getCustomerItems(customerId).pipe(
      first(),
      mergeMap(items => {
        const customerItemsArr: Array<ItemTemplateFO> = _.filter(items, {
          item_template_key: itemTemplateKey,
        });
        return of(customerItemsArr);
      })
    );
  }

  /**
   * Return an observable of customer's address
   * @param customerId customer ID
   */
  public getCustomerDefaultAddress(customerId: number): Observable<DefaultAddress> {
    return this.store.getCustomerDefaultAddress(customerId);
  }

  /**
   * Send SMS code for customer validation
   * @param code code received by SMS
   * @param customer customer
   */
  public validateCustomer(code: string, customerToken: string): Observable<boolean> {
    return this.wsSrv.postValidateCustomer(code, { api_token: customerToken }).pipe(map((success: boolean) => success));
  }

  /**
   * Ask BO to check login provided and send an SMS with validation code
   * @param login customer login
   * @param agencyId agency's id
   */
  public resetPasswordLoginValidation(login: string, agencyId?: number): Observable<any> {
    return this.wsSrv
      .postResetPasswordLoginValidation({ login }, true, agencyId)
      .pipe(map((apiResponse: ApiResponse) => apiResponse));
  }

  /**
   * Change customer password with SMS code
   * @param code code received by SMS on login verification
   * @param login customer login
   * @param password new customer password
   * @param passwordConfirmation new customer password confirmation
   * @param agencyId customer agency id
   */
  public resetCustomerPassword(
    code: string,
    login: string,
    password: string,
    passwordConfirmation: string,
    agencyId: number
  ): Observable<any> {
    return this.wsSrv
      .putResetCustomerPasswordWithSMS(
        {
          login,
          password,
          code,
          password_confirmation: passwordConfirmation,
        },
        true,
        agencyId
      )
      .pipe(map((apiResponse: ApiResponse) => apiResponse));
  }

  /**
   * Change customer password
   * @param oldPassword the old password
   * @param password the new password
   * @param passwordConfirmation new password confirmation
   * @param customerId customer id
   */
  public changeCustomerPassword(
    oldPassword: string,
    password: string,
    passwordConfirmation: string,
    customerId: number
  ): Observable<any> {
    return this.wsSrv
      .putResetCustomerPassword({
        old_password: oldPassword,
        password,
        password_confirmation: passwordConfirmation,
        customer_id_linked: customerId,
      })
      .pipe(map((apiResponse: ApiResponse) => apiResponse));
  }

  /**
   * Register new customer to the BO and return Customer object
   * @param customer customer
   * @param agencyId agency id
   */
  public registerCustomer(customer: Customer, agencyId?: number): Observable<Customer> {
    return this.wsSrv
      .postRegisterCustomer(customer, agencyId)
      .pipe(map((apiResponse: ApiResponse) => apiResponse.customer));
  }

  /**
   * Return an observable of true value  if employee already exists
   * @param lastname customer's last name
   * @param firstname customer's first name
   * @param dob customer's date of birth
   * @param customerTypeId customer type id
   * @param customerId customer's id
   * @param apiToken current customer's apiToken
   */
  public customerExists(
    lastname: string,
    firstname: string,
    dob: string,
    customerTypeId: number,
    customerId: number,
    apiToken
  ): Observable<boolean> {
    const params = { customer_id_linked: customerId };

    if (apiToken) {
      // @ts-ignore
      params.api_token = apiToken;
    }

    return this.wsSrv
      .requestCustomerExists(
        {
          customerTypeId,
          lastname,
          firstname,
          dob,
        },
        params
      )
      .pipe(
        mergeMap(apiResponse => {
          return of(!!apiResponse.customer);
        })
      );
  }

  /**
   * Register a customerLink between two customers
   * @param customerLinkTypeId customer type id
   * @param customerIdLink customer link id
   * @param customerIdLinked customer link id making the link
   * @param apiToken current customer's apiToken
   * @param roleId role ID for employee customerLink
   */
  public registerCustomerLink(
    customerLinkTypeId: number,
    customerIdLink: number,
    customerIdLinked?: number,
    apiToken?: string,
    roleId?: number
  ): Observable<object> {
    const params = { customer_id_linked: customerIdLinked };
    const data = { customer_id_linked: customerIdLink };

    if (apiToken) {
      // @ts-ignore
      params.api_token = apiToken;
    }

    if (customerLinkTypeId) {
      // @ts-ignore
      data.customer_link_type_id = customerLinkTypeId;
    }

    if (roleId) {
      // @ts-ignore
      data.role_id = roleId;
    }

    return this.wsSrv.postCustomerLink(data, params).pipe(map((apiResponse: ApiResponse) => apiResponse.customer));
  }

  /**
   * Get a new pairing code from BO for the current customer only
   * @param customerId customer ID
   */
  public generatePairingCode(customerId: number): Observable<string> {
    const params = { customer_id_linked: customerId };

    return this.wsSrv.requestPairingCode(params).pipe(
      map((apiResponse: ApiResponse) => apiResponse.pairing_code),
      tap((pairingCode: string) => {
        this.store.setCustomerPairingCode(customerId, pairingCode);
      })
    );
  }

  /**
   * Return an observable of pairing code from BO request
   * @param customerIdLinked customer's id of pairing_code wanted
   */
  public getPairingCode(customerIdLinked: number): Observable<string> {
    const params = { customer_id_linked: customerIdLinked };

    return this.wsSrv.requestCustomer(params, true).pipe(pluck('master', 'pairing_code'));
  }

  /**
   * Delete customer link from BO
   * @param customerId customer's id
   * @param customerIdLinked customer linked id
   * @param isBidirectional if true, the link is remove for both customers
   */
  public deleteCustomerLink(
    customerId: number,
    customerIdLinked: number,
    isBidirectional: boolean
  ): Observable<boolean> {
    return this.wsSrv
      .postDeleteCustomerLink({
        customer_id: customerId,
        customer_id_linked: customerIdLinked,
        is_bidirectional: isBidirectional,
      })
      .pipe(
        map((apiResponse: ApiResponse) => {
          this.getMembers().subscribe();
          return apiResponse.success;
        })
      );
  }

  /**
   * Register a customerLink between two customers using a pairing code
   * @param customerLinkTypeId customer link type id
   * @param pairingCode pairing code provided
   * @param customerId  customer's id
   * @param isSignUpProcess true if action is made during sign up
   * @param apiToken current customer's apiToken
   * @param roleId role's id
   */
  public registerCustomerLinkByPairingCode(
    pairingCode: string,
    customerId: number,
    isSignUpProcess?: boolean,
    customerLinkTypeId?: number,
    apiToken?: string,
    roleId?: number
  ): Observable<boolean> {
    const params = { customer_id_linked: customerId };
    if (apiToken) {
      // @ts-ignore
      params.api_token = apiToken;
    }
    const data = { pairing_code: pairingCode };

    if (customerLinkTypeId) {
      // @ts-ignore
      data.customer_link_type_id = customerLinkTypeId;
    }

    if (roleId) {
      // @ts-ignore
      data.role_id = roleId;
    }

    return this.wsSrv.postCustomerLinkPairing(data, params).pipe(
      map((apiResponse: ApiResponse) => {
        if (!isSignUpProcess) {
          this.getMembers().subscribe();
        }
        return apiResponse.success;
      })
    );
  }

  /**
   * Update customer_link right read, write and delete and/or role_id
   * @param customerLinkId the customerLink id
   * @param customerIdLinked the customer linked id
   * @param roleId customer link role's id
   * @param rights read, write and delete rights
   */
  public updateCustomerLink(
    // TODO to comment  APP-595
    customerLinkId: number,
    customerIdLinked: number,
    roleId: number,
    rights?: { read: number; write: number; delete: number }
  ): Observable<boolean> {
    const params = {};
    if (customerIdLinked) {
      // @ts-ignore
      params.customer_id_linked = customerIdLinked;
    }
    return this.wsSrv
      .putCustomerLink(customerLinkId, params, roleId, rights)
      .pipe(map((apiResponse: ApiResponse) => apiResponse.success));
  }

  /**
   * Register customer's device
   * @param device the device used
   * @param customer the customer
   */
  public addCustomerDevice(device: any, customer: Customer): Observable<Customer> {
    return this.wsSrv.postAddCustomerDevice(device, { api_token: customer.api_token }).pipe(map(() => customer));
  }

  /**
   * Save Customer bank data to BO
   * @param bankData bank data of the customer
   * @param customerId customer's id
   */
  public saveCustomerBank(bankData: CustomerBank, customerId?: number): Observable<CustomerBank> {
    const params = { customer_id_linked: customerId };
    const observable = bankData.id
      ? this.wsSrv.putCustomerBank(bankData, params)
      : this.wsSrv.postCustomerBank(bankData, params);

    return observable.pipe(map((apiResponse: ApiResponse) => apiResponse.bank_account));
  }

  /**
   * Add Customer Address
   * @param address customer's address
   * @param customerId customer's id
   * @param apiToken current customer's apiToken
   */
  public putCustomerAddress(address: Address, customerId: number, apiToken?: string): Observable<Address> {
    const params = { customer_id_linked: customerId };
    if (apiToken) {
      // @ts-ignore
      params.api_token = apiToken;
    }
    return this.wsSrv
      .postCustomerAddress(address, params)
      .pipe(map((apiResponse: ApiResponse) => apiResponse.default_address));
  }

  /**
   * Ask for an SMS authentication to the BO
   * @param customerToken  customer's token
   */
  public sendSmsAuthentication(customerToken: string): Observable<number> {
    return this.wsSrv.postSendSmsAuthentication({ api_token: customerToken });
  }

  /**
   * Return an observable of cities array
   * @param zipcode city's postal code
   */
  public getCitiesFromZipCode(zipcode: string): Observable<Array<City>> {
    return this.wsSrv.requestCitiesFromZipCode(zipcode).pipe(
      map((result: Array<City>) => {
        if (!result.length) {
          throwError('COMMON.NO_DATA_FROM_ZIPCODE');
        }
        return result;
      })
    );
  }

  /**
   * Switch current user to employee or customer
   * depending of isPro flag
   * @param isPro flag  for mode pro activated
   */
  public switchModePro(isPro: boolean): void {
    const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');

    this.store
      .getAuthData()
      .pipe(
        filter(val => !_.isEmpty(val)),
        first(),
        mergeMap((authState: AuthState) => {
          return this.store.getCustomer(authState.customerId).pipe(
            filter(val => !_.isEmpty(val)),
            first()
          );
        }),
        mergeMap(storedCustomer => {
          // condition is the customerType we want to display in app
          const condition = isPro ? CUSTOMER_TYPE_ID_EMPLOYEE : CUSTOMER_TYPE_ID_PRIVATE;

          // if the customerType is already displayed then we stop the process
          if (storedCustomer.customer.customer_type_id === condition) {
            return of(null);
          }
          // this.apiSrv.cancelPendingRequests();

          // else we fetch the right customer to display
          const entity = _.find(storedCustomer.customerLinks.available, { customer_type_id: condition });

          if (entity) {
            return this.authSrv.loginOnSwitchMode(entity);
          }
          return of(null);
        })
      )
      .subscribe(
        () => {
          this.navCtrl.navigateRoot('/home').then(() => {});
        },
        err => {
          this.store.setIsProSelected(!isPro);
          this.notificationSrv.showError({ message: err });
        }
      );
  }

  /**
   * Return an observable of stored customer object
   * @param customerId customer ID
   */
  private getStoredCustomer(customerId?: number): Observable<StoredCustomer> {
    let storedCustomer$: Observable<StoredCustomer>;

    if (customerId) {
      storedCustomer$ = this.store.getCustomer(customerId);
    } else {
      // we return the logged in customer
      storedCustomer$ = this.store.getAuthData().pipe(
        first(),
        mergeMap(authState => this.store.getCustomer(authState.customerId))
      );
    }

    return storedCustomer$;
  }

  /**
   * Return observable of contract file's uri if native device else return observable of null
   * @param apiToken Api token of customer
   * @param noContract if true then we don't fetch contract
   */
  private setContract(apiToken: string, noContract: boolean): Observable<MandateContract> {
    const params = {};

    if (apiToken) {
      // @ts-ignore
      params.api_token = apiToken;
    }

    if (this.tools.isNative() && !noContract) {
      return this.wsSrv.requestContract(params).pipe(
        mergeMap(blob => this.documentSrv.storeFile(blob, 'CONTRACT', apiToken + '.pdf', 'pdf')),
        catchError(err => {
          this.tools.error('setContract Error', err);
          return of(null);
        })
      );
    } else {
      return of(null);
    }
  }

  /**
   * Set link received only for customer
   * @param receivedOnlyObject link relation items
   */
  private setCustomerLinkReceivedData(receivedOnlyObject: { [id: number]: LinkRelationItem }): LinkRelationItem[] {
    return _.values(receivedOnlyObject);
  }

  /**
   * Format LinksAPI to Links and emit customerLinksSubject data
   * @param apiLinks links data
   * @param customerLinksStoredAvailable stored customerLinks (only if logged in customer)
   * @param setNotifications flag to add or not notifications
   */
  private setCustomerLinks(
    apiLinks: LinksAPI,
    customerLinksStoredAvailable?: LinkRelationItem[],
    setNotifications?: boolean
  ): {
    available: LinkRelationItem[];
    issued_only: LinkRelationItem[];
    received_only: LinkRelationItem[];
  } {
    const issuedOnly: LinkRelationItem[] = _.values(apiLinks.issued_only);
    const receivedOnly: LinkRelationItem[] = _.values(apiLinks.received_only);
    // @ts-ignore
    const available: LinkRelationItem[] = _.filter(apiLinks, (o, key) => {
      return !!parseInt(key, 10);
    });

    if (customerLinksStoredAvailable) {
      const availableLinksToAdd = this.tools.findNewEntities(
        _.keyBy(customerLinksStoredAvailable, 'id'),
        _.keyBy(available, 'id'),
        'id'
      );

      if (setNotifications) {
        const notifications: NotificationItem[] = [];

        _.forEach(availableLinksToAdd, item => {
          notifications.push({
            done: false,
            id: hash(item),
            icon: 'ios-link',
            mobileLink: '/profile/customer-links',
            tabletLink: '/profile',
            type: NotificationType.CustomerLink,
            text: 'CONTACT.NOTIFICATIONS.NEW_CUSTOMER_LINK',
          });
        });

        this.store.addNotifications(notifications);
      }
    }

    return { available, issued_only: issuedOnly, received_only: receivedOnly };
  }

  /**
   * Generate Members array of logged in customer
   * @param apiResponse HTTP response from BO
   */
  private buildMembers(apiResponse: ApiResponse): Observable<CustomerFamilyMember[]> {
    // Build a member object
    const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    const CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    const CUSTOMER_LINK_TYPE_HOLDING_COMPANY_SUBSIDIARY = this.constantSrv.getValueFromKey(
      'CUSTOMER_LINK_TYPE_HOLDING_COMPANY_SUBSIDIARY'
    );
    const CUSTOMER_LINK_TYPE_HUSBAND_WIFE = this.constantSrv.getValueFromKey('CUSTOMER_LINK_TYPE_HUSBAND_WIFE');

    const apiLinks: LinksAPI = _.cloneDeep(apiResponse.link);
    const customer: Customer = _.cloneDeep(apiResponse.master);
    // @ts-ignore
    const available: LinkRelationItem[] = _.filter(apiLinks, (o, key) => {
      return !!parseInt(key, 10);
    });
    const issuedOnly = _.map(_.values(apiLinks.issued_only), item => {
      item.issued_only = true;
      return item;
    });

    const isPrivateCustomer = customer.customer_type_id === CUSTOMER_TYPE_ID_PRIVATE;
    const customerTypeIdSelected = isPrivateCustomer ? CUSTOMER_TYPE_ID_PRIVATE : CUSTOMER_TYPE_ID_CORPORATE;
    const term = isPrivateCustomer ? 'COMMON.FAMILY' : 'COMMON.ALL_COMPANIES';

    const linkedMembers: LinkRelationItem[] = _.filter(_.concat(available, issuedOnly), {
      customer_type_id: customerTypeIdSelected,
    });
    const members: Array<CustomerFamilyMember> = [];

    if (_.size(linkedMembers)) {
      members.push({
        id: 0,
        api_token: '0',
        first_name: term,
        last_name: '',
        civility: 0,
        customer_link_type_id: 0,
        customer_type_id: isPrivateCustomer ? CUSTOMER_TYPE_ID_PRIVATE : CUSTOMER_TYPE_ID_CORPORATE,
        customer_link_type_name: '',
        sprite: null,
      } as CustomerFamilyMember);
    }
    // Add current user
    members.push({
      id: customer.id,
      first_name: customer.firstname || customer.lastname,
      last_name: customer.lastname,
      civility: customer.civility,
      customer_type_id: customer.customer_type_id,
      customer_link_type_id:
        customer.customer_type_id === CUSTOMER_TYPE_ID_CORPORATE
          ? CUSTOMER_LINK_TYPE_HOLDING_COMPANY_SUBSIDIARY
          : CUSTOMER_LINK_TYPE_HUSBAND_WIFE,
      customer_link_type_name: '',
      customer_dob: customer.dob,
      api_token: customer.api_token,
      pairing_code: customer.pairing_code,
      sprite: null,
    });

    // Go through all members and add them to the family
    if (_.size(linkedMembers)) {
      _.each(linkedMembers, item => {
        let addMember: boolean;

        switch (customer.customer_type_id) {
          case CUSTOMER_TYPE_ID_PRIVATE:
            // We don't want pro customer_type or employee
            addMember = item.customer_type_id === CUSTOMER_TYPE_ID_PRIVATE;
            break;
          case CUSTOMER_TYPE_ID_EMPLOYEE:
            // we allow only customer_type pro
            addMember = item.customer_type_id === CUSTOMER_TYPE_ID_CORPORATE;
            break;
          case CUSTOMER_TYPE_ID_CORPORATE:
            // we want to display only logged in user pro
            addMember = false;
            break;
        }

        if (addMember) {
          members.push({
            id: item.customer_id_linked,
            first_name: item.customer_firstname || item.customer_lastname,
            last_name: item.customer_lastname,
            civility: item.customer_civility,
            customer_type_id: item.customer_type_id,
            customer_agency_id: item.customer_agency_id,
            customer_link_type_id: item.customer_link_type_id,
            customer_link_type_name: item.customer_link_type_name,
            customer_dob: item.customer_dob,
            api_token: item.customer_token,
            permissions: item.permissions,
            read: item.read,
            write: item.write,
            delete: item.delete,
            pairing_code: item.pairing_code,
            issued_only: item.issued_only,
            sprite: null,
          } as CustomerFamilyMember);
        }
      });
    }

    return of(members);
  }

  /**
   * Set goforlife_isProSelected and goforlife_isSelectorDisplayed flag
   * goforlife_isProSelected = true if customer type is CUSTOMER_EMPLOYEE
   * goforlife_isSelectorDisplayed = true if customer has a customer linked employee or private depending on the case
   *
   * @param apiResponse HTTP response from BO
   */
  private setModeDisplay(apiResponse: ApiResponse): void {
    const CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    const CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
    const CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    const customerTypeId = apiResponse.master.customer_type_id;
    let isSelectorDisplayed;
    let isProSelected = false;

    if (customerTypeId === CUSTOMER_TYPE_ID_EMPLOYEE) {
      const isPrivateLinked = _.find(apiResponse.link, { customer_type_id: CUSTOMER_TYPE_ID_PRIVATE });
      isSelectorDisplayed = !!isPrivateLinked;
    }

    if (customerTypeId === CUSTOMER_TYPE_ID_EMPLOYEE || customerTypeId === CUSTOMER_TYPE_ID_CORPORATE) {
      isProSelected = true;
    } else {
      const isEmployeeLinked = _.find(apiResponse.link, { customer_type_id: CUSTOMER_TYPE_ID_EMPLOYEE });
      isSelectorDisplayed = !!isEmployeeLinked;
    }

    this.store.setIsProSelected(isProSelected);
    this.store.setIsProSelectorDisplayed(isSelectorDisplayed);
  }
}
