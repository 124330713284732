import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { AlertController, MenuController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '../../gfl-services/api.service';
import { AuthService } from '../../../authentication/services/auth.service';
import { NotificationService } from '../../gfl-services/notification.service';
import { StoreService } from '../../gfl-services/store.service';

@Component({
  selector: 'gfl-refresher-btn',
  templateUrl: './gfl-refresher-btn.component.html',
  styleUrls: ['./gfl-refresher-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GflRefresherBtnComponent implements OnInit {
  @Input() isOffline: boolean;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();
  public isRefreshing: boolean;

  constructor(
    private menuCtrl: MenuController,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private apiSrv: ApiService,
    private authSrv: AuthService,
    public notificationSrv: NotificationService,
    private store: StoreService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.store.getIsRefreshingData().subscribe(flag => {
      this.isRefreshing = flag;
      this.cd.detectChanges();
    });
  }

  /**
   * react on refresh action
   */
  public onRefresh(): void {
    this.refresh.emit();
    this.openRefreshPopup();
  }

  /**
   * open an alert to choose to refresh all stored data or not
   */
  public openRefreshPopup(): void {
    this.translate
      .get([
        'COMMON.REFRESH_APP',
        'COMMON.REFRESH_APP_CUSTOMER',
        'COMMON.REFRESH_APP_GLOBAL',
        'COMMON.BUTTON_CANCEL',
        'COMMON.BUTTON_VALIDATE',
      ])
      .subscribe(async result => {
        const alert = await this.alertCtrl.create({
          message: result['COMMON.REFRESH_APP'],
          inputs: [
            {
              type: 'radio',
              label: result['COMMON.REFRESH_APP_CUSTOMER'],
              value: 'customer',
              checked: true,
            },
            {
              type: 'radio',
              label: result['COMMON.REFRESH_APP_GLOBAL'],
              value: 'global',
              checked: false,
            },
          ],
          buttons: [
            {
              text: result['COMMON.BUTTON_CANCEL'],
              role: 'cancel',
              cssClass: 'gfl-alert-cancel-btn gfl-alert-btn',
              handler: () => {},
            },
            {
              text: result['COMMON.BUTTON_VALIDATE'],
              cssClass: 'gfl-alert-validate-btn gfl-alert-btn',
              handler: val => {
                this.apiSrv.cancelPendingRequests();

                switch (val) {
                  case 'customer':
                    return this.authSrv.refreshCustomerData();
                  case 'global':
                    return this.authSrv.refreshAllData();
                }
              },
            },
          ],
        });
        await alert.present();
      });
  }
}
