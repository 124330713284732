import { NgModule } from '@angular/core';
import { GflCoreModule } from '../../gfl-core/gfl-core.module';
import { IonicModule } from '@ionic/angular';
import { GflFormGeneratorModule } from '../../gfl-libraries/gfl-form-generator/gfl-form-generator.module';

import { LoginComponent } from './login/login.component';
import { ProgressSignupComponent } from './progress-signup/progress-signup.component';
import { SignatureFullScreenComponent } from './signature-full-screen/signature-full-screen.component';
import { SignupBrokerComponent } from './signup-broker/signup-broker.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { SignupSignatureComponent } from './signup-signature/signup-signature.component';
import { LoginCheckComponent } from './login-check/login-check.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignupErrorComponent } from './signup-error/signup-error.component';
import { ChangeCustomerComponent } from './change-customer/change-customer.component';
import { SmsCodeValidationComponent } from './sms-code-validation/sms-code-validation.component';

@NgModule({
  declarations: [
    LoginComponent,
    ProgressSignupComponent,
    SignatureFullScreenComponent,
    SignupBrokerComponent,
    SignupSignatureComponent,
    LoginCheckComponent,
    ResetPasswordComponent,
    SignupErrorComponent,
    ChangeCustomerComponent,
    SmsCodeValidationComponent,
  ],
  imports: [GflCoreModule, IonicModule, GflFormGeneratorModule.forRoot(), SignaturePadModule],
  entryComponents: [
    LoginComponent,
    ProgressSignupComponent,
    SignatureFullScreenComponent,
    SignupBrokerComponent,
    SignupErrorComponent,
  ],
  exports: [
    LoginComponent,
    ProgressSignupComponent,
    SignatureFullScreenComponent,
    SignupBrokerComponent,
    SignupSignatureComponent,
    LoginCheckComponent,
    ResetPasswordComponent,
    SignupErrorComponent,
    ChangeCustomerComponent,
    SmsCodeValidationComponent,
  ],
})
export class AuthentificationComponentsModule {}
