import { AfterViewInit, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActionSheetController, AlertController, ModalController, NavController, Platform } from '@ionic/angular';
import { ActivatedRoute, NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Plugins } from '@capacitor/core';
import * as _ from 'lodash';

import { BehaviorSubject, combineLatest, fromEvent, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { SliderService } from '../../../gfl-core/gfl-services/slider.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { CompanyService } from '../../../gfl-core/gfl-services/company.service';
import { NavigationService } from '../../../gfl-core/gfl-services/navigation.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ApiService } from '../../../gfl-core/gfl-services/api.service';
import { AgencyService } from '../../../gfl-core/gfl-services/agency.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { SecurityService } from '../../../gfl-core/gfl-services/security.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { AuthService } from '../../../authentication/services/auth.service';
import { InsuranceService } from '../../services/insurance.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';
import { PolicyService } from '../../services/policy.service';
import { MandateService } from '../../services/mandate.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { NetworkMonitorService } from '../../../gfl-core/gfl-services/network-monitor.service';
import { Customer, CustomerFamilyMember } from '../../../customer/models/customer.model';
import { PolicyFO } from '../../models/policy.model';
import { Agency, FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Slider, SliderType } from '../../../gfl-core/gfl-models/slider.model';
import { Mandate } from '../../models/mandate.model';
import { Contact } from '../../../gfl-core/gfl-models/contact.model';
import { Address } from '../../../gfl-core/gfl-models/address.model';
import { DocumentBO } from '../../../gfl-core/gfl-models/document.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';

import { PolicyAddComponent } from '../../components/policy-add/policy-add.component';
import { PolicyClaimComponent } from '../../components/policy-claim/policy-claim.component';
import { PolicyRefundSimpleComponent } from '../../components/policy-refund-simple/policy-refund-simple.component';
import { PolicyResumeComponent } from '../../components/policy-resume/policy-resume.component';
import { PolicyInvoicesComponent } from '../../components/policy-invoices/policy-invoices.component';
import { PolicyCgComponent } from '../../components/policy-cg/policy-cg.component';

const { Browser } = Plugins;

/**
 * This page displays the list of user's policies and mandates
 */
@Component({
  selector: 'gfl-policies',
  templateUrl: './policies.page.html',
  styleUrls: ['./policies.page.scss'],
  entryComponents: [],
})
export class PoliciesPage implements OnInit, AfterViewInit, OnDestroy {
  public members$: Observable<CustomerFamilyMember[]>;
  public selectedMember$: Observable<CustomerFamilyMember | Customer>;
  private policiesByParentInsuranceTypeDisplayed$: Observable<
    Array<{
      parentInsuranceTypeName: string;
      policies: Array<PolicyFO>;
    }>
  >;
  public policiesByParentInsuranceTypeDisplayed: Array<{
    parentInsuranceTypeName: string;
    policies: Array<PolicyFO>;
  }>;
  private policiesByParentInsuranceType$: Observable<
    Array<{
      parentInsuranceTypeName: string;
      policies: PolicyFO[];
    }>
  >;
  private lang$: Observable<string>;
  public policies$: Observable<PolicyFO[]>;
  public mandates$: Observable<Mandate[]>;
  private selectedPolicySubject = new BehaviorSubject<PolicyFO>(null);
  private selectedPolicy$ = this.selectedPolicySubject.asObservable();
  public sliders$: Observable<Slider[] | boolean>;
  private customer$: Observable<Customer>;
  public lastName$: Observable<string>;
  public style$: Observable<FrontTheme>;
  public acls$: Observable<Acls>;

  public agency: Agency;
  public contact: Contact;
  public address: Address;
  public isPro: boolean;
  public isOffline: boolean;
  public subscriptions: Subscription[] = [];
  public isLoading = true;
  public errorDisplay: boolean;
  public noDataDisplay: string;
  public expanded: boolean;
  private policyIdParam: number;

  public slidesOpts = {
    autoplay: {
      delay: 4000,
    },
    noSwipingClass: 'swiper-no-swiping',
  };

  readonly CUSTOMER_TYPE_ID_EMPLOYEE: number;

  /**
   * @ignore
   */
  constructor(
    private navCtrl: NavController,
    private customerSrv: CustomerService,
    private authSrv: AuthService,
    private policySrv: PolicyService,
    private store: StoreService,
    private translate: TranslateService,
    public tools: ToolsService,
    private modalCtrl: ModalController,
    private actionSheetCtrl: ActionSheetController,
    private alertCtrl: AlertController,
    private navigationSrv: NavigationService,
    private mandateSrv: MandateService,
    private documentSrv: DocumentService,
    public notificationSrv: NotificationService,
    private agencySrv: AgencyService,
    private aclsSrv: AclsService,
    private constantSrv: ConstantService,
    private insuranceSrv: InsuranceService,
    private companySrv: CompanyService,
    private sliderSrv: SliderService,
    private securitySrv: SecurityService,
    private apiSrv: ApiService,
    public platform: Platform,
    private activatedRoute: ActivatedRoute,
    public network: NetworkMonitorService,
    private ngZone: NgZone
  ) {
    this.CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.initAcls();

    // we want to get Mode state but we don't want screen to be
    // refreshed before new screen is displayed when user switches mode private/pro
    this.subscriptions.push(
      this.store.getIsProSelected().subscribe(isPro => {
        this.isPro = isPro;
      })
    );

    this.subscriptions.push(
      this.network.isOffline().subscribe(flag => {
        this.isOffline = flag;
      })
    );

    this.selectedMember$ = this.customerSrv.getSelectedMember().pipe(
      delay(0),
      filter(val => !!val), // we don't want undefined value
      shareReplay()
    );
    this.customer$ = this.customerSrv.getCustomer().pipe(filter(val => !_.isEmpty(val)));
    this.lastName$ = this.customer$.pipe(map(customer => customer.lastname));
    this.style$ = this.store.getStyle();
    this.sliders$ = this.sliderSrv.getSliders(SliderType.Private);
    this.lang$ = this.store.getLang();

    this.members$ = this.customerSrv.getMembers().pipe(
      delay(0), // avoid "ExpressionChangedAfterItHasBeenCheckedError" error
      map(members => {
        if (members.length) {
          // remove employee member
          _.remove(members, { customer_type_id: this.CUSTOMER_TYPE_ID_EMPLOYEE });
          // reset display attributes
          this.errorDisplay = false;
          this.noDataDisplay = null;

          this.customerSrv.selectMember(members[0]);
        }

        return members;
      }),
      catchError(err => {
        this.tools.error('InsuranceListPage initialize members$', err);
        this.errorDisplay = true;
        return of([]);
      })
    );

    this.getPoliciesAndMandates();

    this.fabsManagerInit();
  }

  /**
   * @ignore
   */
  ionViewDidEnter() {
    this.navigationSrv.set('InsuranceListPage');
  }

  /**
   * @ignore
   */
  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe(params => {
        if (!_.isEmpty(params)) {
          const key = Object.keys(params)[0];
          this.policySrv
            .getPolicyById(params[key])
            .pipe(
              delay(300),
              first()
            )
            .subscribe(policy => {
              if (this.policyIdParam !== params[key]) {
                this.policyIdParam = params[key];

                switch (key) {
                  case 'policyId':
                    this.openPolicyResumeModal(policy).then(() => {});
                    break;
                  case 'invoicePolicyId':
                    this.openInvoicesModal(policy).then(() => {});
                    break;
                }
              }
            });
        }
      })
    );
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Open page of slider link according to path
   * @param action what to do
   */
  public openSliderPage(action: string): void {
    if (action === 'safebox') {
      this.securitySrv.showCodePin('safebox');
    } else if (action === 'insurance-list') {
      this.showAlert();
    } else {
      this.tools.openPage(action);
    }
  }

  /**
   * Display an alert box asking user to choose a policy in order to declare a claim
   */
  public showAlert(): void {
    this.translate.get(['INSURANCE.ALERT.TITLE', 'INSURANCE.ALERT.DESCRIPTION']).subscribe(async result => {
      const alert = await this.alertCtrl.create({
        header: result['INSURANCE.ALERT.TITLE'],
        subHeader: result['INSURANCE.ALERT.DESCRIPTION'],
        buttons: ['OK'],
      });
      await alert.present();
    });
  }

  /**
   * Set is_active attribute to true to the policy
   * @param selectedPolicy selected policy
   */
  public selectPolicy(selectedPolicy: PolicyFO) {
    this.policySrv.closeAllFabs$.next();
    this.selectedPolicySubject.next(selectedPolicy);
  }

  /**
   * Open policy's detail page
   * @param policy a policy object
   */
  public openPolicyDetail(policy: PolicyFO) {
    const ref = this.tools.showLoader(true);
    this.navCtrl.navigateForward('/policies/policy-detail/' + policy.policy_id).then(() => {
      this.tools.hideLoader(ref);
    });
  }

  /**
   *  Open Add policy modal
   */
  public async openPolicyAddModal(): Promise<void> {
    const policyAddModal = await this.modalCtrl.create({ component: PolicyAddComponent });

    return await policyAddModal.present();
  }

  /**
   *  Open policy's resume page passing policyId and policy object
   *  @param policy a policy object
   */
  public async openPolicyResumeModal(policy: PolicyFO): Promise<void> {
    const isSickness = this.insuranceSrv.isSicknessType(policy.insurance_type_id);
    const isLife = this.insuranceSrv.isLifeType(policy.insurance_type_id);

    const isRefund = isSickness && !isLife;
    const policyResumeModal = await this.modalCtrl.create({
      component: PolicyResumeComponent,
      componentProps: {
        policyId: policy.policy_id,
        isRefund,
        acls$: this.acls$,
        style$: this.style$,
        isOffline: this.isOffline,
      },
    });

    policyResumeModal.onDidDismiss().then(data => {
      const param: { type: string; policy: PolicyFO } = data.data;

      if (param) {
        switch (param.type) {
          case 'onDisplayContract':
            this.openPolicyPdf(param.policy.documents);
            break;
          case 'onRequestClaim':
            this.openInsuranceClaimModal(param.policy);
            break;
          case 'onRequestRefund':
            this.openInsuranceRefundModal(param.policy);
            break;
        }
      }
    });

    return await policyResumeModal.present();
  }

  /**
   *  Open Insurance Claim modal
   *  @param policy a policy object
   */
  public async openInsuranceClaimModal(policy: PolicyFO): Promise<void> {
    if (this.isPro) {
      this.companySrv.getCompanyById(policy.company_id).subscribe(response => {
        // try to get url of claim web page
        const claimUrl =
          response.company && response.company.sinister_url && response.company.sinister_url[policy.insurance_type_id];
        if (claimUrl) {
          if (this.tools.isNative()) {
            Browser.open({ url: claimUrl }).catch(err => this.tools.error('Browser.open ' + claimUrl, err));
          } else {
            window.open(claimUrl);
          }
        } else {
          const companyName = policy.company.name;
          const phone = response.contact && response.contact.phone;
          this.callCompany(phone, companyName);
        }
      });
    } else {
      const insuranceClaimModal = await this.modalCtrl.create({
        component: PolicyClaimComponent,
        componentProps: {
          policy,
          acls$: this.acls$,
        },
      });

      await insuranceClaimModal.present();
    }
  }

  /**
   *  Open Insurance Refund modal
   *  @param policy a policy object
   */
  public async openInsuranceRefundModal(policy: PolicyFO): Promise<void> {
    if (this.isPro) {
      this.companySrv.getCompanyById(policy.company_id).subscribe(response => {
        // try to get url of claim web page
        const claimUrl =
          response.company && response.company.sinister_url && response.company.sinister_url[policy.insurance_type_id];
        if (claimUrl) {
          if (this.tools.isNative()) {
            Browser.open({ url: claimUrl }).catch(err => this.tools.error('Browser.open ' + claimUrl, err));
          } else {
            window.open(claimUrl);
          }
        } else {
          const companyName = policy.company.name;
          const phone = response.contact && response.contact.phone;
          this.callCompany(phone, companyName);
        }
      });
    } else {
      const insuranceRefundModal = await this.modalCtrl.create({
        component: PolicyRefundSimpleComponent,
        componentProps: {
          policy,
          acls$: this.acls$,
        },
      });

      return await insuranceRefundModal.present();
    }
  }

  /**
   * Open the policy document
   * @param  policyDocuments a map of document objects
   */
  public openPolicyPdf(policyDocuments: DocumentBO[]): void {
    const DOCUMENT_CATEGORY_ID_POLICY = this.constantSrv.getValueFromKey('DOCUMENT_CATEGORY_ID_POLICY');

    // Get document to display
    // @ts-ignore
    const documentDisplay: DocumentBO = _.find(policyDocuments, {
      document_category_id: DOCUMENT_CATEGORY_ID_POLICY,
      document_active: 1,
    });
    if (documentDisplay) {
      return this.documentSrv.openDocumentFile(documentDisplay.document_id);
    }

    this.notificationSrv.showError({
      message: 'INSURANCE.DETAIL.NO_DOCUMENT_ERROR',
    });
  }

  /**
   * Open policy.documents Page
   * @param policy policy object
   */
  public openDocumentsPage(policy): void {
    this.subscriptions.push(
      this.customerSrv
        .getSelectedMember()
        .pipe(first())
        .subscribe(selectedMember => {
          const navigationExtras: NavigationExtras = {
            state: {
              insuranceTypeId: policy.insurance_type_id,
              selectedMember,
            },
          };
          this.navCtrl
            .navigateForward('/policies/policy-documents/' + policy.policy_id, navigationExtras)
            .then(() => {});
        })
    );
  }

  /**
   *  Open policy's invoices modal passing policyId and policy object
   *  @param policy a policy object
   */
  public async openInvoicesModal(policy: PolicyFO): Promise<void> {
    const policyInvoiceModal = await this.modalCtrl.create({
      component: PolicyInvoicesComponent,
      componentProps: {
        policyId: policy.policy_id,
      },
    });

    policyInvoiceModal.onDidDismiss().then(() => {});

    return await policyInvoiceModal.present();
  }

  /**
   *  Open policy's CG modal passing policyId and policy object
   *  @param policy a policy object
   */
  public async openCgModal(policy: PolicyFO): Promise<void> {
    const policyCgModal = await this.modalCtrl.create({
      component: PolicyCgComponent,
      componentProps: {
        policyId: policy.policy_id,
      },
    });

    policyCgModal.onDidDismiss().then(() => {});

    return await policyCgModal.present();
  }

  /**
   * Confirm mandate deletion
   * @param policy policy object
   */
  public onDeleteMandate(policy: Mandate): void {
    this.translate
      .get(['INSURANCE.LIST.CONFIRM_DELETE_POLICY', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_DELETE'])
      .subscribe(async result => {
        if (!this.tools.isMobile()) {
          const alert = await this.alertCtrl.create({
            header: result['INSURANCE.LIST.CONFIRM_DELETE_POLICY'],
            buttons: [
              {
                text: result['COMMON.BUTTON_CANCEL'],
                cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
                role: 'cancel', // will always sort to be on the bottom
                handler: () => {},
              },
              {
                text: result['COMMON.BUTTON_DELETE'],
                cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
                handler: () => {
                  this.deleteMandate(policy);
                },
              },
            ],
          });
          await alert.present();
        } else {
          const action = await this.actionSheetCtrl.create({
            header: result['INSURANCE.LIST.CONFIRM_DELETE_POLICY'],
            buttons: [
              {
                text: result['COMMON.BUTTON_DELETE'],
                cssClass: 'gfl-alert-cancel-btn',
                icon: !this.platform.is('ios') ? 'trash' : null,
                handler: () => {
                  this.deleteMandate(policy);
                },
              },
              {
                text: result['COMMON.BUTTON_CANCEL'],
                role: 'cancel', // will always sort to be on the bottom
                icon: !this.platform.is('ios') ? 'close' : null,
                handler: () => {},
              },
            ],
          });
          await action.present();
        }
      });
  }

  /**
   * Set policies and mandates observables
   */
  private getPoliciesAndMandates(): void {
    this.isLoading = true;
    let currentSelectedPolicyId;
    const policiesAndMandates$ = this.policySrv.getPolicies().pipe(shareReplay());

    // observable of policies definition
    this.policies$ = combineLatest([
      policiesAndMandates$.pipe(distinctUntilChanged()),
      this.selectedMember$.pipe(distinctUntilChanged()),
      this.lang$.pipe(distinctUntilChanged()),
      this.store.getPoliciesAndMandatesLock().pipe(distinctUntilChanged()),
    ]).pipe(
      mergeMap(([policiesAndMandates, selectedMember, lang, lock]) => {
        this.policySrv.closeAllFabs$.next();
        this.isLoading = !!lock;
        this.errorDisplay = false;
        return of(
          _.filter(policiesAndMandates[selectedMember.id] && policiesAndMandates[selectedMember.id][lang], {
            is_mandate: false,
          })
        );
      }),
      catchError(err => {
        this.isLoading = false;
        this.errorDisplay = true;
        return of(null);
      })
    );

    // observable of mandates definition
    this.mandates$ = combineLatest([policiesAndMandates$, this.selectedMember$, this.lang$]).pipe(
      switchMap(([policiesAndMandates, selectedMember, lang]) => {
        const collection = policiesAndMandates[selectedMember.id] && policiesAndMandates[selectedMember.id][lang];
        return of(_.filter(collection, { is_mandate: true }));
      }),
      catchError(err => {
        return of(null);
      })
    );

    // observable of policiesByParentInsuranceType definition
    this.policiesByParentInsuranceType$ = this.policies$.pipe(
      switchMap(policies => of(_.cloneDeep(this.policySrv.orderPoliciesByParentInsuranceType(policies))))
    );

    // observable of policiesByParentInsuranceTypeDisplayed  definition
    // this observable is needed in order to manage policy selection in interface

    this.policiesByParentInsuranceTypeDisplayed$ = combineLatest([
      this.policiesByParentInsuranceType$,
      this.selectedPolicy$,
    ]).pipe(
      distinctUntilChanged(),
      mergeMap(([policiesByParentInsuranceType, selectedPolicy]) => {
        this.ngZone.run(() => {
          _.forEach(policiesByParentInsuranceType, item => {
            _.forEach(item.policies, policy => {
              if (selectedPolicy && selectedPolicy.policy_id !== currentSelectedPolicyId) {
                policy.is_active = policy.policy_id === selectedPolicy.policy_id;
              } else {
                policy.is_active = false;
              }
            });
          });
        });

        currentSelectedPolicyId = !selectedPolicy
          ? null
          : currentSelectedPolicyId === selectedPolicy.policy_id
          ? null
          : selectedPolicy.policy_id;

        if (!_.isEqual(this.policiesByParentInsuranceTypeDisplayed, policiesByParentInsuranceType)) {
          this.policiesByParentInsuranceTypeDisplayed = policiesByParentInsuranceType;
        }

        return of(policiesByParentInsuranceType);
      })
    );

    this.subscriptions.push(this.policiesByParentInsuranceTypeDisplayed$.subscribe());

    this.subscriptions.push(
      combineLatest([this.mandates$, this.policiesByParentInsuranceType$, this.selectedMember$]).subscribe(
        ([mandates, policiesByParentInsuranceType, selectedMember]) => {
          this.ngZone.run(() => {
            if (
              !policiesByParentInsuranceType.length &&
              mandates &&
              !mandates.length &&
              selectedMember &&
              // @ts-ignore
              !selectedMember.issued_only &&
              !this.isLoading
            ) {
              this.noDataDisplay = 'COMMON.NO_DATA';
            } else {
              this.noDataDisplay = null;
            }
          });
        },
        err => console.error('combineLatest', err)
      )
    );
  }

  /**
   * Remove mandate from BO
   * @param policy policy object
   */
  private deleteMandate(policy: Mandate) {
    this.tools.showLoader();

    // Delete mandate
    this.mandateSrv
      .deleteMandate(policy.id, { id: policy.customer_id })
      .pipe(
        switchMap(() => this.policySrv.setPoliciesAndMandates()),
        finalize(() => this.tools.hideLoader())
      )
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({ message: 'INSURANCE.LIST.POLICY_REMOVED' });
        },
        err => {
          this.tools.error('InsuranceListPage deleteMandate()', err);
          this.notificationSrv.showError({ message: err });
        }
      );
  }

  /**
   * Call the company contact
   * @param phoneNumber company phone number
   * @param companyName company name
   */
  public callCompany(phoneNumber: string, companyName: string): void {
    this.translate
      .get(['INSURANCE.CLAIM.CALL_ASSISTANCE', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_CALL'], {
        company: companyName,
        phone: phoneNumber,
      })
      .pipe(
        withLatestFrom(this.agencySrv.getAgency()),
        tap(async ([result, agency]) => {
          const alert = await this.alertCtrl.create({
            header: agency.name,
            message: result['INSURANCE.CLAIM.CALL_ASSISTANCE'],
            buttons: [
              {
                text: result['COMMON.BUTTON_CANCEL'],
                cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
                handler: () => {},
              },
              {
                text: result['COMMON.BUTTON_CALL'],
                cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
                handler: () => {
                  this.callAction(phoneNumber);
                },
              },
            ],
          });
          await alert.present();
        })
      )
      .subscribe();
  }

  /**
   * open a web window (works well with native device
   * @param phoneNumber phone number
   */
  private async callAction(phoneNumber: string): Promise<void> {
    window.open('tel:' + phoneNumber, '_system');
  }

  /**
   * Close all fabs if click target is not an insurance item
   */
  private fabsManagerInit(): void {
    this.subscriptions.push(
      fromEvent(document, 'click').subscribe((event: Event) => {
        let isInsuranceItem = false;

        _.forEach(event.composedPath(), (item: Element) => {
          isInsuranceItem = isInsuranceItem || (item.classList && item.classList.contains('insurance-item'));
        });

        if (!isInsuranceItem) {
          this.policySrv.closeAllFabs$.next();
        }
      })
    );
  }

  /**
   * Initialize Acls
   */
  private initAcls(): void {
    this.acls$ = this.customerSrv.getCustomerLinks().pipe(
      mergeMap(customerLinks => this.aclsSrv.getAclsForSelectedMember(customerLinks)),
      shareReplay()
    );
  }
}
