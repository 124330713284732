import { Component, Input, NgZone, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';

import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { SecurityService } from '../../../gfl-core/gfl-services/security.service';
import { CompareService } from '../../services/compare.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { CustomerService } from '../../../customer/services/customer.service';
import { Slider } from '../../../gfl-core/gfl-models/slider.model';
import { Customer, CustomerFamilyMember } from '../../../customer/models/customer.model';
import { PolicyFO } from '../../../policies/models/policy.model';
import { CompareListItem } from '../../models/compare.model';
import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';

@Component({
  selector: 'gfl-compare-list',
  templateUrl: './compare-list.component.html',
  styleUrls: ['./compare-list.component.scss'],
})
export class CompareListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() style$: Observable<FrontTheme>;
  @Input() acls: Acls;
  @Input() isPro: boolean;
  @Input() members$: Observable<CustomerFamilyMember[]>;
  @Input() activePage: string;
  @Input() selectedMember: CustomerFamilyMember | Customer;
  @Input() sliders$: Observable<Slider[] | boolean>;
  @Input() policies: PolicyFO[];
  public comparesByParentInsuranceType$: Observable<
    Array<{ parentInsuranceTypeName: string; compares: CompareListItem[] }>
  >;
  public errorDisplay: boolean;
  public noDataDisplay: string = null;
  private subscriptions: Subscription[] = [];
  public slidesOpts = {
    autoplay: {
      delay: 5000,
    },
    noSwipingClass: 'swiper-no-swiping',
  };
  public isLoading: boolean;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private navCtrl: NavController,
    private compareSrv: CompareService,
    private securitySrv: SecurityService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private notificationSrv: NotificationService,
    private store: StoreService,
    private customerSrv: CustomerService,
    private ngZone: NgZone
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.initialize();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedMember && changes.selectedMember.previousValue !== changes.selectedMember.currentValue) {
      this.comparesByParentInsuranceType$ = this.getCompares();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Emit the member ID selected from IHM
   * @param member a family member or employee
   */
  public selectMember(member: CustomerFamilyMember): void {
    this.customerSrv.selectMember(member);
  }

  /**
   * Open page of slider link according to path
   * @param path url path
   */
  public openSliderPage(path: string): void {
    if (path === 'safebox') {
      this.securitySrv.showCodePin('safebox');
    } else if (path === 'insurance-list') {
      this.showAlert();
    } else {
      this.openPage(path);
    }
  }

  /**
   * Navigate to the page corresponding to the path parameter
   * @param path data separated by /
   */
  public openPage(path: string): void {
    const data = path.split('/');

    let newPath = data[0];

    if (data[1]) {
      newPath += '?action=' + data[1];
    }
    const navigationExtras: NavigationExtras = {
      state: {
        isPro: this.isPro,
      },
    };
    this.navCtrl.navigateForward(newPath, navigationExtras).then(() => {});
  }

  /**
   * Display an alert box asking user to choose a policy in order to declare a claim
   */
  private showAlert(): void {
    this.translate.get(['INSURANCE.ALERT.TITLE', 'INSURANCE.ALERT.DESCRIPTION']).subscribe(async result => {
      const alert = await this.alertCtrl.create({
        header: result['INSURANCE.ALERT.TITLE'],
        subHeader: result['INSURANCE.ALERT.DESCRIPTION'],
        buttons: ['OK'],
      });
      await alert.present();
    });
  }

  /**
   * Initialize InsuranceType list with compares
   */
  private initialize(): void {
    if (this.selectedMember) {
      this.comparesByParentInsuranceType$ = this.getCompares();
    }
  }

  /**
   * Fetch insuranceTypesList
   */
  private getCompares(): Observable<{ parentInsuranceTypeName: string; compares: CompareListItem[] }[]> {
    return combineLatest([
      this.store.getLang(),
      this.compareSrv.getCompares(this.selectedMember && this.selectedMember.id),
      this.store.getComparesLock(),
    ]).pipe(
      mergeMap(([lang, comparesByLang, lock]) => {
        const compares = comparesByLang && comparesByLang[lang];
        if (!compares || this.selectedMember.id === 0) {
          this.isLoading = true;
        }

        const comparesByParentInsuranceType = this.compareSrv.orderCompareByParentInsuranceType(compares);
        this.noDataDisplay = lock ? '' : !comparesByParentInsuranceType.length ? 'COMPARE.LIST.NO_ITEM' : null;
        this.errorDisplay = false;
        this.isLoading = !!lock;

        if (!NgZone.isInAngularZone()) {
          this.ngZone.run(() => {
            this.noDataDisplay = lock ? '' : !comparesByParentInsuranceType.length ? 'COMPARE.LIST.NO_ITEM' : null;
            this.errorDisplay = false;
            this.isLoading = !!lock;
          });
        }
        return of(comparesByParentInsuranceType);
      }),
      catchError(err => {
        this.tools.error('CompareListPage getComparesInsuranceTypesList', err);
        this.isLoading = false;
        this.errorDisplay = true;
        return of(null);
      })
    );
  }
}
