import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import * as moment from 'moment';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, delay, map, shareReplay, withLatestFrom } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { CompareFO, ComparePoliciesFO, RowPremiumFO } from '../../models/compare.model';
import { GflThemeOptions } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form-options.model';
import { PolicyFO } from '../../../policies/models/policy.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { GflModeDisplayType } from '../../../gfl-libraries/gfl-form-generator/models/gfl-form.model';
import { StatusService } from '../../../gfl-core/gfl-services/status.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { CompareService } from '../../services/compare.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';

import { CompareListSelectComponent } from '../compare-list-select/compare-list-select.component';
import { CompareInsurimmoProcessComponent } from '../compare-insurimmo-process/compare-insurimmo-process.component';

@Component({
  selector: 'gfl-compare-display',
  templateUrl: './compare-display.component.html',
  styleUrls: ['./compare-display.component.scss'],
})
export class CompareDisplayComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * the id of compare to be displayed
   */
  @Input() compareId: number;

  @Input() agencyName: string;
  @Input() policies$: Observable<Array<PolicyFO>>;
  @Input() isPro: boolean;
  /**
   * Front theme style
   */
  @Input() style: FrontTheme;
  /**
   * Permissions definition object
   */
  @Input() acls: Acls;
  /**
   * Well formatted compare object
   */
  public compare$: Observable<CompareFO>;
  /**
   * an subscriptions array to be unsubscribed on component OnDestroyed event
   */
  private subscriptions: Subscription[] = [];
  /**
   * flag on true if form is edited
   */
  public isEditMode = false;
  /**
   * Mode display type
   */
  public modeDisplay: GflModeDisplayType;
  /**
   * theme options for main items generated by GflFieldGeneratorComponent
   */
  public mainItemTheme: GflThemeOptions = {};
  /**
   * theme options for sub items generated by GflFieldGeneratorComponent
   */
  public subItemTheme: GflThemeOptions = {};
  /**
   * a list of colors to be used to display policy offers
   */
  public policiesBorderColor: string[];

  public errorDisplay: boolean;
  public noDataDisplay: string;
  public isLoading: boolean;

  public isTerminatedCompare: boolean;

  public zoom = false;
  public selectedInsuredObject = 0;

  public compareForm: { [id: string]: FormGroup } = {};

  readonly COMPARE_TERMINATED: number;
  readonly POLICY_OFFER_REFUSED_CUSTOMER: number;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private compareSrv: CompareService,
    public translate: TranslateService,
    private statusSrv: StatusService,
    private modalCtrl: ModalController,
    private store: StoreService
  ) {
    this.COMPARE_TERMINATED = this.statusSrv.getIdFromKey('COMPARE_TERMINATED');
    this.POLICY_OFFER_REFUSED_CUSTOMER = this.statusSrv.getIdFromKey('POLICY_OFFER_REFUSED_CUSTOMER');
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.setModeDisplay();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.compareId && changes.compareId.currentValue !== changes.compareId.previousValue) {
      this.setData();
    }

    if (changes.style && changes.style.currentValue && changes.style.currentValue !== changes.style.previousValue) {
      this.initialize();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * open action modal about document offer
   * @param policy policy object
   */
  public async displayOfferModal(policy: ComparePoliciesFO | RowPremiumFO): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: CompareListSelectComponent,
      componentProps: {
        policy,
        compare$: this.compare$,
        style: this.style,
        acls: this.acls,
        isTerminatedCompare: this.isTerminatedCompare,
      },
    });
    await modal.present();
  }

  public async displayConditionsInsurimmo(policy: ComparePoliciesFO | RowPremiumFO): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: CompareInsurimmoProcessComponent,
      componentProps: {
        offer: policy,
        agencyName: this.agencyName,
        compare$: this.compare$,
        style: this.style,
        acls: this.acls,
      },
    });
    await modal.present();
  }

  /**
   * Activate zoom display
   */
  public zoomToggle(): void {
    this.zoom = !this.zoom;
  }

  /**
   * Update selectedInsuredObject attribute
   * @param event ion-select change event
   */
  public ioToggle(event: CustomEvent): void {
    this.selectedInsuredObject = event.detail.value;
  }

  /**
   * Initialize compare property with hot reload on lang changes
   */
  private initialize(): void {
    this.subscriptions.push(
      this.store.getLang().subscribe(() => {
        if (this.compareId) {
          this.setData();
        }
      })
    );

    this.mainItemTheme = {
      tabletLabelTxtColor: this.style.color_compareItemLabel_txt,
      tabletContentTxtColor: this.style.color_compareItemValue_txt,
      mobileLabelTxtColor: this.style.color_compareItemLabel_txt,
      mobileContentTxtColor: this.style.color_compareItemValue_txt,
    };

    this.subItemTheme = {
      tabletLabelTxtColor: this.style.color_compareSubItemLabel_txt,
      tabletContentTxtColor: this.style.color_compareSubItemValue_txt,
      mobileLabelTxtColor: this.style.color_compareSubItemLabel_txt,
      mobileContentTxtColor: this.style.color_compareSubItemValue_txt,
    };

    this.policiesBorderColor = [
      this.style.color_policy_1_border,
      this.style.color_policy_2_border,
      this.style.color_policy_3_border,
      this.style.color_policy_4_border,
      this.style.color_policy_5_border,
      this.style.color_policy_6_border,
      this.style.color_policy_7_border,
      this.style.color_policy_8_border,
      this.style.color_policy_9_border,
      this.style.color_policy_10_border,
    ];
  }

  /**
   * Set modeDisplay to mobile or tablet
   */
  private setModeDisplay(): void {
    this.modeDisplay = this.tools.setModeDisplay();
  }

  /**
   * Set compare property
   */
  private setData(): void {
    this.isLoading = true;

    this.compare$ = this.compareSrv.getCompare(this.compareId).pipe(
      delay(0),
      withLatestFrom(this.store.getComparesLock()),
      map(([compare, lock]) => {
        this.noDataDisplay = lock ? null : !compare ? 'COMPARE.ERRORS.NO_COMPARE' : null;
        this.isLoading = !!lock;

        const compareFormatted = _.cloneDeep(compare);

        if (compare) {
          // format  month-year
          compareFormatted.created_at_reformat = moment(compareFormatted.created_at).format('MMMM YYYY');
          // set flag for terminated compare
          this.isTerminatedCompare = compareFormatted.current_status_id === this.COMPARE_TERMINATED;
          _.forEach(compareFormatted.insuredObjects, (insuredObject, idx) => {
            this.initForm('' + idx);
          });
        }

        return compareFormatted;
      }),
      shareReplay(),
      catchError(err => {
        this.tools.log('err', err);
        this.errorDisplay = true;
        return of(null);
      })
    );
  }

  /**
   * Generate dynamic form
   */
  private initForm(insuredObjectId: string): void {
    this.compareForm['insuredObject-' + insuredObjectId] = new FormGroup({});
  }
}
