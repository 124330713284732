import { Component, Input, OnInit } from '@angular/core';
import { ChatItem } from '../../models/contacts.model';

@Component({
  selector: 'gfl-chat-item-customer',
  templateUrl: './chat-item-customer.component.html',
  styleUrls: ['./chat-item-customer.component.scss'],
})
export class ChatItemCustomerComponent implements OnInit {
  @Input() chatItem: ChatItem;

  constructor() {}

  ngOnInit() {}
}
