import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Router } from '@angular/router';

import { Observable, of, Subscription } from 'rxjs';
import { first, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Customer, CustomerFamilyMember, CustomerFormType } from '../../models/customer.model';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';
import { StoreService } from '../../../gfl-core/gfl-services/store.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { CustomerService } from '../../services/customer.service';
import { AclsService } from '../../../gfl-core/gfl-services/acls.service';

@Component({
  selector: 'gfl-customer-create',
  templateUrl: './customer-create.component.html',
  styleUrls: ['./customer-create.component.scss'],
})
export class CustomerCreateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() customerFormType: CustomerFormType;
  @Input() customerTypeId: number;
  @Input() customerLinkTypeId: number;
  @Input() hideCloseButton: boolean;
  @Input() selectedMember$: Observable<CustomerFamilyMember | Customer>;

  public style$: Observable<FrontTheme>;

  public title: string;
  public customerLinked$: Observable<Customer>;
  private subscriptions: Subscription[] = [];

  readonly CUSTOMER_TYPE_ID_PRIVATE: number;
  readonly CUSTOMER_TYPE_ID_CORPORATE: number;
  readonly CUSTOMER_TYPE_ID_EMPLOYEE: number;

  /**
   * @ignore
   */
  constructor(
    public tools: ToolsService,
    private constantSrv: ConstantService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private store: StoreService,
    private notificationSrv: NotificationService,
    private modalCtrl: ModalController,
    private customerSrv: CustomerService,
    private aclsSrv: AclsService,
    private router: Router
  ) {
    this.CUSTOMER_TYPE_ID_PRIVATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_PRIVATE');
    this.CUSTOMER_TYPE_ID_CORPORATE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_CORPORATE');
    this.CUSTOMER_TYPE_ID_EMPLOYEE = this.constantSrv.getValueFromKey('CUSTOMER_TYPE_ID_EMPLOYEE');
  }

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.customerLinked$ = this.selectedMember$.pipe(
      withLatestFrom(this.customerSrv.getCustomersList([])),
      mergeMap(([selectedMember, customers]) => {
        // set the customerLinked and the customerLinkTypeId properties
        if (
          this.customerTypeId === this.CUSTOMER_TYPE_ID_PRIVATE &&
          selectedMember.customer_type_id === this.CUSTOMER_TYPE_ID_EMPLOYEE
        ) {
          this.customerLinkTypeId = this.constantSrv.getValueFromKey('CUSTOMER_LINK_TYPE_EMPLOYEE_PRIVATE_DEFAULT');
        }

        return of(customers[selectedMember.id]);
      })
    );

    this.style$ = this.store.getStyle();
    this.setTitle();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customerTypeId) {
      this.setTitle();
    }
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Display a success message
   * @param customerLink customer linked
   */
  public actionSuccess(customerLink: Customer): void {
    let message: string;

    switch (this.customerTypeId) {
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        message = 'PROFILE.ADD_PRO.NEW_SUCCESS';
        break;
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        message = 'PROFILE.ADD_EMPLOYEE.NEW_SUCCESS';
        break;
      default:
        message = 'PROFILE.ADD_PRIVATE.NEW_SUCCESS';
        break;
    }
    this.notificationSrv.showSuccess({ message });
    this.store
      .getAuthData()
      .pipe(
        first(),
        switchMap(authData =>
          this.customerSrv.setCustomer({
            customerToken: authData.customerToken,
            customerIdLinked: null,
            noUpdateMode: false,
            noAskForValidationCustomerLinks: false,
            fetchCustomerLinks: true,
            noContract: false,
          })
        ),
        switchMap(() => this.aclsSrv.setAcls())
      )
      .subscribe(() => {
        this.tools.hideLoader();
        this.close();
      });
  }

  /**
   * Display an error notification
   * @param err error
   */
  public actionError(err: any): void {
    try {
      if (err && typeof err === 'object' && err.indexOf('email') > -1) {
        this.notificationSrv.showError({ message: 'SIGNUP.ERRORS.EMAIL' });
      } else if (err && typeof err === 'object') {
        this.notificationSrv.showError({ message: err });
      }
      this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
      this.tools.hideLoader();
    } catch (error) {
      this.tools.error('Customer-create component actionError error', err);
      this.notificationSrv.showError({ message: 'API.ERROR_MESSAGE' });
      this.tools.hideLoader();
    }
  }

  /**
   * Display an error notification if customer already exists
   */
  public actionCustomerAlreadyExisting(): void {
    this.notificationSrv.showError({
      message: 'PROFILE.ADD_PRO.ALREADY_EXISTS.TITLE',
    });
  }

  /**
   * Close current view
   */
  public close(): void {
    if (this.tools.isMobile()) {
      this.router.navigateByUrl('/profile').then();
    } else {
      this.modalCtrl.dismiss().then(() => {});
      this.router.navigateByUrl('/profile').then();
    }
  }

  /**
   * Set title
   */
  private setTitle(): void {
    switch (this.customerTypeId) {
      case this.CUSTOMER_TYPE_ID_CORPORATE:
        this.title = 'PROFILE.ADD_PRO.ADD_NEW';
        break;
      case this.CUSTOMER_TYPE_ID_EMPLOYEE:
        this.title = 'PROFILE.ADD_EMPLOYEE.ADD_NEW';
        break;
      default:
        this.title = 'PROFILE.ADD_PRIVATE.ADD_NEW';
        break;
    }
  }
}
