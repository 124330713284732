import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { NotificationItem } from '../../models/contacts.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ContactsService } from '../../services/contacts.service';

import { ChatDisplayComponent } from '../chat-display/chat-display.component';

@Component({
  selector: 'gfl-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss'],
})
export class NotificationItemComponent implements OnInit {
  @Input() notification: NotificationItem;
  @Output() clicked: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    public tools: ToolsService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private contactSrv: ContactsService
  ) {}

  ngOnInit() {}

  public navigate(notification: NotificationItem): void {
    const route = this.tools.isMobile() ? this.notification.mobileLink : this.notification.tabletLink;
    const [url, param] = route.split('?');
    const queryParamArr = param && param.split('=');
    const queryParamObj = { queryParams: {} };

    if (queryParamArr && queryParamArr[0] === 'chat') {
      this.openChatModal().then();
    } else {
      if (queryParamArr && queryParamArr.length > 1) {
        queryParamObj.queryParams[queryParamArr[0]] = queryParamArr[1];
      }

      this.navCtrl.navigateForward(url, queryParamObj).then(() => {
        this.clicked.emit();
      });
    }

    this.contactSrv.updateNotification(notification).subscribe();
  }

  /**
   * Open ChatDisplayComponent in a modal
   */
  private async openChatModal() {
    this.close();

    const modal = await this.modalCtrl.create({
      component: ChatDisplayComponent,
    });
    await modal.present();
  }

  /**
   * Close current view
   */
  public close(): void {
    this.modalCtrl.dismiss().then(() => {});
  }
}
