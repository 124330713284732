import { createReducer, on } from '@ngrx/store';
import { Document, DocumentCategoryItem } from '../gfl-core/gfl-models/document.model';
import { DocumentsActions } from './action.types';

export interface DocumentsState {
  documents: { [id: string]: Document };
  categoriesByInsuranceType: {
    items: DocumentCategoryItem[];
  };
}

export const initialDocumentsState: DocumentsState = {
  documents: {},
  categoriesByInsuranceType: {
    items: [],
  },
};

const _documentsReducer = createReducer(
  initialDocumentsState,

  on(DocumentsActions.reset, () => {
    return initialDocumentsState;
  }),

  on(DocumentsActions.initialize, (state, action) => {
    return { ...action.documentsState };
  }),

  on(DocumentsActions.setDocumentCategories, (state, action) => {
    const categoriesByInsuranceType = {
      items: action.categoriesByInsuranceType,
    };

    return {
      ...state,
      categoriesByInsuranceType,
    };
  }),

  on(DocumentsActions.setDocuments, (state, action) => {
    return {
      ...state,
      documents: action.documents,
    };
  })
);

export function documentsReducer(state, action) {
  return _documentsReducer(state, action);
}
