import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ActionSheetController, AlertController, NavController, Platform } from '@ionic/angular';

import { Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, first, map, shareReplay } from 'rxjs/operators';

import { FrontTheme } from '../../../gfl-core/gfl-models/agency.model';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { Mandate } from '../../models/mandate.model';
import { Customer } from '../../../customer/models/customer.model';
import { MandateService } from '../../services/mandate.service';
import { NotificationService } from '../../../gfl-core/gfl-services/notification.service';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';

@Component({
  selector: 'gfl-mandates-resume',
  templateUrl: './mandates-resume.component.html',
  styleUrls: ['./mandates-resume.component.scss'],
})
export class MandatesResumeComponent implements OnInit, OnChanges {
  @Input() style: FrontTheme;
  @Input() customer: Customer;
  @Input() acls: Acls;
  @Output() displayAddForm = new EventEmitter<any>();
  @Input() signupProcess: boolean;

  public mandates$: Observable<Array<Mandate>>;
  public subscriptions: Array<Subscription> = [];
  public errorDisplay: boolean;
  public noDataDisplay: string;

  /**
   * @ignore
   */
  constructor(
    public navCtrl: NavController,
    private mandateSrv: MandateService,
    private translate: TranslateService,
    private alertCtrl: AlertController,
    private actionSheetCtrl: ActionSheetController,
    public tools: ToolsService,
    private notificationSrv: NotificationService,
    public platform: Platform,
    private ref: ChangeDetectorRef
  ) {}

  /**
   * @ignore
   */
  ngOnInit(): void {
    this.subscriptions.push(
      this.mandateSrv.getUpdateMandates().subscribe(() => {
        this.getMandates();
      })
    );

    this.mandateSrv.setUpdateMandates();
  }

  /**
   * @ignore
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.customer) {
      this.mandateSrv.setUpdateMandates();
    }
  }

  /**
   * Emit onDisplayAddForm event
   */
  public displayAddFormAction(): void {
    this.displayAddForm.next();
  }

  /**
   * Set an observable of mandates array
   */
  private getMandates(): void {
    const customerApiToken = this.signupProcess && this.customer.api_token;

    this.mandates$ = this.mandateSrv.getMandates(this.customer.id, customerApiToken).pipe(
      first(),
      map(mandates => {
        this.noDataDisplay = !mandates.length ? 'COMMON.NO_DATA' : null;
        this.errorDisplay = false;

        return mandates;
      }),
      catchError(() => {
        this.errorDisplay = true;
        this.noDataDisplay = null;

        return of(null);
      }),
      shareReplay()
    );

    this.ref.markForCheck();
  }

  /**
   * Confirm mandate deletion
   * @param mandate nm
   */
  public onDeleteMandate(mandate: Mandate): void {
    this.translate
      .get(['INSURANCE.LIST.CONFIRM_DELETE_POLICY', 'COMMON.BUTTON_CANCEL', 'COMMON.BUTTON_DELETE'])
      .subscribe(async result => {
        if (!this.tools.isMobile()) {
          const alert = await this.alertCtrl.create({
            header: result['INSURANCE.LIST.CONFIRM_DELETE_POLICY'],
            buttons: [
              {
                text: result['COMMON.BUTTON_CANCEL'],
                cssClass: 'gfl-alert-btn gfl-alert-cancel-btn',
                role: 'cancel', // will always sort to be on the bottom
                handler: () => {},
              },
              {
                text: result['COMMON.BUTTON_DELETE'],
                cssClass: 'gfl-alert-btn gfl-alert-validate-btn',
                handler: () => {
                  this.deleteMandate(mandate);
                },
              },
            ],
          });
          await alert.present();
        } else {
          const actionSheet = await this.actionSheetCtrl.create({
            subHeader: result['INSURANCE.LIST.CONFIRM_DELETE_POLICY'],
            buttons: [
              {
                text: result['COMMON.BUTTON_DELETE'],
                cssClass: 'gfl-alert-cancel-btn',
                icon: !this.platform.is('ios') ? 'trash' : null,
                handler: () => {
                  this.deleteMandate(mandate);
                },
              },
              {
                text: result['COMMON.BUTTON_CANCEL'],
                role: 'cancel', // will always sort to be on the bottom
                icon: !this.platform.is('ios') ? 'close' : null,
                handler: () => {},
              },
            ],
          });
          await actionSheet.present();
        }
      });
  }

  /**
   * Display an alert box if no mandates added else navigate to the next step
   */
  public onEndAddMandates(): void {
    this.mandates$.subscribe(mandates => {
      if (mandates.length) {
        const navigationExtras: NavigationExtras = {
          state: {
            customer: this.customer,
          },
        };

        this.navCtrl
          .navigateForward('/authentication/sign-up/add-policies/congratulation', navigationExtras)
          .catch(err => this.tools.error('navigateForward /authentication/sign-up/congratulation', err));
      } else {
        this.translate.get(['INSURANCE.MANDATE.MUST_ADD_POLICY']).subscribe(async result => {
          const alert = await this.alertCtrl.create({
            subHeader: result['INSURANCE.MANDATE.MUST_ADD_POLICY'],
            buttons: ['OK'],
          });
          await alert.present();
        });
      }
    });
  }

  /**
   * Remove mandate from BO
   * @param mandate mandate object
   */
  private deleteMandate(mandate: Mandate): void {
    this.tools.showLoader();

    // Delete mandate
    this.mandateSrv
      .deleteMandate(mandate.id, this.customer, this.signupProcess)
      .pipe(finalize(() => this.tools.hideLoader()))
      .subscribe(
        () => {
          this.notificationSrv.showSuccess({ message: 'INSURANCE.LIST.POLICY_REMOVED' });
          this.mandateSrv.setUpdateMandates();
        },
        err => {
          this.tools.error('InsuranceListPage deleteMandate()', err);
          this.notificationSrv.showError({ message: err });
        }
      );
  }
}
