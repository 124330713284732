import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

import { ChatItem } from '../../models/contacts.model';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';
import { ConstantService } from '../../../gfl-core/gfl-services/constant.service';

@Component({
  selector: 'gfl-chat-item-user',
  templateUrl: './chat-item-user.component.html',
  styleUrls: ['./chat-item-user.component.scss'],
})
export class ChatItemUserComponent implements OnInit {
  @Input() chatItem: ChatItem;

  constructor(
    private tools: ToolsService,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private constantSrv: ConstantService
  ) {}

  ngOnInit() {}

  /**
   * Follow deeplink from chat item
   * @param item a chat item
   */
  public action(item: ChatItem) {
    const COMMENT_COMMENTABLE_TYPE_POLICY = this.constantSrv.getStringValueFromKey('COMMENT_COMMENTABLE_TYPE_POLICY');

    if (!this.tools.isMobile()) {
      if (item.commentable.type === COMMENT_COMMENTABLE_TYPE_POLICY) {
        this.navCtrl
          .navigateForward('/policies', {
            queryParams: {
              policyId: item.commentable.id,
            },
          })
          .then(() => this.modalCtrl.dismiss());
      } else {
        this.modalCtrl.dismiss().then(() => {
          this.navCtrl.navigateForward(item.commentable.deepLink.path).then();
        });
      }
    } else {
      this.navCtrl.navigateForward(item.commentable.deepLink.path).then();
    }
  }
}
