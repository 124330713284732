import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Acls } from '../../../gfl-core/gfl-models/acls.model';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { PolicyFO } from '../../models/policy.model';
import { NetworkMonitorService } from '../../../gfl-core/gfl-services/network-monitor.service';
import { DocumentService } from '../../../gfl-core/gfl-services/document.service';
import { PolicyService } from '../../services/policy.service';
import { ModalController } from '@ionic/angular';
import { ToolsService } from '../../../gfl-core/gfl-services/tools.service';

@Component({
  selector: 'gfl-policy-cg',
  templateUrl: './policy-cg.component.html',
  styleUrls: ['./policy-cg.component.scss'],
})
export class PolicyCgComponent implements OnInit, OnDestroy {
  @Input() policyId: number;
  @Input() acls: Acls;

  public policy$: Observable<PolicyFO>;
  public isOffline: boolean;
  public subscriptions: Subscription[] = [];
  public errorDisplay: boolean;
  public noDataDisplay: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private network: NetworkMonitorService,
    private documentSrv: DocumentService,
    private policySrv: PolicyService,
    private modalCtrl: ModalController,
    public tools: ToolsService
  ) {
    this.policyId = this.policyId || parseInt(this.activatedRoute.snapshot.paramMap.get('policyId'), 10);
  }

  /**
   * @ignore
   */
  ngOnInit() {
    this.subscriptions.push(
      this.network.isOffline().subscribe(flag => {
        this.isOffline = flag;
      })
    );

    this.policy$ = this.policySrv.getPolicyById(this.policyId);
  }

  /**
   * @ignore
   */
  ngOnDestroy(): void {
    this.tools.unsubscribeAll(this.subscriptions).then(() => {});
  }

  /**
   * Close View Modal
   */
  public closeModal(): void {
    this.modalCtrl.dismiss().then(() => {});
  }

  /**
   * Open document file
   * @param documentId document's id
   */
  public openFile(documentId: number) {
    this.documentSrv.openDocumentFile(documentId);
  }
}
